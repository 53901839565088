import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'

export default function MUITable({ columns, rows, pagination, setPagination, listLength, onClickRow }) {
  const renderCell = (column, row) => {
    if (column.renderCell) {
      return column.renderCell({
        row,
        value: row[column.field],
      })
    }
    return row[column.field]
  }

  const onChangePage = (e, newPage) => {
    setPagination({ ...pagination, page: newPage })
  }

  const onChangePageSize = (e) => {
    setPagination({ ...pagination, pageSize: e.target.value })
  }

  return (
    <TableContainer component={Paper} sx={{ height: '100%' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.field}
                sx={{ width: column.width, flex: column.flex || 1, fontWeight: 700, backgroundColor: '#eee' }}
                component="th"
              >
                {column.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIdx) => (
            <TableRow
              key={rowIdx}
              onClick={onClickRow ? () => onClickRow(row) : () => {}}
              sx={
                onClickRow
                  ? {
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      },
                    }
                  : {}
              }
            >
              {columns.map((column) => (
                <TableCell key={`${rowIdx}-${column.field}`} sx={{ width: column.width, flex: column.flex || 1 }} component="td">
                  {renderCell(column, row)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={listLength}
        page={pagination.page}
        onPageChange={onChangePage}
        rowsPerPage={pagination.pageSize}
        rowsPerPageOptions={[10, 20, 30]}
        onRowsPerPageChange={onChangePageSize}
        sx={{ position: 'sticky', bottom: 0, backgroundColor: '#fff' }}
      />
    </TableContainer>
  )
}
