import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { constants } from '../../Constants/constants';
import { put } from "../../helper/baseFetch";
import { getOne } from "../../helper/baseFetch";
import { setMerchantUserGroup, setMerchantUserGroupValue } from "../../actions/merchantUserGroupAction";
import { helperTextStyles } from "../../helper/util";
import { getContent } from "../../Component/EditCreateDisplay/EditUtil";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { GlobalDialog } from "../../Component/GlobalDialog";
import { RestaurantMapTabContent } from "./RestaurantMapTabContent";
import MerchantUserGroupValidation from "../../validations/merchantUserGroupValidation";
import { merchantUserGroupValidationConstants } from "../../Constants/validationConstants";


export const MerchantUserGroupEdit = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();
  const history = useHistory();
  const helperTextStylesClasses = helperTextStyles();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "MerchantUserGroup";
  const savedMerchantUserGroup = useSelector(state => state.merchantUserGroup.ui.merchantUserGroup);
  // const lang = useSelector(state => state.lang.ui.lang);
  // const theme = useSelector(state => state.theme.ui.theme);
  const [, setErrorVersion] = useState(MerchantUserGroupValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.MERCHANT_USER_GROUP_EDIT_TAB.INFO
    },
    {
      label: { resource: resource, key: "restaurantMap" },
      tabIndex: constants.MERCHANT_USER_GROUP_EDIT_TAB.RESTAURANT_MAP
    },
  ]
  const tempMerchantUserEditList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      variant: constants.STYLE.VARIANT.STANDARD,
      label: { resource: resource, key: "groupCode" },
      value: savedMerchantUserGroup.groupCode,
      name: "groupCode",
      isEndOfLine: true,
      required: false,
      disabled: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "groupDesc" },
      value: savedMerchantUserGroup.groupDesc,
      name: "groupDesc",
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: MerchantUserGroupValidation.getErrorMessages(`${merchantUserGroupValidationConstants.KEY_GROUP_DESCRIPTION}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]
  const content = [
    {
      tab: tempMerchantUserEditList.map((inputSetting, index) => {
        let content = getContent(inputSetting, index, onChangeInput);
        return <div className="edit-display-wrapper" key={index}>
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: true
    },
    {
      tab: <RestaurantMapTabContent/>,
      btn: false
    }
  ]

  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    },
  ]

  // const handleClose = () => {
  //   setDialog({
  //     open: false,
  //     dialogActions: []
  //   });
  // };

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.MERCHANT.MERCHANT_USER_GROUP);
  };

  function onChangeInput(name, e) {
    switch(name){
      default:
        dispatch(setMerchantUserGroupValue(name, savedMerchantUserGroup, "", e));
    }
  }

  function onClickSave() {
    MerchantUserGroupValidation.validateEditMerchantUserGroup(savedMerchantUserGroup);
    if (MerchantUserGroupValidation.isValid()) {
      put(constants.PATH.MERCHANT.MERCHANT_USER_GROUP + "/" + params.id, savedMerchantUserGroup, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      MerchantUserGroupValidation.resetErrorMessages();
    } 
    else {
      setErrorVersion(MerchantUserGroupValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(constants.PATH.MERCHANT.MERCHANT_USER_GROUP + "/" + params.id, undefined, (payload) => {
        dispatch(setMerchantUserGroup(payload));
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
    MerchantUserGroupValidation.resetErrorMessages();
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedMerchantUserGroup && savedMerchantUserGroup.username ? savedMerchantUserGroup.username : ""}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="edit"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};