import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';

import { constants, IMAGE_TYPE, PICTURE_SIZE, UPLOAD_TYPE } from '../../Constants/constants';
import { getOne, put } from "../../helper/baseFetch";
import { setRestaurantMedia, setRestaurantMediaValue } from "../../actions/restaurantMediaAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { UploadImage } from "../../Component/UploadImage/UploadImage";
import { GlobalDialog } from "../../Component/GlobalDialog";
import RestaurantMediaValidation from "../../validations/restaurantMediaValidation";
import { restaurantMediaValidationConstants } from '../../Constants/validationConstants';
import useRestaurant from "../../hooks/Restaurant/useRestaurant";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const RestaurantMediaEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const store = useStore();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "RestaurantMedia";
  const savedRestaurantMedia = useSelector(state => state.restaurantMedia.ui.restaurantMedia);
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(RestaurantMediaValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.RESTAURANT_MEDIA_TAB.INFO
    },
  ]
  const tempRestaurantMediaEditList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "type" },
      value: savedRestaurantMedia.type,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESTAURANT_MEDIA.TYPE,
      name: "type",
      disableClearable: true,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: RestaurantMediaValidation.getErrorMessages(restaurantMediaValidationConstants.KEY_TYPE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "displaySequence" },
      value: savedRestaurantMedia.displaySequence,
      name: "displaySequence",
      type: constants.TYPE.NUMBER,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: RestaurantMediaValidation.getErrorMessages(restaurantMediaValidationConstants.KEY_DISPLAY_SEQUENCE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "status" },
      value: savedRestaurantMedia.status,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESTAURANT_MEDIA.STATUS,
      disableClearable: true,
      name: "status",
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: RestaurantMediaValidation.getErrorMessages(restaurantMediaValidationConstants.KEY_STATUS),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "fileType" },
      value: savedRestaurantMedia.fileType,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESTAURANT_MEDIA.FILE_TYPE,
      name: "fileType",
      isEndOfLine: true,
      disableClearable: true,
      disabled: true,
      required: true,
      helperTextIcon: true,
      helperText: RestaurantMediaValidation.getErrorMessages(restaurantMediaValidationConstants.KEY_FILE_TYPE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      picture: savedRestaurantMedia.cosFileEnUrl ? { cosFileUrl: savedRestaurantMedia.cosFileEnUrl } : savedRestaurantMedia.cosFileEnUrl,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enImage" },
      name: "enImage",
      type: constants.TYPE.IMAGE,
      uploadImagePath: constants.PATH.SHOP.UPLOAD_RESTAURANT_MEDIA,
      saveImage: handleSaveImage,
      isEndOfLine: false,
      required: true,
    },
    {
      picture: savedRestaurantMedia.cosFileTcUrl ? { cosFileUrl: savedRestaurantMedia.cosFileTcUrl } : savedRestaurantMedia.cosFileTcUrl,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcImage" },
      name: "tcImage",
      type: constants.TYPE.IMAGE,
      uploadImagePath: constants.PATH.SHOP.UPLOAD_RESTAURANT_MEDIA,
      saveImage: handleSaveImage,
      isEndOfLine: false,
      required: true,
    },
    {
      picture: savedRestaurantMedia.cosFileScUrl ? { cosFileUrl: savedRestaurantMedia.cosFileScUrl } : savedRestaurantMedia.cosFileScUrl,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scImage" },
      name: "scImage",
      type: constants.TYPE.IMAGE,
      uploadImagePath: constants.PATH.SHOP.UPLOAD_RESTAURANT_MEDIA,
      saveImage: handleSaveImage,
      isEndOfLine: false,
      required: true,
    },
  ]
  const content = [
    {
      tab: <>
        {RestaurantMediaValidation.getErrorMessages(restaurantMediaValidationConstants.KEY_MEDIA_KEY) &&
          RestaurantMediaValidation.getErrorMessages(restaurantMediaValidationConstants.KEY_MEDIA_KEY).length > 0 &&
          <div className="error-message">
            <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
            {RestaurantMediaValidation.getErrorMessages(restaurantMediaValidationConstants.KEY_MEDIA_KEY)}
          </div>
        }
        {tempRestaurantMediaEditList.map((inputSetting, index) => {
          let content;
          switch (inputSetting.type) {
            case constants.TYPE.IMAGE:
              content =
                <div className="image">
                  <UploadImage
                    picture={inputSetting.picture}
                    title={inputSetting.label}
                    name={inputSetting.name}
                    uploadImagePath={inputSetting.uploadImagePath}
                    saveImage={inputSetting.saveImage}
                    uploadType={UPLOAD_TYPE.FORMDATA}
                    required={inputSetting.required} 
                    type={IMAGE_TYPE.MEDIA}
                    showApplyToAll={true}
                    size = {PICTURE_SIZE.MID}/>
                </div>
              break;
            case constants.TYPE.SELECT:
              content =
                <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
              break;
            default:
              content =
                <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
          }
          return <div className="edit-display-wrapper" key={index}>
            {inputSetting.name === "enImage" &&
              (
                RestaurantMediaValidation.getErrorMessages(restaurantMediaValidationConstants.KEY_COS_FILE_TC_URL) 
                ||RestaurantMediaValidation.getErrorMessages(restaurantMediaValidationConstants.KEY_COS_FILE_TC_KEY)
              ) &&
              <div className="error-message">
                <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                {RestaurantMediaValidation.getErrorMessages(restaurantMediaValidationConstants.KEY_COS_FILE_TC_URL)}<br/>
                <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                {RestaurantMediaValidation.getErrorMessages(restaurantMediaValidationConstants.KEY_COS_FILE_TC_KEY)}
              </div>
            }
            {inputSetting.name === "enImage" &&
              (
                RestaurantMediaValidation.getErrorMessages(restaurantMediaValidationConstants.KEY_COS_FILE_EN_URL) 
                ||RestaurantMediaValidation.getErrorMessages(restaurantMediaValidationConstants.KEY_COS_FILE_EN_KEY)
              ) &&
              <div className="error-message">
                <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                {RestaurantMediaValidation.getErrorMessages(restaurantMediaValidationConstants.KEY_COS_FILE_EN_URL)}<br/>
                <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                {RestaurantMediaValidation.getErrorMessages(restaurantMediaValidationConstants.KEY_COS_FILE_EN_KEY)}
              </div>
            }
            {inputSetting.name === "enImage" &&
            (
              RestaurantMediaValidation.getErrorMessages(restaurantMediaValidationConstants.KEY_COS_FILE_SC_URL) 
              ||RestaurantMediaValidation.getErrorMessages(restaurantMediaValidationConstants.KEY_COS_FILE_SC_KEY)
            )&&
              <div className="error-message">
                <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                {RestaurantMediaValidation.getErrorMessages(restaurantMediaValidationConstants.KEY_COS_FILE_SC_URL)}<br/>
                <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                {RestaurantMediaValidation.getErrorMessages(restaurantMediaValidationConstants.KEY_COS_FILE_SC_KEY)}
              </div>
            }
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        })}
      </>,
      btn: true
    },
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  function handleSaveImage(name, image, index, applyToAll) {

    const tempMedia = _.cloneDeep(savedRestaurantMedia);

    if(applyToAll){

      tempMedia.cosFileEnKey = image.cosFileKey;
      tempMedia.cosFileEnUrl = image.cosFileUrl;

      tempMedia.cosFileTcKey = image.cosFileKey;
      tempMedia.cosFileTcUrl = image.cosFileUrl;

      tempMedia.cosFileScKey = image.cosFileKey;
      tempMedia.cosFileScUrl = image.cosFileUrl;

      dispatch(setRestaurantMedia(tempMedia));
      return;
    }

    if (name === "tcImage") {
      dispatch(setRestaurantMediaValue("TC_IMAGE", savedRestaurantMedia, "", image));
    } else if (name === "enImage") {
      dispatch(setRestaurantMediaValue("EN_IMAGE", savedRestaurantMedia, "", image));
    } else if (name === "scImage") {
      dispatch(setRestaurantMediaValue("SC_IMAGE", savedRestaurantMedia, "", image));
    }
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + "/" + savedRestaurantMedia.mediaKey + "/" + constants.RESTAURANT_TAB.MEDIA + "/" + constants.RESTAURANT_MEDIA_SUB_TAB.MEDIA);
  };

  function onChangeInput(name, e) {
    switch (name) {
      case "displaySequence":
        dispatch(setRestaurantMediaValue(name, savedRestaurantMedia, "", Number.isInteger(Number(e)) ? Number(e) : 0));
        break;
      default:
        dispatch(setRestaurantMediaValue(name, savedRestaurantMedia, "", e));
    }
  }

  function onClickSave() {
    let tempSavedRestaurantMedia = _.cloneDeep(savedRestaurantMedia);
    RestaurantMediaValidation.validateEditRestaurantMedia(tempSavedRestaurantMedia);
    if (RestaurantMediaValidation.isValid()) {
      put(constants.PATH.SHOP.RESTAURANT_MEDIA + "/" + params.id, tempSavedRestaurantMedia, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      RestaurantMediaValidation.resetErrorMessages();
    } else {
      setErrorVersion(RestaurantMediaValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(constants.PATH.SHOP.RESTAURANT_MEDIA + "/" + params.id, undefined, (payload) => {
        dispatch(setRestaurantMedia(payload));
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
    RestaurantMediaValidation.resetErrorMessages();
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  
  const { getRestaurantIdAndName } = useRestaurant(savedRestaurantMedia.mediaKey)
  const mapCode = getRestaurantIdAndName()

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedRestaurantMedia && savedRestaurantMedia.restaurantMediaCode ? savedRestaurantMedia.restaurantMediaCode : ""}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        mapName={{ resource: "restaurant", key: "restUrlId" }}
        // mapCode={savedRestaurantMedia.mediaKey}
        mapCode={mapCode}
        display="edit"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};