import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { getList, post } from "../../helper/baseFetch";
import { setDistrict, setDistrictValue } from "../../actions/districtAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { TempCustomCheckBox } from "../../Component/Custom/TempCustomCheckBox";
import { GlobalDialog } from "../../Component/GlobalDialog";
import DistrictValidation from "../../validations/districtValidation";
import { districtValidationConstants } from '../../Constants/validationConstants';

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const DistrictCreate = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const [clear, setClear] = useState(false);
  const [ready, setReady] = useState(false);
  const [areaList, setAreaList] = useState([]);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "RestaurantDistrict";
  const savedDistrict = useSelector(state => state.district.ui.district)
  const [districtInputList, setDistrictInputList] = useState(_.cloneDeep(constants.DISTRICT_INPUT));
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(DistrictValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.DISTRICT_TAB.INFO
    },
  ]
  const tempDistrictCreateList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enName" },
      value: savedDistrict.enName,
      name: "enName",
      custom: true,
      customName: { key: "nameCustomize" },
      customValue: savedDistrict.scNameDisable,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: DistrictValidation.getErrorMessages(districtValidationConstants.KEY_EN_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcName" },
      value: savedDistrict.tcName,
      name: "tcName",
      translateName: savedDistrict.scNameDisable ? ["scName"] : null,
      inputList: districtInputList,
      setInputList: setDistrictInputList,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: DistrictValidation.getErrorMessages(districtValidationConstants.KEY_TC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scName" },
      value: savedDistrict.scName,
      name: "scName",
      inputList: districtInputList,
      setInputList: setDistrictInputList,
      isEndOfLine: true,
      disabled: savedDistrict.scNameDisable,
      required: true,
      helperTextIcon: true,
      helperText: DistrictValidation.getErrorMessages(districtValidationConstants.KEY_SC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "area" },
      value: savedDistrict.areaId,
      type: constants.TYPE.SELECT,
      selectValue: areaList,
      name: "areaId",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: DistrictValidation.getErrorMessages(districtValidationConstants.KEY_AREA_ID),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "districtLevel" },
      value: savedDistrict.districtLevel,
      type: constants.TYPE.NUMBER,
      min: 0,
      name: "districtLevel",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: DistrictValidation.getErrorMessages(districtValidationConstants.KEY_DISTRICT_LEVEL),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "menuChiOrder" },
      value: savedDistrict.menuChiOrder,
      type: constants.TYPE.NUMBER,
      min: 0,
      name: "menuChiOrder",
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: DistrictValidation.getErrorMessages(districtValidationConstants.KEY_MENU_CHI_ORDER),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "latitude" },
      value: savedDistrict.latitude,
      type: constants.TYPE.NUMBER,
      name: "latitude",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: DistrictValidation.getErrorMessages(districtValidationConstants.KEY_LATITUDE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "longitude" },
      value: savedDistrict.longitude,
      type: constants.TYPE.NUMBER,
      name: "longitude",
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: DistrictValidation.getErrorMessages(districtValidationConstants.KEY_LONGITUDE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]
  const content = [
    {
      tab: tempDistrictCreateList.map((inputSetting, index) => {
        let content;
        switch (inputSetting.type) {
          case constants.TYPE.SELECT:
            content =
              <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break;
          default:
            content =
              <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
        }
        return <div className="edit-display-wrapper" key={index}>
          {inputSetting.custom && <TempCustomCheckBox className="customize-btn" value={inputSetting.customValue} customName={inputSetting.customName} onSelectCheckbox={onSelectCheckbox} />}
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: true
    },
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  function onSelectCheckbox(value) {
    dispatch(setDistrictValue("scNameDisable", savedDistrict, "", value));
  }

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.RESTAURANT_DISTRICT_WRAPPER);
  };

  function onChangeInput(name, e) {
    if (savedDistrict.scNameDisable === true && name === "tcName") {
      dispatch(setDistrictValue("CHANGE_TC_NAME_AND_SC_NAME", savedDistrict, "", e));
    } else if (name === "districtLevel" || name === "menuChiOrder") {
      dispatch(setDistrictValue(name, savedDistrict, "", Number.isInteger(Number(e)) ? Number(e) : 0));
    } else if (name === "latitude" || name === "longitude") {
      if (e) {
        dispatch(setDistrictValue(name, savedDistrict, "", Number(e)));
      } else {
        dispatch(setDistrictValue(name, savedDistrict, "", e));
      }
    } else {
      dispatch(setDistrictValue(name, savedDistrict, "", e));
    }
  }

  function onClickSave() {
    DistrictValidation.validateCreateDistrict(savedDistrict);
    if (DistrictValidation.isValid()) {
      post(constants.PATH.SHOP.RESTAURANT_DISTRICT, savedDistrict, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: "createSuccess" },
          dialogActions: [
            { label: { key: "backCreate" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      DistrictValidation.resetErrorMessages();
    } else {
      setErrorVersion(DistrictValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    dispatch(setDistrict(constants.DISTRICT_INPUT));
    setClear(false)
    DistrictValidation.resetErrorMessages();
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getList(constants.PATH.SHOP.RESTAURANT_AREA_OPTION, undefined, (payload) => {
      payload = payload.map((item) => {
        return { name: item.tcName, value: item.areaId }
      })
      setAreaList(payload);
      setReady(true)
    }, undefined, store);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <EditCreateDisplay
        code=""
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="create"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};