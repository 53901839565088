import _ from 'lodash'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { constants as co } from '../../Constants/constants'
import { GlobalDialog } from '../../Component/GlobalDialog'

import { getOne, put } from '../../helper/baseFetch'
import store from '../../store'

import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'

import { TempCustomCheckBox } from '../../Component/Custom/TempCustomCheckBox'
import { sify } from 'chinese-conv'

import QueueProfileValidation from '../../validations/queueProfileValidation'
import QueueTransferProfileValidation from '../../validations/queueTransferProfileValidation'

import { queueProfileValidationConstants, queueTransferProfileValidationConstants } from '../../Constants/validationConstants'

// eslint-disable-next-line no-unused-vars
import { setQueueProfile, setQueueProfileDefault, setQueueProfileValue } from '../../actions/queueProfileAction'
import { setQueueTransferProfile, setQueueTransferProfileDefault, setQueueTransferProfileValue } from '../../actions/queueTransferProfileAction'
import GenericGetRefTemplateCode from './GenericGetRefTemplateCode'
import { mergePayloadSubmitTimeSectionList, mergeSubmitTimeSectionList } from '../../helper/GenericQueueProfile/mergeListUtil'
import GenericQueueProfileTimeSectionList from './GenericQueueProfileTimeSectionList'
import { ArrowBack, Clear, LowPriority, Save } from '@mui/icons-material'
import useRestaurant from '../../hooks/Restaurant/useRestaurant'

export default function GenericQueueProfileEdit({ resource }) {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const [clear, setClear] = useState(true)
  // eslint-disable-next-line no-unused-vars
  const [errorVersion, setErrorVersion] = useState(false)
  const [ready, setReady] = useState(false)
  const [dialogInfo, setDialogInfo] = useState({
    open: false,
  })
  const { restUrlId, id: profileId } = params

  const { getRestaurantName } = useRestaurant(restUrlId)
  const restName = getRestaurantName()

  let isQueueTransferProfile = resource === 'QueueTransferProfile'

  const savedData = useSelector((state) => {
    if (isQueueTransferProfile) {
      return state.queueTransferProfile.ui.queueTransferProfile
    } else {
      return state.queueProfile.ui.queueProfile
    }
  })

  let validation = isQueueTransferProfile ? QueueTransferProfileValidation : QueueProfileValidation,
    validationConstants = isQueueTransferProfile ? queueTransferProfileValidationConstants : queueProfileValidationConstants,
    setDispatchDefault = isQueueTransferProfile ? () => dispatch(setQueueTransferProfileDefault()) : () => dispatch(setQueueProfileDefault()),
    setDispatchValue = isQueueTransferProfile
      ? (fieldName, originalObj, key, value) => dispatch(setQueueTransferProfileValue(fieldName, originalObj, key, value))
      : (fieldName, originalObj, key, value) => dispatch(setQueueProfileValue(fieldName, originalObj, key, value)),
    setDispatch = isQueueTransferProfile ? (profile) => dispatch(setQueueTransferProfile(profile)) : (profile) => dispatch(setQueueProfile(profile)),
    apiPath = isQueueTransferProfile ? co.PATH.MERCHANT.QUEUE_TRANSFER_PROFILE + '/' + profileId : co.PATH.MERCHANT.QUEUE_PROFILE + '/' + profileId

  const baseListPath = '/' + co.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + '/' + restUrlId + '/' + co.RESTAURANT_TAB.MERCHANT + '/'

  let listPath = isQueueTransferProfile
    ? baseListPath + co.RESTAURANT_MERCHANT_SUB_TAB.QUEUE_TRANSFER_PROFILE
    : baseListPath + co.RESTAURANT_MERCHANT_SUB_TAB.QUEUE_PROFILE

  const onCloseDialog = () => {
    setDialogInfo({
      open: false,
    })
  }

  const onChangeInput = (name, e) => {
    setDispatchValue(name, savedData, '', e)
  }

  const onChangeScNameDisable = (value) => {
    setDispatchValue('scNameDisable', savedData, '', value)
  }

  const onRedirectToCreateEdit = () => {
    onCloseDialog()
    onClickClear()
  }

  const onRedirectToList = () => {
    setDialogInfo({
      open: false,
    })
    history.push(listPath)
  }

  const onClickSave = () => {
    validation.validateProfile(savedData)
    if (validation.isValid()) {
      const submitList = _.cloneDeep(savedData.submitTimeSectionList)

      put(
        apiPath,
        {
          enName: savedData.enName,
          tcName: savedData.tcName,
          scName: savedData.scName,
          refTemplateCode: savedData.refTemplateCode,
          status: savedData.status,
          timeSectionList: mergeSubmitTimeSectionList(submitList, restUrlId),
        },
        () => {
          setDialogInfo({
            open: true,
            onClose: onRedirectToCreateEdit,
            dialogContentText: { key: 'editSuccess' },
            maxWidth: 'md',
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onRedirectToCreateEdit, icon: <ArrowBack /> },
              {
                label: { key: 'backList' },
                onClick: onRedirectToList,
                icon: <LowPriority />,
              },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
      validation.resetErrorMessages()
    } else {
      setErrorVersion(validation.getVersion())
    }
  }

  const onClickClear = () => {
    setClear(true)
  }

  const content = [
    {
      tab: (
        <>
          <div className="edit-display-wrapper">
            <div>
              <GenericGetRefTemplateCode resource={resource} createEditType={'edit'} />
            </div>
            {savedData.refTemplateCode && (
              <TempCustomCheckBox
                className="customize-btn"
                value={savedData.scNameDisable}
                customName={{ resource: resource, key: 'nameCustomize' }}
                onSelectCheckbox={onChangeScNameDisable}
              />
            )}
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'enName' },
                value: savedData.enName,
                name: 'enName',
                helperText: validation.getErrorMessages(validationConstants.KEY_EN_NAME),
                disabled: !savedData.refTemplateCode,
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'tcName' },
                value: savedData.tcName,
                name: 'tcName',
                helperText: validation.getErrorMessages(validationConstants.KEY_TC_NAME),
                disabled: !savedData.refTemplateCode,
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'scName' },
                value: savedData.scName,
                name: 'scName',
                helperText: validation.getErrorMessages(validationConstants.KEY_SC_NAME),
                disabled: !savedData.refTemplateCode ? true : savedData.scNameDisable,
              }}
              handleChange={onChangeInput}
            />
            <TempAutoSelectInput
              inputSetting={{
                label: { resource: resource, key: 'status' },
                value: savedData.status,
                selectValue: co.CHOICES.QUEUE_PROFILE.STATUS,
                name: 'status',
                disableClearable: true,
                helperText: validation.getErrorMessages(validationConstants.KEY_STATUS),
                disabled: !savedData.refTemplateCode,
              }}
              handleChange={onChangeInput}
            />
            <GenericQueueProfileTimeSectionList resource={resource} />
          </div>
        </>
      ),
      btn: true,
    },
  ]

  useEffect(() => {
    getOne(
      apiPath,
      undefined,
      (payload) => {
        setDispatch({
          enName: payload.enName,
          tcName: payload.tcName,
          scName: payload.scName,
          status: payload.status,
          refTemplateCode: payload.refTemplateCode,
          refTemplateTimeSectionList: payload.refTemplateTimeSectionList,
          timeSectionList: payload.timeSectionList,
          profileCode: payload.profileCode,
          scNameDisable: sify(payload.tcName) === payload.scName,
          submitTimeSectionList: mergePayloadSubmitTimeSectionList(savedData),
          tableTypeColorConfigList: payload.tableTypeColorConfigList,
          defaultTableTypeColorConfigList: payload.defaultTableTypeColorConfigList,
        })
        setClear(false)
        setReady(true)
      },
      undefined,
      store
    )
    validation.resetErrorMessages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear === true])

  useEffect(() => {
    setDispatchDefault()
    validation.resetErrorMessages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    ready && (
      <>
        <EditCreateDisplay
          resource={resource}
          content={content}
          mapName={{ resource: 'restaurant', key: 'restUrlId' }}
          mapCode={`${restUrlId} - ${restName}`}
          display="edit"
          code={savedData.profileCode}
          bottomBtns={[
            {
              label: { key: 'save' },
              disabled: !savedData.refTemplateCode,
              onClick: onClickSave,
              className: 'margin-left-16',
              icon: <Save />,
            },
            {
              label: { key: 'clear' },
              disabled: !savedData.refTemplateCode,
              onClick: onClickClear,
              className: 'margin-left-16',
              icon: <Clear />,
            },
          ]}
        />
        {dialogInfo.open && <GlobalDialog dialogInfo={dialogInfo} />}
      </>
    )
  )
}
