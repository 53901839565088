import { Box } from '@mui/material'

export default function ThreeItemsGridContainer({ children }) {
  return (
    <Box
      sx={{
        gridColumn: '1 / -1',
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        columnGap: 1,
        rowGap: 4,
        width: '100%',
        py: 1
      }}
    >
      {children}
    </Box>
  )
}
