import _ from 'lodash'

const INITIAL_STATE = {
  regionList: [],
  cityList: [],
}

const regionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_REGION_LIST':
      return { ...state, regionList: action }
    case 'SET_CITY_LIST':
      return { ...state, cityList: action }
    default:
      return state
  }
}

export default regionReducer
