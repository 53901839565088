import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { v4 as uuidv4 } from 'uuid'

import { constants, LANDING_ACTION_PARAMETER, LANDING_SECTION_SECTION_TYPE, STATUS } from '../../../Constants/constants'
import { EditCreateDisplay } from '../../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../../Component/Input/TempTextInput'
import { TempAutoSelectInput } from '../../../Component/Input/TempAutoSelectInput'
import { TempBtn } from '../../../Component/Input/TempBtn'
import { TempCustomCheckBox } from '../../../Component/Custom/TempCustomCheckBox'
import { LandingItemEdit } from './LandingItemEdit'
import { setLoading } from '../../../actions/systemAction'
import { translateName, helperTextStyles, getDisplayActionType, isDeepLinkApplicable } from './landingUtil'
import { LandingItem } from './LandingItem'
import { LandingHeader } from './LandingHeader'
import landingSectionValidation from '../../../validations/landingSectionValidation'
// import landingItemValidation from "../../../validations/landingItemValidation";
import { landingSectionValidationConstants } from '../../../Constants/validationConstants'
import { LandingAction } from './LandingAction'
import { QueueTemplateColorPicker } from '../../QueueTemplate/QueueTemplateColorPicker'
import { post } from '../../../helper/baseFetch'
import { GlobalDialog } from '../../../Component/GlobalDialog'
import { handleLandingActionParameters } from './handleLandingActionParameters'
import '../../../styles/landingSection.scss'
import { Card } from '@mui/material'
import { Add, ArrowBack, Cancel, Clear, Delete, LowPriority, Save, SwapHoriz } from '@mui/icons-material'
import moment from 'moment'

export const SectionCreate = ({ item, setItem, setSectionEdit, setErrorVersion, onClickSave, onClickClear, sequenceSelectList = [], createMode }) => {
  const helperTextStylesClasses = helperTextStyles()
  const resource = 'LandingSection'
  const id = item.id || item.customId

  const onSelectCheckbox = () => {
    const tempt = _.cloneDeep(item)
    tempt.scNameDisable = !tempt.scNameDisable
    if (tempt.scNameDisable) tempt.scTitle = tempt.tcTitle ? translateName(tempt.tcTitle) : tempt.tcTitle
    setItem(tempt)
  }

  const getItemType = (list) => {
    if (!list || list.length <= 0) {
      return null
    }

    return list[0].itemType
  }

  const fields = [
    {
      label: { resource: resource, key: 'status' },
      value: item?.status,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.LANDING_SECTION.STATUS,
      name: 'status',
      disableClearable: true,
      isEndOfLine: createMode ? false : true,
      helperTextIcon: true,
      helperText: landingSectionValidation.getErrorMessages(`${landingSectionValidationConstants.KEY_STATUS}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    createMode
      ? {
          label: { resource: resource, key: 'sequenceAfter' },
          type: constants.TYPE.SELECT,
          value: item?.sequence,
          selectValue: sequenceSelectList,
          name: 'sequence',
          isEndOfLine: true,
          helperTextIcon: true,
          helperText: landingSectionValidation.getErrorMessages(`${landingSectionValidationConstants.KEY_SEQUENCE}`),
          FormHelperTextProps: { className: helperTextStylesClasses.root },
        }
      : null,
    {
      label: { resource: resource, key: 'channel' },
      value: item?.channel,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.LANDING_SECTION.CHANNEL,
      name: 'channel',
      disableClearable: true,
      helperTextIcon: true,
      helperText: landingSectionValidation.getErrorMessages(`${landingSectionValidationConstants.KEY_CHANNEL}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
      isEndOfLine: true,
    },
    {
      label: { resource: resource, key: 'serviceType' },
      value: item?.serviceType,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.LANDING_SECTION.LANDING_SECTION_SERVICE_TYPE,
      name: 'serviceType_section',
      isEndOfLine: true,
    },
    {
      label: { resource: resource, key: 'itemType' },
      value: getItemType(item.itemList) || item?.itemType,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.LANDING_SECTION.LANDING_ITEM_ITEM_TYPE,
      name: 'itemType',
      helperTextIcon: true,
      helperText: landingSectionValidation.getErrorMessages(`${landingSectionValidationConstants.KEY_ITEM_TYPE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
      isEndOfLine: true,
    },
    {
      label: { resource: resource, key: 'mainSection' },
      value: item?.mainSection,
      name: 'mainSection',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.LANDING_SECTION.MAIN_SECTION,
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'sectionType' },
      value: item?.sectionType,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.LANDING_SECTION.LANDING_SECTION_SECTION_TYPE,
      disableClearable: true,
      name: 'sectionType',
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: landingSectionValidation.getErrorMessages(`${landingSectionValidationConstants.KEY_SECTION_TYPE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      label: { resource: resource, key: 'enTitle' },
      value: item?.enTitle,
      name: 'enTitle',
      isEndOfLine: false,
      custom: true,
      customName: { key: 'nameCustomize' },
      customValue: item.scNameDisable,
      helperTextIcon: true,
      helperText: landingSectionValidation.getErrorMessages(`${landingSectionValidationConstants.KEY_EN_TITLE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      label: { resource: resource, key: 'tcTitle' },
      value: item?.tcTitle,
      name: 'tcTitle',
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: landingSectionValidation.getErrorMessages(`${landingSectionValidationConstants.KEY_TC_TITLE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      label: { resource: resource, key: 'scTitle' },
      value: item?.scTitle,
      name: 'scTitle',
      disabled: item?.scNameDisable,
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: landingSectionValidation.getErrorMessages(`${landingSectionValidationConstants.KEY_SC_TITLE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      label: { resource: resource, key: 'titleColor' },
      value: item?.titleColor,
      name: 'titleColor',
      onClickEndAdornmentIconButton: () => {},
      endAdornmentIconButton: <QueueTemplateColorPicker color={item?.titleColor} setColor={(color) => onChangeInput('titleColor', color)} />,
      helperTextIcon: true,
      helperText: landingSectionValidation.getErrorMessages(`${landingSectionValidationConstants.KEY_TITLE_COLOR}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
      isEndOfLine: true,
    },
    {
      label: { resource: resource, key: 'startDisplayTimestamp' },
      value: item?.startDisplayTimestamp,
      type: constants.TYPE.DATETIME_LOCAL,
      step: 1,
      name: 'startDisplayTimestamp',
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: landingSectionValidation.getErrorMessages(`${landingSectionValidationConstants.KEY_START_DISPLAY_TIMESTAMP}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      label: { resource: resource, key: 'endDisplayTimestamp' },
      value: item?.endDisplayTimestamp,
      type: constants.TYPE.DATETIME_LOCAL,
      step: 1,
      name: 'endDisplayTimestamp',
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: landingSectionValidation.getErrorMessages(`${landingSectionValidationConstants.KEY_END_DISPLAY_TIMESTAMP}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      type: constants.TYPE.FUNCTION,
      value: (
        <LandingAction
          actionSelectList={constants.CHOICES.LANDING_SECTION.ACTION_TYPE}
          resource={resource}
          tempItem={item}
          onChangeInput={onChangeInput}
          validation={landingSectionValidation}
          id={id}
        />
      ),
      isEndOfLine: true,
    },
  ].filter((item) => item !== null)

  const content = [
    {
      tab: fields.map((inputSetting, index) => {
        let content = <></>
        switch (inputSetting.type) {
          case constants.TYPE.SELECT:
            content = <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break
          case constants.TYPE.FUNCTION:
            content = inputSetting.value
            break
          default:
            content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
        }
        return (
          <div className={inputSetting.backgroundColorClass ? 'image-card-background card-border' : 'edit-display-wrapper'} key={index}>
            {inputSetting.custom && (
              <TempCustomCheckBox
                className="customize-btn"
                value={inputSetting.customValue}
                customName={inputSetting.customName}
                onSelectCheckbox={onSelectCheckbox}
              />
            )}
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        )
      }),
      btn: true,
    },
  ]

  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <Save />,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <Clear />,
    },
  ]

  function onChangeInput(name, e) {
    let tempt = _.cloneDeep(item)

    if (!tempt.parameters) tempt.parameters = {}

    switch (name) {
      case 'url':
      case 'type':
      case 'title':
      case 'query':
      case 'restUrlId':
      case 'folderCode':
      case 'folderType':
      case 'from':
      case 'categoryCode':
      case 'brandCode':
      case 'name':
      case 'refId':
      case 'code':
      case 'ticketId':
      case 'infoId':
      case 'groupCode':
      case 'youtubeCode':
        tempt.parameters[`${name}`] = e
        Object.keys(tempt.parameters).forEach((k) => (tempt.parameters[k] === null || tempt.parameters[k] === '') && delete tempt.parameters[k])
        // tempt.actionParameters = JSON.stringify(tempt.parameters)
        tempt.action = LANDING_ACTION_PARAMETER.WEB_VIEW
        tempt.actionParameters = handleLandingActionParameters(
          {
            url: tempt.parameters.url,
            action: tempt.parameters.action,
            restUrlId: tempt.parameters.restUrlId,
            ecouponCode: tempt.parameters.ecouponCode,
            folderCode: tempt.parameters.folderCode,
            productParent: tempt.parameters.productParent,
            categoryCode: tempt.parameters.categoryCode,
            brandCode: tempt.parameters.brandCode,
            catProductCode: tempt.parameters.productCode,
            brandProductCode: tempt.parameters.productCode,
            folderType: tempt.parameters.folderType,
            title: tempt.parameters.title,
            type: tempt.parameters.type,
            name: tempt.parameters.name,
            query: tempt.parameters.query,
            from: tempt.parameters.from,
            refId: tempt.parameters.refId,
            code: tempt.parameters.code,
            ticketId: tempt.parameters.ticketId,
            infoId: tempt.parameters.infoId,
            groupCode: tempt.parameters.groupCode,
            showCategoryList: tempt.parameters.showCategoryList,
            showBrandList: tempt.parameters.showBrandList,
            videoId: tempt.parameters.youtubeCode,
          },
          tempt
        )
        break
      case 'urlAction':
        tempt.parameters.action = e
        Object.keys(tempt.parameters).forEach((k) => (tempt.parameters[k] === null || tempt.parameters[k] === '') && delete tempt.parameters[k])
        // tempt.actionParameters = JSON.stringify(tempt.parameters)
        tempt.action = LANDING_ACTION_PARAMETER.WEB_VIEW
        tempt.actionParameters = handleLandingActionParameters(
          {
            url: tempt.parameters.url,
            action: tempt.parameters.action,
            restUrlId: tempt.parameters.restUrlId,
            ecouponCode: tempt.parameters.ecouponCode,
            folderCode: tempt.parameters.folderCode,
            productParent: tempt.parameters.productParent,
            categoryCode: tempt.parameters.categoryCode,
            brandCode: tempt.parameters.brandCode,
            catProductCode: tempt.parameters.productCode,
            brandProductCode: tempt.parameters.productCode,
            folderType: tempt.parameters.folderType,
            title: tempt.parameters.title,
            type: tempt.parameters.type,
            name: tempt.parameters.name,
            query: tempt.parameters.query,
            from: tempt.parameters.from,
            refId: tempt.parameters.refId,
            code: tempt.parameters.code,
            ticketId: tempt.parameters.ticketId,
            infoId: tempt.parameters.infoId,
            groupCode: tempt.parameters.groupCode,
            showCategoryList: tempt.parameters.showCategoryList,
            showBrandList: tempt.parameters.showBrandList,
            videoId: tempt.parameters.youtubeCode,
          },
          tempt
        )
        break
      case 'productCode':
      case 'ecouponCode':
        tempt.parameters[`${name}`] = e
        tempt.parameters.brandCode = null
        tempt.parameters.categoryCode = null
        Object.keys(tempt.parameters).forEach((k) => (tempt.parameters[k] === null || tempt.parameters[k] === '') && delete tempt.parameters[k])
        // tempt.actionParameters = JSON.stringify(tempt.parameters)
        tempt.action = LANDING_ACTION_PARAMETER.WEB_VIEW
        tempt.actionParameters = handleLandingActionParameters(
          {
            url: tempt.parameters.url,
            action: tempt.parameters.action,
            restUrlId: tempt.parameters.restUrlId,
            ecouponCode: tempt.parameters.ecouponCode,
            folderCode: tempt.parameters.folderCode,
            productParent: tempt.parameters.productParent,
            categoryCode: tempt.parameters.categoryCode,
            brandCode: tempt.parameters.brandCode,
            catProductCode: tempt.parameters.productCode,
            brandProductCode: tempt.parameters.productCode,
            folderType: tempt.parameters.folderType,
            title: tempt.parameters.title,
            type: tempt.parameters.type,
            name: tempt.parameters.name,
            query: tempt.parameters.query,
            from: tempt.parameters.from,
            refId: tempt.parameters.refId,
            code: tempt.parameters.code,
            ticketId: tempt.parameters.ticketId,
            infoId: tempt.parameters.infoId,
            groupCode: tempt.parameters.groupCode,
            showCategoryList: tempt.parameters.showCategoryList,
            showBrandList: tempt.parameters.showBrandList,
            videoId: tempt.parameters.youtubeCode,
          },
          tempt
        )
        break
      case 'sequence':
        tempt[name] = Number.isInteger(Number(e)) ? Number(e) : 0
        break
      case 'serviceType':
        tempt.parameters[`${name}`] = e
        if (!e) tempt.parameters.type = null
        Object.keys(tempt.parameters).forEach((k) => (tempt.parameters[k] === null || tempt.parameters[k] === '') && delete tempt.parameters[k])
        tempt.actionParameters = JSON.stringify(tempt.parameters)
        tempt.displayActionType = getDisplayActionType(JSON.parse(tempt.actionParameters))
        break
      case 'subAction':
        tempt.parameters.action = e
        Object.keys(tempt.parameters).forEach((k) => (tempt.parameters[k] === null || tempt.parameters[k] === '') && delete tempt.parameters[k])
        tempt.actionParameters = JSON.stringify(tempt.parameters)
        tempt.displayActionType = getDisplayActionType(JSON.parse(tempt.actionParameters))
        break
      case 'showCategoryBrandList':
        if (e === 'SHOW_CATEGORY_LIST') {
          tempt.parameters.showCategoryList = true
          tempt.parameters.showBrandList = null
          tempt.parameters.brandCode = null
        } else if (e === 'SHOW_BRAND_LIST') {
          tempt.parameters.showBrandList = true
          tempt.parameters.showCategoryList = null
          tempt.parameters.categoryCode = null
        } else {
          tempt.parameters.showBrandList = null
          tempt.parameters.showCategoryList = null
          tempt.parameters.brandCode = null
          tempt.parameters.categoryCode = null
        }
        Object.keys(tempt.parameters).forEach((k) => (tempt.parameters[k] === null || tempt.parameters[k] === '') && delete tempt.parameters[k])
        // tempt.actionParameters = JSON.stringify(tempt.parameters)
        tempt.action = LANDING_ACTION_PARAMETER.WEB_VIEW
        tempt.actionParameters = handleLandingActionParameters(
          {
            url: tempt.parameters.url,
            action: tempt.parameters.action,
            restUrlId: tempt.parameters.restUrlId,
            ecouponCode: tempt.parameters.ecouponCode,
            folderCode: tempt.parameters.folderCode,
            productParent: tempt.parameters.productParent,
            categoryCode: tempt.parameters.categoryCode,
            brandCode: tempt.parameters.brandCode,
            catProductCode: tempt.parameters.productCode,
            brandProductCode: tempt.parameters.productCode,
            folderType: tempt.parameters.folderType,
            title: tempt.parameters.title,
            type: tempt.parameters.type,
            name: tempt.parameters.name,
            query: tempt.parameters.query,
            from: tempt.parameters.from,
            refId: tempt.parameters.refId,
            code: tempt.parameters.code,
            ticketId: tempt.parameters.ticketId,
            infoId: tempt.parameters.infoId,
            groupCode: tempt.parameters.groupCode,
            showCategoryList: tempt.parameters.showCategoryList,
            showBrandList: tempt.parameters.showBrandList,
            videoId: tempt.parameters.youtubeCode,
          },
          tempt
        )
        break
      case 'displayActionType':
        tempt[name] = e ? e : null
        tempt.action = e ? (isDeepLinkApplicable(e) ? LANDING_ACTION_PARAMETER.WEB_VIEW : e) : null
        tempt.parameters = {}
        tempt.actionParameters = handleLandingActionParameters(
          {
            action: tempt.parameters.displayActionType,
          },
          tempt
        )
        landingSectionValidation.resetErrorMessages('reset_param')
        break
      // case 'subAction':
      //   tempt.parameters.action = e
      //   Object.keys(tempt.parameters).forEach((k) => (tempt.parameters[k] === null || tempt.parameters[k] === '') && delete tempt.parameters[k])
      //   tempt.actionParameters = JSON.stringify(tempt.parameters)
      //   break
      case 'serviceType_section':
        tempt.serviceType = e
        break
      case 'tcTitle':
        tempt[name] = e
        if (item.scNameDisable) tempt.scTitle = e ? translateName(e) : e
        break
      case 'itemType':
        tempt.itemType = e
        tempt.itemList.forEach((item) => (item.itemType = e))
        break
      case 'endDisplayTimestamp':
      case 'startDisplayTimestamp':
        const date = e ? Number(moment(e).format('x')) : null
        tempt[name] = date
        break
      default:
        tempt[name] = e
        break
    }
    setItem(tempt)
    setSectionEdit(tempt)
  }

  return (
    <EditCreateDisplay
      code={item.id}
      resource={resource}
      tabs={false}
      content={content}
      onChangeInput={onChangeInput}
      bottomBtns={bottomBtns}
      display={createMode ? 'create' : 'edit'}
    />
  )
}

export const DragnDropList = ({ currentEdit, setCurrentEdit, setSectionEdit }) => {
  const dispatch = useDispatch()

  function handleOnDragEnd(result) {
    dispatch(setLoading(true))
    let tempList = _.cloneDeep(currentEdit)
    if (!result.destination) return
    const [reorderedItem] = tempList.itemList.splice(result.source.index, 1)
    tempList.itemList.splice(result.destination.index, 0, reorderedItem)
    setCurrentEdit(tempList)
    setSectionEdit(tempList)
    dispatch(setLoading(false))
  }

  const confirmEditItem = (targetItem) => {
    const temptCurrentEdit = _.cloneDeep(currentEdit)

    temptCurrentEdit.itemList.forEach((item, index) => {
      if ((item.customId || item.id) === (targetItem.customId || targetItem.id)) {
        temptCurrentEdit.itemList[index] = _.cloneDeep(targetItem)
      }
    })
    setCurrentEdit(temptCurrentEdit)
    setSectionEdit(temptCurrentEdit)
  }

  const handleDeleteItem = (id) => {
    const tempt = _.cloneDeep(currentEdit)
    tempt.itemList = tempt.itemList.filter((item) => (item.customId || item.id) !== id)
    setCurrentEdit(tempt)
    setSectionEdit(tempt)
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="landing-section-item-templateValue" direction="horizontal">
        {(provided) => {
          return (
            <div className="landing-item-preview" {...provided.droppableProps} ref={provided.innerRef}>
              {currentEdit?.itemList
                ?.filter((item) => item.status !== STATUS.DELETE)
                ?.map((item, index) => {
                  return (
                    <Draggable
                      key={`${item.id}-${index}`}
                      draggableId={'' + index}
                      index={index}
                      isDragDisabled={currentEdit.itemList.length > 1 ? false : true}
                    >
                      {(provided) => {
                        const id = item.id ? item.id : item.customId
                        return (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <Card key={index} className="landing-image-list image-card-background">
                              {
                                //add for new item error
                                landingSectionValidation.getErrorMessages(`${id}-${landingSectionValidationConstants.KEY_ITEM_ERROR}`) && (
                                  <div className="error-message">
                                    <Cancel className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                                    {landingSectionValidation.getErrorMessages(`${id}-${landingSectionValidationConstants.KEY_ITEM_ERROR}`)}
                                  </div>
                                )
                              }
                              <div className="landing-image-list-content">
                                <div className="landing-image-list-icon-bar">
                                  {currentEdit.itemList.length > 1 ? (
                                    <SwapHoriz color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.LARGE} />
                                  ) : (
                                    <div />
                                  )}
                                  <div className="delete-icon">
                                    <Delete color={constants.STYLE.PRIMARY} onClick={() => handleDeleteItem(id)} />
                                  </div>
                                </div>
                                <div className="edit-section-item">
                                  <div key={index} className="item-box">
                                    <LandingItem item={item} />
                                  </div>
                                </div>
                                <LandingItemEdit
                                  item={item}
                                  setItem={confirmEditItem}
                                  index={index}
                                  // handleDeleteItem={handleDeleteItem}
                                  hasMinWidth={true}
                                />
                              </div>
                            </Card>
                          </div>
                        )
                      }}
                    </Draggable>
                  )
                })}
              {provided.placeholder}
            </div>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
}

export const LandingSectionCreate = () => {
  const [landingSection, setLandingSection] = useState(_.cloneDeep(constants.LANDING_SECTION_INPUT))
  // eslint-disable-next-line no-unused-vars
  const [errorVersion, setErrorVersion] = useState(landingSectionValidation.getVersion())
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const [clear, setClear] = useState(true)
  const store = useStore()
  const history = useHistory()
  const savedLandingSection = useSelector((state) => state.landingSection.ui.landingSection)
  const [sequenceSelectList, setSequenceSelectList] = useState([])

  const handleAddItem = () => {
    const tempt = _.cloneDeep(constants.LANDING_ITEM_INPUT)
    tempt.customId = uuidv4()
    tempt.sectionId = landingSection.id
    tempt.itemType = landingSection.itemList && landingSection.itemList.length > 0 ? landingSection.itemList[0].itemType : landingSection.itemType
    const temptLandingSection = _.cloneDeep(landingSection)
    temptLandingSection.itemList.unshift(tempt)
    setLandingSection(temptLandingSection)
  }

  const onClickClear = () => {
    setClear(true)
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + constants.PATH.LANDING.LANDING_SECTION + '/0')
  }

  const onClickSave = () => {
    const tempLandingSection = _.cloneDeep(landingSection)
    let itemListLength = tempLandingSection.itemList ? tempLandingSection.itemList.length : 0
    tempLandingSection.itemList?.forEach((item) => {
      const sequence = itemListLength * 10
      itemListLength--
      item.sequence = sequence
    })
    delete tempLandingSection.itemType
    landingSectionValidation.validateLandingSection(tempLandingSection)
    if (landingSectionValidation.isValid()) {
      post(
        constants.PATH.LANDING.LANDING_SECTION,
        tempLandingSection,
        (payload) => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBack /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriority /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
      landingSectionValidation.resetErrorMessages()
    } else {
      setErrorVersion(landingSectionValidation.getVersion())
    }
  }

  useEffect(() => {
    setLandingSection(_.cloneDeep(constants.LANDING_SECTION_INPUT))
    setClear(false)
    landingSectionValidation.resetErrorMessages()
  }, [clear === true]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (savedLandingSection && savedLandingSection.length > 0) {
      const sequenceChoice = [{ value: (savedLandingSection.length + 1) * 10, name: 'INSERT AT THE TOP' }]
      let sectionLength = savedLandingSection.length
      for (const landingSection of savedLandingSection) {
        sequenceChoice.push({
          value: sectionLength * 10,
          name: landingSection.tcTitle || (landingSection.sectionType === 'ADS' ? 'ADS' : landingSection.sectionType),
        })
        sectionLength--
      }
      setSequenceSelectList(sequenceChoice)
    }
  }, [savedLandingSection])

  return (
    <Card className="card-wrapper overflow">
      <div className="landing-section-preview-wrapper">
        <div className="title">
          <div></div>
          <TempBtn
            btnSetting={{
              color: constants.STYLE.PRIMARY,
              className: 'brand-btn margin-16-4',
              label: { resource: 'LandingSection', key: 'addItem' },
              onClick: handleAddItem,
              icon: <Add />,
            }}
          />
        </div>
      </div>
      <div className="landing-item-list-preview-main">
        {landingSection.sectionType === LANDING_SECTION_SECTION_TYPE.HEADER_ITEMS && <LandingHeader item={landingSection} isPreview={false} />}
        <div className="landing-item-list-preview">
          <DragnDropList currentEdit={landingSection} setCurrentEdit={setLandingSection} setSectionEdit={setLandingSection} />
        </div>
      </div>
      <SectionCreate
        item={landingSection}
        setItem={setLandingSection}
        setSectionEdit={setLandingSection}
        setErrorVersion={setErrorVersion}
        onClickSave={onClickSave}
        onClickClear={onClickClear}
        sequenceSelectList={sequenceSelectList}
        createMode
      />
      {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
    </Card>
  )
}
