import { Box } from '@mui/material'

export default function CardTitle({ children }) {
  return (
    <Box
      sx={{
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        p: 2,
        fontSize: '17px',
        fontWeight: 700,
        textTransform: 'uppercase',
        fontFamily: 'Segoe UI',
        backgroundColor: '#eee',
        m: -2
      }}
    >
      {children}
    </Box>
  )
}
