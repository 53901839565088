export const AGENT_TYPE_OPTION = [
  { label: 'WEB', value: 'web' },
  { label: 'WECHAT', value: 'weChat' },
]

export const CODE_TYPE_OPTION = [
  {
    value: 'BRAND',
    label: 'BRAND',
  },
  {
    value: 'CATEGORY',
    label: 'CATEGORY',
  },
]

export const ACTION_TYPE = {
  CUSTOM_URL: 'CUSTOM_URL',
  WEB_VIEW: 'WEB_VIEW',
  LINK: 'LINK',
  PIXEL: 'PIXEL',
  VIDEO: 'VIDEO',
  PRODUCT_CATEGORY: 'PRODUCT_CATEGORY',
  PRODUCT: 'PRODUCT',
  CATEGORY_PRODUCT: 'CATEGORY_PRODUCT',
  CATEGORY_PRODUCT_MAP: 'CATEGORY_PRODUCT_MAP',
  CATEGORY: 'CATEGORY',
  BRAND: 'BRAND',
  PRODUCT_BRAND: 'PRODUCT_BRAND',
  BRAND_PRODUCT: 'BRAND_PRODUCT',
  BRAND_PRODUCT_MAP: 'BRAND_PRODUCT_MAP',
  FOLDER: 'FOLDER',
  REST: 'REST',
  SEARCH: 'SEARCH',
  BOOKMARK: 'BOOKMARK',
  SEARCH_NEAR_BY: 'SEARCH_NEAR_BY',
  MY_TICKET: 'MY_TICKET',
  TRANSFORM_TICKET_FROM_PAPER: 'TRANSFORM_TICKET_FROM_PAPER',
  TRANSFORM_ECOUPON_FROM_PAPER: 'TRANSFORM_ECOUPON_FROM_PAPER',
  EXPRESS_TICKET_VOUCHER: 'EXPRESS_TICKET_VOUCHER',
  SHARE: 'SHARE',
  GIFT: 'GIFT',
  ECOUPON: 'ECOUPON',
  SCAN_QRCODE: 'SCAN_QRCODE',
  LANDING: 'LANDING',
  SPRINGBOARD: 'SPRINGBOARD',
  DOWNLOAD: 'DOWNLOAD',
  E_COUPON: 'E_COUPON',
  E_COUPON_CATEGORY: 'E_COUPON_CATEGORY',
  E_COUPON_BRAND: 'E_COUPON_BRAND',
  PRODUCT_COUPON_CATEGORY_MAP: 'PRODUCT_COUPON_CATEGORY_MAP',
  PRODUCT_COUPON_BRAND_MAP: 'PRODUCT_COUPON_BRAND_MAP',
}

export const WEB_VIEW_TYPE_OPTION = [
  {
    value: 'CAMPAIGN',
    label: 'CAMPAIGN',
  },
  {
    value: 'CLP',
    label: 'CLP',
  },
]

export const FOLDER_TYPE_OPTION = [
  {
    value: 'CAMPAIGN',
    label: 'CAMPAIGN',
  },
]

export const ACTION_TYPE_OPTION = [
  { value: ACTION_TYPE.WEB_VIEW, label: ACTION_TYPE.WEB_VIEW, group: 'Download GULU App' },
  { value: ACTION_TYPE.LINK, label: ACTION_TYPE.LINK, group: 'Download GULU App' },
  { value: ACTION_TYPE.VIDEO, label: ACTION_TYPE.VIDEO, group: 'Download GULU App' },
  { value: ACTION_TYPE.PRODUCT, label: ACTION_TYPE.PRODUCT, group: 'Download GULU App' },
  {
    value: ACTION_TYPE.PRODUCT_CATEGORY,
    label: ACTION_TYPE.PRODUCT_CATEGORY,
    group: 'Download GULU App',
  },
  {
    value: ACTION_TYPE.PRODUCT_BRAND,
    label: ACTION_TYPE.PRODUCT_BRAND,
    group: 'Download GULU App',
  },
  { value: ACTION_TYPE.E_COUPON, label: ACTION_TYPE.E_COUPON, group: 'Download GULU App' },
  {
    value: ACTION_TYPE.E_COUPON_CATEGORY,
    label: ACTION_TYPE.E_COUPON_CATEGORY,
    group: 'Download GULU App',
  },
  {
    value: ACTION_TYPE.E_COUPON_BRAND,
    label: ACTION_TYPE.E_COUPON_BRAND,
    group: 'Download GULU App',
  },
  { value: ACTION_TYPE.FOLDER, label: ACTION_TYPE.FOLDER, group: 'Download GULU App' },
  { value: ACTION_TYPE.REST, label: ACTION_TYPE.REST, group: 'Download GULU App' },
  { value: ACTION_TYPE.SEARCH, label: ACTION_TYPE.SEARCH, group: 'Download GULU App' },
  { value: ACTION_TYPE.BOOKMARK, label: ACTION_TYPE.BOOKMARK, group: 'Download GULU App' },
  {
    value: ACTION_TYPE.SEARCH_NEAR_BY,
    label: ACTION_TYPE.SEARCH_NEAR_BY,
    group: 'Download GULU App',
  },
  { value: ACTION_TYPE.MY_TICKET, label: ACTION_TYPE.MY_TICKET, group: 'Download GULU App' },
  {
    value: ACTION_TYPE.TRANSFORM_TICKET_FROM_PAPER,
    label: ACTION_TYPE.TRANSFORM_TICKET_FROM_PAPER,
    group: 'Download GULU App',
  },
  {
    value: ACTION_TYPE.TRANSFORM_ECOUPON_FROM_PAPER,
    label: ACTION_TYPE.TRANSFORM_ECOUPON_FROM_PAPER,
    group: 'Download GULU App',
  },
  {
    value: ACTION_TYPE.EXPRESS_TICKET_VOUCHER,
    label: ACTION_TYPE.EXPRESS_TICKET_VOUCHER,
    group: 'Download GULU App',
  },
  { value: ACTION_TYPE.SHARE, label: ACTION_TYPE.SHARE, group: 'Download GULU App' },
  { value: ACTION_TYPE.GIFT, label: ACTION_TYPE.GIFT, group: 'Download GULU App' },
  { value: ACTION_TYPE.LANDING, label: ACTION_TYPE.LANDING, group: 'Download GULU App' },
  { value: ACTION_TYPE.DOWNLOAD, label: ACTION_TYPE.DOWNLOAD, group: 'Download GULU App' },
  { value: ACTION_TYPE.SCAN_QRCODE, label: ACTION_TYPE.SCAN_QRCODE, group: 'Download GULU App' },
  { value: ACTION_TYPE.CUSTOM_URL, label: ACTION_TYPE.CUSTOM_URL, group: 'Others' },
  { value: ACTION_TYPE.SPRINGBOARD, label: ACTION_TYPE.SPRINGBOARD, group: 'Others', disabled: true },
]

export const FROM_OPTION = [
  { value: 'qrcode', label: 'qrcode' },
  { value: 'campaign_qrcode', label: 'campaign_qrcode' },
  { value: 'edm', label: 'edm' },
  { value: 'fb', label: 'fb' },
  { value: 'urlMap', label: 'urlMap' },
  { value: 'web', label: 'web' },
  { value: 'SPLASHAD', label: 'SPLASHAD' },
]

export const REST_URL_TYPE = {
  universal: 'UNIVERSAL',
  downloadHtml: 'DOWNLOAD_HTML',
  other: 'OTHER',
}

export const REST_URL_TYPE_OPTION = [
  { value: REST_URL_TYPE.universal, label: REST_URL_TYPE.universal },
  { value: REST_URL_TYPE.downloadHtml, label: REST_URL_TYPE.downloadHtml },
  { value: REST_URL_TYPE.other, label: REST_URL_TYPE.other },
]

export const QUERY_OPTION = [
  { value: 'QUEUE', label: 'QUEUE' },
  { value: 'TAKEAWAY', label: 'TAKEAWAY' },
  { value: 'RESERVE', label: 'RESERVE' },
  { value: 'APPOINTMENT', label: 'APPOINTMENT' },
  { value: 'RACK_PRODUCT', label: 'RACK_PRODUCT' },
]

export const SERVICE_TYPE_OPTION = [
  { value: 'QUEUE', label: 'QUEUE' },
  { value: 'RESERVE', label: 'RESERVE' },
  { value: 'APPOINTMENT', label: 'APPOINTMENT' },
  { value: 'TAKEAWAY', label: 'TAKEAWAY' },
  { value: 'RACK_PRODUCT', label: 'RACK_PRODUCT' },
  { value: 'ECOUPON', label: 'ECOUPON' },
]

export const GIFT_SERVICE_TYPE_OPTION = [
  { value: 'RACK_PRODUCT', label: 'RACK_PRODUCT' },
  { value: 'ECOUPON', label: 'ECOUPON' },
]

export const DATA_DEFAULT = {
  actionType: '',
  mapCode: '',
  mapValue: '',
  url: '',
  qrCodeUrl: '',
  agentType: '',
  status: '',
  restUrlId: '',
  restUrlType: '',
}

export const OTHER_DATA_DEFAULT = {
  action: '',
  title: '',
  productCode: '',
  codeType: '',
  categoryCode: '',
  brandCode: '',
  eCouponCode: '',
  folderCode: '',
  folderType: '',
  queryRestUrlId: '',
  query: '',
  ticketId: '',
  infoId: '',
  groupCode: '',
  refId: '',
  code: '',
  customUrl: '',
  weChat: '',
  name: '',
  type: '',
  from: '',
}

export const ACTION_OPTION = [
  { value: 'INFO', label: 'INFO' },
  { value: 'WALL', label: 'WALL' },
  { value: 'NEW_REVIEW', label: 'NEW_REVIEW' },
  { value: 'MENU', label: 'MENU' },
  { value: 'QUEUE', label: 'QUEUE' },
  { value: 'RESERVE', label: 'RESERVE' },
  { value: 'APPOINTMENT', label: 'APPOINTMENT' },
  { value: 'callback', label: 'APPOINTMENT_CALLBACK' },
  { value: 'appointmentContactShop', label: 'APPOINTMENT_CONTACT_SHOP' },
  { value: 'TAKEAWAY', label: 'TAKEAWAY' },
]

export const RACK_PRODUCT_TYPE_OPTION = [
  { value: 'ECOUPON', label: 'ECOUPON' },
  { value: 'ORDER', label: 'ORDER' },
]

export const DOWNLOAD_HTML_URL = 'https://www.thegulu.com/download.html'
export const Q_HK_WALLET_URL = 'https://qhkwallet.thegulu.com'
