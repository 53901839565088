import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { constants, SYSTEM_USER } from "../../../../../../Constants/constants";
import { helperTextStyles } from "../../../../../../helper/util";
import { post } from "../../../../../../helper/baseFetch";
import { getContent } from "../../../../../EditCreateDisplay/EditUtil";
import { GlobalDialog } from "../../../../../GlobalDialog";
import { EditCreateDisplay } from "../../../../../EditCreateDisplay/EditCreateDisplay";
import { setSystemUserValue, setSystemUser } from "../../../../../../actions/SystemUserAction";
import { SystemUserRestField } from "./SystemUserRestField";
import { systemUserValidationConstants } from "../../../../../../Constants/validationConstants";
import SystemUserValidation from "../../../../../../validations/systemUserValidation";

export const SystemUserCreate = ( ) =>{
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "systemUser";
  const savedSystemUser = useSelector(state => state.systemUser.ui.systemUser);
  const savedSystemGroup = useSelector(state => state.systemGroup.ui.systemGroup);
  const [, setErrorVersion] = useState(SystemUserValidation.getVersion());

  const helperTextStylesClasses = helperTextStyles();
  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.SYSTEM_USER.INFO
    },
  ]
  const tempSelectTagEditList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      variant: constants.STYLE.VARIANT.STANDARD,
      label: { resource: resource, key: "systemGroupId" },
      value: savedSystemGroup.id,
      name: "systemGroupId",
      type: constants.TYPE.TEXT,
      isEndOfLine: true,
      disabled: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "username" },
      value: savedSystemUser.username,
      name: "username",
      type: constants.TYPE.TEXT,
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: SystemUserValidation.getErrorMessages(systemUserValidationConstants.KEY_USERNAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "password" },
      value: savedSystemUser.password,
      name: "password",
      type: constants.TYPE.TEXT,
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: SystemUserValidation.getErrorMessages(systemUserValidationConstants.KEY_PASSWORD),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "email" },
      value: savedSystemUser.email,
      name: "email",
      type: constants.TYPE.TEXT,
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: SystemUserValidation.getErrorMessages(systemUserValidationConstants.KEY_EMAIL),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "userRole" },
      value: savedSystemUser.userRole,
      name: "userRole",
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.SYSTEM_USER.USER_ROLE,
      isEndOfLine: false,
      required: true,
      disabled: false,
      helperTextIcon: true,
      helperText: SystemUserValidation.getErrorMessages(systemUserValidationConstants.KEY_USER_ROLE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "privilegeAttribute" },
      value: savedSystemUser.privilegeAttribute,
      name: "privilegeAttribute",
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.SYSTEM_USER.PRIVILEGE_ATTRIBUTE,
      isEndOfLine: false,
      required: true,
      disabled: false,
      helperTextIcon: true,
      helperText: SystemUserValidation.getErrorMessages(systemUserValidationConstants.KEY_PRIVILEGE_ATTRIBUTE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input ${savedSystemUser.privilegeAttribute === SYSTEM_USER.PRIVILEGE_ATTRIBUTE.G?"display-none": ""}`,
      size: constants.SIZE.SMALL,
      type: constants.TYPE.FUNCTION,
      value: <SystemUserRestField onChangeInput={onChangeInput} />
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "apiGroup" },
      value: savedSystemUser.apiGroup,
      name: "apiGroup",
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.SYSTEM_USER.APIGROUP,
      isEndOfLine: false,
      required: false,
    },
  ]

  const content = [ //to-do export this function
    {
      tab: tempSelectTagEditList.map((inputSetting, index) => {
        let content = getContent(inputSetting, index, onChangeInput);
        return <div className="edit-display-wrapper" key={index}>
                  {content}
                  {inputSetting.isEndOfLine && <div></div>}
                </div>
      }),
      btn: true
    },
  ]
  const bottomBtns = [
    {
      className: "margin-left-16",
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    },
  ]

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    
    history.push("/" + constants.PATH.PRODUCT.SYSTEM_GROUP +"/"+ savedSystemGroup.id + "/" + constants.SYSTEM_GROUP.SYSTEM_USER );
  };

  function onChangeInput(name, e) {
    dispatch(setSystemUserValue(name, savedSystemUser, "", e));
  }

  function onClickSave() {

    SystemUserValidation.validateCreateSystemUser(savedSystemUser);
    if (SystemUserValidation.isValid()) {
      post(constants.PATH.SYSTEM_GROUP.SYSTEM_USER+ "/" , {...savedSystemUser, systemGroupId: savedSystemGroup.id}, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backCreate" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      SystemUserValidation.resetErrorMessages();
    } else {
      setErrorVersion(SystemUserValidation.getVersion());
    }

  }

  function onClickClear() {
    setClear(true);
    // SelectTagValidation.resetErrorMessages();
  }

  useEffect(() => {
    let tempInput = _.cloneDeep(constants.SYSTEM_USER_INPUT);
    dispatch(setSystemUser(tempInput));
    setClear(false)
    SystemUserValidation.resetErrorMessages();
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <EditCreateDisplay
        code={savedSystemUser && savedSystemUser.id ? savedSystemUser.id : ""}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="create"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
}