import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
//import _ from "lodash";

import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { getOne, put } from "../../helper/baseFetch";
import { setSelectTag, setSelectTagValue } from "../../actions/selectTagAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
//import { UploadImage } from "../../Component/UploadImage/UploadImage";
import { GlobalDialog } from "../../Component/GlobalDialog";
import { TempCustomCheckBox } from "../../Component/Custom/TempCustomCheckBox";
import SelectTagValidation from "../../validations/selectTagValidation";
import { selectTagValidationConstants } from '../../Constants/validationConstants';
import useRestaurant from "../../hooks/Restaurant/useRestaurant";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const SelectTagConfigEdit = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "SelectTagConfig";
  const savedSelectTag = useSelector(state => state.selectTag.ui.selectTag);
  const [, setErrorVersion] = useState(SelectTagValidation.getVersion());

  const { getRestaurantIdAndName } = useRestaurant(savedSelectTag.restUrlId)

  const helperTextStylesClasses = helperTextStyles();

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.SELECT_TAG_EDIT_TAB.INFO
    },
  ]
  const tempSelectTagEditList = [
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "restUrlId" },
      value: savedSelectTag.restUrlId,
      name: "restUrlId",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: true,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tag_code" },
      value: savedSelectTag.tagCode,
      name: "tagCode",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: true,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tagEnName" },
      value: savedSelectTag.tagEnName,
      name: "tagEnName",
      isEndOfLine: false,
      disabled: true,
      type: constants.TYPE.TEXT,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tagTcName" },
      value: savedSelectTag.tagTcName,
      name: "tagTcName",
      isEndOfLine: false,
      disabled: true,
      type: constants.TYPE.TEXT,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      variant: constants.STYLE.VARIANT.STANDARD,
      label: { resource: resource, key: "tagScName" },
      value: savedSelectTag.tagScName,
      name: "tagScName",
      isEndOfLine: true,
      disabled: true,
      type: constants.TYPE.TEXT, 
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "sequence" },
      value: savedSelectTag.sequence,
      name: "sequence",
      type: constants.TYPE.NUMBER,
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: SelectTagValidation.getErrorMessages(selectTagValidationConstants.KEY_DISPLAY_SEQUENCE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "type" },
      value: savedSelectTag.type,
      name: "type",
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.SELECT_TAG.TYPE,
      isEndOfLine: true,
      required: true,
      disabled: false,
      helperTextIcon: true,
      helperText: SelectTagValidation.getErrorMessages(selectTagValidationConstants.KEY_TYPE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "status" },
      value: savedSelectTag.status,
      name: "status",
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.SELECT_TAG.STATUS,
      required: true,
      isEndOfLine: true,
      disabled: false,
      helperTextIcon: true,
      helperText: SelectTagValidation.getErrorMessages(selectTagValidationConstants.KEY_STATUS),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    
  ]

  const content = [ //to-do export this function
    {
      tab: tempSelectTagEditList.map((inputSetting, index) => {
        let content = <></>;
        switch (inputSetting.type) {
          case constants.TYPE.SELECT:
            content =
              <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break;
          default:
            content =
              <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
        }
        return <div className={inputSetting.backgroundColorClass ? "image-card-background card-border" : "edit-display-wrapper"} key={index}>
          {inputSetting.custom && <TempCustomCheckBox className="customize-btn" value={inputSetting.customValue} customName={inputSetting.customName} onSelectCheckbox={onSelectCheckbox} />}
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: true
    },
  ]
  const bottomBtns = [
    {
      className: "margin-left-16",
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    },
  ]

  //to-do validation

  function onSelectCheckbox(value, customName) {
    //bug --> cusomeName.key --> other component same problem, export this function
    if (customName.key === "nameCustomize") {
      dispatch(setSelectTagValue("scNameDisable", savedSelectTag, "", value));
    } 
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + "/" + savedSelectTag.restUrlId + "/" + constants.RESTAURANT_TAB.INFO + "/" + constants.RESTAURANT_SUB_TAB.SELECT_TAG);
  };

  function onChangeInput(name, e) {
    if (savedSelectTag.scNameDisable === true && name === "tcName") {
      dispatch(setSelectTagValue("CHANGE_TC_NAME_AND_SC_NAME", savedSelectTag, "", e));
    } else if (name === "sequence") {
      let sequence = Number.isInteger(Number(e)) ? Number(e) : 0;
      dispatch(setSelectTagValue(name, savedSelectTag, "", sequence));
    }else {
      dispatch(setSelectTagValue(name, savedSelectTag, "", e));
    }
  }

  function onClickSave() {
    SelectTagValidation.validateEditSelectTagConfig(savedSelectTag);
    if (SelectTagValidation.isValid()) {
      put(constants.PATH.SELECTED_TAG.SELECT_TAG_CONFIG + "/" + params.id, savedSelectTag, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      SelectTagValidation.resetErrorMessages();
    } else {
      setErrorVersion(SelectTagValidation.getVersion());
    }

  }

  function onClickClear() {
    setClear(true);
    SelectTagValidation.resetErrorMessages();
  }

  useEffect(() => {
    if (clear === true) {
      getOne(constants.PATH.SELECTED_TAG.SELECT_TAG_CONFIG + "/" + params.id, undefined, (payload) => {
        dispatch(setSelectTag(payload));
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  const mapCode = getRestaurantIdAndName()

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedSelectTag && savedSelectTag.id ? savedSelectTag.id : ""}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        mapName={{ resource: "SelectTagConfig", key: "restUrlId" }}
        mapCode={mapCode}
        display="edit"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};
