import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { constants as co } from '../../Constants/constants'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { GlobalDialog } from '../../Component/GlobalDialog'
import { getOne, put } from '../../helper/baseFetch'
import { getContent } from '../../Component/EditCreateDisplay/EditUtil'
import StaffValidation from '../../validations/staffValidation'
import { staffValidationConstants } from '../../Constants/validationConstants'
import { setStaff, setStaffValue } from '../../actions/staffAction'
import { helperTextStyles } from '../../helper/util'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CachedIcon from '@mui/icons-material/Cached'
import { ResetUsernameAndPasswordDialog } from '../MerchantUser/MerchantUserEdit/ResetUsernameAndPasswordDialog'
import { ProfileCodeSection } from './StaffProfileCodeSection'
import useRestaurant from '../../hooks/Restaurant/useRestaurant'

const resource = 'Staff'

export const StaffEdit = () => {
  const dispatch = useDispatch()
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const helperTextStylesClasses = helperTextStyles()
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(true)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })

  const savedStaff = useSelector((state) => state.staff.ui.staff)
  const [, setErrorVersion] = useState(StaffValidation.getVersion())
  const [openResetDialog, setOpenResetDialog] = useState(false)
  const restUrlId = params.restUrlId

  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: co.MERCHANT_USER_EDIT_TAB.INFO,
    },
  ]
  const tempMerchantUserEditList = [
    {
      className: 'edit-create-input',
      variant: co.STYLE.VARIANT.STANDARD,
      label: { resource: resource, key: 'username' },
      value: savedStaff.username,
      name: 'username',
      disabled: true,
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: StaffValidation.getErrorMessages(`${staffValidationConstants.KEY_NAME}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input`,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'staffRole' },
      value: savedStaff.staffRole,
      name: 'staffRole',
      type: co.TYPE.SELECT,
      disableClearable: true,
      selectValue: co.CHOICES.STAFF.USER_ROLE,
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: StaffValidation.getErrorMessages(`${staffValidationConstants.KEY_STAFF_ROLE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      type: co.TYPE.FUNCTION,
      value: <ProfileCodeSection displayData={savedStaff} onChangeInput={onChangeInput} restUrlId={params.restUrlId} showQueueTransferProfileCode />,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'refCode' },
      value: savedStaff.refCode,
      name: 'refCode',
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: StaffValidation.getErrorMessages(`${staffValidationConstants.KEY_REF_CODE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'surname' },
      value: savedStaff.surname,
      name: 'surname',
      isEndOfLine: false,
      helperTextIcon: false,
      helperText: StaffValidation.getErrorMessages(`${staffValidationConstants.KEY_SURNAME}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'firstName' },
      value: savedStaff.firstName,
      name: 'firstName',
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: StaffValidation.getErrorMessages(`${staffValidationConstants.KEY_FIRST_NAME}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'displayName' },
      value: savedStaff.displayName,
      name: 'displayName',
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: StaffValidation.getErrorMessages(`${staffValidationConstants.KEY_DISPLAY_NAME}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'email' },
      value: savedStaff.email,
      name: 'email',
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: StaffValidation.getErrorMessages(`${staffValidationConstants.KEY_EMAIL}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'countryCode' },
      value: savedStaff.countryCode,
      name: 'countryCode',
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: StaffValidation.getErrorMessages(`${staffValidationConstants.KEY_COUNTRY_CODE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'mobile' },
      value: savedStaff.mobile,
      name: 'mobile',
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: StaffValidation.getErrorMessages(`${staffValidationConstants.KEY_MOBILE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]
  const content = [
    {
      tab: tempMerchantUserEditList.map((inputSetting, index) => {
        let content = getContent(inputSetting, index, onChangeInput)
        return (
          <div className="edit-display-wrapper" key={index}>
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        )
      }),
      btn: true,
    },
  ]

  const bottomBtns = [
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
    {
      className: 'special-button special-button-margin-left',
      variant: co.STYLE.VARIANT.CONTAINED,
      icon: <CachedIcon />,
      label: { key: 'resetAccountNameAndPassword' },
      onClick: () => setOpenResetDialog(true),
    },
  ]

  const onClickBackToEdit = () => {
    setOpenResetDialog(false)
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setOpenResetDialog(false)
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push(
      `/${co.PATH.SHOP.SYSTEM_GROUP_RESTAURANT}/${restUrlId}/${co.RESTAURANT_TAB.MERCHANT}/${co.RESTAURANT_MERCHANT_SUB_TAB.STAFF}`
    )
  }

  function onChangeInput(name, e) {
    switch (name) {
      case 'queueProfileCode':
      case 'queueTransferProfileCode':
      case 'counterProfileCode':
        dispatch(setStaffValue(name, savedStaff, '', e === '' ? null : e))
        break
      default:
        dispatch(setStaffValue(name, savedStaff, '', e))
    }
  }

  function onClickResetDialog(username, password) {
    put(
      co.PATH.MERCHANT.UPDATE_STAFF_PASSWORD + '/' + params.id,
      { password, username },
      () => {
        setOpenResetDialog(false)
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: 'editSuccess' },
          dialogActions: [
            { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
          ],
        })
        setClear(false)
      },
      undefined,
      store
    )
  }

  function onClickSave() {
    StaffValidation.validateSavedData(savedStaff)
    if (StaffValidation.isValid()) {
      put(
        co.PATH.MERCHANT.STAFF + '/' + params.id,
        savedStaff,
        () => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
      StaffValidation.resetErrorMessages()
    } else {
      setErrorVersion(StaffValidation.getVersion())
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(
        co.PATH.MERCHANT.STAFF + '/' + params.id,
        undefined,
        (payload) => {
          dispatch(setStaff(payload))
          setReady(true)
          setClear(false)
        },
        undefined,
        store
      )
    }
    StaffValidation.resetErrorMessages()
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  const { getRestaurantIdAndName } = useRestaurant(restUrlId)
  const mapCode = getRestaurantIdAndName()

  return (
    ready && (
      <>
        <EditCreateDisplay
          mapName={{ resource: 'restaurant', key: 'restUrlId' }}
          mapCode={mapCode}
          code={savedStaff && savedStaff.id ? savedStaff.id : ''}
          resource={resource}
          tabs={tabs}
          content={content}
          onChangeInput={onChangeInput}
          bottomBtns={bottomBtns}
          display="edit"
        />
        {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
        <ResetUsernameAndPasswordDialog
          prevUsername={savedStaff.username}
          onChangeInput={onChangeInput}
          openResetDialog={openResetDialog}
          setOpenResetDialog={setOpenResetDialog}
          onClickReset={onClickResetDialog}
          resource={resource}
        />
      </>
    )
  )
}
