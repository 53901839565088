import { Card, CardActions, CardContent, CardHeader } from '@mui/material'

export default function MUICard({ children, header, actions, sx }) {
  return (
    <Card sx={{ height: '100%', display: 'flex', gap: 1, flexDirection: 'column', ...sx }}>
      <CardHeader title={header} />
      <CardContent sx={{ flex: '1 0', overflow: 'auto' }}>{children}</CardContent>
      {actions && <CardActions>{actions}</CardActions>}
    </Card>
  )
}
