import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { post } from "../../helper/baseFetch";
import { setTnC, setTnCValue } from "../../actions/tncAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { TempCustomCheckBox } from "../../Component/Custom/TempCustomCheckBox";
import { GlobalDialog } from "../../Component/GlobalDialog";
import TnCValidation from "../../validations/tncValidation";
import { tncValidationConstants } from '../../Constants/validationConstants';
import useRestaurant from "../../hooks/Restaurant/useRestaurant";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const TnCCreate = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const params = useParams();
  const [clear, setClear] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "TnC";
  const savedTnC = useSelector(state => state.tnc.ui.tnc);
  const [tncInputList, setTnCInputList] = useState(_.cloneDeep(constants.TNC_INPUT));
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(TnCValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.TNC_EDIT_TAB.INFO
    },
  ]
  const tempTnCEditList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "type" },
      value: savedTnC.type,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.TNC.TYPE,
      name: "type",
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: TnCValidation.getErrorMessages(tncValidationConstants.KEY_TYPE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "filterKey" },
      value: savedTnC.filterKey,
      name: "filterKey",
      clear: true,
      isEndOfLine: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enDescription" },
      value: savedTnC.enDescription,
      name: "enDescription",
      custom: true,
      customName: { resource: resource, key: "descriptionCustomize" },
      customValue: savedTnC.scDescriptionDisable,
      isEndOfLine: false,
      multiline: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcDescription" },
      value: savedTnC.tcDescription,
      name: "tcDescription",
      translateName: savedTnC.scDescriptionDisable ? ["scDescription"] : null,
      inputList: tncInputList,
      setInputList: setTnCInputList,
      isEndOfLine: false,
      multiline: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scDescription" },
      value: savedTnC.scDescription,
      name: "scDescription",
      inputList: tncInputList,
      setInputList: setTnCInputList,
      isEndOfLine: true,
      disabled: savedTnC.scDescriptionDisable,
      multiline: true,
    },
  ]
  const content = [
    {
      tab:
        <>
          {TnCValidation.getErrorMessages(tncValidationConstants.KEY_REST_URL_ID) &&
            TnCValidation.getErrorMessages(tncValidationConstants.KEY_REST_URL_ID).length > 0 &&
            <div className="error-message">
              <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
              {TnCValidation.getErrorMessages(tncValidationConstants.KEY_REST_URL_ID)}
            </div>
          }
          {tempTnCEditList.map((inputSetting, index) => {
            let content;
            switch (inputSetting.type) {
              case constants.TYPE.SELECT:
                content =
                  <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
                break;
              default:
                content =
                  <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            }
            return <div className="edit-display-wrapper" key={index}>
              {inputSetting.custom && <TempCustomCheckBox value={inputSetting.customValue} className="customize-btn" customName={inputSetting.customName} onSelectCheckbox={onSelectCheckbox} />}
              {content}
              {inputSetting.isEndOfLine && <div></div>}
            </div>
          })}
        </>,
      btn: true
    }
  ]

  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  function onSelectCheckbox(value, customName) {
    switch (customName.key) {
      case "descriptionCustomize":
        dispatch(setTnCValue("scDescriptionDisable", savedTnC, "", value));
        break;
      default:
    }
  }

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  }
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + "/" + params.restUrlId + "/" + constants.RESTAURANT_TAB.INFO + "/" + constants.RESTAURANT_SUB_TAB.TNC);
  };

  function onChangeInput(name, e) {
    let changedValue = e ? e : null;
    if (name === "tcDescription" && savedTnC.scDescriptionDisable === true) {
      dispatch(setTnCValue("CHANGE_TC_DESCRIPTION_AND_SC_DESCRIPTION", savedTnC, "", changedValue));
    } else {
      dispatch(setTnCValue(name, savedTnC, "", changedValue));
    }
  }

  function onClickSave() {
    TnCValidation.validateCreateTnC(savedTnC);
    if (TnCValidation.isValid()) {
      let tempSavedCampaignDescription = _.cloneDeep(savedTnC);
      post(constants.PATH.SHOP.TNC, tempSavedCampaignDescription, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: "createSuccess" },
          dialogActions: [
            { label: { key: "backCreate" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      TnCValidation.resetErrorMessages();
    } else {
      setErrorVersion(TnCValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    TnCValidation.resetErrorMessages();
    let tempTnC = _.cloneDeep(constants.TNC_INPUT);
    tempTnC.restUrlId = params.restUrlId;
    dispatch(setTnC(tempTnC));
    setClear(false)
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  const { getRestaurantIdAndName } = useRestaurant(params.restUrlId)
  const mapCode = getRestaurantIdAndName(params.restUrlId)

  return (
    <>
      <EditCreateDisplay
        code=""
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        mapName={{ resource: "restaurant", key: "restUrlId" }}
        mapCode={mapCode}
        display="create"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};
