import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import _ from "lodash";
import { sify } from "chinese-conv";
import moment from 'moment';

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import SplitPane from 'react-split-pane';
import { Allotment } from 'allotment'
import 'allotment/dist/style.css'
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';

import { constants, RESERVATION_TABLE_TYPE_LIST_INPUT } from '../../Constants/constants';
import { getOne, put } from "../../helper/baseFetch";
import { setReservationTemplate, setReservationTemplateTimeSectionValue, setReservationTemplateTableTypeValue } from "../../actions/reservationTemplateAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { ReservationTemplateTimeSection } from "./ReservationTimeSection";
import { ReservationTemplateTimeSectionList } from "./ReservationTimeSectionList";
import { ReservationTemplateTableType } from "./ReservationTemplateTableType";
import ReservationTemplateValidation from "../../validations/reservationTemplateValidation";
import { GlobalDialog } from "../../Component/GlobalDialog";
import { getString } from "../../helper/util";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const ReservationTemplateEdit = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);
  const savedReservationTemplate = useSelector(state => state.reservationTemplate.ui.reservationTemplate);
  const savedRestaurant = useSelector(state => state.restaurant.ui.restaurant);
  const [reservationTemplateInputList, setReservationTemplateInputList] = useState({
    templateCode: "",
    description: "",
    status: "",
  });
  const [customIdNumber, setCustomIdNumber] = useState(0);
  const [selectedType, setSelectedType] = useState("");
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const [, setErrorVersion] = useState(ReservationTemplateValidation.getVersion());
  const helperTextStylesClasses = helperTextStyles();

  // eslint-disable-next-line no-unused-vars
  const [splitPane, setSplitPane] = useState(500);
  const resource = "ReservationTemplate";
  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.RESERVATION_TEMPLATE_TAB.INFO
    }
  ]

  // const handlePanelSize = (newSize) => {
  //   setSplitPane(newSize);
  // };

  const content = [
    {
      tab: <>
        <div>
          <TempTextInput
            inputSetting={{
              className: "edit-create-input",
              variant: constants.STYLE.VARIANT.STANDARD,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "templateCode" },
              value: reservationTemplateInputList.templateCode,
              name: "templateCode",
              multiline: true,
              disabled: true
            }}
            handleChange={handleDescription}
          />
          <TempTextInput
            inputSetting={{
              className: "edit-create-input",
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "description" },
              value: reservationTemplateInputList.description,
              name: "description",
              multiline: true,
              helperTextIcon: true,
              helperText: ReservationTemplateValidation.getErrorMessages("description"),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
            }}
            handleChange={handleDescription}
          />
          <TempAutoSelectInput
            inputSetting={{
              className: "edit-create-input",
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "status" },
              value: reservationTemplateInputList.status,
              type: constants.TYPE.SELECT,
              selectValue: constants.CHOICES.RESERVATION_TEMPLATE.STATUS,
              name: "status",
              disableClearable: true,
              isEndOfLine: false,
            }}
            handleChange={handleStatus}
          />
          <TempTextInput
            inputSetting={{
              className: "edit-create-input",
              variant: constants.STYLE.VARIANT.STANDARD,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "inUse" },
              value: reservationTemplateInputList.inUse,
              name: "inUse",
              multiline: true,
              disabled: true
            }}
            handleChange={handleDescription}
          />
        </div>
        <Allotment defaultSizes={[100, 200]}>
        {/* <SplitPane
          split="vertical"
          size={splitPane}
          style={{ position: 'initial' }}
          onDragFinished={(newSize) => {
            handlePanelSize(newSize);
          }}
        > */}
          <ReservationTemplateTimeSectionList resource={resource} setSelectedType={setSelectedType} setCustomIdNumber={setCustomIdNumber} customIdNumber={customIdNumber} />
          <div className="queue-template-flex-right">
            {selectedType === "timeSection" ?
              <ReservationTemplateTimeSection setCustomIdNumber={setCustomIdNumber} customIdNumber={customIdNumber} />
              : selectedType === "tableType" ?
                <ReservationTemplateTableType setCustomIdNumber={setCustomIdNumber} customIdNumber={customIdNumber} />
                : <></>}
          </div>
        {/* </SplitPane> */}
        </Allotment>
      </>,
      btn: true,
    },
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: () => reservationTemplateInputList.inUse ? handleEffectiveDateDialog() : onClickSave(),
      className: "margin-left-16",
      icon: <SaveIcon />,
      disabled: !savedReservationTemplate || savedReservationTemplate.length === 0,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  function handleDescription(name, e) {
    let tempReservationTemplateInputList = _.cloneDeep(reservationTemplateInputList);
    tempReservationTemplateInputList.description = e;
    setReservationTemplateInputList(tempReservationTemplateInputList);
  }

  function handleStatus(name, e) {
    let tempReservationTemplateInputList = _.cloneDeep(reservationTemplateInputList);
    tempReservationTemplateInputList.status = e;
    setReservationTemplateInputList(tempReservationTemplateInputList);
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + "/" + savedRestaurant.restUrlId + "/" + constants.RESTAURANT_TAB.RESERVATION + "/" + constants.RESTAURANT_RESERVATION_SUB_TAB.RESERVATION_TEMPLATE);
  };

  function handleEffectiveDateDialog() {
    let date = new Date();
    let effectiveDate = moment(date).format("x");
    setDialog({
      open: true,
      onClose: onClickBackToEdit,
      dialogTitle: { key: "dialogTitle" },
      dialogContent: <div className="draft-name-wrapper">
        <TextField
          className="draft-name-input"
          label={getString(lang, resource, "effectiveDate", theme)}
          variant="outlined"
          defaultValue={moment(date).format("YYYY-MM-DD")}
          type={constants.TYPE.DATE}
          InputLabelProps={{ shrink: true }}
          inputProps={{ min: moment(date).format("YYYY-MM-DD") }}
          onChange={(draftName) => effectiveDate = moment(draftName.target.value).format("x")}
          required
        />
      </div>,
      dialogActions: [
        { label: { key: "backEdit" }, onClick: () => onClickBackToEdit(), icon: <ArrowBackIcon /> },
        { label: { key: "save" }, onClick: () => onClickSave(effectiveDate), icon: <SaveIcon /> }
      ],
    });
  }

  function onClickSave(effectiveDate) {
    ReservationTemplateValidation.validateTemplate(savedReservationTemplate, reservationTemplateInputList.description);
    if (ReservationTemplateValidation.isValid()) {
      let tempsavedReservationTemplate = _.cloneDeep(savedReservationTemplate);
      let newTicketTypeList = []
      tempsavedReservationTemplate.forEach((tempsavedReservationTemplateValue) => {
        let tempticketTypeList = _.cloneDeep(tempsavedReservationTemplateValue.tableTypeList);
        delete tempsavedReservationTemplateValue.id;
        delete tempsavedReservationTemplateValue.status;
        delete tempsavedReservationTemplateValue.tableTypeList;
        tempticketTypeList.forEach((item) => newTicketTypeList.push(Object.assign(item, tempsavedReservationTemplateValue)))
      })
      put(constants.PATH.SHOP.RESERVATION.RESERVATION_TEMPLATE + "/" + params.id,
        {
          restUrlId: savedRestaurant.restUrlId,
          description: reservationTemplateInputList.description,
          status: reservationTemplateInputList.status,
          timeSectionList: newTicketTypeList,
          effectiveDate: effectiveDate,
        }
        , (payload) => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: "editSuccess" },
            maxWidth: "md",
            dialogActions: [
              { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
              { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ]
          })
          setClear(false)
        }, undefined, store);
      ReservationTemplateValidation.resetErrorMessages();
    } else {
      setErrorVersion(ReservationTemplateValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    dispatch(setReservationTemplate(constants.RESERVATION_TEMPLATE_INPUT_LIST));
    ReservationTemplateValidation.resetErrorMessages();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clear === true) {
      getOne(constants.PATH.SHOP.RESERVATION.RESERVATION_TEMPLATE + "/" + params.id, undefined, (payload) => {
        setReservationTemplateInputList({
          templateCode: payload.templateCode,
          description: payload.description,
          status: payload.status,
          inUse: payload.inUse,
        })

        let tempTimeSectionList = _.cloneDeep(payload.timeSectionList);

        let tempReservationTemplate = [];

        tempTimeSectionList.forEach((tempTimeSection) => {
          if (tempReservationTemplate.length > 0 && tempReservationTemplate.map((reservationTemplate, index) => { return reservationTemplate.timeSectionId }).includes(tempTimeSection.timeSectionId)) {
            tempReservationTemplate.forEach((reservationTemplate, index) => {
              if (reservationTemplate.timeSectionId === tempTimeSection.timeSectionId) {
                if ((!tempTimeSection.timeSectionTcLabel && !tempTimeSection.timeSectionScLabel) || (tempTimeSection.timeSectionTcLabel && (sify(tempTimeSection.timeSectionTcLabel) === tempTimeSection.timeSectionScLabel))) {
                  tempTimeSection.disableLabel = true;
                } else {
                  tempTimeSection.disableLabel = false;
                };
                if ((!tempTimeSection.tableTypeMobileTcLabel && !tempTimeSection.tableTypeMobileScLabel) || (tempTimeSection.tableTypeMobileTcLabel && (sify(tempTimeSection.tableTypeMobileTcLabel) === tempTimeSection.tableTypeMobileScLabel))) {
                  tempTimeSection.disableMobileLabel = true;
                } else {
                  tempTimeSection.disableMobileLabel = false;
                };
                tempTimeSection.customId = tempTimeSection.id;
                tempTimeSection.timeSectionCustomId = reservationTemplate.id;
                reservationTemplate.tableTypeList.push(tempTimeSection)
              }
            })
          } else {
            if ((!tempTimeSection.timeSectionTcLabel && !tempTimeSection.timeSectionScLabel) || (tempTimeSection.timeSectionTcLabel && (sify(tempTimeSection.timeSectionTcLabel) === tempTimeSection.timeSectionScLabel))) {
              tempTimeSection.disableLabel = true;
            } else {
              tempTimeSection.disableLabel = false;
            };
            if ((!tempTimeSection.tableTypeMobileTcLabel && !tempTimeSection.tableTypeMobileScLabel) || (tempTimeSection.tableTypeMobileTcLabel && (sify(tempTimeSection.tableTypeMobileTcLabel) === tempTimeSection.tableTypeMobileScLabel))) {
              tempTimeSection.disableMobileLabel = true;
            } else {
              tempTimeSection.disableMobileLabel = false;
            };
            tempTimeSection.customId = tempTimeSection.id;
            tempTimeSection.timeSectionCustomId = tempTimeSection.id;
            tempReservationTemplate.push({
              id: tempTimeSection.id,
              customId: tempTimeSection.id,
              templateCode: tempTimeSection.templateCode,
              timeSectionId: tempTimeSection.timeSectionId,
              groupedSection: tempTimeSection.groupedSection,
              timeSectionTcLabel: tempTimeSection.timeSectionTcLabel,
              timeSectionEnLabel: tempTimeSection.timeSectionEnLabel,
              timeSectionScLabel: tempTimeSection.timeSectionScLabel,
              disableLabel: tempTimeSection.disableLabel,
              startTime: tempTimeSection.startTime,
              endTime: tempTimeSection.endTime,
              timeSectionDescription: tempTimeSection.timeSectionDescription,
              status: "A",
              tableTypeList: [tempTimeSection]
            })
          }
        })

        dispatch(setReservationTemplate(tempReservationTemplate));
        dispatch(setReservationTemplateTimeSectionValue(constants.RESERVATION_TEMPLATE_INPUT));
        dispatch(setReservationTemplateTableTypeValue(RESERVATION_TABLE_TYPE_LIST_INPUT));
        setSelectedType("");
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedReservationTemplate && savedReservationTemplate.id ? savedReservationTemplate.id : ""}
        resource={resource}
        tabs={tabs}
        content={content}
        bottomBtns={bottomBtns}
        mapName={{ resource: "restaurant", key: "restUrlId" }}
        mapCode={savedRestaurant.restUrlId}
        display="edit"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};