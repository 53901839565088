import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider, createTheme } from '@mui/material/styles'

import { constants, ENV } from './Constants/constants'

import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'

import './styles/global.scss'
import TempAppBar from './AppBar'
import Menu from './Menu'
import { GlobalDialog } from './Component/GlobalDialog'
import RoutesForLogin from './routesForLogin'
import { Loading } from './Component/loading'

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            lineHeight: 1.2,
          },
        },
      },
    },
  },
})

const App = () => {
  const [openMenu, setOpenMenu] = useState(true)
  const token = localStorage.getItem(constants.LOCAL_STORAGE_KEY.TOKEN)
  const message = useSelector((state) => state.system.ui.message)
  const loading = useSelector((state) => state.system.ui.loading)

  function handleOpenMenu() {
    if (!openMenu) {
      setOpenMenu(true)
    } else {
      setOpenMenu(false)
    }
  }

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== ENV.PRODUCTION) {
      document.title = '[' + process.env.REACT_APP_ENV + '] THE GULU Console'
    }
    return () => {
      // componentWillUnmount is here!
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
        {token && <TempAppBar handleOpenMenu={handleOpenMenu} />}
        <div className="content-wrapper">
          {token ? (
            <>
              <Menu openMenu={openMenu} />
              <div className={!openMenu ? 'content-main' : 'content-main-with-menu'}>
                <Routes />
              </div>
            </>
          ) : (
            <div className="content-wrapper login-background">
              <RoutesForLogin />
            </div>
          )}
        </div>
      </BrowserRouter>
      <Loading openLoading={loading} />
      {/* <Dialog /> */}
      {message && <GlobalDialog dialogInfo={{}} />}
    </ThemeProvider>
  )
}

export default App
