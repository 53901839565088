
import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Drawer } from "@mui/material";
import { Card } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import { constants } from '../../Constants/constants';
import { post } from "../../helper/baseFetch";
import { setLoading } from "../../actions/systemAction";
import { setRestaurantTagMap } from "../../actions/restaurantTagMapAction";
import { EditCreateDisplay } from '../EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../Input/TempTextInput"
import { TempBtn } from "../Input/TempBtn"
import { RestaurantList } from "../../Page/Restaurant/RestaurantList";
import { GlobalDialog } from "../GlobalDialog";
import RestaurantMapValidation from "../../validations/restaurantMapValidation";
import { restaurantMapValidationConstants } from '../../Constants/validationConstants';

export const RestaurantMapCreate = ({ resourceName, pathName, backListName, newShowRestaurantTextPanel, restaurantTagCreate, reset }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const [showRestaurantTextPanel, setShowRestaurantTextPanel] = useState(newShowRestaurantTextPanel === false ? newShowRestaurantTextPanel : true);
  // const [selectedList, setSelectedList] = useState([]);
  // const [selectedId, setSelectedId] = useState([]);
  const [selectMap, setSelectMap] = useState(new Map());
  const [confirmList, setConfirmList] = useState([]);
  const [clear, setClear] = useState(false);
  // const [previewSelectedList, setPreviewSelectedList] = useState([]);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const savedRestaurantTag = useSelector(state => state.restaurantTag.ui.restaurantTag)
  const savedRestaurantTagMap = useSelector(state => state.restaurantTagMap.ui.restaurantTagMap)
  const [, setErrorVersion] = useState(RestaurantMapValidation.getVersion());

  const tabs = [
    {
      label: { resource: resourceName, key: "info" },
      tabIndex: constants.AREA_EDIT_TAB.INFO
    },
  ]

  // const processAddRedeemLocation = (selectedId) => {
  //   dispatch(setLoading(true));
  //   return new Promise((resolve) => {
  //     let saveList = _.cloneDeep(savedRestaurantTagMap);
  //     let oldRestaurantMapList = saveList.map((item) => { return item.restUrlId })

  //     selectedId = selectedId.filter((item) => {
  //       return item
  //     })

  //     saveList = saveList.filter((redeemLocationList) => {
  //       if (!redeemLocationList.id) {
  //         return selectedId.includes(redeemLocationList.restUrlId)
  //       } else {
  //         return redeemLocationList
  //       }
  //     })

  //     let oldRedeem;
  //     oldRedeem = selectedId.filter((oldRestaurantMap) => {
  //       return !oldRestaurantMapList.includes(oldRestaurantMap)
  //     })

  //     oldRedeem.forEach((item) => {
  //       previewSelectedList.forEach((previewSelected) => {
  //         if (previewSelected.id === item) {
  //           saveList.push(
  //             {
  //               id: "",
  //               restUrlId: item,
  //               restTcName: previewSelected.tcName,
  //               restEnName: previewSelected.enName,
  //               restScName: previewSelected.scName,
  //               startTimestamp: null,
  //               endTimestamp: null,
  //               sequence: 0,
  //             }
  //           )
  //         }
  //       })
  //     })
  //     setSelectedList([]);
  //     setSelectedId(selectedId);
  //     resolve(saveList);
  //     dispatch(setLoading(false));
  //   });
  // }

  const handleRestUrlId = async () => {
    // const tempRedeemLocationList = await processAddRedeemLocation(selectedId);
    const confirmList = Array.from(selectMap, ([name, value]) => ({...value, name: null, address: null}));
    setConfirmList([...confirmList]);
    setShowRestaurantTextPanel(false);
    // setPreviewSelectedList([]);
    dispatch(setRestaurantTagMap([...confirmList]));
  }

  function handeleDelete(restUrlId) {
    // let saveList = _.cloneDeep(savedRestaurantTagMap);
    // setSelectedList(selectedList.filter((item, index) => { return index !== deleteIndex }));
    // setSelectedId(selectedId.filter((item, index) => { return index !== deleteIndex }));
    // dispatch(setRestaurantTagMap(saveList.filter((item, index) => { return index !== deleteIndex })));

    const temptMap = _.cloneDeep(selectMap);
    temptMap.delete(restUrlId);
    const confirmList = Array.from(temptMap, ([name, value]) => ({...value, name: null, address: null}));
    setSelectMap(temptMap);
    setConfirmList([...confirmList]);
    dispatch(setRestaurantTagMap([...confirmList]));
  }

  let content = [
    {
      tab:
        <>
          <>
            {RestaurantMapValidation.getErrorMessages(restaurantMapValidationConstants.KEY_REST_MAP_ERROR) &&
              RestaurantMapValidation.getErrorMessages(restaurantMapValidationConstants.KEY_REST_MAP_ERROR).length > 0 &&
              <div className="error-message">
                <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                {RestaurantMapValidation.getErrorMessages(restaurantMapValidationConstants.KEY_REST_MAP_ERROR)}
              </div>
            }
          </>
          <TempBtn
            btnSetting=
            {{
              onClick: handleAddRestaurantMapList,
              variant: constants.STYLE.VARIANT.CONTAINED,
              color: constants.STYLE.PRIMARY,
              icon: <AddIcon />,
              label: { resource: resourceName, key: "addRestaurantMap" },
            }}
          >
          </TempBtn>
          {savedRestaurantTagMap && savedRestaurantTagMap.length > 0 && savedRestaurantTagMap.map((item, index) => {
            let tempList = [
              {
                className: "edit-create-input",
                variant: constants.STYLE.VARIANT.STANDARD,
                size: constants.SIZE.SMALL,
                label: { resource: resourceName, key: "restUrlId" },
                value: item.restUrlId,
                name: "restUrlId",
                disabled: true,
                isEndOfLine: true,
                required: true,
              },
              {
                className: "edit-create-input",
                variant: constants.STYLE.VARIANT.STANDARD,
                size: constants.SIZE.SMALL,
                label: { resource: resourceName, key: "restEnName" },
                value: item.enName,
                name: "restEnName",
                disabled: true,
                isEndOfLine: false
              },
              {
                className: "edit-create-input",
                variant: constants.STYLE.VARIANT.STANDARD,
                size: constants.SIZE.SMALL,
                label: { resource: resourceName, key: "restTcName" },
                value: item.tcName,
                name: "restTcName",
                disabled: true,
                isEndOfLine: false
              },
              {
                className: "edit-create-input",
                variant: constants.STYLE.VARIANT.STANDARD,
                size: constants.SIZE.SMALL,
                label: { resource: resourceName, key: "restScName" },
                value: item.scName,
                name: "restScName",
                disabled: true,
                isEndOfLine: true
              },
              {
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                label: { resource: resourceName, key: "startTimestamp" },
                value: item.startTimestamp,
                type: constants.TYPE.DATETIME_LOCAL,
                step: 1,
                clear: true,
                name: "startTimestamp",
                isEndOfLine: false
              },
              {
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                label: { resource: resourceName, key: "endTimestamp" },
                value: item.endTimestamp,
                type: constants.TYPE.DATETIME_LOCAL,
                step: 1,
                clear: true,
                name: "endTimestamp",
                isEndOfLine: false
              },
              {
                className: "edit-create-input",
                size: constants.SIZE.SMALL,
                label: { resource: resourceName, key: "sequence" },
                value: item.sequence,
                min: 0,
                name: "sequence",
                type: constants.TYPE.NUMBER,
                isEndOfLine: false
              },
            ]
            return <Card key={index} className="image-list"> {
              <>
                <div className="delete-icon">
                  <TempBtn btnSetting={{
                    className: "edit-create-btn",
                    variant: constants.STYLE.VARIANT.CONTAINED,
                    color: constants.STYLE.PRIMARY,
                    onClick: () => handeleDelete(item.restUrlId),
                    label: { key: "deleteBtn" },
                    icon: <DeleteIcon />,
                  }} />
                </div>
                {tempList.map((inputSetting, inputIndex) => {
                  return <div className="edit-display-wrapper" key={inputIndex}>
                    <TempTextInput
                      inputSetting={inputSetting}
                      handleChange={onChangeInput}
                      index={index}
                    />
                    {inputSetting.isEndOfLine && <div></div>}
                  </div>
                })}
              </>
            }
            </Card>
          })}
          <Drawer
            classes={{ paper: "drawer-margin-left" }}
            anchor="right"
            open={showRestaurantTextPanel}
            onClose={handleRestaurantTextCloseClick}
          >
            <RestaurantList
              editBtn={false}
              createBtn={false}
              btnList={false}
              mutipleSelect={true}
              selectMap={selectMap}
              setSelectMap={setSelectMap}
              // setSelectedIdList={setSelectedId}
              // selectedIdList={selectedId}
              // setPreviewSelectedList={setPreviewSelectedList}
              // previewSelectedList={previewSelectedList}
              newPath={restaurantTagCreate === "RestaurantTagCreate" ? constants.PATH.SHOP.RESTAURANT : constants.PATH.RESTAURANT_TAG.RESTAURANT_LIST}
              // newFilter={savedRestaurantTag.id && selectedId && selectedId.length > 0 ?
              //   { tagId: savedRestaurantTag.id, selected: { column: 'restUrlId', valueList: selectedId.map((item) => { return item }).reverse() } } :
              //   selectedId && selectedId.length > 0 ?
              //     { selected: { column: 'restUrlId', valueList: selectedId.map((item) => { return item }).reverse() } } :
              //     savedRestaurantTag.id ? { tagId: savedRestaurantTag.id } : null}
              newFilter={savedRestaurantTag.id && selectMap && selectMap.size > 0 ?
                { tagId: savedRestaurantTag.id, selected: { column: 'restUrlId', valueList: Array.from(selectMap, ([name, value]) => (name)) }} :
                selectMap && selectMap.size > 0 ?
                  { selected: { column: 'restUrlId', valueList: Array.from(selectMap, ([name, value]) => (name)) }} :
                  savedRestaurantTag.id ? { tagId: savedRestaurantTag.id } : null}
              savedData={savedRestaurantTagMap}
              dialogFooterBtn={
                <div className="get-push-card-wrapper">
                  <TempBtn
                    btnSetting=
                    {{
                      className: "get-push-card-cancel-btn",
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: handleRestUrlId,
                      icon: <AddIcon />,
                      label: { key: "submit" },
                    }}
                  >
                  </TempBtn>
                  <TempBtn
                    btnSetting=
                    {{
                      className: "get-push-card-cancel-btn",
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: handleRestaurantTextCloseClick,
                      icon: <ClearIcon />,
                      label: { key: "close" },
                    }}
                  >
                  </TempBtn>
                </div>}
            />
          </Drawer>
        </>,
      btn: !restaurantTagCreate ? true : false
    },
  ]

  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  function handleRestaurantTextCloseClick() {
    dispatch(setLoading(true));
    setShowRestaurantTextPanel(false);
    // setPreviewSelectedList([]);
    // setSelectedId(_.cloneDeep(savedRestaurantTagMap.map((item) => item.restUrlId)));
    setSelectMap(new Map(confirmList.map(i => [i.restUrlId, i])));
    dispatch(setLoading(false));
  };

  function handleAddRestaurantMapList() {
    dispatch(setLoading(true));
    // setRestUrlIdList(formData.restaurantMapList.map((item) => { return item.restUrlId }))
    setShowRestaurantTextPanel(true);
    dispatch(setLoading(false));
  }

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    onClickClear();
  };
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + backListName + "/" + savedRestaurantTag.id + "/" + constants.TAG_EDIT_TAB.RESTAURANT_MAP_LIST);
  };

  function onChangeInput(name, e, index) {
    let tempSavedRestaurantTagMap = _.cloneDeep(savedRestaurantTagMap);
    if (name === "startTimestamp" || name === "endTimestamp") {
      if (e === "") {
        e = null
      }
      tempSavedRestaurantTagMap[index][`${name}`] = e ? Number(moment(e).format("x")) : "";
    } else if (name === "sequence") {
      tempSavedRestaurantTagMap[index][`${name}`] = Number.isInteger(Number(e)) ? Number(e) : 0;
    } else {
      tempSavedRestaurantTagMap[index][`${name}`] = e;
    }
    dispatch(setRestaurantTagMap(tempSavedRestaurantTagMap));
  }

  function onClickSave() {
    RestaurantMapValidation.validateCreateRestaurantMap(savedRestaurantTagMap);
    if (RestaurantMapValidation.isValid()) {
      const confirmedRestaurantTagMap = savedRestaurantTagMap.map((item)=>({restUrlId: item.restUrlId, startTimestamp: item.startTimestamp, endTimestamp: item.endTimestamp, sequence: item.sequence}))
      post(pathName, { tagId: savedRestaurantTag.id, restaurantMapList: confirmedRestaurantTagMap }, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: "createSuccess" },
          dialogActions: [
            { label: { key: "backCreate" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      RestaurantMapValidation.resetErrorMessages();
    } else {
      setErrorVersion(RestaurantMapValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    dispatch(setLoading(true));
    resetData();
    dispatch(setLoading(false));
    RestaurantMapValidation.resetErrorMessages();
  }, [clear === true || reset === true]); // eslint-disable-line react-hooks/exhaustive-deps

  const resetData = () => {
    dispatch(setRestaurantTagMap([]));
    setSelectMap(new Map());
    setConfirmList([]);
    // setSelectedId([]);
    // setSelectedList([]);
    // setPreviewSelectedList([]);
  }

  return (
    <>
      <EditCreateDisplay
        code=""
        resource={resourceName}
        tabs={!restaurantTagCreate ? tabs : ""}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        mapName={{ resource: resourceName, key: "tagCode" }}
        mapCode={savedRestaurantTag.tagCode}
        display={restaurantTagCreate === "RestaurantTagCreate" ? "none" : "create"}
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};