import { Clear } from '@mui/icons-material'
import { Autocomplete, Box, Chip, IconButton, TextField, Tooltip } from '@mui/material'
import { useRef } from 'react'
import { useEffect, useState } from 'react'
import variables from '../../../styles/globalVariables.scss'

const { blackColor, subItemColor, middleGrayColor } = variables

export default function MUISelectField({
  valueKey = 'value',
  label,
  onChange,
  options,
  value,
  size = 'small',
  required,
  sx = {},
  filterSelectedOptions = false,
  getOptionLabel = (option) => option.label,
  multiple,
  disableCloseOnSelect,
  renderOption = (props, option) => (
    <li {...props} key={option[valueKey]}>
      {option.label}
    </li>
  ),
  disabled,
  error,
  endAdornment,
  readOnly = false,
  showClearAdornment = false,
  showSelectAllAdornment = false,
  showLimitTags = false,
  // https://mui.com/material-ui/react-autocomplete/#playground
}) {
  const [optionValue, setOptionValue] = useState(multiple ? [] : null)
  const [inputValue, setInputValue] = useState('')
  const autocompleteRef = useRef(null)
  const tagRefs = useRef([]) // Create refs for each tag
  const [inputBaseWidth, setInputBaseWidth] = useState(0)
  const [limitTags, setLimitTags] = useState(10000)
  const [tags, setTags] = useState([])

  const newSx = {
    icon: {
      '& .MuiIconButton-root': {
        color: `${subItemColor}`,
      },
    },
    label: {
      '& .MuiInputBase-root': {
        color: `#2e7d32`,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `#4caf50`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#4caf50',
      },
    },
    '& .MuiFormLabel-root': {
      color: `${blackColor}`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${blackColor}`,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'rgba(18, 18, 18, 0.54)',
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: 'error.main'
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'error.main'
    },
    ...sx,
  }

  const handleResize = () => {
    const inputBaseDiv = Array.from(autocompleteRef.current.getElementsByTagName('div')).find(
      (div) => div.classList.contains('MuiInputBase-root') && div.classList.contains('MuiOutlinedInput-root')
    )

    if (inputBaseDiv) {
      const computedStyle = window.getComputedStyle(inputBaseDiv)
      const paddingLeft = parseFloat(computedStyle.paddingLeft)
      const paddingRight = parseFloat(computedStyle.paddingRight)
      const newInputBaseWidth = inputBaseDiv.clientWidth - paddingLeft - paddingRight - 60

      setInputBaseWidth(newInputBaseWidth)
      setLimitTags(10)
    }
  }

  useEffect(() => {
    if (!options || options?.length === 0) {
      return
    }

    if (multiple) {
      setOptionValue(value)
    } else {
      const newOptionValue = options.find((option) => option[valueKey] === value) || null
      setOptionValue(newOptionValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options])

  useEffect(() => {
    if (showLimitTags && multiple && autocompleteRef.current) {
      handleResize()
      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autocompleteRef])

  useEffect(() => {
    if (!showLimitTags || !multiple || inputBaseWidth === 0 || tags.length === 0) {
      return
    }

    let totalTagsWidth = 0

    tagRefs.current.forEach((tagRef, idx) => {
      if (tagRef) {
        const tagWidth = tagRef.clientWidth

        if (tagWidth > 0) {
          totalTagsWidth += tagWidth
        }

        if (totalTagsWidth > inputBaseWidth) {
          setLimitTags(idx - 1)
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputBaseWidth, tags, optionValue])

  return (
    <Autocomplete
      ref={autocompleteRef}
      multiple={multiple}
      disableClearable={true}
      groupBy={(option) => option.group}
      getOptionDisabled={(option) => option.disabled}
      // disableClearable={!showClearAdornment}
      sx={newSx}
      size={size}
      fullWidth
      value={optionValue}
      options={options || []}
      onChange={(e, newOptionValue) => {
        e.preventDefault()
        setOptionValue(newOptionValue)
        onChange(newOptionValue)
      }}
      inputValue={inputValue}
      onInputChange={(e, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          InputProps={{
            ...params.InputProps,
            sx: { position: 'relative' },
            endAdornment: (
              <>
                <Box
                  sx={{
                    position: 'absolute',
                    right: 0,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    marginRight: '33px',
                  }}
                >
                  {!disabled && endAdornment}
                  {showSelectAllAdornment && options.length !== optionValue.length && multiple && !disabled && !readOnly && (
                    <Tooltip title="Select All" placement="top">
                      <IconButton
                        onClick={() => {
                          setOptionValue(options)
                          onChange(options)
                        }}
                        sx={{ p: '2px' }}
                      ></IconButton>
                    </Tooltip>
                  )}
                  {showClearAdornment && !disabled && !readOnly && optionValue && (
                    <Tooltip title="Clear All" placement="top">
                      <IconButton
                        onClick={() => {
                          setOptionValue(multiple ? [] : null)
                          onChange(multiple ? [] : null)
                        }}
                        sx={{ p: '2px' }}
                      >
                        <Clear />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
                {params.InputProps.endAdornment}
              </>
            ),
            readOnly: readOnly,
          }}
          error={!!error}
          helperText={error}
        />
      )}
      getOptionLabel={getOptionLabel}
      filterSelectedOptions={filterSelectedOptions}
      isOptionEqualToValue={(option, value) => option[valueKey] === value[valueKey]}
      disableCloseOnSelect={disableCloseOnSelect}
      renderOption={(props, option) => renderOption(props, option)}
      disabled={disabled}
      limitTags={limitTags}
      renderTags={(renderTags, getTagProps) => {
        setTags(renderTags)
        return renderTags.map((renderTag, idx) => {
          return (
            <Chip
              {...getTagProps({ index: idx })}
              key={idx}
              label={getOptionLabel(renderTag)}
              ref={(renderTag) => (tagRefs.current[idx] = renderTag)}
            />
          )
        })
      }}
    />
  )
}
