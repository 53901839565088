import { useStore } from 'react-redux'
import { Box, Button, IconButton, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { newGetList } from '../../helper/baseFetch'
import MultiLangLabel from '../../Component/common/label/MultiLangLabel'
import MUITable from '../../Component/common/table/MUITable'
import Iconify from '../../Component/common/iconify/Iconify'
import { ICONIFY } from '../../Constants/iconify'
import { API_PATH } from '../../Constants/path'
import Region from './Region'
import { Add } from '@mui/icons-material'
import SharedDialog from '../../Component/common/dialog/SharedDialog'

export default function RegionList({ onClickRegionRow }) {
  const store = useStore()
  const [regionList, setRegionList] = useState([])
  const [id, setId] = useState('')

  const [regionListLength, setRegionListLength] = useState(0)
  const [regionListPagination, setRegionListPagination] = useState({
    sort: 'updateTimestamp',
    order: 'DESC',
    pageSize: 10,
    page: 0,
    filter: JSON.stringify({}),
  })

  const [showRegion, setShowRegion] = useState(false)
  const [isCreateMode, setIsCreateMode] = useState(false)
  const [showSaveDialog, setShowSaveDialog] = useState(false)

  const columns = [
    {
      field: 'regionCode',
      headerName: 'Region Code',
      width: 100,
    },
    {
      field: 'name',
      headerName: 'Name',
      renderCell: (params) => {
        return <MultiLangLabel nameObj={params.row.name} direction="vertical" gap={0.5} />
      },
      flex: 1,
    },
    {
      field: 'sequence',
      headerName: 'Sequence',
      width: 100,
    },
    {
      field: 'backgroundColor',
      headerName: 'Background Color',
      width: 150,
      renderCell: (params) => {
        return (
          <Stack direction="column" gap={0.5}>
            {params.row.backgroundColor}
            <Box sx={{ backgroundColor: params.row.backgroundColor, borderRadius: '5px', height: '50px', width: '50px' }} />
          </Stack>
        )
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
    },
    onClickRegionRow
      ? {}
      : {
          field: '',
          headerName: '',
          width: 50,
          renderCell: (params) => {
            return (
              <IconButton onClick={() => onClickEditRegion(params.row.id)}>
                <Iconify iconify={ICONIFY.edit} />
              </IconButton>
            )
          },
        },
  ]

  const getRegionList = () => {
    newGetList({
      path: API_PATH.region,
      queryObj: regionListPagination,
      successCallback: (payload) => setRegionList(payload),
      store: store,
      getListLength: (listLength) => setRegionListLength(listLength),
    })
  }

  const onClickCreate = () => {
    setIsCreateMode(true)
    setShowRegion(true)
  }

  const onClickEditRegion = (id) => {
    setId(id)
    setIsCreateMode(false)
    setShowRegion(true)
  }

  useEffect(() => {
    getRegionList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionListPagination])

  const [filterRegionCode, setFilterRegionCode] = useState('')

  return (
    <>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}>
        {!onClickRegionRow && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Button startIcon={<Add />} variant="contained" onClick={onClickCreate}>
              Add New Region
            </Button>
          </Box>
        )}
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          <MUITable
            columns={columns}
            rows={regionList}
            pagination={regionListPagination}
            setPagination={setRegionListPagination}
            listLength={regionListLength}
            onClickRow={onClickRegionRow}
          />
        </Box>
      </Box>
      {showRegion && (
        <Region
          id={id}
          isCreateMode={isCreateMode}
          showRegion={showRegion}
          setShowRegion={setShowRegion}
          getRegionList={getRegionList}
          setShowSaveDialog={setShowSaveDialog}
        />
      )}
      {showSaveDialog && (
        <SharedDialog
          open={showSaveDialog}
          onClose={() => setShowSaveDialog(false)}
          title=""
          content={<Box sx={{ display: 'flex', alignItems: 'center' }}>Successfully saved!</Box>}
          maxWidth="md"
          actions={
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" onClick={() => setShowSaveDialog(false)}>
                OK
              </Button>
            </Box>
          }
        />
      )}
    </>
  )
}
