import { useState, useEffect } from 'react'
import { constants } from '../../../Constants/constants'
import { useSelector, useDispatch, useStore } from 'react-redux'
import _ from 'lodash'

import MTextField from '@mui/material/TextField'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CheckIcon from '@mui/icons-material/Check'
import CancelIcon from '@mui/icons-material/Cancel'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { makeStyles } from '@mui/styles'

import { BaseWrapper } from '../../BaseWrapper'
import { setProductValue } from '../../../actions/productCreateAction'
import ProductCreateValidation from '../../../validations/productCreateValidation'
import { productValidationConstants } from '../../../Constants/validationConstants'
import { setLoading } from '../../../actions/systemAction'
import { getOne } from '../../../helper/baseFetch'
import { ProductListCheckBox } from './ProductListCheckBox'
import { TempBtn } from '../../Input/TempBtn'
import { getString, permissionsCheck, getScDisabled } from '../../../helper/util'
import { GlobalDialog } from '../../GlobalDialog'

import '../../../styles/productDraftCreate.scss'
import { getClonedProduct } from '../../../helper/Product/productUtil'

const normalTextStyle = makeStyles((theme) => ({
  label: {
    '& .MuiFormLabel-root': {
      color: '#121212',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#121212',
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'rgba(18, 18, 18, 0.54)',
    },
  },
}))

export const ProductCreateProductList = () => {
  const [disableInput, setDisableInput] = useState(false)
  const [hiddenInput, setHiddenInput] = useState(false)

  const permission = localStorage.getItem(constants.LOCAL_STORAGE_KEY.ROLE)

  const dispatch = useDispatch()
  const store = useStore()
  const savedProduct = useSelector((state) => state.product.ui.product)
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)
  const [mezzofyCouponID, setMezzofyCouponID] = useState(undefined)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const normalTextStyleClasses = normalTextStyle()

  useEffect(() => {
    if (permissionsCheck(constants.PERMISSION_KEY.PRODUCT_CREATE_LIST_FINANCE)) {
      setDisableInput(true)
      setHiddenInput(true)
    } else {
      setDisableInput(false)
      setHiddenInput(false)
    }
  }, [permission]) // eslint-disable-line react-hooks/exhaustive-deps

  function addMezzofyProduct() {
    dispatch(setLoading(true))
    handleDialog(undefined)
    dispatch(setLoading(false))
  }

  const handleTypeChangeDialogClose = () => {
    dispatch(setLoading(true))
    setDialog({
      open: false,
      dialogActions: [],
    })
    setMezzofyCouponID()
    dispatch(setLoading(false))
  }

  const handleTypeChangeDialogConfirm = () => {
    let tempDraft = _.cloneDeep(savedProduct.draft)
    let productList = tempDraft.productList
    let subProductList = tempDraft.subProductList
    let redeemGroupList = tempDraft.redeemGroupList
    let tempProductCode = _.cloneDeep(savedProduct.draft.codeNumber)
    getOne(
      constants.PATH.PRODUCT.MEZZOFY_COUPON_DATA + '/' + mezzofyCouponID,
      undefined,
      (payload) => {
        payload.scNameDisable = getScDisabled(payload.tcName, payload.scName)
        payload.scDescriptionDisable = getScDisabled(payload.tcDescription, payload.scDescription)
        payload.scTacDisable = getScDisabled(payload.tcTac, payload.scTac)
        payload.scRedeemDescriptionDisable = getScDisabled(payload.tcRedeemDescription, payload.scRedeemDescription)
        payload.scDiscountDescriptionDisable = getScDisabled(payload.tcDiscountDescription, payload.scDiscountDescription)
        if (payload.attributeList && payload.attributeList.length > 0) {
          payload.attributeList.map((attribute, attributeIndex) => {
            if (attribute.optionList && attribute.optionList.length > 0) {
              attribute.optionList.map((optionItem, optionIndex) => {
                optionItem.scValueDisable = getScDisabled(optionItem.tcValue, optionItem.scValue)
                return optionItem
              })
            }
            return attribute
          })
        }

        payload.redeemGroup.redeemMezzofy = true

        let date = new Date()
        let minDate = date.setHours(0, 0, 0)
        let maxDate = date.setHours(23, 59, 59)

        if (!payload.redeemGroup.startRedeemTimestamp) {
          payload.redeemGroup.startRedeemTimestamp = minDate
        }
        if (!payload.redeemGroup.endRedeemTimestamp) {
          payload.redeemGroup.endRedeemTimestamp = maxDate
        }

        payload.product.lotInventoryList.forEach((lotInventory) => {
          lotInventory.lotInventoryValiCode = 'Lot_Inventory_' + tempProductCode
          tempProductCode = tempProductCode + 1
        })
        if (payload.product.subProductMapList) {
          payload.product.subProductMapList.forEach((item) => {
            item.subProductMapValiCode = 'Sub_Product_Map_' + tempProductCode
            tempProductCode = tempProductCode + 1
          })
        }
        if (payload.subProductList) {
          payload.subProductList.forEach((item) => {
            subProductList.push(item)
          })
        }
        productList.push(payload.product)
        redeemGroupList.push(payload.redeemGroup)
        tempDraft.productList = productList
        tempDraft.subProductList = subProductList
        tempDraft.redeemGroupList = redeemGroupList
        tempDraft.codeNumber = tempProductCode + 1
        setDialog({
          open: false,
          dialogActions: [],
        })
        dispatch(setProductValue('', _.cloneDeep(savedProduct), '', tempDraft))
      },
      undefined,
      store
    )
  }

  function createProduct(cloneProduct) {
    dispatch(setLoading(true))
    let tempDraft = _.cloneDeep(savedProduct.draft)
    let tempProductCode = _.cloneDeep(savedProduct.draft.codeNumber)
    tempDraft.product = cloneProduct || _.cloneDeep(constants.PRODUCT)
    tempDraft.product.productCode = 'Product_' + tempProductCode
    tempDraft.codeNumber = tempProductCode + 1
    dispatch(setProductValue('', _.cloneDeep(savedProduct), '', tempDraft))
    dispatch(setLoading(false))
  }

  function handleDialog(tempMezzofyCouponID) {
    setDialog({
      open: true,
      onClose: handleTypeChangeDialogClose,
      dialogTitle: { resource: 'productCreateProductList', key: 'addMezzofyProduct' },
      dialogContent: (
        <MTextField
          className={`${normalTextStyleClasses.label} mezzofy-coupon-ID-input`}
          size={constants.SIZE.SMALL}
          variant={constants.STYLE.VARIANT.OUTLINED}
          label={getString(lang, 'productCreateProductList', 'mezzofyCouponID', theme)}
          value={tempMezzofyCouponID}
          onChange={(item) => setMezzofyCouponID(item.target.value)}
        />
      ),
      dialogActions: [
        { label: { key: 'backEdit' }, onClick: handleTypeChangeDialogClose, icon: <ArrowBackIcon /> },
        { label: { key: 'comfirm' }, onClick: handleTypeChangeDialogConfirm, icon: <CheckIcon />, disabled: !tempMezzofyCouponID },
      ],
    })
  }

  const cloneProduct = (e, targetProduct, index) => {
    e.stopPropagation()
    const clonedItem = getClonedProduct(targetProduct)
    createProduct(clonedItem)
  }

  useEffect(() => {
    if (mezzofyCouponID) {
      handleDialog(mezzofyCouponID)
    }
  }, [mezzofyCouponID]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BaseWrapper className="list-wrapper product-list-wrapper">
      <div className="array-list-wrapper product-array-list-wrapper">
        <div className="sub-title">{getString(lang, 'productCreateProductList', 'listName', theme)} : </div>
        {!hiddenInput && (
          <>
            <TempBtn
              btnSetting={{
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                onClick: addMezzofyProduct,
                label: { resource: 'productCreateProductList', key: 'addMezzofyProduct' },
                disabled: disableInput,
                icon: <AddIcon />,
              }}
            />
            <TempBtn
              btnSetting={{
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                onClick: ()=>createProduct(),
                label: { key: 'create' },
                disabled: disableInput,
                icon: <AddIcon />,
              }}
            />
          </>
        )}
      </div>
      <div className="step-content-content">
        <ProductListCheckBox listCheckBox="productListCheckBox" />
        {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_LIST).map((error) => {
          return error
        }) &&
          ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_LIST).map((error) => {
            return error
          }).length > 0 && (
            <div className="error-message">
              <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
              {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_LIST).map((error) => {
                return error
              })}
            </div>
          )}
        <div className="tab-background">
          {savedProduct.draft.productList.map((item, index) => {
            let list = ''
            if (savedProduct.draft.productListCheckBox[item.type] || savedProduct.draft.productListCheckBox['ALL']) {
              list =
                item.type !== constants.PRODUCT_TYPE.PACKAGE_ITEM ? (
                  <div
                    key={item.productCode}
                    className={
                      savedProduct.draft.product.productCode === item.productCode
                        ? 'product-create-list-item selected-product'
                        : 'product-create-list-item'
                    }
                    onClick={(e) => {
                      dispatch(setLoading(true))
                      e.stopPropagation()
                      dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, _.cloneDeep(savedProduct), 'product', _.cloneDeep(item)))
                      dispatch(setLoading(false))
                    }}
                  >
                    <div className="code">
                      <div>{item.productCode}</div>
                      <div className="type">{item.type}</div>
                    </div>
                    <div className="content">
                      <div className="top">
                        <div className="name">{item.tcName}</div>
                      </div>
                      <div className="button-wrapper">
                        <div className="price">${item.sellingPrice}</div>
                        {!hiddenInput && (
                          <>
                            <TempBtn
                              btnSetting={{
                                className: 'delete-button',
                                variant: constants.STYLE.VARIANT.CONTAINED,
                                color: constants.STYLE.PRIMARY,
                                label: { key: 'deleteBtn' },
                                onClick: (e) => {
                                  e.stopPropagation()
                                  let newProductList = _.cloneDeep(
                                    savedProduct.draft.productList.filter((productListItem, productListIndex) => {
                                      return productListItem.productCode !== item.productCode
                                    })
                                  )
                                  dispatch(setLoading(true))
                                  dispatch(
                                    setProductValue(
                                      constants.ACTION_KEY.PRODUCT.PRODUCT_INFO,
                                      _.cloneDeep(savedProduct),
                                      '',
                                      {
                                        subProductList: _.cloneDeep(savedProduct.draft.subProductList),
                                        product:
                                          newProductList.length > 0
                                            ? newProductList[0]
                                            : savedProduct.draft.subProductList.length > 0
                                            ? savedProduct.draft.subProductList[0]
                                            : {},
                                        productList: newProductList,
                                      },
                                      0,
                                      'delete'
                                    )
                                  )
                                  dispatch(setLoading(false))
                                },
                                disabled: disableInput,
                                icon: <DeleteIcon />,
                              }}
                            />
                            <TempBtn
                              btnSetting={{
                                className: 'delete-button',
                                variant: constants.STYLE.VARIANT.CONTAINED,
                                color: constants.STYLE.PRIMARY,
                                label: { key: 'Clone' },
                                onClick: (e) => cloneProduct(e, item, index),
                                disabled: disableInput,
                                icon: <FileCopyIcon />,
                              }}
                            />
                          </>
                        )}
                      </div>
                      {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_ERROR, item.productCode).map((error) => {
                        return error
                      }) &&
                        ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_ERROR, item.productCode).map((error) => {
                          return error
                        }).length > 0 && (
                          <div className="error-message">
                            <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                            {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_ERROR, item.productCode).map((error) => {
                              return error
                            })}
                          </div>
                        )}
                    </div>
                  </div>
                ) : (
                  <div
                    key={item.productCode}
                    className={
                      savedProduct.draft.product.productCode === item.productCode
                        ? 'product-create-list-item selected-product'
                        : 'product-create-list-item'
                    }
                    onClick={(e) => {
                      dispatch(setLoading(true))
                      e.stopPropagation()
                      dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, _.cloneDeep(savedProduct), 'product', _.cloneDeep(item)))
                      dispatch(setLoading(false))
                    }}
                  >
                    <div className="code">
                      {item.productCode}
                      <div className="type">{item.type}</div>
                    </div>
                    <div className="content">
                      <div className="top">
                        <div className="name">{item.tcName}</div>
                        <div className="price">${item.sellingPrice}</div>
                        {!hiddenInput && (
                          <TempBtn
                            btnSetting={{
                              className: 'delete-button',
                              variant: constants.STYLE.VARIANT.CONTAINED,
                              color: constants.STYLE.PRIMARY,
                              label: { key: 'deleteBtn' },
                              onClick: (e) => {
                                e.stopPropagation()
                                let newProductList = _.cloneDeep(
                                  savedProduct.draft.productList.filter((productListItem, productListIndex) => {
                                    return productListItem.productCode !== item.productCode
                                  })
                                )
                                dispatch(setLoading(true))
                                dispatch(
                                  setProductValue(
                                    constants.ACTION_KEY.PRODUCT.PRODUCT_INFO,
                                    _.cloneDeep(savedProduct),
                                    '',
                                    {
                                      subProductList: _.cloneDeep(savedProduct.draft.subProductList),
                                      product:
                                        newProductList.length > 0
                                          ? newProductList[0]
                                          : savedProduct.draft.subProductList.length > 0
                                          ? savedProduct.draft.subProductList[0]
                                          : {},
                                      productList: newProductList,
                                    },
                                    0,
                                    'delete'
                                  )
                                )
                                dispatch(setLoading(false))
                              },
                              disabled: disableInput,
                              icon: <DeleteIcon />,
                            }}
                          />
                        )}
                      </div>
                      <div className="bottom">
                        {item.subProductMapList.map((subProductMapListItem, subProductMapListIndex) => {
                          const subItem = _.cloneDeep(
                            savedProduct.draft.subProductList.find((item) => {
                              return item.productCode === subProductMapListItem.productCode
                            })
                          )
                          return (
                            <div
                              key={subProductMapListItem.productCode + ' ' + subProductMapListIndex}
                              className={
                                savedProduct.draft.product.productCode === subProductMapListItem.productCode
                                  ? 'sub-item selected-product'
                                  : 'sub-item'
                              }
                              onClick={(e) => {
                                dispatch(setLoading(true))
                                e.stopPropagation()
                                if (subItem) {
                                  dispatch(
                                    setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, _.cloneDeep(savedProduct), 'product', _.cloneDeep(subItem))
                                  )
                                }
                                dispatch(setLoading(false))
                              }}
                            >
                              <div className="code">{subItem && subItem.productCode}</div>
                              <div className="sub-centent">
                                <div className="sub-content-item">
                                  <div className="sub-item-name">{subItem ? subItem.tcName : ''}</div>
                                  <div className="multiply-icon">x</div>
                                  <div className="quantity">{subProductMapListItem.quantity}</div>
                                </div>
                                {!disableInput && (
                                  <div
                                    className="button-on-top"
                                    onClick={(e) => {
                                      dispatch(setLoading(true))
                                      e.stopPropagation()
                                      let tempDraft = _.cloneDeep(savedProduct.draft)
                                      let tempProductList = _.cloneDeep(savedProduct.draft.productList)
                                      let tempProduct = _.cloneDeep(savedProduct.draft.product)

                                      if (tempProduct) {
                                        if (tempProduct.subProductMapList) {
                                          tempProduct.subProductMapList = tempProduct.subProductMapList.filter(
                                            (subProductMap) => subProductMap.productCode !== subProductMapListItem.productCode
                                          )
                                        }
                                        if (tempProduct.lotInventoryList) {
                                          tempProduct.lotInventoryList.map((lotInventory) => {
                                            lotInventory.subProductLotInventoryList = lotInventory.subProductLotInventoryList.filter(
                                              (subProductLotInventory) => subProductLotInventory.productCode !== subProductMapListItem.productCode
                                            )
                                            return lotInventory
                                          })
                                        }
                                      }

                                      tempProductList.map((item) => {
                                        if (item) {
                                          if (item.subProductMapList) {
                                            item.subProductMapList = item.subProductMapList.filter(
                                              (subProductMap) => subProductMap.productCode !== subProductMapListItem.productCode
                                            )
                                          }
                                          if (item.lotInventoryList) {
                                            item.lotInventoryList.map((lotInventory) => {
                                              lotInventory.subProductLotInventoryList = lotInventory.subProductLotInventoryList.filter(
                                                (subProductLotInventory) => subProductLotInventory.productCode !== subProductMapListItem.productCode
                                              )
                                              return lotInventory
                                            })
                                          }
                                        }
                                        return item
                                      })

                                      tempDraft.product = tempProduct
                                      tempDraft.productList = tempProductList
                                      dispatch(setProductValue('', _.cloneDeep(savedProduct), '', tempDraft, 0, 'delete'))
                                      dispatch(setLoading(false))
                                    }}
                                  >
                                    <DeleteIcon />
                                  </div>
                                )}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_ERROR, item.productCode).map((error) => {
                        return error
                      }) &&
                        ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_ERROR, item.productCode).map((error) => {
                          return error
                        }).length > 0 && (
                          <div className="error-message">
                            <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                            {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_ERROR, item.productCode).map((error) => {
                              return error
                            })}
                          </div>
                        )}
                    </div>
                  </div>
                )
            }
            return list
          })}
          {savedProduct.draft.subProductList.map((item, index) => {
            let list = ''
            if (savedProduct.draft.productListCheckBox[item.type] || savedProduct.draft.productListCheckBox['ALL']) {
              list = (
                <div
                  key={item.productCode}
                  className={
                    savedProduct.draft.product.productCode === item.productCode
                      ? 'product-create-list-item selected-product'
                      : 'product-create-list-item'
                  }
                  onClick={(e) => {
                    dispatch(setLoading(true))
                    e.stopPropagation()
                    dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, _.cloneDeep(savedProduct), 'product', _.cloneDeep(item)))
                    dispatch(setLoading(false))
                  }}
                >
                  <div className="code">
                    <div>{item.productCode}</div>
                    <div className="type">{item.type}</div>
                  </div>
                  <div className="content">
                    <div className="top">
                      <div className="name">{item.tcName}</div>
                      <div className="price">${item.sellingPrice}</div>
                      {!hiddenInput && (
                        <TempBtn
                          btnSetting={{
                            className: 'delete-button',
                            variant: constants.STYLE.VARIANT.CONTAINED,
                            color: constants.STYLE.PRIMARY,
                            label: { key: 'deleteBtn' },
                            onClick: (e) => {
                              e.stopPropagation()
                              let newSubProductList = _.cloneDeep(savedProduct.draft.subProductList).filter((item, subProductListIndex) => {
                                return subProductListIndex !== index
                              })
                              dispatch(setLoading(true))
                              dispatch(
                                setProductValue(
                                  constants.ACTION_KEY.PRODUCT.PRODUCT_INFO,
                                  _.cloneDeep(savedProduct),
                                  '',
                                  {
                                    productList: _.cloneDeep(savedProduct.draft.productList).map((productListItem, productListIndex) => {
                                      let subProductMapList = _.cloneDeep(productListItem.subProductMapList).filter(
                                        (subProductMapItem, subProductMapIndex) => {
                                          return item.productCode !== subProductMapItem.productCode
                                        }
                                      )
                                      productListItem.subProductMapList = _.cloneDeep(subProductMapList)
                                      return productListItem
                                    }),
                                    product:
                                      savedProduct.draft.productList.length > 0
                                        ? savedProduct.draft.productList[0]
                                        : newSubProductList.length > 0
                                        ? newSubProductList[0]
                                        : {},
                                    subProductList: newSubProductList,
                                  },
                                  0,
                                  'delete'
                                )
                              )
                              dispatch(setLoading(false))
                            },
                            disabled: disableInput,
                            icon: <DeleteIcon />,
                          }}
                        />
                      )}
                    </div>
                    {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_ERROR, item.productCode).map((error) => {
                      return error
                    }) &&
                      ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_ERROR, item.productCode).map((error) => {
                        return error
                      }).length > 0 && (
                        <div className="error-message">
                          <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                          {ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_ERROR, item.productCode).map((error) => {
                            return error
                          })}
                        </div>
                      )}
                  </div>
                </div>
              )
            }
            return list
          })}
        </div>
      </div>
      {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
    </BaseWrapper>
  )
}
