import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sify } from "chinese-conv";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import { Card } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { constants, RESERVATION_TABLE_TYPE_LIST_INPUT } from '../../Constants/constants';
import { setReservationTemplate, setReservationTemplateTimeSectionValue, setReservationTemplateTableTypeValue } from "../../actions/reservationTemplateAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { TempCustomCheckBox } from "../../Component/Custom/TempCustomCheckBox";
import { reservationTemplateValidationConstants } from '../../Constants/validationConstants';
import ReservationTemplateValidation from "../../validations/reservationTemplateValidation";
import { GlobalDialog } from "../../Component/GlobalDialog";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const ReservationTemplateTimeSection = ({ setCustomIdNumber, customIdNumber }) => {
  const dispatch = useDispatch();
  const savedReservationTemplate = useSelector(state => state.reservationTemplate.ui.reservationTemplate);
  const savedTimeSection = useSelector(state => state.reservationTemplate.ui.timeSection);
  const [timeSectionInputList, setTimeSectionInputList] = useState(_.cloneDeep(savedTimeSection));
  const helperTextStylesClasses = helperTextStyles();

  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });

  const [, setErrorVersion] = useState(ReservationTemplateValidation.getVersion());

  const resource = "TimeSection";

  const tempReservationTemplateCreateList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "timeSectionId" },
      value: savedTimeSection.timeSectionId,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESERVATION_TEMPLATE.TIME_SECTION_ID,
      name: "timeSectionId",
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_TIME_SECTION_ID, savedTimeSection.customId).map((error) => { return error }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "groupedSection" },
      value: savedTimeSection.groupedSection,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESERVATION_TEMPLATE.GROUPED_SECTION,
      name: "groupedSection",
      isEndOfLine: savedTimeSection.id ? false : true,
      helperTextIcon: true,
      helperText: ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_GROUPED_SECTION, savedTimeSection.customId).map((error) => { return error }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    savedTimeSection.id ?
      {
        className: "edit-create-input",
        size: constants.SIZE.SMALL,
        label: { resource: resource, key: "status" },
        value: savedTimeSection.status,
        type: constants.TYPE.SELECT,
        selectValue: constants.CHOICES.RESERVATION_TEMPLATE.STATUS,
        name: "status",
        disableClearable: true,
        helperTextIcon: true,
        helperText: ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_TIME_SECTION_STATUS, savedTimeSection.customId).map((error) => { return error }),
        FormHelperTextProps: { className: helperTextStylesClasses.root },
        isEndOfLine: true
      }
      : {},
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "timeSectionDescription" },
      value: savedTimeSection.timeSectionDescription,
      name: "timeSectionDescription",
      type: constants.TYPE.TEXT,
      isEndOfLine: false,
      multiline: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "startTime" },
      value: savedTimeSection.startTime,
      name: "startTime",
      step: "2",
      type: constants.TYPE.TIME,
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_TIME_SECTION_START_TIME, savedTimeSection.customId).map((error) => { return error }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "endTime" },
      value: savedTimeSection.endTime,
      name: "endTime",
      step: "2",
      type: constants.TYPE.TIME,
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_TIME_SECTION_END_TIME, savedTimeSection.customId).map((error) => { return error }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "timeSectionEnLabel" },
      value: savedTimeSection.timeSectionEnLabel,
      name: "timeSectionEnLabel",
      custom: true,
      customName: { resource: resource, key: "isAutoTranslateTimeSectionLabel" },
      customValue: savedTimeSection.disableLabel,
      type: constants.TYPE.TEXT,
      isEndOfLine: false,
      multiline: true,
      helperTextIcon: true,
      helperText: ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_TIME_SECTION_EN_LABEL, savedTimeSection.customId).map((error) => { return error }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "timeSectionTcLabel" },
      value: savedTimeSection.timeSectionTcLabel,
      name: "timeSectionTcLabel",
      translateName: savedTimeSection.disableLabel ? ["timeSectionScLabel"] : null,
      inputList: timeSectionInputList,
      setInputList: setTimeSectionInputList,
      type: constants.TYPE.TEXT,
      isEndOfLine: false,
      multiline: true,
      helperTextIcon: true,
      helperText: ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_TIME_SECTION_TC_LABEL, savedTimeSection.customId).map((error) => { return error }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "timeSectionScLabel" },
      value: savedTimeSection.timeSectionScLabel,
      name: "timeSectionScLabel",
      inputList: timeSectionInputList,
      setInputList: setTimeSectionInputList,
      type: constants.TYPE.TEXT,
      isEndOfLine: true,
      disabled: savedTimeSection.disableLabel,
      multiline: true,
      helperTextIcon: true,
      helperText: ReservationTemplateValidation.getErrorMessages(reservationTemplateValidationConstants.KEY_TIME_SECTION_SC_LABEL, savedTimeSection.customId).map((error) => { return error }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]

  const content = [
    {
      tab: <>
        <Card className="card-wrapper">
          {tempReservationTemplateCreateList.map((inputSetting, index) => {
            let content;
            switch (inputSetting.type) {
              case constants.TYPE.SELECT:
                content =
                  <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
                break;
              case constants.TYPE.TEXT:
              case constants.TYPE.TIME:
                content =
                  <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
                break;
              default:
                content =
                  <></>
            }
            return <div className="edit-display-wrapper" key={index}>
              {inputSetting.custom && <TempCustomCheckBox className="customize-btn" value={inputSetting.customValue} customName={inputSetting.customName} onSelectCheckbox={onSelectCheckbox} />}
              {content}
              {inputSetting.isEndOfLine && <div></div>}
            </div>
          })}
        </Card>
      </>,
      btn: false,
    },
  ]

  function onSelectCheckbox(value, customName) {
    const tempReservationTemplate = _.cloneDeep(savedReservationTemplate);
    const newSavedTimeSection = _.cloneDeep(savedTimeSection);
    switch (customName.key) {
      case "isAutoTranslateTimeSectionLabel":
        newSavedTimeSection.disableLabel = value;
        break;
      default:
    }
    let tempTimeSectionIndex = tempReservationTemplate.findIndex((reservationTemplate) => savedTimeSection.customId === reservationTemplate.customId)
    if (tempTimeSectionIndex >= 0) {
      tempReservationTemplate[tempTimeSectionIndex] = newSavedTimeSection;
      dispatch(setReservationTemplateTimeSectionValue(newSavedTimeSection));
    } else {
      newSavedTimeSection.customId = "section_" + customIdNumber;
      setCustomIdNumber(customIdNumber + 1);
      tempReservationTemplate.push(newSavedTimeSection)
      dispatch(setReservationTemplateTimeSectionValue(newSavedTimeSection));
    }
    dispatch(setReservationTemplate(tempReservationTemplate));
    const newTableTypeInput = _.cloneDeep(RESERVATION_TABLE_TYPE_LIST_INPUT);
    dispatch(setReservationTemplateTableTypeValue(newTableTypeInput));

    ReservationTemplateValidation.validateTemplateTimeSection(tempReservationTemplate);
    setErrorVersion(ReservationTemplateValidation.getVersion());
    handleDialogClose();
  }

  function handleTimeSectionStatus(value) {
    const tempReservationTemplate = _.cloneDeep(savedReservationTemplate);
    const newSavedTimeSection = _.cloneDeep(savedTimeSection);
    newSavedTimeSection["status"] = value;
    newSavedTimeSection.tableTypeList = newSavedTimeSection.tableTypeList.filter((tableType) => {
      if (tableType.id) {
        tableType.status = "D";
      }
      return tableType.id
    });
    let tempTimeSectionIndex = tempReservationTemplate.findIndex((reservationTemplate) => savedTimeSection.customId === reservationTemplate.customId)
    if (tempTimeSectionIndex >= 0) {
      tempReservationTemplate[tempTimeSectionIndex] = newSavedTimeSection;
      dispatch(setReservationTemplateTimeSectionValue(newSavedTimeSection));
    } else {
      newSavedTimeSection.customId = "section_" + customIdNumber;
      setCustomIdNumber(customIdNumber + 1);
      tempReservationTemplate.push(newSavedTimeSection)
      dispatch(setReservationTemplateTimeSectionValue(newSavedTimeSection));
    }
    dispatch(setReservationTemplate(tempReservationTemplate));
    const newTableTypeInput = _.cloneDeep(RESERVATION_TABLE_TYPE_LIST_INPUT);
    dispatch(setReservationTemplateTableTypeValue(newTableTypeInput));

    ReservationTemplateValidation.validateTemplateTimeSection(tempReservationTemplate);
    setErrorVersion(ReservationTemplateValidation.getVersion());
    handleDialogClose();
  }

  function handleDialogClose() {
    setDialog({
      open: false,
      dialogActions: []
    });
  }

  function onChangeInput(name, e, index) {
    const tempReservationTemplate = _.cloneDeep(savedReservationTemplate);
    const newSavedTimeSection = _.cloneDeep(savedTimeSection);
    switch (name) {
      case "timeSectionTcLabel":
        if (savedTimeSection.disableLabel === true) {
          newSavedTimeSection[`${name}`] = e;
          newSavedTimeSection["timeSectionScLabel"] = sify(e);
        } else {
          newSavedTimeSection[`${name}`] = e;
        }
        break;
      case "status":
        if (e === "D") {
          setDialog({
            open: true,
            onClose: handleDialogClose,
            dialogContent: "After Time Section saves, all the Table Type Status will change to D (Deactive) and new created Table Type will be deleted. Please press Yes if you confirm!",
            dialogActions: [
              { label: { key: "yes" }, onClick: () => handleTimeSectionStatus(e), icon: <SaveIcon /> },
              { label: { key: "no" }, onClick: () => handleDialogClose(), icon: <ClearIcon /> }
            ]
          })
        } else {
          newSavedTimeSection[`${name}`] = e;
          newSavedTimeSection.tableTypeList.map((tableType) => {
            tableType.status = "A";
            return tableType
          });
        }
        break;
      default:
        newSavedTimeSection[`${name}`] = e;
    }

    let tempTimeSectionIndex = tempReservationTemplate.findIndex((reservationTemplate) => savedTimeSection.customId === reservationTemplate.customId)
    if (tempTimeSectionIndex >= 0) {
      tempReservationTemplate[tempTimeSectionIndex] = newSavedTimeSection;
      dispatch(setReservationTemplateTimeSectionValue(newSavedTimeSection));
    } else {
      newSavedTimeSection.customId = "section_" + customIdNumber;
      setCustomIdNumber(customIdNumber + 1);
      tempReservationTemplate.push(newSavedTimeSection)
      dispatch(setReservationTemplateTimeSectionValue(newSavedTimeSection));
    }
    dispatch(setReservationTemplate(tempReservationTemplate));
    const newTableTypeInput = _.cloneDeep(RESERVATION_TABLE_TYPE_LIST_INPUT);
    dispatch(setReservationTemplateTableTypeValue(newTableTypeInput));

    ReservationTemplateValidation.validateTemplateTimeSection(tempReservationTemplate);
    setErrorVersion(ReservationTemplateValidation.getVersion());
  }

  return (
    <div className="sub-tab-wrapper-margin-32">
      <EditCreateDisplay
        code={savedTimeSection && savedTimeSection.customId ? savedTimeSection.customId : ""}
        resource={resource}
        tabs={false}
        content={content}
        onChangeInput={onChangeInput}
        display={savedTimeSection.customId ? "edit" : "create"}
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </div>
  )
};