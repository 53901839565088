import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector, useStore } from 'react-redux'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { makeStyles } from '@mui/styles'

import { constants as co } from '../../Constants/constants'
import { getOne, put } from '../../helper/baseFetch'
import { getScDisabled } from '../../helper/util'
import { setLandmark, setLandmarkValue } from '../../actions/landmarkAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'
import { TempCustomCheckBox } from '../../Component/Custom/TempCustomCheckBox'
import { DistrictList } from '../District/DistrictList'
import { GlobalDialog } from '../../Component/GlobalDialog'
import LandmarkValidation from '../../validations/landmarkValidation'
import { landmarkValidationConstants } from '../../Constants/validationConstants'
import RestaurantMapList from './RestaurantMap/RestaurantMapList'
import useLandmark from '../../hooks/Landmark/useLandmark'

const helperTextStyles = makeStyles((theme) => ({
  root: {
    color: 'red !important',
  },
}))

export const LandMarkEdit = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const store = useStore()
  const history = useHistory()
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(true)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const resource = 'RestaurantLandmark'
  const savedLandmark = useSelector((state) => state.landmark.ui.landmark)
  const [landmarkInputList, setLandmarkInputList] = useState(_.cloneDeep(savedLandmark))
  const helperTextStylesClasses = helperTextStyles()
  const [, setErrorVersion] = useState(LandmarkValidation.getVersion())

  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: co.LANDMARK_TAB.INFO,
    },
    {
      label: { resource: resource, key: 'districtList' },
      tabIndex: co.LANDMARK_TAB.DISTRICT_LIST,
    },
    {
      label: { resource: resource, key: 'restaurantList' },
      tabIndex: co.LANDMARK_TAB.RESTAURANT_LIST,
    },
  ]
  const tempLandmarkEditList = [
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'enName' },
      value: savedLandmark.enName,
      name: 'enName',
      custom: true,
      customName: { key: 'nameCustomize' },
      customValue: savedLandmark.scNameDisable,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: LandmarkValidation.getErrorMessages(landmarkValidationConstants.KEY_EN_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'tcName' },
      value: savedLandmark.tcName,
      name: 'tcName',
      translateName: savedLandmark.scNameDisable ? ['scName'] : null,
      inputList: landmarkInputList,
      setInputList: setLandmarkInputList,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: LandmarkValidation.getErrorMessages(landmarkValidationConstants.KEY_TC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'scName' },
      value: savedLandmark.scName,
      name: 'scName',
      inputList: landmarkInputList,
      setInputList: setLandmarkInputList,
      isEndOfLine: true,
      disabled: savedLandmark.scNameDisable,
      required: true,
      helperTextIcon: true,
      helperText: LandmarkValidation.getErrorMessages(landmarkValidationConstants.KEY_SC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'landmarkOrder' },
      value: savedLandmark.landmarkOrder,
      type: co.TYPE.NUMBER,
      min: 0,
      name: 'landmarkOrder',
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: LandmarkValidation.getErrorMessages(landmarkValidationConstants.KEY_LANDMARK_ORDER),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'favorite' },
      value: savedLandmark.favorite,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.RESTAURANT_LANDMARK.FAVORITE,
      name: 'favorite',
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: LandmarkValidation.getErrorMessages(landmarkValidationConstants.KEY_FAVORITE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'status' },
      value: savedLandmark.status,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.RESTAURANT_LANDMARK.STATUS,
      name: 'status',
      isEndOfLine: true,
      required: true,
      disableClearable: true,
      helperTextIcon: true,
      helperText: LandmarkValidation.getErrorMessages(landmarkValidationConstants.KEY_STATUS),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]
  const content = [
    {
      tab: tempLandmarkEditList.map((inputSetting, index) => {
        let content
        switch (inputSetting.type) {
          case co.TYPE.SELECT:
            content = <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break
          default:
            content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
        }
        return (
          <div className="edit-display-wrapper" key={index}>
            {inputSetting.custom && (
              <TempCustomCheckBox
                className="customize-btn"
                value={inputSetting.customValue}
                customName={inputSetting.customName}
                onSelectCheckbox={onSelectCheckbox}
              />
            )}
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        )
      }),
      btn: true,
    },
    {
      tab: (
        <DistrictList
          newPath={co.PATH.SHOP.RESTAURANT_LANDMARK_DISTRICT_MAP}
          newFilter={{ id: params.id }}
          newListTitles={[
            {
              key: co.LIST_DISPLAY_TYPE.STRING,
              value: 'districtId',
              sort: true,
            },
            {
              key: co.LIST_DISPLAY_TYPE.STRING,
              value: 'name',
              sort: false,
            },
            {
              key: co.LIST_DISPLAY_TYPE.STRING,
              value: 'districtStatus',
              sort: true,
            },
          ]}
          editBtn={true}
          createBtn={true}
        />
      ),
      btn: false,
    },
    {
      tab: <RestaurantMapList editBtn={true} createBtn={true} />,
      btn: false,
    },
  ]
  const bottomBtns = [
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  function onSelectCheckbox(value) {
    dispatch(setLandmarkValue('scNameDisable', savedLandmark, '', value))
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + co.PATH.SHOP.RESTAURANT_LANDMARK)
  }

  function onChangeInput(name, e) {
    if (savedLandmark.scNameDisable === true && name === 'tcName') {
      dispatch(setLandmarkValue('CHANGE_TC_NAME_AND_SC_NAME', savedLandmark, '', e))
    } else if (name === 'landmarkOrder') {
      dispatch(setLandmarkValue(name, savedLandmark, '', Number.isInteger(Number(e)) ? Number(e) : 0))
    } else {
      dispatch(setLandmarkValue(name, savedLandmark, '', e))
    }
  }

  function onClickSave() {
    LandmarkValidation.validateEditLandmark(savedLandmark)
    if (LandmarkValidation.isValid()) {
      put(
        co.PATH.SHOP.RESTAURANT_LANDMARK + '/' + params.id,
        savedLandmark,
        (payload) => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
      LandmarkValidation.resetErrorMessages()
    } else {
      setErrorVersion(LandmarkValidation.getVersion())
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(
        co.PATH.SHOP.RESTAURANT_LANDMARK + '/' + params.id,
        undefined,
        (payload) => {
          payload.scNameDisable = getScDisabled(payload.tcName, payload.scName)
          dispatch(setLandmark(_.cloneDeep(payload)))
          setLandmarkInputList(_.cloneDeep(payload))
          setReady(true)
          setClear(false)
        },
        undefined,
        store
      )
    }
    LandmarkValidation.resetErrorMessages()
  }, [clear === true]) // eslint-disable-line react-hooks/exhaustive-deps

  const { getLandmarkIdAndName } = useLandmark()
  const code = getLandmarkIdAndName()

  return (
    ready && (
      <>
        <EditCreateDisplay
          code={code}
          resource={resource}
          tabs={tabs}
          content={content}
          onChangeInput={onChangeInput}
          bottomBtns={bottomBtns}
          display="edit"
        />
        {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
      </>
    )
  )
}
