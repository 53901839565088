import { RestaurantTagList } from '../../Component/RestaurantTag/RestaurantTagList'
import { constants } from '../../Constants/constants'

export const RestaurantCategoryList = ({ changeUrlQueryString, onClickListRow, createBtn, editBtn }) => {
    return (
        <RestaurantTagList
            resource={constants.PATH.RESTAURANT_TAG.RESTAURANT_CATEGORY}
            resourceName='restaurant_category'
            changeUrlQueryString={changeUrlQueryString}
            onClickListRow={onClickListRow}
            createBtn={createBtn}
            editBtn={editBtn}
        />
    )
}
