import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import _ from "lodash";
import moment from 'moment';

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { getList, getOne, put } from "../../helper/baseFetch";
import { setChargeDate } from "../../actions/chargeDateAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import ChargeDateValidation from "../../validations/chargeDateValidation";
import { chargeDateValidationConstants } from '../../Constants/validationConstants';
import { GlobalDialog } from "../../Component/GlobalDialog";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const ChargeDateEdit = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const savedChargeDate = useSelector(state => state.chargeDate.ui.chargeDate);
  const savedRestaurant = useSelector(state => state.restaurant.ui.restaurant);
  const [chargeCodeList, setChargeCodeList] = useState([]);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const [, setErrorVersion] = useState(ChargeDateValidation.getVersion());
  const helperTextStylesClasses = helperTextStyles();

  const resource = "ChargeDate";
  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.CHARGE_DATE_TAB.INFO
    }
  ]

  const content = [
    {
      tab: <>
        <div>
          <TempTextInput
            inputSetting={{
              className: "edit-create-input",
              label: { resource: resource, key: "date" },
              value: savedChargeDate.date ? moment(savedChargeDate.date).format('YYYY-MM-DD') : "",
              name: "date",
              type: constants.TYPE.DATE,
              size: constants.SIZE.SMALL,
              helperTextIcon: true,
              helperText: ChargeDateValidation.getErrorMessages(chargeDateValidationConstants.KEY_DATE, savedChargeDate.id).map((error) => { return error }),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
            }}
            handleChange={onChangeInput}
          />
          <TempAutoSelectInput
            inputSetting={{
              className: "edit-create-input",
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "chargeCode" },
              value: savedChargeDate.chargeCode,
              type: constants.TYPE.SELECT,
              selectValue: chargeCodeList,
              name: "chargeCode",
              disableClearable: true,
              multiline: true,
              isEndOfLine: false,
              helperTextIcon: true,
              helperText: ChargeDateValidation.getErrorMessages(chargeDateValidationConstants.KEY_CARGE_DATE, savedChargeDate.id).map((error) => { return error }),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
            }}
            handleChange={onChangeInput}
          />
        </div>
      </>,
      btn: true,
    },
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />,
      disabled: !savedRestaurant.restUrlId,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  function onChangeInput(name, e) {
    const tempChargeDate = _.cloneDeep(savedChargeDate);
    switch (name) {
      case "date":
        tempChargeDate[`${name}`] = e ? Number(moment(e).format('x')) : null;
        break;
      default:
        tempChargeDate[`${name}`] = e;
    }
    dispatch(setChargeDate(tempChargeDate));
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + "/" + savedRestaurant.restUrlId + "/" + constants.RESTAURANT_TAB.RESERVATION + "/" + constants.RESTAURANT_RESERVATION_SUB_TAB.CHARGE_DATE);
  };

  function onClickSave() {
    let tempsavedChargeDate = _.cloneDeep(savedChargeDate);
    tempsavedChargeDate.restUrlId = savedRestaurant.restUrlId;
    ChargeDateValidation.validateChargeDateList([tempsavedChargeDate], "edit");
    if (ChargeDateValidation.isValid()) {
      put(constants.PATH.SHOP.RESERVATION.CHARGE_DATE + "/" + params.id, tempsavedChargeDate, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      ChargeDateValidation.resetErrorMessages();
    } else {
      setErrorVersion(ChargeDateValidation.getVersion());
    }
  }

  function handleChargeCode() {
    getList(constants.PATH.SHOP.RESERVATION.RESERVATION_CHARGE_CODE_OPTIONS, undefined, (payload) => {
      let tempChargeCodeList = [];
      payload.map((item) => {
        tempChargeCodeList.push({ value: item.chargeCode, name: "[" + item.chargeCode + "]  " + item.tcDescription })
        return item;
      })
      setChargeCodeList(tempChargeCodeList);
      setReady(true)
    }, undefined, store);
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(constants.PATH.SHOP.RESERVATION.CHARGE_DATE + "/" + params.id, undefined, (payload) => {
        dispatch(setChargeDate(payload));
        setClear(false)
        if (!ready) {
          handleChargeCode();
        }
      }, undefined, store);
    }
    ChargeDateValidation.resetErrorMessages();
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedChargeDate && savedChargeDate.id ? savedChargeDate.id : ""}
        resource={resource}
        tabs={tabs}
        content={content}
        bottomBtns={bottomBtns}
        mapName={{ resource: "restaurant", key: "restUrlId" }}
        mapCode={savedRestaurant.restUrlId}
        display="edit"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};