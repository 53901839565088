import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import RestaurantIcon from "@mui/icons-material/Restaurant";
import CropFreeIcon from "@mui/icons-material/CropFree";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import SettingsIcon from "@mui/icons-material/Settings";
// import ImageIcon from '@mui/icons-material/Image';
// import ApartmentIcon from '@mui/icons-material/Apartment';
import StyleIcon from '@mui/icons-material/Style';
import NearMeIcon from '@mui/icons-material/NearMe';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CheckIcon from '@mui/icons-material/Check';
import PaymentIcon from '@mui/icons-material/Payment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PublishIcon from '@mui/icons-material/Publish';
import PeopleIcon from '@mui/icons-material/People';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import DraftsIcon from '@mui/icons-material/Drafts';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import LandscapeIcon from '@mui/icons-material/Landscape';
import FolderIcon from '@mui/icons-material/Folder';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import EmailIcon from '@mui/icons-material/Email';
import PrintIcon from '@mui/icons-material/Print';
import StorageIcon from '@mui/icons-material/Storage';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
// import DomainIcon from '@mui/icons-material/Domain';
import RoomIcon from '@mui/icons-material/Room';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ChildCareIcon from '@mui/icons-material/ChildCare';
// import TableChartIcon from '@mui/icons-material/TableChart';
import GroupIcon from '@mui/icons-material/Group';
import SlideshowIcon from '@mui/icons-material/Slideshow';
// import FilterIcon from '@mui/icons-material/Filter';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import Divider from '@mui/material/Divider';
import BuildIcon from '@mui/icons-material/Build';

import { constants } from './Constants/constants';
import { setProductDefault } from "./actions/productCreateAction";
import ProductCreateValidation from "./validations/productCreateValidation";
import { getString, permissionsCheck } from "./helper/util";
import { Map, TableChart } from "@mui/icons-material";

const Menu = ({ logout, openMenu }) => {
  const history = useHistory();
  const location = useLocation();
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);
  const [menuClicked, setMenuClicked] = useState("");
  const dispatch = useDispatch();

  function clearSavedProduct() {
    dispatch(setProductDefault());
    ProductCreateValidation.resetErrorMessages();
  }

  const [menu, setMenu] = useState({
    shop: false,
    restaurant: false,
    sales: false,
    takeaway: false,
    product: false,
    restaurantTag: false,
    landing: false,
    ticket: false,
    template: false,
    brand: false,
    location: false,
    merchant: false,
    slideshow: false,
  });

  function handleHistory(link) {
    if (menuClicked) {
      document.getElementById(menuClicked).style.backgroundColor = null;
    } 
    let newPath = location.pathname;
    if (newPath) {
      newPath = newPath.substring(1);
    }
    document.querySelectorAll(`[id^='${newPath}']`).forEach(value => {
      value.style.backgroundColor = null;
    });
    setMenuClicked(link);
    history.push("/" + link);
    if (link) {
      document.getElementById(link).style.backgroundColor = "#e2e2e2";
    }
  }

  function handleSubMenu(name) {
    let tempMenu = _.cloneDeep(menu);
    tempMenu[name] = !tempMenu[name]
    setMenu(tempMenu);
  }

  useEffect(() => {
    if (menuClicked) {
      setMenuClicked(menuClicked);
      document.getElementById(menuClicked).style.backgroundColor = "#e2e2e2";
    } else {
      let newPath = location.pathname;
      if (newPath) {
        newPath = newPath.substring(1);
      }
      if(document.querySelectorAll(`[id^='${newPath}']`))document.querySelectorAll(`[id^='${newPath}']`).forEach(value => {
        value.style.backgroundColor = "#e2e2e2";
      });
    }
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={`${openMenu ? "" : "display-none"}`}>
      <div className="vertical-menu">
        {(permissionsCheck(constants.PERMISSION_KEY.MENU_SYSTEM_ADMIN) || permissionsCheck(constants.PERMISSION_KEY.MENU_RESTAURANT_ADMIN)) &&
          <>
            <div id={constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT} onClick={() => handleHistory(constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT)}><RestaurantIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "restaurant", "mainTitle", theme)}</div>
            <div className="vertical-menu-sub-menu-title-wrapper" onClick={() => handleSubMenu("merchant")}>
              <span className="vertical-menu-sub-menu-title"><StorefrontIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "", "merchantMenu", theme)}</span>
              <span className="vertical-menu-sub-menu-title">{menu.merchant ? <ExpandLessIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} /> :
                <ExpandMoreIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />}</span>
            </div>
            <span className={`${menu.merchant ? "vertical-sub-menu" : "vertical-sub-menu display-none"}`}>
              <div id={constants.PATH.MERCHANT.MERCHANT_USER} onClick={() => handleHistory(constants.PATH.MERCHANT.MERCHANT_USER)}><PersonIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "MerchantUser", "mainTitle", theme)}</div>
              {!permissionsCheck(constants.PERMISSION_KEY.MENU_RESTAURANT_ADMIN) &&
                <>
                  <div id={constants.PATH.MERCHANT.MERCHANT_SALES_USER} onClick={() => handleHistory(constants.PATH.MERCHANT.MERCHANT_SALES_USER)}><PersonOutlineIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "MerchantSalesUser", "mainTitle", theme)}</div>
                  <div id={constants.PATH.MERCHANT.MERCHANT_USER_GROUP} onClick={() => handleHistory(constants.PATH.MERCHANT.MERCHANT_USER_GROUP)}><GroupIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "MerchantUserGroup", "mainTitle", theme)}</div>
                  {/* <div id={constants.PATH.MERCHANT.DRAFT} onClick={() => handleHistory(constants.PATH.MERCHANT.DRAFT)}><AddIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "MerchantDraft", "mainTitle", theme)}</div> */}
                  <div id={constants.PATH.MERCHANT.BRAND} onClick={() => handleHistory(constants.PATH.MERCHANT.BRAND)}><CheckIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "brandApprove", "mainTitle", theme)}</div>
                </>
              }
            </span>
          </>
        }
        {permissionsCheck(constants.PERMISSION_KEY.MENU_SYSTEM_ADMIN) &&
          <>
            <div id={constants.PATH.SHOP.URL_MAP} onClick={() => handleHistory(constants.PATH.SHOP.URL_MAP)}><CropFreeIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "UrlMap", "mainTitle", theme)}</div>
            <div className="vertical-menu-sub-menu-title-wrapper" onClick={() => handleSubMenu("product")}>
              <span className="vertical-menu-sub-menu-title"><LocalMallIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "", "productMenu", theme)}</span>
              <span className="vertical-menu-sub-menu-title">{menu.product ? <ExpandLessIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} /> :
                <ExpandMoreIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />}</span>
            </div>
            <span className={`${menu.product ? "vertical-sub-menu" : "vertical-sub-menu display-none"}`}>
              <div id={constants.PATH.PRODUCT.DRAFT} onClick={() => { handleHistory(constants.PATH.PRODUCT.DRAFT); clearSavedProduct(); }}><AddIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "", "productCreateMenuAdmin", theme)}</div>
              <div id={constants.PATH.PRODUCT.PRODUCT_CATEGORY} onClick={() => { handleHistory(constants.PATH.PRODUCT.PRODUCT_CATEGORY); clearSavedProduct(); }}><EditAttributesIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "productCreateProductCategoryList", "menuName", theme)}</div>
              <div id={constants.PATH.PRODUCT.REDEEM_GROUP} onClick={() => { handleHistory(constants.PATH.PRODUCT.REDEEM_GROUP); clearSavedProduct(); }}><EditLocationIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "productCreateRedeemGroupList", "mainTitle", theme)}</div>
              <div id={constants.PATH.PRODUCT.PRODUCT} onClick={() => { handleHistory(constants.PATH.PRODUCT.PRODUCT); clearSavedProduct(); }}><EditIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "productCreateProductList", "mainTitle", theme)}</div>
            </span>
            <div className="vertical-menu-sub-menu-title-wrapper" onClick={() => handleSubMenu("template")}>
              <span className="vertical-menu-sub-menu-title"><DescriptionIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "", "template", theme)}</span>
              <span className="vertical-menu-sub-menu-title">{menu.template ? <ExpandLessIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} /> :
                <ExpandMoreIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />}</span>
            </div>
            <span className={`${menu.template ? "vertical-sub-menu" : "vertical-sub-menu display-none"}`}>
              <div id={constants.PATH.SHOP.QUEUE.PRINT_TEMPLATE} onClick={() => handleHistory(constants.PATH.SHOP.QUEUE.PRINT_TEMPLATE)}><PrintIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "PrintTemplate", "mainTitle", theme)}</div>
              <div id={constants.PATH.SHOP.EMAIL_TEMPLATE} onClick={() => handleHistory(constants.PATH.SHOP.EMAIL_TEMPLATE)}><EmailIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "EmailTemplate", "mainTitle", theme)}</div>
            </span>
            {/* <div className="vertical-menu-sub-menu-title-wrapper" onClick={() => handleSubMenu("brand")}>
              <span className="vertical-menu-sub-menu-title"><DomainIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "", "brand", theme)}</span>
              <span className="vertical-menu-sub-menu-title">{menu.brand ? <ExpandLessIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} /> :
                <ExpandMoreIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />}</span>
            </div>
            <span className={`${menu.brand ? "vertical-sub-menu" : "vertical-sub-menu display-none"}`}> */}
            {/* <div id={constants.PATH.SHOP.BRAND_GROUP} onClick={() => handleHistory(constants.PATH.SHOP.BRAND_GROUP)}><ApartmentIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "BrandGroup", "mainTitle", theme)}</div> */}
            <div id={constants.PATH.SHOP.BRAND} onClick={() => handleHistory(constants.PATH.SHOP.BRAND)}><StyleIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "Brand", "mainTitle", theme)}</div>
            {/* </span> */}
          </>
        }
        {(permissionsCheck(constants.PERMISSION_KEY.MENU_SYSTEM_ADMIN) || permissionsCheck(constants.PERMISSION_KEY.MENU_RESTAURANT_ADMIN)) &&
          <>
            <div className="vertical-menu-sub-menu-title-wrapper" onClick={() => handleSubMenu("restaurantTag")}>
              <span className="vertical-menu-sub-menu-title"><AssignmentIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "", "restaurantTag", theme)}</span>
              <span className="vertical-menu-sub-menu-title">{menu.restaurantTag ? <ExpandLessIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} /> :
                <ExpandMoreIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />}</span>
            </div>
            <span className={`${menu.restaurantTag ? "vertical-sub-menu" : "vertical-sub-menu display-none"}`}>
              <div id={constants.PATH.RESTAURANT_TAG.RESTAURANT_CATEGORY} onClick={() => handleHistory(constants.PATH.RESTAURANT_TAG.RESTAURANT_CATEGORY)}><EditAttributesIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "restaurant_category", "mainTitle", theme)}</div>
              <div id={constants.PATH.RESTAURANT_TAG.RESTAURANT_CUISINE} onClick={() => handleHistory(constants.PATH.RESTAURANT_TAG.RESTAURANT_CUISINE)}><FastfoodIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "restaurant_cuisine", "mainTitle", theme)}</div>
              <div id={constants.PATH.RESTAURANT_TAG.RESTAURANT_KEYWORD} onClick={() => handleHistory(constants.PATH.RESTAURANT_TAG.RESTAURANT_KEYWORD)}><KeyboardIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "restaurant_keyword", "mainTitle", theme)}</div>
            </span>
            <div className="vertical-menu-sub-menu-title-wrapper" onClick={() => handleSubMenu("location")}>
              <span className="vertical-menu-sub-menu-title"><RoomIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "", "location", theme)}</span>
              <span className="vertical-menu-sub-menu-title">{menu.location ? <ExpandLessIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} /> :
                <ExpandMoreIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />}</span>
            </div>
            <span className={`${menu.location ? "vertical-sub-menu" : "vertical-sub-menu display-none"}`}>
              <div id={constants.PATH.SHOP.REGION} onClick={() => handleHistory(constants.PATH.SHOP.REGION)}><Map className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />Region / City</div>
              <div id={constants.PATH.SHOP.RESTAURANT_AREA} onClick={() => handleHistory(constants.PATH.SHOP.RESTAURANT_AREA)}><SettingsInputAntennaIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "Area", "mainTitle", theme)}</div>
              <div id={constants.PATH.SHOP.RESTAURANT_DISTRICT_WRAPPER} onClick={() => handleHistory(constants.PATH.SHOP.RESTAURANT_DISTRICT_WRAPPER)}><NearMeIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "RestaurantDistrict", "mainTitle", theme)}</div>
              <div id={constants.PATH.SHOP.RESTAURANT_LANDMARK} onClick={() => handleHistory(constants.PATH.SHOP.RESTAURANT_LANDMARK)}><LandscapeIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "RestaurantLandmark", "mainTitle", theme)}</div>
            </span>
          </>
        }
        {permissionsCheck(constants.PERMISSION_KEY.MENU_SYSTEM_ADMIN) &&
          <>
            <div id={constants.PATH.BANNER.BANNER} onClick={() => handleHistory(constants.PATH.BANNER.BANNER)}><ViewCarouselIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "Banner", "mainTitle", theme)}</div>
            <div id={constants.PATH.FOLDER.FOLDER} onClick={() => handleHistory(constants.PATH.FOLDER.FOLDER)}><FolderIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "Folder", "mainTitle", theme)}</div>
            <div className="vertical-menu-sub-menu-title-wrapper" onClick={() => handleSubMenu("landing")}>
              <span className="vertical-menu-sub-menu-title"><PhoneIphoneIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "Landing", "mainTitle", theme)}</span>
              <span className="vertical-menu-sub-menu-title">{menu.landing ? <ExpandLessIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} /> :
                <ExpandMoreIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />}</span>
            </div>
            <span className={`${menu.landing ? "vertical-sub-menu" : "vertical-sub-menu display-none"}`}>
              <div id={constants.PATH.LANDING.LANDING_BANNER} onClick={() => handleHistory(constants.PATH.LANDING.LANDING_BANNER)}><ViewCarouselIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "Banner", "mainTitle", theme)}</div>
              <div id={constants.PATH.LANDING.LANDING_SECTION+"/0"} onClick={() => handleHistory(constants.PATH.LANDING.LANDING_SECTION+"/0")}><TableChart className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "LandingSection", "mainTitle", theme)}</div> 
            </span>

            <div className="vertical-menu-sub-menu-title-wrapper" onClick={() => handleSubMenu("ticket")}>
              <span className="vertical-menu-sub-menu-title"><ConfirmationNumberIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "Ticket", "mainTitle", theme)}</span>
              <span className="vertical-menu-sub-menu-title">{menu.ticket ? <ExpandLessIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} /> :
                <ExpandMoreIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />}</span>
            </div>
            <span className={`${menu.ticket ? "vertical-sub-menu" : "vertical-sub-menu display-none"}`}>
              <div id={constants.PATH.TICKET.TICKET_BANNER} onClick={() => handleHistory(constants.PATH.TICKET.TICKET_BANNER)}><ViewCarouselIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "Banner", "mainTitle", theme)}</div>
              <div id={constants.PATH.SHOP.QUEUE.TICKET_TYPE} onClick={() => handleHistory(constants.PATH.SHOP.QUEUE.TICKET_TYPE)}><StorageIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "TicketType", "mainTitle", theme)}</div>
            </span>

            <div className="vertical-menu-sub-menu-title-wrapper" onClick={() => handleSubMenu("slideshow")}>
              <span className="vertical-menu-sub-menu-title"><SlideshowIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "Slideshow", "mainTitle", theme)}</span>
              <span className="vertical-menu-sub-menu-title">{menu.slideshow ? <ExpandLessIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} /> :
                <ExpandMoreIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />}</span>
            </div>
            <span className={`${menu.slideshow ? "vertical-sub-menu" : "vertical-sub-menu display-none"}`}>
              <div id={constants.PATH.SLIDESHOW.ALL_SLIDESHOW} onClick={() => handleHistory(constants.PATH.SLIDESHOW.ALL_SLIDESHOW)}><FilterNoneIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "Slideshow", "all_slideshow", theme)}</div>
            </span>

            <div id={constants.PATH.PUSH.PUSH} onClick={() => handleHistory(constants.PATH.PUSH.PUSH)}><NotificationsIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "Push", "mainTitle", theme)}</div>
            <div id={constants.PATH.SELECTED_TAG.SELECTED_TAG} onClick={() => handleHistory(constants.PATH.SELECTED_TAG.SELECTED_TAG)}><ChildCareIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "SelectTag", "mainTitle", theme)}</div>
            <div id={constants.PATH.PRODUCT.SYSTEM_GROUP} onClick={() => handleHistory(constants.PATH.PRODUCT.SYSTEM_GROUP)}><GroupIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "productCreateSystemGroupList", "mainTitle", theme)}</div>
            {/* <div id={constants.PATH.UPLOAD_IMAGE} onClick={() => handleHistory(constants.PATH.UPLOAD_IMAGE)}><ImageIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "UploadImage", "mainTitle", theme)}</div> */}
          </>
        }
        {(permissionsCheck(constants.PERMISSION_KEY.MENU_FINANCE) || permissionsCheck(constants.PERMISSION_KEY.MENU_SYSTEM_ADMIN)) &&
          <>
            <div id={constants.PATH.CONTACT_US} onClick={() => handleHistory(constants.PATH.CONTACT_US)}><ContactSupportIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "contactUs", "mainTitle", theme)}</div>
          </>
        }
        {(permissionsCheck(constants.PERMISSION_KEY.MENU_SYSTEM_ADMIN) || permissionsCheck(constants.PERMISSION_KEY.MENU_SUPPORT)) &&
          <>
            <div id={constants.PATH.TROUBLE_SHOOTING} onClick={() => handleHistory(constants.PATH.TROUBLE_SHOOTING)}><PhonelinkSetupIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "AdminDevice", "troubleShooting", theme)}</div>
          </>
        }
        {permissionsCheck(constants.PERMISSION_KEY.MENU_SYSTEM_ADMIN) &&
          <>
            <div id={constants.PATH.TAKEAWAY} onClick={() => handleHistory(constants.PATH.TAKEAWAY)}><CardTravelIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "", "takeawayMenu", theme)}</div>
          </>
        }
        {permissionsCheck(constants.PERMISSION_KEY.MENU_FINANCE) &&
          <>
            <Divider />
            <span className="vertical-menu-sub-menu-title-disable">
              Finance
            </span>
            <div className="vertical-menu-sub-menu-title-wrapper" onClick={() => handleSubMenu("sales")}>
              <span className="vertical-menu-sub-menu-title"><PeopleIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "", "sales", theme)}</span>
              <span className="vertical-menu-sub-menu-title">{menu.sales ? <ExpandLessIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} /> :
                <ExpandMoreIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />}</span>
            </div>
            <span className={`${menu.sales ? "vertical-sub-menu" : "vertical-sub-menu display-none"}`}>
              <div id={constants.PATH.SALES.SALES_RESTAURANT} onClick={() => handleHistory(constants.PATH.SALES.SALES_RESTAURANT)}><PeopleIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "salesRestaurant", "mainTitle", theme)}</div>
              <div id={constants.PATH.SALES.SALES_SHOP_FRONT_IMAGE} onClick={() => handleHistory(constants.PATH.SALES.SALES_SHOP_FRONT_IMAGE)}><PhotoLibraryIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "salesFrontImage", "mainTitle", theme)}</div>
              <div id={constants.PATH.SALES.SALES_RACK_PRODUCT} onClick={() => handleHistory(constants.PATH.SALES.SALES_RACK_PRODUCT)}><LocalOfferIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "salesRackProduct", "mainTitle", theme)}</div>
            </span>
            <div className="vertical-menu-sub-menu-title-wrapper" onClick={() => handleSubMenu("restaurant")}>
              <span className="vertical-menu-sub-menu-title"><AssignmentIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "", "approveMenu", theme)}</span>
              <span className="vertical-menu-sub-menu-title">{menu.restaurant ? <ExpandLessIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} /> :
                <ExpandMoreIcon color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />}</span>
            </div>
            <span className={`${menu.restaurant ? "vertical-sub-menu" : "vertical-sub-menu display-none"}`}>
              <div id={constants.PATH.APPROVAL.RESTAURANT} onClick={() => handleHistory(constants.PATH.APPROVAL.RESTAURANT)}><CheckIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "restaurantApprove", "mainTitle", theme)}</div>
              <div id={constants.PATH.APPROVAL.PAYMENT} onClick={() => handleHistory(constants.PATH.APPROVAL.PAYMENT)}><PaymentIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "payment", "mainTitle", theme)}</div>
              <div id={constants.PATH.APPROVAL.UPLOADPAYMENT} onClick={() => handleHistory(constants.PATH.APPROVAL.UPLOADPAYMENT)}><PublishIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "uploadPayment", "mainTitle", theme)}</div>
              <div id={constants.PATH.APPROVAL.RESTAURANT_APPROVAL_SALES_RACK_PRODUCT} onClick={() => handleHistory(constants.PATH.APPROVAL.RESTAURANT_APPROVAL_SALES_RACK_PRODUCT)}><LocalOfferIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "restaurantApprovalSalesRackProduct", "mainTitle", theme)}</div>
              <div id={constants.PATH.PRODUCT.DRAFT} onClick={() => { handleHistory(constants.PATH.PRODUCT.DRAFT); clearSavedProduct(); }}><DraftsIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "", "productCreateMenuFinance", theme)}</div>
              <div id={constants.PATH.PRODUCT.PRODUCT} onClick={() => { handleHistory(constants.PATH.PRODUCT.PRODUCT); clearSavedProduct(); }}><EditIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "productCreateProductList", "mainTitle", theme)}</div>
            </span>
            <Divider />
          </>
        }
        <div id={constants.PATH.TOOLS} onClick={() => handleHistory(constants.PATH.TOOLS)}><BuildIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "", "tools", theme)}</div>
        <div id={constants.PATH.CONFIGURATION} onClick={() => handleHistory(constants.PATH.CONFIGURATION)}><SettingsIcon className="vertical-menu-sub-menu-icon" color={constants.STYLE.PRIMARY} fontSize={constants.SIZE.SMALL} />{getString(lang, "", "configuration", theme)}</div>
      </div>
      {logout}
    </div>
  );
};

export default Menu;
