export const BOOLEAN_OPTIONS = [
  { label: 'true', value: true },
  { label: 'false', value: false },
]

export const STATUS_OPTIONS = [
  { label: 'Enable (A)', value: 'A' },
  { label: 'Disable (D)', value: 'D' },
  { label: 'Pending (P)', value: 'P' },
]
