import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import _ from "lodash";
import moment from "moment";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { getOne, put } from "../../helper/baseFetch";
import { setRestaurantBlackout, setRestaurantBlackoutValue } from "../../actions/restaurantBlackoutAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { GlobalDialog } from "../../Component/GlobalDialog";
import RestaurantBlackoutValidation from "../../validations/restaurantBlackoutValidation";
import { restaurantBlackoutValidationConstants } from '../../Constants/validationConstants';
import useRestaurant from "../../hooks/Restaurant/useRestaurant";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const RestaurantBlackoutEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const store = useStore();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "RestaurantBlackout";
  const savedRestaurantBlackout = useSelector(state => state.restaurantBlackout.ui.restaurantBlackout);
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(RestaurantBlackoutValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.RESTAURANT_BLACKOUT_TAB.INFO
    },
  ]
  const tempRestaurantMediaEditList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "refCode" },
      value: savedRestaurantBlackout.refCode,
      name: "refCode",
      disabled: true,
      variant: constants.STYLE.VARIANT.STANDARD,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: RestaurantBlackoutValidation.getErrorMessages(restaurantBlackoutValidationConstants.KEY_REFCODE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "type" },
      value: savedRestaurantBlackout.type,
      name: "type",
      disabled: true,
      variant: constants.STYLE.VARIANT.STANDARD,
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: RestaurantBlackoutValidation.getErrorMessages(restaurantBlackoutValidationConstants.KEY_TYPE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      type: constants.TYPE.DATETIME_LOCAL,
      size: constants.SIZE.SMALL,
      name: "startTimestamp",
      label: { resource: resource, key: "startTimestamp" },
      value: savedRestaurantBlackout.startTimestamp,
      step: 1,
      helperTextIcon: true,
      isEndOfLine: false,
      helperText: RestaurantBlackoutValidation.getErrorMessages(restaurantBlackoutValidationConstants.KEY_START_TIMESTAMP),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      type: constants.TYPE.DATETIME_LOCAL,
      size: constants.SIZE.SMALL,
      name: "endTimestamp",
      label: { resource: resource, key: "endTimestamp" },
      value: savedRestaurantBlackout.endTimestamp,
      step: 1,
      helperTextIcon: true,
      isEndOfLine: true,
      helperText: RestaurantBlackoutValidation.getErrorMessages(restaurantBlackoutValidationConstants.KEY_END_TIMESTAMP),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "status" },
      value: savedRestaurantBlackout.status,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESTAURANT_BLACKOUT.STATUS,
      disableClearable: true,
      name: "status",
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: RestaurantBlackoutValidation.getErrorMessages(restaurantBlackoutValidationConstants.KEY_STATUS),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]
  const content = [
    {
      tab: <>
        {tempRestaurantMediaEditList.map((inputSetting, index) => {
          let content;
          switch (inputSetting.type) {
            case constants.TYPE.SELECT:
              content =
                <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
              break;
            default:
              content =
                <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
          }
          return <div className="edit-display-wrapper" key={index}>
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        })}
      </>,
      btn: true
    },
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + "/" + savedRestaurantBlackout.refCode + "/" + constants.RESTAURANT_TAB.PRODUCT + "/" + constants.RESTAURANT_PRODUCT_SUB_TAB.BLACKOUT);
  };

  function onChangeInput(name, e) {
    switch (name) {
      case "startTimestamp":
      case "endTimestamp":
        const date = Number(moment(e).format("x"));
        dispatch(setRestaurantBlackoutValue(name, savedRestaurantBlackout, "", date));
        break;
      default:
        dispatch(setRestaurantBlackoutValue(name, savedRestaurantBlackout, "", e));
    }
  }

  function onClickSave() {
    let tempSavedRestaurantBlackout = _.cloneDeep(savedRestaurantBlackout);
    RestaurantBlackoutValidation.validateEditRestaurantBlackout(tempSavedRestaurantBlackout);
    if (RestaurantBlackoutValidation.isValid()) {
      put(constants.PATH.SHOP.RESTAURANT_BLACKOUT + "/" + params.id, tempSavedRestaurantBlackout, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      RestaurantBlackoutValidation.resetErrorMessages();
    } else {
      setErrorVersion(RestaurantBlackoutValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(constants.PATH.SHOP.RESTAURANT_BLACKOUT + "/" + params.id, undefined, (payload) => {
        dispatch(setRestaurantBlackout(payload));
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
    RestaurantBlackoutValidation.resetErrorMessages();
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  const { getRestaurantIdAndName } = useRestaurant(savedRestaurantBlackout.refCode)
  const mapCode = getRestaurantIdAndName()

  return (
    ready &&
    <>
      <EditCreateDisplay
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        mapName={{ resource: "restaurant", key: "restUrlId" }}
        mapCode={mapCode}
        display="edit"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};