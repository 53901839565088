import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
// import SplitPane from 'react-split-pane';
import { Allotment } from 'allotment'
import 'allotment/dist/style.css'
import { makeStyles } from '@mui/styles'

import { constants, TABLE_TYPE_DEFAULT_VALUE } from '../../Constants/constants'
import { getOne, post } from '../../helper/baseFetch'
import {
  setQueueTemplate,
  setQueueTemplateTimeSectionValue,
  setQueueTemplateTableTypeValue,
  setQueueTemplateTableTypeColorList,
  setQueueTemplateDefaultTableTypeColorList,
} from '../../actions/queueTemplateAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { QueueTemplateTimeSection } from './QueueTemplateTimeSection'
import { QueueTemplateTimeSectionList } from './QueueTemplateTimeSectionList'
import { QueueTemplateTableType } from './QueueTemplateTableType'
import QueueTemplateValidation from '../../validations/queueTemplateValidation'
import { GlobalDialog } from '../../Component/GlobalDialog'
import { QueueTemplateGroupedSection } from './QueueTemplateGroupedSection'
import useRestaurant from '../../hooks/Restaurant/useRestaurant'

const helperTextStyles = makeStyles(() => ({
  root: {
    color: 'red !important',
  },
}))

export const QueueTemplateCreate = ({ toolBar }) => {
  const dispatch = useDispatch()
  const store = useStore()
  const history = useHistory()
  const params = useParams()
  const [clear, setClear] = useState(false)
  const savedQueueTemplate = useSelector((state) => state.queueTemplate.ui.queueTemplate)
  const [queueTemplateInputList, setQueueTemplateInputList] = useState({
    description: '',
  })
  const [customIdNumber, setCustomIdNumber] = useState(0)
  const [selectedType, setSelectedType] = useState('')
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const helperTextStylesClasses = helperTextStyles()
  // eslint-disable-next-line no-unused-vars
  const [errorVersion, setErrorVersion] = useState(QueueTemplateValidation.getVersion())
  const [soundVoiceList, setSoundVoiceList] = useState([])

  // eslint-disable-next-line no-unused-vars
  const [splitPane, setSplitPane] = useState(500)
  const resource = 'QueueTemplate'
  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: constants.QUEUE_TEMPLATE_TAB.INFO,
    },
  ]

  const content = [
    {
      tab: (
        <>
          <div>
            <TempTextInput
              inputSetting={{
                className: 'edit-create-input',
                size: constants.SIZE.SMALL,
                label: { resource: resource, key: 'description' },
                value: queueTemplateInputList.description,
                name: 'description',
                helperTextIcon: true,
                helperText: QueueTemplateValidation.getErrorMessages('description'),
                FormHelperTextProps: { className: helperTextStylesClasses.root },
              }}
              handleChange={onChangeDesc}
            />
          </div>
          <Allotment defaultSizes={[100, 200]}>
            <QueueTemplateTimeSectionList
              resource={resource}
              setSelectedType={setSelectedType}
              setCustomIdNumber={setCustomIdNumber}
              customIdNumber={customIdNumber}
            />
            <div className="queue-template-flex-right">
              {selectedType === 'timeSection' ? (
                <QueueTemplateTimeSection setCustomIdNumber={setCustomIdNumber} customIdNumber={customIdNumber} />
              ) : selectedType === 'tableType' ? (
                <QueueTemplateTableType setCustomIdNumber={setCustomIdNumber} customIdNumber={customIdNumber} soundVoiceList={soundVoiceList} />
              ) : selectedType === 'groupedSection' ? (
                <QueueTemplateGroupedSection />
              ) : (
                <></>
              )}
            </div>
          </Allotment>
        </>
      ),
      btn: true,
    },
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
      disabled: !savedQueueTemplate || savedQueueTemplate.length === 0,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  function onChangeDesc(name, e) {
    let tempQueueTemplateInputList = _.cloneDeep(queueTemplateInputList)
    tempQueueTemplateInputList.description = e
    setQueueTemplateInputList(tempQueueTemplateInputList)
  }

  const onBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push(
      '/' +
        constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT +
        '/' +
        params.restUrlId +
        '/' +
        constants.RESTAURANT_TAB.QUEUE +
        '/' +
        constants.RESTAURANT_QUEUE_SUB_TAB.QUEUE_TEMPLATE
    )
  }

  function onClickSave() {
    QueueTemplateValidation.validateTemplate(savedQueueTemplate, queueTemplateInputList.description)
    if (QueueTemplateValidation.isValid()) {
      let tempsavedQueueTemplate = _.cloneDeep(savedQueueTemplate)
      let newTicketTypeList = []
      tempsavedQueueTemplate.forEach((tempsavedQueueTemplateValue) => {
        let tempticketTypeList = _.cloneDeep(tempsavedQueueTemplateValue.tableTypeList)
        let tempticketTypeListIdx = tempticketTypeList.length - 1
        delete tempsavedQueueTemplateValue.tableTypeList
        tempticketTypeList.forEach((item) =>
          newTicketTypeList.push({ ...tempsavedQueueTemplateValue, ...item, displaySequence: tempticketTypeListIdx-- * 10 })
        )
      })
      post(
        constants.PATH.SHOP.QUEUE.QUEUE_TEMPLATE,
        {
          restUrlId: params.restUrlId,
          description: queueTemplateInputList.description,
          timeSectionList: newTicketTypeList,
        },
        (payload) => {
          setDialog({
            open: true,
            onClose: onBackToCreate,
            dialogContentText: { key: 'createSuccess' },
            maxWidth: 'md',
            dialogActions: [
              { label: { key: 'backCreate' }, onClick: onBackToCreate, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
      QueueTemplateValidation.resetErrorMessages()
    } else {
      setErrorVersion(QueueTemplateValidation.getVersion())
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    dispatch(setQueueTemplate(constants.QUEUE_TEMPLATE_INPUT_LIST))
    dispatch(setQueueTemplateTimeSectionValue(constants.TIME_SECTION_DEFAULT_VALUE))
    dispatch(setQueueTemplateTableTypeValue(TABLE_TYPE_DEFAULT_VALUE))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(setQueueTemplate(constants.QUEUE_TEMPLATE_INPUT_LIST))
    dispatch(setQueueTemplateTimeSectionValue(constants.TIME_SECTION_DEFAULT_VALUE))
    dispatch(setQueueTemplateTableTypeValue(TABLE_TYPE_DEFAULT_VALUE))
    dispatch(setQueueTemplateTableTypeColorList(null))
    dispatch(setQueueTemplateDefaultTableTypeColorList(null))
    setQueueTemplateInputList({ description: '' })
    setSelectedType('')
    getOne(
      constants.PATH.MERCHANT.SOUND_INFO_VOICE,
      undefined,
      (payload) => {
        setSoundVoiceList(payload.map((voiceSound) => ({ name: voiceSound.name, value: voiceSound.code })))
      },
      undefined,
      store
    )
    QueueTemplateValidation.resetErrorMessages()
    setClear(false)
  }, [clear === true]) // eslint-disable-line react-hooks/exhaustive-deps

  const { getRestaurantIdAndName } = useRestaurant(params.restUrlId)
  const displayMapCode = getRestaurantIdAndName()

  return (
    <>
      <EditCreateDisplay
        code={''}
        resource={resource}
        tabs={toolBar !== false ? tabs : ''}
        content={content}
        bottomBtns={bottomBtns}
        mapName={{ resource: 'restaurant', key: 'restUrlId' }}
        mapCode={displayMapCode}
        display="create"
      />
      {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
    </>
  )
}
