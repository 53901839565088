import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from 'moment';

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Card } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { getList, post } from "../../helper/baseFetch";
import { setChargeDateList } from "../../actions/chargeDateAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { TempBtn } from "../../Component/Input/TempBtn"
import ChargeDateValidation from "../../validations/chargeDateValidation";
import { chargeDateValidationConstants } from '../../Constants/validationConstants';
import { GlobalDialog } from "../../Component/GlobalDialog";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const ChargeDateCreate = ({ toolBar, mapName, mapCode, tab }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(false);
  const savedChargeDateList = useSelector(state => state.chargeDate.ui.chargeDateList);
  const savedRestaurant = useSelector(state => state.restaurant.ui.restaurant);
  const [customIdIndex, setCustomIdIndex] = useState(0);
  const [chargeCodeList, setChargeCodeList] = useState([]);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(ChargeDateValidation.getVersion());

  const resource = "ChargeDate";
  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.CHARGE_DATE_TAB.INFO
    }
  ]

  const content = [
    {
      tab: <>
        <TempBtn btnSetting={{
          variant: constants.STYLE.VARIANT.CONTAINED,
          color: constants.STYLE.PRIMARY,
          label: { resource: resource, key: "addChargeDate" },
          disabled: false,
          onClick: () => handleAddChargeDate(),
          name: "handleAddChargeDate",
          type: constants.TYPE.BUTTON,
          icon: <AddIcon />,
        }} />
        {
          savedChargeDateList.map((item, index) => {
            return <Card className="card-wrapper card" key={index}>
              <div className="delete-icon-row">
                <TempBtn btnSetting={{
                  className: "edit-create-btn",
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: () => handleDelete(index),
                  label: { key: "deleteBtn" },
                  icon: <DeleteIcon />,
                }} />
              </div>
              <div className="delete-icon-row">
                <TempBtn btnSetting={{
                  className: "edit-create-btn",
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: () => handleClone(index),
                  label: { key: "Clone" },
                  icon: <FileCopyIcon />,
                }} />
              </div>
              <div>
                <TempTextInput
                  inputSetting={{
                    className: "edit-create-input",
                    label: { resource: resource, key: "startDate" },
                    value: item.startDate ? moment(item.startDate).format('YYYY-MM-DD') : "",
                    name: "startDate",
                    type: constants.TYPE.DATE,
                    size: constants.SIZE.SMALL,
                    helperTextIcon: true,
                    helperText: ChargeDateValidation.getErrorMessages(chargeDateValidationConstants.KEY_START_DATE, item.customId).map((error) => { return error }),
                    FormHelperTextProps: { className: helperTextStylesClasses.root },
                  }}
                  handleChange={onChangeInput}
                  index={index}
                />
                <TempTextInput
                  inputSetting={{
                    className: "edit-create-input",
                    label: { resource: resource, key: "endDate" },
                    value: item.endDate ? moment(item.endDate).format('YYYY-MM-DD') : "",
                    name: "endDate",
                    type: constants.TYPE.DATE,
                    size: constants.SIZE.SMALL,
                    helperTextIcon: true,
                    helperText: ChargeDateValidation.getErrorMessages(chargeDateValidationConstants.KEY_END_DATE, item.customId).map((error) => { return error }),
                    FormHelperTextProps: { className: helperTextStylesClasses.root },
                  }}
                  handleChange={onChangeInput}
                  index={index}
                />
                <TempAutoSelectInput
                  inputSetting={{
                    className: "edit-create-input",
                    size: constants.SIZE.SMALL,
                    label: { resource: resource, key: "chargeCode" },
                    value: item.chargeCode,
                    type: constants.TYPE.SELECT,
                    selectValue: chargeCodeList,
                    name: "chargeCode",
                    disableClearable: true,
                    multiline: true,
                    isEndOfLine: false,
                    helperTextIcon: true,
                    helperText: ChargeDateValidation.getErrorMessages(chargeDateValidationConstants.KEY_CHARGE_CODE, item.customId).map((error) => { return error }),
                    FormHelperTextProps: { className: helperTextStylesClasses.root },
                  }}
                  handleChange={onChangeInput}
                  index={index}
                />
              </div>
            </Card>
          })
        }
      </>,
      btn: true,
    },
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />,
      disabled: !savedRestaurant.restUrlId || savedChargeDateList.length === 0,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  function handleAddChargeDate() {
    const tempChargeDate = _.cloneDeep(savedChargeDateList);
    const newChargeDate = _.cloneDeep(constants.CHARGE_DATE_INPUT);
    newChargeDate.customId = "CHARGE_DATE_" + customIdIndex;
    setCustomIdIndex(customIdIndex + 1)
    tempChargeDate.push(newChargeDate)
    dispatch(setChargeDateList(tempChargeDate));
  }

  function handleDelete(index) {
    let tempChargeDate = _.cloneDeep(savedChargeDateList);
    tempChargeDate = tempChargeDate.filter((tempChargeDateValue, tempChargeDateIndex) =>
      tempChargeDateIndex !== index
    )
    dispatch(setChargeDateList(tempChargeDate));
  }

  function handleClone(index) {
    let tempChargeDate = _.cloneDeep(savedChargeDateList);
    let cloneChargeDate = _.cloneDeep(tempChargeDate.find((tempChargeDateValue, tempChargeDateIndex) =>
      tempChargeDateIndex === index
    ))
    cloneChargeDate.customId = "CHARGE_DATE_" + customIdIndex;
    setCustomIdIndex(customIdIndex + 1)
    tempChargeDate.push(cloneChargeDate);
    dispatch(setChargeDateList(tempChargeDate));
  }

  function onChangeInput(name, e, index) {
    const tempChargeDate = _.cloneDeep(savedChargeDateList);
    tempChargeDate.map((tempChargeDateValue, tempChargeDateIndex) => {
      if (index === tempChargeDateIndex) {
        switch (name) {
          case "startDate":
          case "endDate":
            tempChargeDateValue[`${name}`] = e ? Number(moment(e).format('x')) : null;
            break;
          default:
            tempChargeDateValue[`${name}`] = e;
        }
      }
      return tempChargeDateValue;
    })
    dispatch(setChargeDateList(tempChargeDate));
  }

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + "/" + savedRestaurant.restUrlId + "/" + constants.RESTAURANT_TAB.RESERVATION + "/" + constants.RESTAURANT_RESERVATION_SUB_TAB.CHARGE_DATE);
  };

  function onClickSave() {
    let tempsavedChargeDateList = _.cloneDeep(savedChargeDateList);
    delete tempsavedChargeDateList.customId;
    let newSavedChargeDateList = {
      restUrlId: savedRestaurant.restUrlId,
      chargeDateRangeList: tempsavedChargeDateList
    }
    ChargeDateValidation.validateChargeDateList(tempsavedChargeDateList, "create");
    if (ChargeDateValidation.isValid()) {
      post(constants.PATH.SHOP.RESERVATION.CHARGE_DATE_LIST, newSavedChargeDateList, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: "createSuccess" },
          dialogActions: [
            { label: { key: "backCreate" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      ChargeDateValidation.resetErrorMessages();
    } else {
      setErrorVersion(ChargeDateValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    dispatch(setChargeDateList(constants.CHARGE_DATE_LIST));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(setChargeDateList(constants.CHARGE_DATE_LIST));
    setClear(false)
    ChargeDateValidation.resetErrorMessages();
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getList(constants.PATH.SHOP.RESERVATION.RESERVATION_CHARGE_CODE_OPTIONS, undefined, (payload) => {
      let tempChargeCodeList = [];
      payload.map((item) => {
        tempChargeCodeList.push({ value: item.chargeCode, name: "[" + item.chargeCode + "]  " + item.tcDescription })
        return item;
      })
      setChargeCodeList(tempChargeCodeList);
      setReady(true)
    }, undefined, store);
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    ready &&
    <>
      <EditCreateDisplay
        code={""}
        resource={resource}
        tabs={toolBar !== false ? tabs : ""}
        content={content}
        bottomBtns={bottomBtns}
        mapName={{ resource: "restaurant", key: "restUrlId" }}
        mapCode={savedRestaurant.restUrlId}
        display="create"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};