import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import moment from 'moment';

import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoneIcon from '@mui/icons-material/Done';

import { constants, IMAGE_TYPE } from '../../Constants/constants';
import { getOne, put } from "../../helper/baseFetch";
import { setPayment, setPaymentValue } from "../../actions/paymentAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { UploadImage } from "../../Component/UploadImage/UploadImage";
import { getString } from "../../helper/util";
import { GlobalDialog } from "../../Component/GlobalDialog";

export const PaymentEdit = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "payment";
  const savedPayment = useSelector(state => state.payment.ui.payment)
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);
  const uploadPaymentChosenTranDate = localStorage.getItem(constants.LOCAL_STORAGE_KEY.UPLOAD_PAYMENT_CHOSEN_TRANDATE);
  const uploadPaymentChosenMatchStatus = localStorage.getItem(constants.LOCAL_STORAGE_KEY.UPLOAD_PAYMENT_CHOSEN_MATCHSTATUS);

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.PAYMENT_EDIT_TAB.INFO
    },
  ]
  const tempPaymentEditList = [
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "name" },
      className: "first-sub-title",
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tranRef" },
      value: savedPayment.tranRef,
      name: "tranRef",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tranDate" },
      value: savedPayment.tranDate ? moment(savedPayment.tranDate).format("YYYY-MM-DD HH:mm") : savedPayment.tranDate,
      name: "tranDate",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "status" },
      value: savedPayment.status,
      name: "status",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "paymentCode" },
      value: savedPayment.paymentCode,
      name: "paymentCode",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    savedPayment.approvalStatus === constants.APPROVAL_STATUS.ACTIVE &&
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "paymentDate" },
      value: savedPayment.paymentDate ? moment(savedPayment.paymentDate).format("YYYY-MM-DD HH:mm") : savedPayment.paymentDate,
      name: "paymentDate",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "orderChargePrice" },
      value: savedPayment.orderChargePrice,
      name: "orderChargePrice",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "channel" },
      value: savedPayment.channel,
      name: "channel",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "approvalStatus" },
      value: savedPayment.approvalStatus,
      name: "approvalStatus",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    savedPayment.paymentCode === "CHEQUE" &&
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "cheque" },
    },
    savedPayment.paymentCode === "CHEQUE" &&
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "bankEnName" },
      value: savedPayment.bankEnName,
      name: "bankEnName",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    savedPayment.paymentCode === "CHEQUE" &&
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "bankTcName" },
      value: savedPayment.bankTcName,
      name: "bankTcName",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    savedPayment.paymentCode === "CHEQUE" &&
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "bankScName" },
      value: savedPayment.bankScName,
      name: "bankScName",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    savedPayment.paymentCode === "CHEQUE" &&
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "bankCode" },
      value: savedPayment.bankCode,
      name: "bankCode",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    savedPayment.paymentCode === "CHEQUE" &&
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "bankAccountNumber" },
      value: savedPayment.bankAccountNumber,
      name: "bankAccountNumber",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    savedPayment.paymentCode === "CHEQUE" &&
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "chequeNumber" },
      value: savedPayment.chequeNumber,
      name: "chequeNumber",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    savedPayment.paymentCode === "CHEQUE" && savedPayment.chequeImage !== null &&
    {
      type: constants.TYPE.IMAGE,
      picture: savedPayment.chequeImage,
      size: constants.SIZE.SMALL,
      label: { resource: "payment", key: "chequeImage" },
      name: "chequeImage",
      disableInput: true,
      isEndOfLine: false,
    },
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "plan" },
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "planEnName" },
      value: savedPayment.planEnName,
      name: "planEnName",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "planTcName" },
      value: savedPayment.planTcName,
      name: "planTcName",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "planScName" },
      value: savedPayment.planScName,
      name: "planScName",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "planCode" },
      value: savedPayment.planCode,
      name: "planCode",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "planPeriodType" },
      value: savedPayment.planPeriodType,
      name: "planPeriodType",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    savedPayment.planPeriodType === "EXACT_DATE" &&
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "planTargetDate" },
      value: savedPayment.planTargetDate,
      name: "planTargetDate",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    savedPayment.planPeriodType === "MONTHY" &&
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "planPeriod" },
      value: savedPayment.planPeriod,
      name: "planPeriod",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "planEcouponPublishLimit" },
      value: savedPayment.planEcouponPublishLimit,
      name: "planEcouponPublishLimit",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "restaurant" },
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "restUrlId" },
      value: savedPayment.restUrlId,
      name: "restUrlId",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "email" },
      value: savedPayment.email,
      name: "email",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: true,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "restEnName" },
      value: savedPayment.restEnName,
      name: "restEnName",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "restTcName" },
      value: savedPayment.restTcName,
      name: "restTcName",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "restScName" },
      value: savedPayment.restScName,
      name: "restScName",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    savedPayment.approvalStatus !== constants.APPROVAL_STATUS.ACTIVE &&
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "paymentDate" },
      // backgroundColorClass: true,
    },
    savedPayment.approvalStatus !== constants.APPROVAL_STATUS.ACTIVE &&
    {
      className: "full-size-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "bankInDate" },
      value: savedPayment.bankInDate === 0 ? "" : savedPayment.bankInDate,
      name: "bankInDate",
      type: constants.TYPE.DATETIME_LOCAL,
      step: 1,
      backgroundColorClass: true,
      required: true,
    },
  ]
  const content = [
    {
      tab: tempPaymentEditList.map((inputSetting, index) => {
        let content = <></>;
        switch (inputSetting.type) {
          case constants.TYPE.TITLE:
            content =
              inputSetting.className ?
                <div className={`${inputSetting.className + " sub-title"}`}>{getString(lang, inputSetting.value.resource, inputSetting.value.key, theme)}</div>
                : <div className="line"><div className="sub-title">{getString(lang, inputSetting.value.resource, inputSetting.value.key, theme)}</div></div>
            break;
          case constants.TYPE.TEXT:
            content =
              <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break;
          case constants.TYPE.DATETIME_LOCAL:
            content =
              <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break;
          case constants.TYPE.IMAGE:
            content =
              <div className="image">
                <UploadImage
                  picture={inputSetting.picture}
                  title={inputSetting.label}
                  name={inputSetting.name}
                  uploadImagePath={inputSetting.uploadImagePath}
                  saveImage={inputSetting.saveImage}
                  disableInput={inputSetting.disableInput} 
                  type={IMAGE_TYPE.PAYMENT}/>
              </div>
            break;
          default:
            <></>
        }
        return <div className={inputSetting.backgroundColorClass ? "image-card-background card-border" : "edit-display-wrapper"} key={index}>
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: true
    },
  ]
  const bottomBtns = [
    {
      className: "margin-left-16",
      disabled: savedPayment.approvalStatus === constants.APPROVAL_STATUS.ACTIVE,
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "approve" },
      onClick: onClickSave,
      icon: <DoneIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: savedPayment.approvalStatus === constants.APPROVAL_STATUS.ACTIVE,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    },
    {
      className: "margin-left-16",
      type: constants.TYPE.TEXT,
      label: savedPayment.lastUpdateDate ? { key: "updateTimestamp" } : "",
      value: savedPayment.lastUpdateDate ? ": " + moment(savedPayment.lastUpdateDate).format('YYYY-MM-DD HH:mm:ss') : "",
    },
  ]

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.APPROVAL.PAYMENT);
  };

  function onChangeInput(name, e) {
    dispatch(setPaymentValue(name, savedPayment, "", e));
  }

  function onClickSave() {
    put(constants.PATH.APPROVAL.PAYMENT_APPROVE + "/" + params.id, { bankInDate: moment(savedPayment.bankInDate).valueOf() }, (payload) => {
      setDialog({
        open: true,
        onClose: onClickBackToEdit,
        dialogContentText: { key: "editSuccess" },
        dialogActions: [
          { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
          { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
        ]
      })
      setClear(false)
    }, undefined, store);
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(constants.PATH.APPROVAL.PAYMENT + "/" + params.id, undefined, (payload) => {
        if (!payload.bankInDate) {
          payload.bankInDate = Date.now();
        }
        dispatch(setPayment(payload));
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedPayment && savedPayment.id ? savedPayment.id : ""}
        mapName={null}
        mapCode={<>
          {savedPayment && savedPayment.restUrlId &&
            <h3 className="chosen-data">{getString(lang, "restaurant", "restUrlId", theme)}: {savedPayment.restUrlId}</h3>
          }
          {uploadPaymentChosenTranDate &&
            <h3 className="chosen-data">{getString(lang, "uploadPayment", "tranDate", theme)}: {uploadPaymentChosenTranDate}</h3>
          }
          {uploadPaymentChosenMatchStatus &&
            <h3 className="chosen-data">{getString(lang, "uploadPayment", "matchStatus", theme)}: {uploadPaymentChosenMatchStatus}</h3>
          }</>}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="edit"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};
