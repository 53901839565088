import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector, useStore } from 'react-redux'

import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveIcon from '@mui/icons-material/Save'

import { constants, CONTACT_US_MESSAGE_TYPE, IMAGE_TYPE } from '../../Constants/constants'
import { getOne, put } from '../../helper/baseFetch'
import { setContactUs, setContactUsValue } from '../../actions/contactUsAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'
import { UploadImage } from '../../Component/UploadImage/UploadImage'
import { GlobalDialog } from '../../Component/GlobalDialog'
import { TextInputWithChips } from './TextInputWithChips'
import { SubTitle } from '../../Component/EditCreateDisplay/EditUtil'

export const ContactUsEdit = () => {
  const dispatch = useDispatch()
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(true)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)
  const resource = 'contactUs'
  const savedContactUs = useSelector((state) => state.contactUs.ui.contactUs)
  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: constants.CONTACT_US_EDIT_TAB.INFO,
    },
  ]
  const tempContactUsEditList = [
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'caseCode' },
      value: savedContactUs.caseCode,
      name: 'caseCode',
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'topic' },
      value: savedContactUs.topic,
      name: 'topic',
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'messageType' },
      value: savedContactUs.messageType,
      name: 'messageType',
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'contactPerson' },
      value: savedContactUs.contactPerson,
      name: 'contactPerson',
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'contactNumber' },
      value: savedContactUs.contactNumber,
      name: 'contactNumber',
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'email' },
      value: savedContactUs.email,
      name: 'email',
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'appMemberId' },
      value: savedContactUs.appMemberId,
      name: 'appMemberId',
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'appVersion' },
      value: savedContactUs.appVersion,
      name: 'appVersion',
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'osVersion' },
      value: savedContactUs.osVersion,
      name: 'osVersion',
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'refId' },
      value: savedContactUs.refId,
      name: 'refId',
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'companyType' },
      value: savedContactUs.companyType,
      name: 'companyType',
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'restaurantName' },
      value: savedContactUs.restaurantName,
      name: 'restaurantName',
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: 'full-size-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'createTimestamp' },
      value: savedContactUs.createTimestamp,
      name: 'createTimestamp',
      type: constants.TYPE.DATETIME_LOCAL,
      step: 1,
      disabled: true,
      isEndOfLine: true,
    },
    {
      className: 'full-size-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'message' },
      value: savedContactUs.message,
      name: 'message',
      type: constants.TYPE.TEXT,
      multiline: true,
      disabled: true,
      isEndOfLine: true,
    },
    {
      type: constants.TYPE.IMAGE_MULTI,
      picture: savedContactUs.mediaList,
      size: constants.SIZE.SMALL,
      label: { resource: 'contactUs', key: 'mediaList' },
      name: 'mediaList',
      disableInput: true,
      isEndOfLine: true,
    },
    {
      className: 'full-size-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'remarks' },
      value: savedContactUs.remarks,
      name: 'remarks',
      type: constants.TYPE.TEXT,
      isEndOfLine: true,
      multiline: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'status' },
      value: savedContactUs.status,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.CONTACT_US.STATUS,
      name: 'status',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: 'tagList' },
      className: 'first-sub-title',
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      type: constants.TYPE.FUNCTION,
      value: (
        <TextInputWithChips
          key={clear}
          chipList={savedContactUs.tagList?.map((tag) => ({ label: tag, value: tag }))}
          handleDelete={handleTagsDelete}
          resource={resource}
          handleAdd={handleTagsAdd}
        />
      ),
    },
  ]
  const content = [
    {
      tab: tempContactUsEditList.map((inputSetting, index) => {
        let content = <></>
        switch (inputSetting.type) {
          case constants.TYPE.DATETIME_LOCAL:
          case constants.TYPE.TEXT:
            content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break
          case constants.TYPE.SELECT:
            content = <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break
          case constants.TYPE.IMAGE_MULTI:
            function handleContent(newPicture, index) {
              return (
                <div className="image" key={index}>
                  <UploadImage
                    picture={newPicture}
                    title={inputSetting.label}
                    name={inputSetting.name}
                    uploadImagePath={inputSetting.uploadImagePath}
                    saveImage={inputSetting.saveImage}
                    disableInput={inputSetting.disableInput}
                    type={IMAGE_TYPE.CONTACT_US}
                  />
                </div>
              )
            }
            content =
              inputSetting.picture && inputSetting.picture.length > 0
                ? inputSetting.picture.map((pictureValue, index) => {
                    return handleContent(pictureValue, index)
                  })
                : handleContent()
            break
          case constants.TYPE.FUNCTION:
            content = inputSetting.value
            break
          case constants.TYPE.TITLE:
            content = <SubTitle inputSetting={inputSetting} lang={lang} theme={theme} />
            break
          default:
            ;<></>
        }
        return (
          <div className={inputSetting.backgroundColorClass ? 'image-card-background card-border' : 'edit-display-wrapper'} key={index}>
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        )
      }),
      btn: true,
    },
  ]
  const bottomBtns = [
    {
      className: 'margin-left-16',
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      icon: <SaveIcon />,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'clear' },
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + constants.PATH.CONTACT_US)
  }

  function handleTagsDelete(item, idx) {
    const temptTagList = savedContactUs?.tagList ? [...savedContactUs.tagList] : []
    const newTemptTagList = temptTagList.filter((tag)=>tag!==item.label)
    onChangeInput('tagList', newTemptTagList)
  }

  function handleTagsAdd(item) {
    if(!item) {
      return
    }
    const temptTagList = savedContactUs?.tagList ? [...savedContactUs.tagList] : []
    if (!temptTagList.includes(item)) {
      temptTagList.push(item)
      onChangeInput('tagList', temptTagList)
    }
  }

  function onChangeInput(name, e) {
    dispatch(setContactUsValue(name, savedContactUs, '', e))
  }

  function onClickSave() {
    put(
      constants.PATH.CONTACT_US + '/' + params.id,
      { id: savedContactUs.id, remarks: savedContactUs.remarks, status: savedContactUs.status, tagList: savedContactUs.tagList },
      (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: 'editSuccess' },
          dialogActions: [
            { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
          ],
        })
        setClear(false)
      },
      undefined,
      store
    )
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(
        constants.PATH.CONTACT_US + '/' + params.id,
        undefined,
        (payload) => {
          switch (payload.messageType) {
            case 'A':
              payload.messageType = CONTACT_US_MESSAGE_TYPE.MOBILE
              break
            case 'M':
              payload.messageType = CONTACT_US_MESSAGE_TYPE.MERCHANT
              break
            case 'W':
              payload.messageType = CONTACT_US_MESSAGE_TYPE.WEB
              break
            default:
          }
          dispatch(setContactUs(payload))
          setReady(true)
          setClear(false)
        },
        undefined,
        store
      )
    }
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready && (
      <>
        <EditCreateDisplay
          code={savedContactUs && savedContactUs.id ? savedContactUs.id : ''}
          resource={resource}
          tabs={tabs}
          content={content}
          onChangeInput={onChangeInput}
          bottomBtns={bottomBtns}
          display="edit"
        />
        {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
      </>
    )
  )
}
