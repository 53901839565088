import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import _ from "lodash";
import moment from 'moment';
import queryString from "query-string";

import { Drawer } from "@mui/material";
import { Card } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import TextField from '@mui/material/TextField';

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay';

import { constants, RESERVATION_TEMPLATE_MAP_TYPE } from '../../Constants/constants';
import { getList, put, post } from "../../helper/baseFetch";
import { setLoading } from "../../actions/systemAction";
import { RestaurantList } from "../Restaurant/RestaurantList";
import { ReservationTemplateMapList } from "../ReservationTemplateMap/ReservationTemplateMapList";
import { TempBtn } from "../../Component/Input/TempBtn"
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { GlobalDialog } from "../../Component/GlobalDialog";
import { getString } from "../../helper/util";

export const ReservationTemplateList = ({ editBtn, createBtn, onClickListRow, btnList, dialogFooterBtn }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const [reservationTemplateList, setReservationTemplateList] = useState([]);
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(false);
  const resource = "ReservationTemplate";
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);
  const savedRestaurant = useSelector(state => state.restaurant.ui.restaurant);
  const [reservationTemplateId, setReservationTemplateId] = useState("");
  const [reservationTemplateData, setReservationTemplateIdData] = useState("");
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const [showRestaurantTextPanel, setShowRestaurantTextPanel] = useState(false);
  const [showReservationTemplatePanel, setShowReservationTemplatePanel] = useState(false);
  const [previewSelectedList, setPreviewSelectedList] = useState([]);
  const [confirmSelectedList, setConfirmSelectedList] = useState([]);
  const [restUrlIdList, setRestUrlIdList] = useState(null);
  const [reservationTemplateIdList, setReservationTemplateIdList] = useState([]);
  const [previewIdList, setPreviewIdList] = useState([]);


  const reservationTemplateTitleList = [
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "templateCode",
      sort: true
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "description",
      sort: true
    },
    {
      key: constants.LIST_DISPLAY_TYPE.BOOLEAN,
      value: "inUse",
      sort: true
    },
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "status",
      sort: true
    }
  ];
  const listFilters = [
    {
      className: "filter-input",
      label: { resource: resource, key: "templateCode" },
      clear: true,
      value: '',
      name: "templateCode",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "description" },
      clear: true,
      value: '',
      name: "description",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "status" },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESERVATION_TEMPLATE.STATUS,
      name: "status",
      size: constants.SIZE.SMALL
    }
  ];

  const [param, setParam] = useState({
    path: "",
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: 0,
  })

  function onChangeParam(value) {
    value.count = value.count + 1;
    setParam(value);
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param);
    tempParam.listFilters = list;
    tempParam.page = 0;
    onChangeParam(tempParam);
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param);
    tempParam.sort = sortItem;
    tempParam.direction = tempParam.direction === "DESC" ? "ASC" : "DESC";
    onChangeParam(tempParam);
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = page;
    onChangeParam(tempParam);
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = 0;
    tempParam.rowsPerPage = rowsPerPage;
    onChangeParam(tempParam);
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    setReservationTemplateId("");
    setReservationTemplateIdData([]);
    setPreviewSelectedList([]);
    setConfirmSelectedList([]);
    setPreviewIdList(null);
    setRestUrlIdList(null);
    setReservationTemplateIdList(null);
  }

  function handleRestaurantDelete(restUrlId) {
    setConfirmSelectedList(confirmSelectedList.filter((confirmSelectedItem) => confirmSelectedItem.restUrlId !== restUrlId));
    if (restUrlIdList) {
      setRestUrlIdList(restUrlIdList.filter((item) => item !== restUrlId))
    }
  }

  function handleCloneDialog(id, data) {
    setReservationTemplateId(id);
    setReservationTemplateIdData(data);
    setDialog({
      maxWidth: 'xl',
      open: true,
      onClose: onClickBackToList,
      dialogTitle: { resource: resource, key: "cloneReservationTemplate" },
      dialogSubTitle: data.description + " (" + data.templateCode + ") ",
      dialogContent:
        <>
          <div>
            <TempBtn btnSetting={{
              variant: constants.STYLE.VARIANT.CONTAINED,
              color: constants.STYLE.PRIMARY,
              className: "edit-create-btn",
              size: constants.SIZE.SMALL,
              onClick: () => handleOnClickDrawer("Restaurant"),
              icon: <AddIcon />,
              label: { resource: resource, key: "addRestaurant" },
            }} />
          </div>
          {confirmSelectedList.map((item, index) => {
            return <Card className="card" key={index}>
              <div className="deleteIcon">
                <TempBtn btnSetting={{
                  className: "edit-create-btn",
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: () => handleRestaurantDelete(item.restUrlId),
                  label: { key: "deleteBtn" },
                  icon: <DeleteIcon />,
                }} />
              </div>
              <TempTextInput inputSetting={{
                className: "edit-create-input",
                variant: constants.STYLE.VARIANT.STANDARD,
                size: constants.SIZE.SMALL,
                label: { resource: "restaurant", key: "restUrlId" },
                value: item.restUrlId,
                name: "restUrlId",
                disabled: true,
              }} />
              <div>
                <TempTextInput inputSetting={{
                  className: "edit-create-input",
                  variant: constants.STYLE.VARIANT.STANDARD,
                  size: constants.SIZE.SMALL,
                  label: { resource: "restaurant", key: "enName" },
                  value: item.enName,
                  name: "enName",
                  disabled: true,
                  multiline: true,
                }} />
                <TempTextInput inputSetting={{
                  className: "edit-create-input",
                  variant: constants.STYLE.VARIANT.STANDARD,
                  size: constants.SIZE.SMALL,
                  label: { resource: "restaurant", key: "tcName" },
                  value: item.tcName,
                  name: "tcName",
                  disabled: true,
                  multiline: true,
                }} />
                <TempTextInput inputSetting={{
                  className: "edit-create-input",
                  variant: constants.STYLE.VARIANT.STANDARD,
                  size: constants.SIZE.SMALL,
                  label: { resource: "restaurant", key: "scName" },
                  value: item.scName,
                  name: "scName",
                  disabled: true,
                  multiline: true,
                }} />
              </div>
            </Card>
          })}
        </>,
      dialogActions: [
        { label: { key: "save" }, onClick: () => handleClone(id), icon: <SaveIcon />, disabled: confirmSelectedList.length === 0 },
        { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
      ]
    })
  }

  function handleClone(id) {
    let data = { id: id, restUrlIdList: restUrlIdList };
    post(constants.PATH.SHOP.RESERVATION.CLONE_RESERVATION_TEMPLATE + "?" + queryString.stringify(data), data, (payload) => {
      setDialog({
        open: true,
        onClose: onClickBackToList,
        dialogContentText: { key: "createSuccess" },
        dialogActions: [
          { label: { key: "backList" }, onClick: () => { onClickBackToList(); }, icon: <LowPriorityIcon /> }
        ]
      })
      setClear(true);
      setReservationTemplateId("");
      setReservationTemplateIdData([]);
      setPreviewSelectedList([]);
      setConfirmSelectedList([]);
      setPreviewIdList(null);
      setRestUrlIdList(null);
      setReservationTemplateIdList(null);
    }, undefined, store);
  }

  function handleApplyDialog(id, data) {
    let date = new Date();
    let effectiveDate = moment(date).format("x");
    setReservationTemplateId(id);
    setReservationTemplateIdData(data);
    setDialog({
      maxWidth: 'xl',
      open: true,
      onClose: onClickBackToList,
      dialogTitle: { resource: resource, key: "applyReservationTemplate" },
      dialogSubTitle: data.description + " (" + data.templateCode + ") ",
      dialogContent:
        <>
          <TextField
            className="edit-create-input"
            label={getString(lang, resource, "effectiveDate", theme)}
            variant="outlined"
            defaultValue={moment(date).format("YYYY-MM-DD")}
            type={constants.TYPE.DATE}
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: moment(date).format("YYYY-MM-DD") }}
            onChange={(draftName) => effectiveDate = moment(draftName.target.value).format("x")}
            required
          />
          <div>
            <TempBtn btnSetting={{
              variant: constants.STYLE.VARIANT.CONTAINED,
              color: constants.STYLE.PRIMARY,
              className: "edit-create-btn",
              size: constants.SIZE.SMALL,
              onClick: () => handleOnClickDrawer("Queue"),
              icon: <AddIcon />,
              label: { resource: resource, key: "addReservationTemplateMap" },
            }} />
          </div>
          {confirmSelectedList.map((item, index) => {
            return <Card className="card" key={index}>
              <div className="deleteIcon">
                <TempBtn btnSetting={{
                  className: "edit-create-btn",
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: () => handleReservationTemplateMapDelete(item.id),
                  label: { key: "deleteBtn" },
                  icon: <DeleteIcon />,
                }} />
              </div>
              <div>
                <TempTextInput inputSetting={{
                  className: "edit-create-input",
                  variant: constants.STYLE.VARIANT.STANDARD,
                  size: constants.SIZE.SMALL,
                  label: { resource: "ReservationTemplateMap", key: "description" },
                  value: item.description,
                  name: "description",
                  disabled: true,
                  multiline: true,
                }} />
              </div>
              <div>
                <TempTextInput inputSetting={{
                  className: "edit-create-input",
                  variant: constants.STYLE.VARIANT.STANDARD,
                  size: constants.SIZE.SMALL,
                  label: { resource: "ReservationTemplateMap", key: "type" },
                  value: item.type,
                  name: "type",
                  disabled: true,
                  multiline: true,
                }} />
                {item.type === RESERVATION_TEMPLATE_MAP_TYPE.DAY_OF_WEEK &&
                  <TempTextInput inputSetting={{
                    className: "edit-create-input",
                    variant: constants.STYLE.VARIANT.STANDARD,
                    size: constants.SIZE.SMALL,
                    label: { resource: "ReservationTemplateMap", key: "dayOfWeek" },
                    value: item.dayOfWeek,
                    name: "dayOfWeek",
                    disabled: true,
                    multiline: true,
                  }} />
                }
                {item.type === RESERVATION_TEMPLATE_MAP_TYPE.DATE_RANGE &&
                  <>
                    <TempTextInput inputSetting={{
                      className: "edit-create-input",
                      variant: constants.STYLE.VARIANT.STANDARD,
                      size: constants.SIZE.SMALL,
                      label: { resource: "ReservationTemplateMap", key: "startDate" },
                      value: moment(item.startDate).format('YYYY-MM-DD'),
                      name: "startDate",
                      disabled: true,
                      multiline: true,
                    }} />
                    <TempTextInput inputSetting={{
                      className: "edit-create-input",
                      variant: constants.STYLE.VARIANT.STANDARD,
                      size: constants.SIZE.SMALL,
                      label: { resource: "ReservationTemplateMap", key: "endDate" },
                      value: moment(item.endDate).format('YYYY-MM-DD'),
                      name: "endDate",
                      disabled: true,
                      multiline: true,
                    }} />
                  </>
                }
              </div>
            </Card>
          })}
        </>,
      dialogActions: [
        { label: { key: "save" }, onClick: () => handleApply(id, effectiveDate), icon: <SaveIcon />, disabled: confirmSelectedList.length === 0 },
        { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
      ]
    })
  }

  function handleApply(id, effectiveDate) {
    let data = { restUrlId: savedRestaurant.restUrlId, templateMapIdList: reservationTemplateIdList, effectiveDate: effectiveDate };
    put(constants.PATH.SHOP.RESERVATION.RESERVATION_TEMPLATE_APPLY_MAP_LIST + "/" + id, data, (payload) => {
      setDialog({
        open: true,
        onClose: onClickBackToList,
        dialogContentText: { key: "applySuccess" },
        dialogActions: [
          { label: { key: "backList" }, onClick: () => { onClickBackToList(); }, icon: <LowPriorityIcon /> }
        ]
      })
      setClear(true);
      setReservationTemplateId("");
      setPreviewSelectedList([]);
      setConfirmSelectedList([]);
      setPreviewIdList(null);
      setRestUrlIdList(null);
      setReservationTemplateIdList(null);
    }, undefined, store);
  }

  function handleReservationTemplateMapDelete(id) {
    setConfirmSelectedList(confirmSelectedList.filter((confirmSelectedItem) => confirmSelectedItem.id !== id));
    if (reservationTemplateIdList) {
      setReservationTemplateIdList(reservationTemplateIdList.filter((item) => item !== id))
    }
  }

  function handleOnClickDrawer(type) {
    if (type === "Restaurant") {
      setShowRestaurantTextPanel(true);
      setPreviewIdList(restUrlIdList);
      setPreviewSelectedList(confirmSelectedList);
    } else if (type === "Queue") {
      setShowReservationTemplatePanel(true);
      setPreviewIdList(reservationTemplateIdList);
      setPreviewSelectedList(confirmSelectedList);
    }
  }

  function handleCloseClick() {
    dispatch(setLoading(true));
    setShowRestaurantTextPanel(false);
    setShowReservationTemplatePanel(false);
    dispatch(setLoading(false));
  };

  function handleRestUrlId() {
    dispatch(setLoading(true));
    setShowRestaurantTextPanel(false);
    setConfirmSelectedList(previewSelectedList);
    setRestUrlIdList(previewIdList);
    dispatch(setLoading(false));
  }

  function handleReservationTemplateId() {
    dispatch(setLoading(true));
    setShowReservationTemplatePanel(false);
    setConfirmSelectedList(previewSelectedList);
    setReservationTemplateIdList(previewIdList);
    dispatch(setLoading(false));
  }

  useEffect(() => {
    if (restUrlIdList && reservationTemplateId) {
      handleCloneDialog(reservationTemplateId, reservationTemplateData);
    } else if (reservationTemplateIdList && reservationTemplateId) {
      handleApplyDialog(reservationTemplateId, reservationTemplateData);
    }
  }, [restUrlIdList, reservationTemplateIdList]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let tempParam = _.cloneDeep(param);
    const filter = { id: savedRestaurant.restUrlId };
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value;
      }
    })

    getList(constants.PATH.SHOP.RESERVATION.RESERVATION_TEMPLATE, { sort: { field: tempParam.sort, order: tempParam.direction }, filter: filter, pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage } }, (payload) => {
      setReservationTemplateList(payload);
      setReady(true);
      setClear(false);
    }, undefined, store);

  }, [param.count, clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <Card className="card-wrapper overflow">
        <ListDisplay
          onClickListRow={onClickListRow}
          page={param.page}
          rowsPerPage={param.rowsPerPage}
          onChangeListSort={onChangeListSort}
          resource={resource}
          dataList={reservationTemplateList}
          sort={param.sort}
          direction={param.direction}
          listTitles={reservationTemplateTitleList}
          listFilters={param.listFilters}
          onChangeListPage={onChangeListPage}
          onChangeListRowsPerPage={onChangeListRowsPerPage}
          editBtn={editBtn}
          createBtn={createBtn}

          dialogFooterBtn={dialogFooterBtn}
          btnList={btnList || btnList === false ? btnList : [
            {
              handleChange: handleCloneDialog,
              color: constants.STYLE.PRIMARY,
              label: { key: "Clone" },
              icon: <FileCopyIcon />,
            },
            {
              handleChange: handleApplyDialog,
              color: constants.STYLE.PRIMARY,
              label: { key: "applyToMap" },
              icon: <PlaylistAddCheckIcon />,
            }
          ]}
          onChangeListFilters={onChangeListFilters}
          redirectPath={"/" + constants.PATH.SHOP.RESERVATION.RESERVATION_TEMPLATE + "/"}
        />
      </Card>
      <Drawer
        classes={{ paper: "drawer-margin-left" }}
        anchor="right"
        open={showRestaurantTextPanel || showReservationTemplatePanel}
        onClose={handleCloseClick}
      >
        {showRestaurantTextPanel &&
          <RestaurantList
            editBtn={false}
            createBtn={false}
            btnList={false}
            mutipleSelect={true}
            setSelectedIdList={setPreviewIdList}
            selectedIdList={previewIdList}
            setPreviewSelectedList={setPreviewSelectedList}
            previewSelectedList={previewSelectedList}
            newPath={constants.PATH.SHOP.RESTAURANT}
            newFilter={restUrlIdList && restUrlIdList.length > 0 ? { selected: { column: 'restUrlId', valueList: restUrlIdList.map((item) => { return item }).reverse() } } : null}
            savedData={restUrlIdList}
            dialogFooterBtn={<>
              <TempBtn btnSetting={{
                className: "get-push-card-cancel-btn",
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                onClick: handleRestUrlId,
                icon: <AddIcon />,
                label: { key: "submit" },
              }} />
              <TempBtn btnSetting={{
                className: "get-push-card-cancel-btn margin-left-16",
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                onClick: handleCloseClick,
                icon: <ClearIcon />,
                label: { key: "close" },
              }} />
            </>
            }
          />
        }

        {showReservationTemplatePanel &&
          <ReservationTemplateMapList
            editBtn={false}
            createBtn={false}
            btnList={false}
            mutipleSelect={true}
            setSelectedIdList={setPreviewIdList}
            selectedIdList={previewIdList}
            setPreviewSelectedList={setPreviewSelectedList}
            previewSelectedList={previewSelectedList}
            savedData={reservationTemplateIdList}
            newFilter={reservationTemplateIdList && reservationTemplateIdList.length > 0 ? { selected: { column: 'id', valueList: reservationTemplateIdList.map((item) => { return item }).reverse() }, id: savedRestaurant.restUrlId } : { id: savedRestaurant.restUrlId }}
            dialogFooterBtn={<>
              <TempBtn btnSetting={{
                className: "get-push-card-cancel-btn",
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                onClick: handleReservationTemplateId,
                icon: <AddIcon />,
                label: { key: "submit" },
              }} />
              <TempBtn btnSetting={{
                className: "get-push-card-cancel-btn margin-left-16",
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                onClick: handleCloseClick,
                icon: <ClearIcon />,
                label: { key: "close" },
              }} />
            </>
            }
          />
        }
      </Drawer>
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};