import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { put } from "../../helper/baseFetch";
import { getOne } from "../../helper/baseFetch";
import { setAdminDevice, setAdminDeviceValue } from "../../actions/adminDeviceAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { GlobalDialog } from "../../Component/GlobalDialog";
import AdminDeviceValidation from "../../validations/adminDeviceValidation";
import { adminDeviceValidationConstants } from '../../Constants/validationConstants';
import useRestaurant from "../../hooks/Restaurant/useRestaurant";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const AdminDeviceEdit = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "AdminDevice";
  const savedAdminDevice = useSelector(state => state.adminDevice.ui.adminDevice);
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(AdminDeviceValidation.getVersion());


  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.ADMIN_DEVICE_EDIT_TAB.INFO
    },
  ]
  const tempAdminDeviceEditList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "reportStatus" },
      value: savedAdminDevice.reportStatus,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.ADMIN_DEVICE.REPORT_STATUS,
      name: "reportStatus",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: AdminDeviceValidation.getErrorMessages(adminDeviceValidationConstants.KEY_REPORT),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "takeawayStatus" },
      value: savedAdminDevice.takeawayStatus,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.ADMIN_DEVICE.TAKEAWAY_STATUS,
      name: "takeawayStatus",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: AdminDeviceValidation.getErrorMessages(adminDeviceValidationConstants.KEY_TAKEAWAYS),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "queueStatus" },
      value: savedAdminDevice.queueStatus,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.ADMIN_DEVICE.QUEUE_STATUS,
      name: "queueStatus",
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: AdminDeviceValidation.getErrorMessages(adminDeviceValidationConstants.KEY_QUEUE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "preorderStatus" },
      value: savedAdminDevice.preorderStatus,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.ADMIN_DEVICE.PREORDER_STATUS,
      name: "preorderStatus",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: AdminDeviceValidation.getErrorMessages(adminDeviceValidationConstants.KEY_PRE_ORDER),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tagStatus" },
      value: savedAdminDevice.tagStatus,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.ADMIN_DEVICE.TAG_STATUS,
      name: "tagStatus",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: AdminDeviceValidation.getErrorMessages(adminDeviceValidationConstants.KEY_TAG),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "reservationStatus" },
      value: savedAdminDevice.reservationStatus,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.ADMIN_DEVICE.RESERVATION_STATUS,
      name: "reservationStatus",
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: AdminDeviceValidation.getErrorMessages(adminDeviceValidationConstants.KEY_RESERVE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "banquetStatus" },
      value: savedAdminDevice.banquetStatus,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.ADMIN_DEVICE.BANQUET_STATUS,
      name: "banquetStatus",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: AdminDeviceValidation.getErrorMessages(adminDeviceValidationConstants.KEY_BANQUET),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "ecouponStatus" },
      value: savedAdminDevice.ecouponStatus,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.ADMIN_DEVICE.ECOUPON_STATUS,
      name: "ecouponStatus",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: AdminDeviceValidation.getErrorMessages(adminDeviceValidationConstants.KEY_ECOUPON),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "menuStatus" },
      value: savedAdminDevice.menuStatus,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.ADMIN_DEVICE.MENU_STATUS,
      name: "menuStatus",
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: AdminDeviceValidation.getErrorMessages(adminDeviceValidationConstants.KEY_MENU),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "ecouponDistributeStatus" },
      value: savedAdminDevice.ecouponDistributeStatus,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.ADMIN_DEVICE.ECOUPON_DISTRIBUTE_STATUS,
      name: "ecouponDistributeStatus",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: AdminDeviceValidation.getErrorMessages(adminDeviceValidationConstants.KEY_ECOUPON_DISTRIBUTE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]
  const content = [
    {
      tab:
        <>
          {AdminDeviceValidation.getErrorMessages(adminDeviceValidationConstants.KEY_REST_URL_ID) &&
            AdminDeviceValidation.getErrorMessages(adminDeviceValidationConstants.KEY_REST_URL_ID).length > 0 &&
            <div className="error-message">
              <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
              {AdminDeviceValidation.getErrorMessages(adminDeviceValidationConstants.KEY_REST_URL_ID)}
            </div>
          }
          {tempAdminDeviceEditList.map((inputSetting, index) => {
            let content;
            switch (inputSetting.type) {
              case constants.TYPE.SELECT:
                content =
                  <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
                break;
              default:
                content =
                  <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            }
            return <div className="edit-display-wrapper" key={index}>
              {content}
              {inputSetting.isEndOfLine && <div></div>}
            </div>
          })}
        </>,
      btn: true
    }
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    onClickClear();
  };
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + "/" + params.id + "/" + constants.RESTAURANT_TAB.INFO + "/" + constants.RESTAURANT_SUB_TAB.ADMIN_DEVICE);
  };

  function onChangeInput(name, e) {
    dispatch(setAdminDeviceValue(name, savedAdminDevice, "", e));
  }

  function onClickSave() {
    AdminDeviceValidation.validateEditAdminDevice(savedAdminDevice);
    if (AdminDeviceValidation.isValid()) {
      put(constants.PATH.SHOP.ADMIN_DEVICE + "/" + params.id, savedAdminDevice, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      AdminDeviceValidation.resetErrorMessages();
    } else {
      setErrorVersion(AdminDeviceValidation.getVersion());
    }
  }
  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    AdminDeviceValidation.resetErrorMessages();
    if (clear === true) {
      getOne(constants.PATH.SHOP.ADMIN_DEVICE + "/" + params.id, undefined, (payload) => {
        dispatch(setAdminDevice(payload));
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  const { getRestaurantIdAndName } = useRestaurant(savedAdminDevice.restUrlId)
  const mapCode = getRestaurantIdAndName()

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedAdminDevice && savedAdminDevice.id ? savedAdminDevice.id : ""}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        mapName={{ resource: "restaurant", key: "restUrlId" }}
        mapCode={mapCode}
        display="edit"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};