import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory } from 'react-router-dom'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
// import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants'
import { put } from '../../helper/baseFetch'
import { getOne } from '../../helper/baseFetch'
import { setMerchantDevice, setMerchantDeviceValue } from '../../actions/merchantDeviceAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { GlobalDialog } from '../../Component/GlobalDialog'
import { getContent } from '../../Component/EditCreateDisplay/EditUtil'
import { DEVICE_TYPE_NAME_MAP } from './merchantDeviceConstants'
import { ProfileCodeSection } from '../Staff/StaffProfileCodeSection'
import { MerchantDeviceLogList } from './MerchantDeviceLogList'

const resource = 'MerchantDevice'

export const MerchantDeviceEdit = () => {
  const dispatch = useDispatch()
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const [showQueueTransferProfileCode, setShowQueueTransferProfileCode] = useState(false)
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(true)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const savedMerchantDevice = useSelector((state) => state.merchantDevice.ui.merchantDevice)

  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: constants.MERCHANT_DEVICE_EDIT_TAB.INFO,
    },
    // {
    //   label: { resource: resource, key: 'deviceLog' },
    //   tabIndex: constants.MERCHANT_DEVICE_EDIT_TAB.DEVICE_LOG,
    // },
  ]
  const tempMerchantUserEditList = [
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'deviceName' },
      value: savedMerchantDevice.deviceName,
      name: 'deviceName',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      type: constants.TYPE.FUNCTION,
      value: (
        <ProfileCodeSection
          displayData={savedMerchantDevice}
          onChangeInput={onChangeInput}
          restUrlId={params.restUrlId}
          showQueueTransferProfileCode={showQueueTransferProfileCode}
        />
      ),
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'activationStatus' },
      value: savedMerchantDevice.activationStatus,
      variant: constants.STYLE.VARIANT.STANDARD,
      disabled: true,
      name: 'activationStatus',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'deviceType' },
      value: savedMerchantDevice.displayDeviceType,
      variant: constants.STYLE.VARIANT.STANDARD,
      disabled: true,
      name: 'deviceType',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'appVersion' },
      value: savedMerchantDevice.appVersion,
      variant: constants.STYLE.VARIANT.STANDARD,
      disabled: true,
      name: 'appVersion',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      variant: constants.STYLE.VARIANT.STANDARD,
      disabled: true,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'appId' },
      value: savedMerchantDevice.appId,
      name: 'appId',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      variant: constants.STYLE.VARIANT.STANDARD,
      disabled: true,
      label: { resource: resource, key: 'deviceIp' },
      value: savedMerchantDevice.deviceIp,
      name: 'deviceIp',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      variant: constants.STYLE.VARIANT.STANDARD,
      disabled: true,
      label: { resource: resource, key: 'deviceModel' },
      value: savedMerchantDevice.deviceModel,
      name: 'deviceModel',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      variant: constants.STYLE.VARIANT.STANDARD,
      disabled: true,
      label: { resource: resource, key: 'manufacturer' },
      value: savedMerchantDevice.manufacturer,
      name: 'manufacturer',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      variant: constants.STYLE.VARIANT.STANDARD,
      disabled: true,
      label: { resource: resource, key: 'os' },
      value: savedMerchantDevice.os,
      name: 'os',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      variant: constants.STYLE.VARIANT.STANDARD,
      disabled: true,
      label: { resource: resource, key: 'osId' },
      value: savedMerchantDevice.osId,
      name: 'osId',
      isEndOfLine: true,
    },
    {
      className: 'full-size-input',
      size: constants.SIZE.SMALL,
      variant: constants.STYLE.VARIANT.STANDARD,
      disabled: true,
      label: { resource: resource, key: 'pushNotificationToken' },
      value: savedMerchantDevice.pushNotificationToken,
      name: 'pushNotificationToken',
      isEndOfLine: false,
    },
  ]
  const content = [
    {
      tab: tempMerchantUserEditList.map((inputSetting, index) => {
        let content = getContent(inputSetting, index, onChangeInput)
        return (
          <div className="edit-display-wrapper" key={index}>
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        )
      }),
      btn: true,
    },
    {
      tab: <MerchantDeviceLogList />,
      btn: true,
    },
  ]

  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push(
      '/' +
        constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT +
        '/' +
        params.restUrlId +
        '/' +
        constants.RESTAURANT_TAB.MERCHANT +
        '/' +
        constants.RESTAURANT_MERCHANT_SUB_TAB.MERCHANT_DEVICE
    )
  }

  function onChangeInput(name, e) {
    switch (name) {
      case 'queueProfileCode':
      case 'queueTransferProfileCode':
      case 'counterProfileCode':
        dispatch(setMerchantDeviceValue(name, savedMerchantDevice, '', e === '' ? null : e))
        break
      default:
        dispatch(setMerchantDeviceValue(name, savedMerchantDevice, '', e))
    }
  }

  function onClickSave() {
    const putData = {
      counterProfileCode: savedMerchantDevice.counterProfileCode,
      queueProfileCode: savedMerchantDevice.queueProfileCode,
      queueTransferProfileCode: savedMerchantDevice.queueTransferProfileCode,
      deviceName: savedMerchantDevice.deviceName,
    }

    put(
      constants.PATH.MERCHANT.MERCHANT_DEVICE + '/' + params.id,
      putData,
      () => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: 'editSuccess' },
          dialogActions: [
            { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
          ],
        })
        setClear(false)
      },
      undefined,
      store
    )
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(
        constants.PATH.MERCHANT.MERCHANT_DEVICE + '/' + params.id,
        undefined,
        (payload) => {
          payload.displayDeviceType = DEVICE_TYPE_NAME_MAP[`${payload.deviceType}`]
          dispatch(setMerchantDevice(payload))
          setReady(true)
          setClear(false)
        },
        undefined,
        store
      )
    }
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  const optOutList = [DEVICE_TYPE_NAME_MAP.pr, DEVICE_TYPE_NAME_MAP.STAFF_PR, DEVICE_TYPE_NAME_MAP.campaignQueue, DEVICE_TYPE_NAME_MAP.fallback]

  useEffect(() => {
    if (optOutList.includes(savedMerchantDevice.displayDeviceType)) {
      setShowQueueTransferProfileCode(true)
    } else {
      setShowQueueTransferProfileCode(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedMerchantDevice.displayDeviceType])

  return (
    ready && (
      <>
        <EditCreateDisplay
          code={savedMerchantDevice && savedMerchantDevice.id ? savedMerchantDevice.id : ''}
          resource={resource}
          tabs={tabs}
          content={content}
          onChangeInput={onChangeInput}
          bottomBtns={bottomBtns}
          display="edit"
        />
        {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
      </>
    )
  )
}
