import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { memo } from 'react'
import BoldTitle from '../typography/BoldTitle'
import Iconify from '../iconify/Iconify'

const SharedDialog = memo(({ title, content, actions, maxWidth = 'sm', onClose, open }) => {
  return (
    <Dialog open={open} maxWidth={maxWidth} fullWidth onClose={onClose} sx={{ zIndex: (theme) => theme.zIndex.drawer - 2 }}>
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <BoldTitle>{title}</BoldTitle>
          <Box onClick={onClose} sx={{ cursor: 'pointer' }}>
            <Iconify iconify="material-symbols:close" />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ fontSize: '16px' }}>{content}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  )
})

export default SharedDialog
