import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CancelIcon from '@mui/icons-material/Cancel'
import { makeStyles } from '@mui/styles'

import { constants, IMAGE_TYPE, PICTURE_SIZE } from '../../Constants/constants'
import { post } from '../../helper/baseFetch'
import { setBrand, setBrandValue } from '../../actions/brandAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempCustomCheckBox } from '../../Component/Custom/TempCustomCheckBox'
import { GlobalDialog } from '../../Component/GlobalDialog'
// import { BrandGroupEditBtn } from "../../Component/Brand/BrandGroupEditBtn";
import { UploadImage } from '../../Component/UploadImage/UploadImage'
import { ImageList } from '../../Component/UploadImage/ImageList'
import BrandValidation from '../../validations/brandValidation'
import { brandValidationConstants } from '../../Constants/validationConstants'

const helperTextStyles = makeStyles((theme) => ({
  root: {
    color: 'red !important',
  },
}))

export const BrandCreate = ({ onCancel }) => {
  const dispatch = useDispatch()
  const store = useStore()
  const history = useHistory()
  const [clear, setClear] = useState(false)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const resource = 'Brand'
  const savedBrand = useSelector((state) => state.Brand.ui.Brand)
  const [brandInputList, setBrandInputList] = useState(_.cloneDeep(constants.BRAND_INPUT))
  const helperTextStylesClasses = helperTextStyles()
  const [, setErrorVersion] = useState(BrandValidation.getVersion())

  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: constants.BRAND.INFO,
    },
  ]
  const tempBrandEditList = [
    // {
    //   className: "edit-create-input",
    //   size: constants.SIZE.SMALL,
    //   type: constants.TYPE.FUNCTION,
    //   value:
    //     <>
    //       <BrandGroupEditBtn savedData={savedBrand} sendBrandGroup={handleBrandGroup} handleClearButtonClick={handleClearBrandGroup} />
    //     </>,
    //   isEndOfLine: true,
    //   disabled: true,
    // },
    // {
    //   className: "edit-create-input",
    //   size: constants.SIZE.SMALL,
    //   label: { resource: resource, key: "groupEnName" },
    //   value: savedBrand.groupEnName,
    //   name: "groupEnName",
    //   isEndOfLine: false,
    //   disabled: true,
    // },
    // {
    //   className: "edit-create-input",
    //   size: constants.SIZE.SMALL,
    //   label: { resource: resource, key: "groupTcName" },
    //   value: savedBrand.groupTcName,
    //   name: "groupTcName",
    //   isEndOfLine: false,
    //   disabled: true,
    // },
    // {
    //   className: "edit-create-input",
    //   size: constants.SIZE.SMALL,
    //   label: { resource: resource, key: "groupScName" },
    //   value: savedBrand.groupScName,
    //   name: "groupScName",
    //   isEndOfLine: true,
    //   disabled: true,
    // },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'enName' },
      value: savedBrand.enName,
      name: 'enName',
      custom: true,
      customName: { key: 'nameCustomize' },
      customValue: savedBrand.scNameDisable,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: BrandValidation.getErrorMessages(brandValidationConstants.KEY_EN_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'tcName' },
      value: savedBrand.tcName,
      name: 'tcName',
      translateName: savedBrand.scNameDisable ? ['scName'] : null,
      inputList: brandInputList,
      setInputList: setBrandInputList,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: BrandValidation.getErrorMessages(brandValidationConstants.KEY_TC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'scName' },
      value: savedBrand.scName,
      name: 'scName',
      inputList: brandInputList,
      setInputList: setBrandInputList,
      isEndOfLine: true,
      disabled: savedBrand.scNameDisable,
      required: true,
      helperTextIcon: true,
      helperText: BrandValidation.getErrorMessages(brandValidationConstants.KEY_SC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'enDescription' },
      value: savedBrand.enDescription,
      name: 'enDescription',
      custom: true,
      customName: { key: 'descriptionCustomize' },
      customValue: savedBrand.scDescriptionDisable,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'tcDescription' },
      value: savedBrand.tcDescription,
      name: 'tcDescription',
      translateName: savedBrand.scDescriptionDisable ? ['scDescription'] : null,
      inputList: brandInputList,
      setInputList: setBrandInputList,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'scDescription' },
      value: savedBrand.scDescription,
      name: 'scDescription',
      inputList: brandInputList,
      setInputList: setBrandInputList,
      isEndOfLine: true,
      disabled: savedBrand.scDescriptionDisable,
    },
    {
      picture: savedBrand.logoImage,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'logoImage' },
      name: 'logoImage',
      type: constants.TYPE.IMAGE,
      uploadImagePath: constants.PATH.SHOP.UPLOAD_BRAND_IMAGE,
      saveImage: onChangeInput,
      isEndOfLine: true,
      required: true,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      type: constants.TYPE.FUNCTION,
      value: (
        <>
          <ImageList
            uploadPath={constants.PATH.SHOP.UPLOAD_BRAND_IMAGE}
            resource={resource}
            type={IMAGE_TYPE.BRAND}
            savedData={savedBrand}
            helperTextStylesClasses={helperTextStylesClasses}
          />
        </>
      ),
      isEndOfLine: true,
      disabled: true,
    },
  ]
  const content = [
    {
      tab: tempBrandEditList.map((inputSetting, index) => {
        let content
        switch (inputSetting.type) {
          case constants.TYPE.IMAGE:
            content = (
              <div className="image">
                <UploadImage
                  picture={inputSetting.picture}
                  title={inputSetting.label}
                  name={inputSetting.name}
                  uploadImagePath={inputSetting.uploadImagePath}
                  saveImage={inputSetting.saveImage}
                  required={inputSetting.required}
                  size={PICTURE_SIZE.MID}
                />
              </div>
            )
            break
          case constants.TYPE.FUNCTION:
            content = inputSetting.value
            break
          default:
            content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
        }
        return (
          <div className="edit-display-wrapper" key={index}>
            {inputSetting.name === 'logoImage' && (
              <>
                {BrandValidation.getErrorMessages(brandValidationConstants.KEY_LOGO_IMAGE) &&
                  BrandValidation.getErrorMessages(brandValidationConstants.KEY_LOGO_IMAGE).length > 0 && (
                    <div className="error-message">
                      <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                      {BrandValidation.getErrorMessages(brandValidationConstants.KEY_LOGO_IMAGE)}
                    </div>
                  )}
              </>
            )}
            {inputSetting.custom && (
              <TempCustomCheckBox
                className="customize-btn"
                value={inputSetting.customValue}
                customName={inputSetting.customName}
                onSelectCheckbox={onSelectCheckbox}
              />
            )}
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        )
      }),
      btn: true,
    },
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  // function handleClearBrandGroup() {
  //   dispatch(setBrandValue("CHANGE_BRAND_GROUP", savedBrand, "", {
  //     groupId: "",
  //     groupTcName: "",
  //     groupEnName: "",
  //     groupScName: "",
  //   }));
  // }

  // function handleBrandGroup(data) {
  //   dispatch(setBrandValue("CHANGE_BRAND_GROUP", savedBrand, "", {
  //     groupId: data.groupId,
  //     groupTcName: data.groupTcName,
  //     groupEnName: data.groupEnName,
  //     groupScName: data.groupScName,
  //   }));
  // }

  function onSelectCheckbox(value, customName) {
    if (customName.key === 'nameCustomize') {
      dispatch(setBrandValue('scNameDisable', savedBrand, '', value))
    } else if (customName.key === 'descriptionCustomize') {
      dispatch(setBrandValue('scDescriptionDisable', savedBrand, '', value))
    }
  }

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + constants.PATH.SHOP.BRAND)
  }

  function onChangeInput(name, e) {
    if (savedBrand.scNameDisable === true && name === 'tcName') {
      dispatch(setBrandValue('CHANGE_TC_NAME_AND_SC_NAME', savedBrand, '', e))
    } else if (savedBrand.scDescriptionDisable === true && name === 'tcDescription') {
      dispatch(setBrandValue('CHANGE_TC_DESCRIPTION_AND_SC_DESCRIPTION', savedBrand, '', e))
    } else if (name === 'logoImage') {
      e.type = 'MERCHANT_BRAND_LOGO'
      e.displaySequence = 0
      e.fileType = 'png'
      dispatch(setBrandValue(name, savedBrand, '', e))
    } else {
      dispatch(setBrandValue(name, savedBrand, '', e))
    }
  }

  function onClickSave() {
    BrandValidation.validateCreateBrand(savedBrand)
    if (BrandValidation.isValid()) {
      post(
        constants.PATH.SHOP.BRAND,
        savedBrand,
        (payload) => {
          setDialog({
            open: true,
            onClose: onClickBackToCreate,
            dialogContentText: { key: 'createSuccess' },
            dialogActions: onCancel
              ? [
                  { label: { key: 'backCreate' }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
                  { label: { key: 'backEdit' }, onClick: onCancel, icon: <LowPriorityIcon /> },
                ]
              : [
                  { label: { key: 'backCreate' }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
                  { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
                ],
          })
          setClear(false)
        },
        undefined,
        store
      )
      BrandValidation.resetErrorMessages()
    } else {
      setErrorVersion(BrandValidation.getVersion())
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    let tempInput = _.cloneDeep(constants.BRAND_INPUT)
    delete tempInput.brandCode
    dispatch(setBrand(tempInput))
    setClear(false)
    BrandValidation.resetErrorMessages()
  }, [clear === true]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <EditCreateDisplay
        code=""
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="create"
      />
      {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} sx={{ zIndex: 1100 }} />}
    </>
  )
}
