
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';

import { constants, UPLOAD_TYPE } from '../../Constants/constants';
import { put, getOne } from "../../helper/baseFetch";
import { getScDisabled } from "../../helper/util";
import { setRestaurantTag, setRestaurantTagValue } from "../../actions/restaurantTagAction";
import { RestaurantMapList } from './RestaurantMapList';
import { EditCreateDisplay } from '../EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../Input/TempTextInput";
import { TempAutoSelectInput } from "../Input/TempAutoSelectInput";
import { TempCustomCheckBox } from "../Custom/TempCustomCheckBox";
import { UploadImage } from "../UploadImage/UploadImage";
import { GlobalDialog } from "../GlobalDialog";
import RestaurantTagValidation from "../../validations/restaurantTagValidation";
import { restaurantTagValidationConstants } from '../../Constants/validationConstants';

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

const LOCAL_CONSTANT = {
  EN_IMAGE: 'enImage',
  TC_IMAGE: 'tcImage',
  SC_IMAGE: 'scImage',
}

export const RestaurantTagEdit = ({ resource, resourceName }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const savedRestaurantTag = useSelector(state => state.restaurantTag.ui.restaurantTag)
  const [restTagInputList, setRestTagInputList] = useState(_.cloneDeep(savedRestaurantTag));
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(RestaurantTagValidation.getVersion());

  const tabs = [
    {
      label: { resource: resourceName, key: "info" },
      tabIndex: constants.TAG_EDIT_TAB.INFO
    },
    {
      label: { resource: resourceName, key: "restaurantMapList" },
      tabIndex: constants.TAG_EDIT_TAB.RESTAURANT_MAP_LIST
    }
  ]
  const tempAreaEditList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resourceName, key: "enName" },
      value: savedRestaurantTag.enName,
      name: "enName",
      custom: true,
      customName: { key: "nameCustomize" },
      customValue: savedRestaurantTag.scNameDisable,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: RestaurantTagValidation.getErrorMessages(restaurantTagValidationConstants.KEY_EN_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resourceName, key: "tcName" },
      value: savedRestaurantTag.tcName,
      name: "tcName",
      translateName: savedRestaurantTag.scNameDisable ? ["scName"] : null,
      inputList: restTagInputList,
      setInputList: setRestTagInputList,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: RestaurantTagValidation.getErrorMessages(restaurantTagValidationConstants.KEY_TC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resourceName, key: "scName" },
      value: savedRestaurantTag.scName,
      name: "scName",
      inputList: restTagInputList,
      setInputList: setRestTagInputList,
      isEndOfLine: true,
      disabled: savedRestaurantTag.scNameDisable,
      required: true,
      helperTextIcon: true,
      helperText: RestaurantTagValidation.getErrorMessages(restaurantTagValidationConstants.KEY_SC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resourceName, key: "orderSequence" },
      value: savedRestaurantTag.orderSequence,
      type: constants.TYPE.NUMBER,
      min: 0,
      name: "orderSequence",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: RestaurantTagValidation.getErrorMessages(restaurantTagValidationConstants.KEY_ORDER_SEQUENCE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resourceName, key: "status" },
      value: savedRestaurantTag.status,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.RESTAURANT_TAG.STATUS,
      name: "status",
      isEndOfLine: true,
      disableClearable: true,
      required: true,
      helperTextIcon: true,
      helperText: RestaurantTagValidation.getErrorMessages(restaurantTagValidationConstants.KEY_STATUS),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      picture: savedRestaurantTag.enImage,
      size: constants.SIZE.SMALL,
      label: { resource: resourceName, key: "enPictures" },
      name: "enImage",
      type: constants.TYPE.IMAGE,
      uploadImagePath: constants.PATH.SHOP.UPLOAD_RESTAURANT_MEDIA,
      saveImage: handleSaveImage,
      isEndOfLine: false,
      required: true,
    },
    {
      picture: savedRestaurantTag.tcImage,
      size: constants.SIZE.SMALL,
      label: { resource: resourceName, key: "tcPictures" },
      name: "tcImage",
      type: constants.TYPE.IMAGE,
      uploadImagePath: constants.PATH.SHOP.UPLOAD_RESTAURANT_MEDIA,
      saveImage: handleSaveImage,
      isEndOfLine: false,
      required: true,
    },
    {
      picture: savedRestaurantTag.scImage,
      size: constants.SIZE.SMALL,
      label: { resource: resourceName, key: "scPictures" },
      name: "scImage",
      type: constants.TYPE.IMAGE,
      uploadImagePath: constants.PATH.SHOP.UPLOAD_RESTAURANT_MEDIA,
      saveImage: handleSaveImage,
      isEndOfLine: true,
      required: true,
    },
  ]
  const content = [
    {
      tab:
        <>
          {tempAreaEditList.map((inputSetting, index) => {
            let content;
            switch (inputSetting.type) {
              case constants.TYPE.SELECT:
                content =
                  <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
                break;
              case constants.TYPE.IMAGE:
                content =
                  <div className="image">
                    <UploadImage
                      picture={inputSetting.picture}
                      title={inputSetting.label}
                      name={inputSetting.name}
                      uploadType={UPLOAD_TYPE.FORMDATA}
                      uploadImagePath={inputSetting.uploadImagePath}
                      saveImage={inputSetting.saveImage}
                      showApplyToAll={true}
                      required={inputSetting.required} />
                  </div>
                break;
              default:
                content =
                  <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            }
            return <div className="edit-display-wrapper" key={index}>
              {inputSetting.custom && <TempCustomCheckBox className="customize-btn" value={inputSetting.customValue} customName={inputSetting.customName} onSelectCheckbox={onSelectCheckbox} />}
              {inputSetting.name === "enImage" &&
                (
                  <>
                    <>
                      {RestaurantTagValidation.getErrorMessages(restaurantTagValidationConstants.KEY_TC_IMAGE) &&
                        RestaurantTagValidation.getErrorMessages(restaurantTagValidationConstants.KEY_TC_IMAGE).length > 0 &&
                        <div className="error-message">
                          <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                          {RestaurantTagValidation.getErrorMessages(restaurantTagValidationConstants.KEY_TC_IMAGE)}
                        </div>
                      }
                    </>
                    <>
                      {RestaurantTagValidation.getErrorMessages(restaurantTagValidationConstants.KEY_SC_IMAGE) &&
                        RestaurantTagValidation.getErrorMessages(restaurantTagValidationConstants.KEY_SC_IMAGE).length > 0 &&
                        <div className="error-message">
                          <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                          {RestaurantTagValidation.getErrorMessages(restaurantTagValidationConstants.KEY_SC_IMAGE)}
                        </div>
                      }
                    </>
                    <>
                      {RestaurantTagValidation.getErrorMessages(restaurantTagValidationConstants.KEY_EN_IMAGE) &&
                        RestaurantTagValidation.getErrorMessages(restaurantTagValidationConstants.KEY_EN_IMAGE).length > 0 &&
                        <div className="error-message">
                          <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                          {RestaurantTagValidation.getErrorMessages(restaurantTagValidationConstants.KEY_EN_IMAGE)}
                        </div>
                      }
                    </>
                  </>
                )
              }
              {content}
              {inputSetting.isEndOfLine && <div></div>}
            </div>
          })}
        </>,
      btn: true
    },
    {
      tab:
        <RestaurantMapList
          newPath={resource + "_map"}
          newFilter={{ tagId: params.id }}
          newListTitles={[
            {
              key: constants.LIST_DISPLAY_TYPE.STRING,
              value: "restUrlId",
              sort: true
            }, {
              key: constants.LIST_DISPLAY_TYPE.STRING,
              value: "name",
              sort: false
            }, {
              key: constants.LIST_DISPLAY_TYPE.STRING,
              value: "sequence",
              sort: true
            }, {
              key: constants.LIST_DISPLAY_TYPE.STRING,
              value: "status",
              sort: true
            }
          ]}
          editBtn={false}
          resourceName="restaurant_map" />,
      btn: false
    }
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  function handleSaveImage(name, image, index, applyToAll) {

    const tempRestaurantTag = _.cloneDeep(savedRestaurantTag);

    if(applyToAll){
      tempRestaurantTag[LOCAL_CONSTANT.EN_IMAGE] = image;
      tempRestaurantTag[LOCAL_CONSTANT.SC_IMAGE] = image;
      tempRestaurantTag[LOCAL_CONSTANT.TC_IMAGE] = image;
      dispatch(setRestaurantTag(tempRestaurantTag));
      return;
    }

    dispatch(setRestaurantTagValue(name, savedRestaurantTag, "", image));
  }

  function onSelectCheckbox(value) {
    dispatch(setRestaurantTagValue("scNameDisable", savedRestaurantTag, "", value));
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + resource);
  };

  function onChangeInput(name, e) {
    if (savedRestaurantTag.scNameDisable === true && name === "tcName") {
      dispatch(setRestaurantTagValue("CHANGE_TC_NAME_AND_SC_NAME", savedRestaurantTag, "", e));
    } else if (name === "orderSequence") {
      dispatch(setRestaurantTagValue(name, savedRestaurantTag, "", Number.isInteger(Number(e)) ? Number(e) : 0));
    } else {
      dispatch(setRestaurantTagValue(name, savedRestaurantTag, "", e));
    }
  }

  function onClickSave() {
    RestaurantTagValidation.validateEditRestaurantTag(savedRestaurantTag);
    if (RestaurantTagValidation.isValid()) {
      put(resource + "/" + params.id, savedRestaurantTag, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      RestaurantTagValidation.resetErrorMessages();
    } else {
      setErrorVersion(RestaurantTagValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(resource + "/" + params.id, undefined, (payload) => {
        payload.scNameDisable = getScDisabled(payload.tcName, payload.scName);
        dispatch(setRestaurantTag(_.cloneDeep(payload)));
        setRestTagInputList(_.cloneDeep(payload))
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
    RestaurantTagValidation.resetErrorMessages();
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedRestaurantTag.tagCode ? savedRestaurantTag.tagCode : ""}
        resource={resourceName}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="edit"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};