import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import CachedIcon from '@mui/icons-material/Cached'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import { Card } from '@mui/material'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { constants as co } from '../../Constants/constants'
import { getOne, put, post } from '../../helper/baseFetch'
import { setQueueConfig, setQueueConfigValue, setQueueConfigResourcesStepsValue } from '../../actions/queueConfigAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempBtn } from '../../Component/Input/TempBtn'
import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'
import { getString, helperTextStyles } from '../../helper/util'
import { GlobalDialog } from '../../Component/GlobalDialog'
import { ResourcesSteps } from './ResourcesSteps'
import { ResetDialog } from './ResetDialog'
import { CounterProfileSelectField } from './CounterProfileSelectField'
import useRestaurant from '../../hooks/Restaurant/useRestaurant'
import { BOOLEAN_OPTIONS } from '../../Constants/type'
import { Clear } from '@mui/icons-material'

export const QueueConfigEdit = () => {
  const dispatch = useDispatch()
  const store = useStore()
  const history = useHistory()
  const params = useParams()
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(true)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })

  const defaultError = { transferable: '', checkOutType: '', maxTicketPerSection: '', limitTicketInMinute: '' }
  const [error, setError] = useState(defaultError)
  const helperTextStylesClasses = helperTextStyles()
  const [resetDialogOpen, setResetDialogOpen] = useState(false)
  const resource = 'QueueConfig'
  const savedQueueConfig = useSelector((state) => state.queueConfig.ui.queueConfig)
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)
  const { getRestaurantIdAndName } = useRestaurant(params.id)

  const fields = [
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'queueType' },
      value: savedQueueConfig.queueType,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_TEMPLATE.QUEUE_TYPE,
      disableClearable: true,
      name: 'queueType',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'rollTagType' },
      value: savedQueueConfig.rollTagType,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_TEMPLATE.ROLL_TAG_TYPE,
      disableClearable: true,
      name: 'rollTagType',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'rollTagShiftValue' },
      value: savedQueueConfig.rollTagShiftValue,
      name: 'rollTagShiftValue',
      type: co.TYPE.NUMBER,
      isEndOfLine: true,
    },
    {
      type: co.TYPE.TITLE,
      value: { resource: resource, key: 'display' },
      className: 'first-sub-title',
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'showTableNo' },
      value: savedQueueConfig.showTableNo,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.SHOW_TABLE_NO,
      name: 'showTableNo',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'showTableType' },
      value: savedQueueConfig.showTableType,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.SHOW_TABLE_TYPE,
      name: 'showTableType',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'tagSequenceDisplay' },
      value: savedQueueConfig.tagSequenceDisplay,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.TAG_SEQUENCE_DISPLAY,
      name: 'tagSequenceDisplay',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'displayType' },
      value: savedQueueConfig.displayType,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_TEMPLATE.DISPLAY_TYPE,
      disableClearable: true,
      name: 'displayType',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'counterTagType' },
      value: savedQueueConfig.counterTagType,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.COUNTER_TAG_TYPE,
      name: 'counterTagType',
      disableClearable: true,
      isEndOfLine: true,
    },
    {
      type: co.TYPE.TITLE,
      value: { resource: resource, key: 'quota' },
      className: 'first-sub-title',
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'quotaType' },
      value: savedQueueConfig.quotaType,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.QUOTA_TYPE,
      disableClearable: true,
      name: 'quotaType',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'timeSectionQuota' },
      value: savedQueueConfig.timeSectionQuota,
      name: 'timeSectionQuota',
      type: co.TYPE.NUMBER,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'personalLimitType' },
      value: savedQueueConfig.personalLimitType,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.PERSONAL_LIMIT_TYPE,
      disableClearable: true,
      name: 'personalLimitType',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'releaseQuotaOnDelete' },
      value: savedQueueConfig.releaseQuotaOnDelete,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.RELEASE_QUOTA_ON_DELETE,
      disableClearable: true,
      name: 'releaseQuotaOnDelete',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'maxTicketPerSection' },
      value: savedQueueConfig.maxTicketPerSection,
      type: co.TYPE.NUMBER,
      disableClearable: true,
      onClickEndAdornmentIconButton: () => {
        setNull('maxTicketPerSection')
      },
      endAdornmentIconButton: <Clear fontSize={co.SIZE.SMALL} />,
      name: 'maxTicketPerSection',
      isEndOfLine: false,
      helperText: error.maxTicketPerSection,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'limitTicketInMinute' },
      value: savedQueueConfig.limitTicketInMinute,
      type: co.TYPE.NUMBER,
      disableClearable: true,
      onClickEndAdornmentIconButton: () => {
        setNull('limitTicketInMinute')
      },
      endAdornmentIconButton: <Clear fontSize={co.SIZE.SMALL} />,
      name: 'limitTicketInMinute',
      isEndOfLine: true,
      helperText: error.limitTicketInMinute,
    },
    {
      type: co.TYPE.TITLE,
      value: { resource: resource, key: 'checkInCheckOut' },
      className: 'first-sub-title',
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'checkInType' },
      value: savedQueueConfig.checkInType,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.QUEUE_CHECK_IN_TYPE,
      disableClearable: true,
      name: 'checkInType',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'ticketExpiredMinute' },
      value: savedQueueConfig.ticketExpiredMinute,
      name: 'ticketExpiredMinute',
      type: co.TYPE.NUMBER,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'checkInBeforeMinute' },
      value: savedQueueConfig.checkInBeforeMinute === -1 ? '' : savedQueueConfig.checkInBeforeMinute,
      type: co.TYPE.NUMBER,
      disableClearable: true,
      onClickEndAdornmentIconButton: () => {
        setUnlimitedQuota('checkInBeforeMinute')
      },
      endAdornmentIconButton: <AllInclusiveIcon fontSize={co.SIZE.SMALL} />,
      name: 'checkInBeforeMinute',
      placeholder: savedQueueConfig.checkInBeforeMinute === -1 ? 'Unlimited' : '',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'checkInLateMinute' },
      value: savedQueueConfig.checkInLateMinute === -1 ? '' : savedQueueConfig.checkInLateMinute,
      type: co.TYPE.NUMBER,
      disableClearable: true,
      onClickEndAdornmentIconButton: () => {
        setUnlimitedQuota('checkInLateMinute')
      },
      endAdornmentIconButton: <AllInclusiveIcon fontSize={co.SIZE.SMALL} />,
      name: 'checkInLateMinute',
      placeholder: savedQueueConfig.checkInLateMinute === -1 ? 'Unlimited' : '',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'inputCheckInSize' },
      value: savedQueueConfig.inputCheckInSize,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.INPUT_CHECK_IN_SIZE,
      name: 'inputCheckInSize',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'minCheckInSize' },
      value: savedQueueConfig.minCheckInSize,
      name: 'minCheckInSize',
      type: co.TYPE.NUMBER,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'maxCheckInSize' },
      value: savedQueueConfig.maxCheckInSize,
      name: 'maxCheckInSize',
      type: co.TYPE.NUMBER,
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'checkOutType' },
      value: savedQueueConfig.checkOutType,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.QUEUE_CHECK_OUT_TYPE,
      disableClearable: true,
      name: 'checkOutType',
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: error.checkOutType,
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      type: co.TYPE.TITLE,
      value: { resource: resource, key: 'delay' },
      className: 'first-sub-title',
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'delayEnable' },
      value: savedQueueConfig.delayEnable,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.DELAY_ENABLE,
      disableClearable: true,
      name: 'delayEnable',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'delayMaxMinute' },
      value: savedQueueConfig.delayMaxMinute,
      name: 'delayMaxMinute',
      type: co.TYPE.NUMBER,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'delayReminderAnswerSecond' },
      value: savedQueueConfig.delayReminderAnswerSecond,
      name: 'delayReminderAnswerSecond',
      type: co.TYPE.NUMBER,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'delayReminderTriggerSecond' },
      value: savedQueueConfig.delayReminderTriggerSecond,
      name: 'delayReminderTriggerSecond',
      type: co.TYPE.NUMBER,
      isEndOfLine: false,
    },
    {
      type: co.TYPE.TITLE,
      value: { resource: resource, key: 'others' },
      className: 'first-sub-title',
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'queuePeriod' },
      value: savedQueueConfig.queuePeriod,
      name: 'queuePeriod',
      type: co.TYPE.NUMBER,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'repeatQueue' },
      value: savedQueueConfig.repeatQueue,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.REPEAT_QUEUE,
      name: 'repeatQueue',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'quickQueueGroupName' },
      value: savedQueueConfig.quickQueueGroupName,
      name: 'quickQueueGroupName',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'checksumExpiryLimit' },
      value: savedQueueConfig.checksumExpiryLimit,
      name: 'checksumExpiryLimit',
      type: co.TYPE.NUMBER,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'genFromTemplate' },
      value: savedQueueConfig.genFromTemplate,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.GEN_FROM_TEMPLATE,
      name: 'genFromTemplate',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'redisSequence' },
      value: savedQueueConfig.redisSequence,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.REDIS_SEQUENCE,
      disableClearable: true,
      name: 'redisSequence',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'deliveryType' },
      value: savedQueueConfig.deliveryType,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.DELIVERY_TYPE,
      disableClearable: true,
      name: 'deliveryType',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'requiredOtp' },
      value: savedQueueConfig.requiredOtp,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.REQUIRED_OTP,
      disableClearable: true,
      name: 'requiredOtp',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      value: savedQueueConfig.autoTimeType,
      label: { resource: resource, key: 'autoTimeType' },
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.AUTO_TIME_TYPE,
      name: 'autoTimeType',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      value: savedQueueConfig.vipType,
      label: { resource: resource, key: 'vipType' },
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.VIP_TYPE,
      name: 'vipType',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'prioritize' },
      value: savedQueueConfig.prioritize,
      type: co.TYPE.SELECT,
      selectValue: BOOLEAN_OPTIONS,
      disableClearable: true,
      name: 'prioritize',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'webShare' },
      value: savedQueueConfig.webShare,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.WEB_SHARE,
      disableClearable: true,
      name: 'webShare',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'mobileShare' },
      value: savedQueueConfig.mobileShare,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.MOBILE_SHARE,
      disableClearable: true,
      name: 'mobileShare',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'transferable' },
      value: savedQueueConfig.transferable,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.TRANSFERABLE,
      disableClearable: true,
      name: 'transferable',
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: error.transferable,
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'useTransferableTicketLabel' },
      value: savedQueueConfig.useTransferableTicketLabel,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.USE_TRANSFERABLE_LABEL,
      disableClearable: true,
      name: 'useTransferableTicketLabel',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'limitDistance' },
      value: savedQueueConfig.limitDistance,
      name: 'limitDistance',
      type: co.TYPE.NUMBER,
      clear: true,
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'requiredPass' },
      value: savedQueueConfig.requiredPass,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.REQUIRED_PASS,
      disableClearable: true,
      name: 'requiredPass',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      type: co.TYPE.FUNCTION,
      value: (
        <CounterProfileSelectField
          resource={resource}
          resourceKey={'counterProfileCode'}
          value={savedQueueConfig.counterProfileCode}
          onChangeInput={onChangeInput}
          restUrlId={params.id}
        />
      ),
    },
    {
      type: co.TYPE.TITLE,
      value: { resource: resource, key: 'dailyReset' },
      className: 'first-sub-title',
    },
    {
      className: 'edit-create-input',
      variant: co.STYLE.VARIANT.STANDARD,
      label: { key: 'resetUpdateTimestamp' },
      value: savedQueueConfig.resetUpdateTimestamp ? moment(savedQueueConfig.resetUpdateTimestamp).format('YYYY-MM-DD HH:mm:ss') : '',
      name: 'resetUpdateTimestamp',
      isEndOfLine: true,
      disabled: true,
    },
    {
      className: 'edit-create-input',
      type: co.TYPE.TIME,
      value: savedQueueConfig.dailyResetTime,
      label: { resource: resource, key: 'dailyResetTime' },
      name: 'dailyResetTime',
      clear: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'resetQueueStatus' },
      value: savedQueueConfig.resetQueueStatus,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.RESET_QUEUE_STATUS,
      disableClearable: true,
      name: 'resetQueueStatus',
      isEndOfLine: true,
    },
    {
      label: { resource: resource, key: 'autoTask' },
      value: savedQueueConfig.autoTask,
      type: co.TYPE.SELECT,
      selectValue: BOOLEAN_OPTIONS,
      disableClearable: true,
      name: 'autoTask',
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'autoSwitchTimeSection' },
      value: savedQueueConfig.autoSwitchTimeSection,
      type: co.TYPE.SELECT,
      selectValue: BOOLEAN_OPTIONS,
      name: 'autoSwitchTimeSection',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'autoRequestTicketStatus' },
      value: savedQueueConfig.autoRequestTicketStatus,
      type: co.TYPE.SELECT,
      selectValue: BOOLEAN_OPTIONS,
      disableClearable: true,
      name: 'autoRequestTicketStatus',
      isEndOfLine: false,
    },
    {
      type: co.TYPE.TITLE,
      value: { resource: resource, key: 'queueSteps' },
    },
    {
      className: 'edit-create-input',
      type: co.TYPE.FUNCTION,
      value: (
        <>
          <div>
            <TempBtn
              btnSetting={{
                onClick: addQueueSteps,
                variant: co.STYLE.VARIANT.CONTAINED,
                color: co.STYLE.PRIMARY,
                label: { resource: resource, key: 'addQueueSteps' },
                icon: <AddIcon />,
              }}
            ></TempBtn>
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="templateValue">
              {(provided) => {
                return (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {savedQueueConfig.queueSteps &&
                      savedQueueConfig.queueSteps.length > 0 &&
                      savedQueueConfig.queueSteps.map((item, queueStepsIndex) => {
                        return (
                          <Draggable key={item + queueStepsIndex} draggableId={item + queueStepsIndex} index={queueStepsIndex}>
                            {(provided) => {
                              return (
                                <Card
                                  className="card brand-id-wrapper"
                                  key={queueStepsIndex}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className="content-icon">
                                    <SwapVertIcon color={co.STYLE.PRIMARY} fontSize={co.SIZE.LARGE} />
                                  </div>
                                  <TempAutoSelectInput
                                    inputSetting={{
                                      className: 'edit-create-input',
                                      label: { resource: resource, key: 'queueSteps' },
                                      value: item,
                                      type: co.TYPE.SELECT,
                                      selectValue: co.CHOICES.QUEUE_CONFIG.QUEUE_STEPS,
                                      name: 'queueSteps',
                                      disableClearable: true,
                                      isEndOfLine: true,
                                    }}
                                    handleChange={onChangeInput}
                                    index={queueStepsIndex}
                                  />
                                  <TempBtn
                                    btnSetting={{
                                      className: 'edit-create-btn',
                                      onClick: () => removeQueueSteps(queueStepsIndex),
                                      variant: co.STYLE.VARIANT.CONTAINED,
                                      color: co.STYLE.PRIMARY,
                                      label: { key: 'deleteBtn' },
                                      icon: <DeleteIcon />,
                                    }}
                                  ></TempBtn>
                                </Card>
                              )
                            }}
                          </Draggable>
                        )
                      })}
                    {provided.placeholder}
                  </div>
                )
              }}
            </Droppable>
          </DragDropContext>
        </>
      ),
      isEndOfLine: true,
    },
    {
      type: co.TYPE.TITLE,
      value: { resource: resource, key: 'campaign' },
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'campaignQueue' },
      value: savedQueueConfig.campaignQueue,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.CAMPAIGN_QUEUE,
      name: 'campaignQueue',
      isEndOfLine: false,
    },
    {
      className: `edit-create-input`,
      label: { resource: resource, key: 'campaignType' },
      value: savedQueueConfig.campaignType,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.CAMPAIGN_TYPE,
      disableClearable: true,
      name: 'campaignType',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'eventCheckInType' },
      value: savedQueueConfig.eventCheckInType,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.QUEUE_CHECK_IN_TYPE,
      disableClearable: true,
      name: 'eventCheckInType',
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'campaignStartTimestamp' },
      value: savedQueueConfig.campaignStartTimestamp,
      name: 'campaignStartTimestamp',
      type: co.TYPE.DATETIME_LOCAL,
      step: 1,
      clear: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'campaignEndTimestamp' },
      value: savedQueueConfig.campaignEndTimestamp,
      name: 'campaignEndTimestamp',
      type: co.TYPE.DATETIME_LOCAL,
      step: 1,
      clear: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'preparationDay' },
      value: savedQueueConfig.preparationDay,
      name: 'preparationDay',
      type: co.TYPE.NUMBER,
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'email' },
      value: !savedQueueConfig.email ? false : savedQueueConfig.email,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.EMAIL,
      name: 'email',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'mobile' },
      value: !savedQueueConfig.mobile ? false : savedQueueConfig.mobile,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.QUEUE_CONFIG.MOBILE,
      name: 'mobile',
      disableClearable: true,
      isEndOfLine: true,
    },
    {
      className: 'sub-sub-title',
      type: co.TYPE.TITLE,
      value: { resource: resource, key: 'resourcesSteps' },
    },
    {
      className: 'edit-create-input',
      type: co.TYPE.FUNCTION,
      value: <ResourcesSteps onChangeInput={onChangeInput} resource={resource} />,
      isEndOfLine: true,
    },
  ]
  const content = [
    {
      tab: fields.map((inputSetting, index) => {
        let content
        switch (inputSetting.type) {
          case co.TYPE.SELECT:
            content = <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break
          case co.TYPE.FUNCTION:
            content = inputSetting.value
            break
          case co.TYPE.TITLE:
            content = (
              <div className={`${inputSetting.className + ' sub-title'}`}>
                {getString(lang, inputSetting.value.resource, inputSetting.value.key, theme)}
              </div>
            )
            break
          default:
            content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
        }
        return (
          <div className="edit-display-wrapper" key={index}>
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        )
      }),
      btn: true,
    },
  ]

  const bottomBtns = [
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: () => setClear(true),
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
    // {
    //   className: "margin-left-16",
    //   type: constants.TYPE.TEXT,
    //   label: savedQueueConfig.resetUpdateTimestamp ? { key: "resetUpdateTimestamp" } : "",
    //   value: savedQueueConfig.resetUpdateTimestamp ? ": " + moment(savedQueueConfig.resetUpdateTimestamp).format('YYYY-MM-DD HH:mm:ss') : "",
    // },
    {
      className: 'special-button special-button-margin-left',
      variant: co.STYLE.VARIANT.CONTAINED,
      icon: <CachedIcon />,
      label: { resource: resource, key: 'reset' },
      onClick: onClickClearDialog,
    },
  ]

  // const handleClose = () => {
  //   setDialog({
  //     open: false,
  //     dialogActions: []
  //   })
  // };

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    setClear(true)
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + co.PATH.SHOP.SYSTEM_GROUP_RESTAURANT)
  }

  const setUnlimitedQuota = (name) => {
    onChangeInput(name, -1)
  }

  const setNull = (name) => {
    dispatch(setQueueConfigValue(name, savedQueueConfig, '', null))
  }

  function onDragEnd(result) {
    if (!result.destination) return
    let newQueueSteps = _.cloneDeep(savedQueueConfig.queueSteps)
    const [reorderedItem] = newQueueSteps.splice(result.source.index, 1)
    newQueueSteps.splice(result.destination.index, 0, reorderedItem)
    dispatch(setQueueConfigValue('queueSteps', savedQueueConfig, '', newQueueSteps))
  }

  function addQueueSteps() {
    let newQueueSteps = _.cloneDeep(savedQueueConfig.queueSteps)
    newQueueSteps && newQueueSteps.length > 0 ? newQueueSteps.push('') : (newQueueSteps = [''])
    dispatch(setQueueConfigValue('queueSteps', savedQueueConfig, '', newQueueSteps))
  }

  function removeQueueSteps(idx) {
    let newQueueSteps = _.cloneDeep(savedQueueConfig.queueSteps)
    if (newQueueSteps && newQueueSteps.length > 0) {
      newQueueSteps = newQueueSteps.filter((item, newQueueStepsIndex) => newQueueStepsIndex !== idx)
    }
    dispatch(setQueueConfigValue('queueSteps', savedQueueConfig, '', newQueueSteps))
  }

  function validate(savedQueueConfig) {
    let newError = { ...error }

    // console.log('Number.isInteger(savedQueueConfig.limitTicketInMinute)', Number.isInteger(savedQueueConfig.limitTicketInMinute))
    // console.log('Number.isInteger(savedQueueConfig.maxTicketPerSection)', Number.isInteger(savedQueueConfig.maxTicketPerSection))
    // console.log('savedQueueConfig.limitTicketInMinute !== null', savedQueueConfig.limitTicketInMinute !== null)
    // console.log('savedQueueConfig.maxTicketPerSection !== null', savedQueueConfig.maxTicketPerSection !== null)

    if (
      (Number.isInteger(savedQueueConfig.limitTicketInMinute) && Number.isInteger(savedQueueConfig.maxTicketPerSection)) ||
      (savedQueueConfig.limitTicketInMinute === null && savedQueueConfig.maxTicketPerSection === null)
    ) {
      newError.maxTicketPerSection = ''
      newError.limitTicketInMinute = ''
    } else {
      newError.maxTicketPerSection = 'Both Max Ticket Per Section and Limit Ticket In Minute should be integer or empty.'
      newError.limitTicketInMinute = 'Both Max Ticket Per Section and Limit Ticket In Minute should be integer or empty.'
    }

    if (savedQueueConfig.transferable && savedQueueConfig.checkOutType === 'NONE') {
      newError.transferable = 'Check Out Type Should Not be NONE if transferrable is true'
      newError.checkOutType = 'Check Out Type Should Not be NONE if transferrable is true'
    } else {
      newError.transferable = ''
      newError.checkOutType = ''
    }

    setError(newError)
  }

  function onChangeInput(name, e, idx) {
    if (e === '') {
      e = null
    }

    switch (name) {
      case 'campaignStartTimestamp':
      case 'campaignEndTimestamp':
        dispatch(setQueueConfigValue(name, savedQueueConfig, '', e ? Number(moment(e).format('x')) : null))
        break
      case 'timeSectionQuota':
      case 'rollTagShiftValue':
      case 'ticketExpiredMinute':
      case 'checksumExpiryLimit':
      case 'queuePeriod':
      case 'minCheckInSize':
      case 'maxCheckInSize':
      case 'checkInLateMinute':
      case 'checkInBeforeMinute':
        //TO-DO
        //a quick fix,
        // when user input invalid number, the input will fixed as 0
        // user input 0.1 > fixed to 0
        // second time user input 0.2 > the non-change 0 does not trigger useState change
        // causing the input field displaying 0.2
        dispatch(setQueueConfigValue(name, savedQueueConfig, '', e))
        dispatch(setQueueConfigValue(name, savedQueueConfig, '', e === '-' || !e ? e : Number.isInteger(Number(e)) ? parseInt(e) : 0))
        break
      case 'queueSteps':
        let newQueueSteps = _.cloneDeep(savedQueueConfig.queueSteps)
        newQueueSteps = newQueueSteps.map((newQueueStepsValue, newQueueStepsIndex) => {
          if (newQueueStepsIndex === idx) {
            newQueueStepsValue = e
          }
          return newQueueStepsValue
        })
        dispatch(setQueueConfigValue(name, savedQueueConfig, '', newQueueSteps))
        break
      case 'dailyResetTime':
        if (!e) {
          dispatch(setQueueConfigValue(name, savedQueueConfig, '', e))
          return
        }

        let time = e
        const timeRegex = /^[0-9]{2}:[0-9]{2}$/
        if (time.match(timeRegex)) {
          time = time + ':00'
        }
        dispatch(setQueueConfigValue(name, savedQueueConfig, '', time))
        break
      case 'resourcesSteps':
        dispatch(setQueueConfigResourcesStepsValue(name, savedQueueConfig, '', e, idx))
        break
      case 'transferable':
      case 'checkOutType':
        dispatch(setQueueConfigValue(name, savedQueueConfig, '', e))
        break
      case 'maxTicketPerSection':
      case 'limitTicketInMinute':
        dispatch(setQueueConfigValue(name, savedQueueConfig, '', Number(e)))
        break
      default:
        dispatch(setQueueConfigValue(name, savedQueueConfig, '', e))
        break
    }
  }

  function onClickClearDialog() {
    setResetDialogOpen(true)
  }

  function onClickReset() {
    put(
      co.PATH.SHOP.QUEUE.RESET_CURRENT_TIME_SECTION + '/' + params.id,
      undefined,
      () => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: 'resetSuccess' },
          dialogActions: [
            { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
          ],
        })
        setClear(false)
      },
      undefined,
      store
    )
  }

  function onClickSave() {
    let newSavedQueueConfig = _.cloneDeep(savedQueueConfig)

    if (Object.values(error).some((value) => value)) {
      return
    }

    setError(defaultError)

    let newCustomData = []

    if (newSavedQueueConfig.email) {
      newCustomData.push({ type: 'EMAIL', required: true })
    }
    if (newSavedQueueConfig.mobile) {
      newCustomData.push({ type: 'MOBILE', required: true })
    }

    delete newSavedQueueConfig.resetUpdateTimestamp

    newSavedQueueConfig.customData = newCustomData.length === 0 ? null : JSON.stringify(newCustomData)
    newSavedQueueConfig.queueSteps =
      newSavedQueueConfig.queueSteps && newSavedQueueConfig.queueSteps.length > 0
        ? JSON.stringify(newSavedQueueConfig.queueSteps.filter((item) => item))
        : null
    newSavedQueueConfig.resourcesSteps =
      newSavedQueueConfig.resourcesSteps && newSavedQueueConfig.resourcesSteps.length > 0
        ? JSON.stringify(newSavedQueueConfig.resourcesSteps.filter((item) => item))
        : null

    if (savedQueueConfig.id) {
      put(
        co.PATH.SHOP.QUEUE.QUEUE_CONFIG + '/' + params.id,
        newSavedQueueConfig,
        () => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
      // console.log(tempSavedQueueConfig)
    } else {
      newSavedQueueConfig.restUrlId = params.id
      post(
        co.PATH.SHOP.QUEUE.QUEUE_CONFIG,
        newSavedQueueConfig,
        () => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
    }
  }

  useEffect(() => {
    if (clear === true) {
      setError(defaultError)
      getOne(
        co.PATH.SHOP.QUEUE.QUEUE_CONFIG + '/' + params.id,
        undefined,
        (payload) => {
          let newPayload = _.cloneDeep(payload)
          let newCustomData = JSON.parse(newPayload.customData)
          newPayload.queueSteps = JSON.parse(newPayload.queueSteps)
          newPayload.resourcesSteps = JSON.parse(newPayload.resourcesSteps)

          if (newCustomData) {
            newCustomData.forEach((item) => {
              if (item.type === 'EMAIL') {
                newPayload.email = item.required
              } else if (item.type === 'MOBILE') {
                newPayload.mobile = item.required
              }
            })
          }

          if (!newPayload.campaignType) {
            newPayload.campaignType = 'NONE'
          }

          if (!newPayload.vipType) {
            newPayload.vipType = 'NONE'
          }

          if (newPayload.prioritize === undefined) {
            newPayload.prioritize = false
          }

          dispatch(setQueueConfig(newPayload))
          setReady(true)
          setClear(false)
        },
        undefined,
        store
      )
    }
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   console.log('savedQueueConfig', savedQueueConfig)
  // }, [savedQueueConfig])

  // useEffect(() => {
  //   console.log('error', error)
  // }, [error])

  useEffect(() => {
    validate(savedQueueConfig)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedQueueConfig])

  return (
    ready && (
      <>
        <EditCreateDisplay
          code={params.id ? params.id : ''}
          resource={resource}
          tabs={false}
          content={content}
          onChangeInput={onChangeInput}
          bottomBtns={bottomBtns}
          display="none"
        />
        {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
        {resetDialogOpen && (
          <ResetDialog
            open={resetDialogOpen}
            setOpen={setResetDialogOpen}
            onClickReset={onClickReset}
            resetTimeStamp={savedQueueConfig.resetUpdateTimestamp}
            shopName={getRestaurantIdAndName(params.id)}
          />
        )}
      </>
    )
  )
}
