import { Check } from '@mui/icons-material'
import { Box, Button, Menu, MenuItem } from '@mui/material'
import { useState } from 'react'

export default function MenuButton({ button, options, onChange, value }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const onClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  const onClickOption = (value) => {
    onChange(value)
    onClose()
  }

  return (
    <>
      <Button onClick={onClick} variant={button.variant} color={button.color}>
        {button.label}
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} onClick={() => onClickOption(option.value)} selected={option.value === value}>
            {option.value === value ? <Check sx={{ mr: '8px' }} /> : <Box sx={{ width: '32px' }} />}
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
