import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import moment from 'moment';

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CachedIcon from '@mui/icons-material/Cached';

import { constants, SYSTEM_USER } from "../../../../../../Constants/constants";
import { helperTextStyles } from "../../../../../../helper/util";
import { getOne, put } from "../../../../../../helper/baseFetch";
import { getContent } from "../../../../../EditCreateDisplay/EditUtil";
import { GlobalDialog } from "../../../../../GlobalDialog";
import { EditCreateDisplay } from "../../../../../EditCreateDisplay/EditCreateDisplay";
import { TempTextInput } from "../../../../../Input/TempTextInput";
import { setSystemUserValue, setSystemUser } from "../../../../../../actions/SystemUserAction";
import { SystemUserRestField } from "./SystemUserRestField";
import { systemUserValidationConstants } from "../../../../../../Constants/validationConstants";
import SystemUserValidation from "../../../../../../validations/systemUserValidation";

export const SystemUserEdit = ({redirectPath}) =>{
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const [password, setPassword] = useState(null);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "systemUser";
  const savedSystemUser = useSelector(state => state.systemUser.ui.systemUser);
  // const [selectTagInputList, setSelectTagInputList] = useState(_.cloneDeep(constants.SELECT_TAG_INPUT));
  const [, setErrorVersion] = useState(SystemUserValidation.getVersion());

  const helperTextStylesClasses = helperTextStyles();
  //console.log(savedSelectTag)
  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.SYSTEM_USER.INFO
    },
  ]
  const tempSelectTagEditList = [
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "username" },
      value: savedSystemUser.username,
      name: "username",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "email" },
      value: savedSystemUser.email,
      name: "email",
      type: constants.TYPE.TEXT,
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: SystemUserValidation.getErrorMessages(systemUserValidationConstants.KEY_EMAIL),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "userRole" },
      value: savedSystemUser.userRole,
      name: "userRole",
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.SYSTEM_USER.USER_ROLE,
      isEndOfLine: false,
      required: true,
      disabled: false,
      helperTextIcon: true,
      helperText: SystemUserValidation.getErrorMessages(systemUserValidationConstants.KEY_USER_ROLE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "privilegeAttribute" },
      value: savedSystemUser.privilegeAttribute,
      name: "privilegeAttribute",
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.SYSTEM_USER.PRIVILEGE_ATTRIBUTE,
      isEndOfLine: false,
      required: true,
      disabled: false,
      helperTextIcon: true,
      helperText: SystemUserValidation.getErrorMessages(systemUserValidationConstants.KEY_PRIVILEGE_ATTRIBUTE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input ${savedSystemUser.privilegeAttribute === SYSTEM_USER.PRIVILEGE_ATTRIBUTE.G?"display-none": ""}`,
      size: constants.SIZE.SMALL,
      type: constants.TYPE.FUNCTION,
      value: <SystemUserRestField onChangeInput={onChangeInput} />
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "apiGroup" },
      value: savedSystemUser.apiGroup,
      name: "apiGroup",
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.SYSTEM_USER.APIGROUP,
      isEndOfLine: false,
      required: false,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "status" },
      value: savedSystemUser.status,
      name: "status",
      isEndOfLine: true,
      required: true,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.SYSTEM_USER.STATUS,
      helperTextIcon: true,
      helperText: SystemUserValidation.getErrorMessages(systemUserValidationConstants.KEY_STATUS),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]

  const content = [ //to-do export this function
    {
      tab: tempSelectTagEditList.map((inputSetting, index) => {
        let content = getContent(inputSetting, index, onChangeInput);
        return <div className="edit-display-wrapper" key={index}>
                  {content}
                  {inputSetting.isEndOfLine && <div></div>}
                </div>
      }),
      btn: true
    },
  ]
  const bottomBtns = [
    {
      className: "margin-left-16",
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    },
    {
      className: "margin-left-16",
      type: constants.TYPE.TEXT,
      label: { key: "lastLoginTimestamp" },
      value: savedSystemUser.lastLoginTimestamp ? ": " + moment(savedSystemUser.lastLoginTimestamp).format('YYYY-MM-DD HH:mm:ss') : " -----",
    },
    {
      className: "special-button special-button-margin-left",
      variant: constants.STYLE.VARIANT.CONTAINED,
      icon: <CachedIcon />,
      label: { key: "resetPassword" },
      onClick: () => handleResetPassword(null),
    },
  ]

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    
    history.push("/" + constants.PATH.PRODUCT.SYSTEM_GROUP +"/"+ savedSystemUser.systemGroupId + "/" + constants.SYSTEM_GROUP.SYSTEM_USER );
  };

  function onChangeInput(name, e) {
    switch(name){
      case "password":
        setPassword(e);
        break;
      default:
        dispatch(setSystemUserValue(name, savedSystemUser, "", e));
    }
  }

  function onClickSave() {

    SystemUserValidation.validateEditSystemUser(savedSystemUser);
    if (SystemUserValidation.isValid()) {
      put(constants.PATH.SYSTEM_GROUP.SYSTEM_USER+ "/" + params.id, savedSystemUser, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      SystemUserValidation.resetErrorMessages();
    } else {
      setErrorVersion(SystemUserValidation.getVersion());
    }

  }

  
  const handleClose = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
  };

  function handleResetPassword(newPassword) {
    setDialog({
      open: true,
      onClose: handleClose,
      dialogContent:
        <TempTextInput
          inputSetting={{
            className: "full-size-input",
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: "newPassword" },
            value: newPassword,
            name: "password",
          }}
          handleChange={onChangeInput}
        />,
      dialogActions: [
        { label: { key: "resetPassword" }, onClick: () => onClickReset(newPassword), icon: <CachedIcon /> },
        { label: { key: "backEdit" }, onClick: handleClose, icon: <ArrowBackIcon /> }
      ]
    })
  }

  function onClickReset(newPassword) {
    put(constants.PATH.SYSTEM_GROUP.SYSTEM_USER_UPDATE_PASSWORD + "/" + params.id, { password: newPassword }, (payload) => {
      setDialog({
        open: true,
        onClose: onClickBackToEdit,
        dialogContentText: { key: "editSuccess" },
        dialogActions: [
          { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
          { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
        ]
      })
      setClear(false)
    }, undefined, store);
  }

  function onClickClear() {
    setClear(true);
    // SelectTagValidation.resetErrorMessages();
  }

  useEffect(() => {
    if (password) {
      handleResetPassword(password);
    }
  }, [password])

  useEffect(() => {
    if (clear === true) {
      getOne(constants.PATH.SYSTEM_GROUP.SYSTEM_USER + "/" + params.id, undefined, (payload) => {
        dispatch(setSystemUser(payload));
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedSystemUser && savedSystemUser.id ? savedSystemUser.id : ""}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="edit"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
}