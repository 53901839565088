import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector, useStore } from 'react-redux'
import _ from 'lodash'

import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AddIcon from '@mui/icons-material/Add'

import { constants } from '../../../../../Constants/constants'
import { put, getOne } from '../../../../../helper/baseFetch'
import { helperTextStyles } from '../../../../../helper/util'
import { setSystemGroup, setSystemGroupValue, addSystemGroupEmailList } from '../../../../../actions/SystemGroupAction'
import { EditCreateDisplay } from '../../../../EditCreateDisplay/EditCreateDisplay'
// import { TempBtn } from "../../../../Input/TempBtn";
import { GlobalDialog } from '../../../../GlobalDialog'
import SystemGroupValidation from '../../../../../validations/systemGroupValidation'
import { systemGroupValidationConstants } from '../../../../../Constants/validationConstants'
// import { RestaurantList } from "../../../../../Page/Restaurant/RestaurantList";
import { getContent, getDefaultBottomBtnList } from '../../../../EditCreateDisplay/EditUtil'
import { tabs } from './displayDetail'
import { RestaurantMapTabContent } from './RestaurantMapTabContent'
import { EmailField } from './EmailField'
import { SystemUserList } from './SystemUser/SystemUserList'

export const ProductSystemGroupEdit = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const store = useStore()
  const history = useHistory()
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(true)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const resource = 'productCreateSystemGroupList'
  const savedSystemGroup = useSelector((state) => state.systemGroup.ui.systemGroup)
  const helperTextStylesClasses = helperTextStyles()
  const [, setErrorVersion] = useState(SystemGroupValidation.getVersion())

  const handleAddEmail = () => {
    dispatch(addSystemGroupEmailList(savedSystemGroup))
  }

  const tempEditList = [
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'name' },
      value: savedSystemGroup.name,
      name: 'name',
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: SystemGroupValidation.getErrorMessages(systemGroupValidationConstants.KEY_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { resource: resource, key: 'addEmail' },
      disabled: false,
      onClick: () => handleAddEmail(),
      name: 'addEmail',
      type: constants.TYPE.BUTTON,
      icon: <AddIcon />,
      isEndOfLine: true,
    },
  ]

  savedSystemGroup?.groupEmailList?.forEach((item, index) => {
    tempEditList.push({
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      type: constants.TYPE.FUNCTION,
      isEndOfLine: true,
      value: <EmailField item={item} index={index} onChangeInput={onChangeInput} />,
    })
  })

  const content = [
    {
      tab: tempEditList.map((inputSetting, index) => {
        let content = getContent(inputSetting, index, onChangeInput)
        return (
          <div className="edit-display-wrapper" key={index}>
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        )
      }),
      btn: true,
    },
    {
      tab: <RestaurantMapTabContent />,
      btn: false,
    },
    {
      tab: <SystemUserList />,
      btn: false,
    },
  ]
  const bottomBtns = getDefaultBottomBtnList(onClickSave, onClickClear)

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + constants.PATH.PRODUCT.SYSTEM_GROUP)
  }

  function onChangeInput(name, e) {
    if (name.startsWith('groupEmails')) {
      const index = name.split('-')[1]
      dispatch(setSystemGroupValue('groupEmails', savedSystemGroup, index, e))
      return
    }
    dispatch(setSystemGroupValue(name, savedSystemGroup, '', e))
  }

  function onClickSave() {
    SystemGroupValidation.validateEditSystemGroup(savedSystemGroup)
    if (SystemGroupValidation.isValid()) {
      put(
        constants.PATH.PRODUCT.SYSTEM_GROUP + '/' + params.id,
        savedSystemGroup,
        (payload) => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
      SystemGroupValidation.resetErrorMessages()
    } else {
      setErrorVersion(SystemGroupValidation.getVersion())
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(
        constants.PATH.PRODUCT.SYSTEM_GROUP + '/' + params.id,
        undefined,
        (payload) => {
          dispatch(setSystemGroup(_.cloneDeep(payload)))
          setReady(true)
          setClear(false)
        },
        undefined,
        store
      )
    }
    SystemGroupValidation.resetErrorMessages()
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready && (
      <>
        <EditCreateDisplay
          code={savedSystemGroup && savedSystemGroup.id ? savedSystemGroup.id : ''}
          resource={resource}
          tabs={tabs}
          content={content}
          onChangeInput={onChangeInput}
          bottomBtns={bottomBtns}
          display="edit"
        />
        {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
      </>
    )
  )
}
