import { useState, useEffect } from "react";
import { EditCreateDisplay } from '../../../Component/EditCreateDisplay/EditCreateDisplay';
import { constants } from '../../../Constants/constants';
import { getContent, getDefaultBottomBtnList } from '../../../Component/EditCreateDisplay/EditUtil';
import { helperTextStyles } from '../../../helper/util';
import passwordValidation from "../../../validations/passwordValidation";
import { passwordValidationConstants } from '../../../Constants/validationConstants';
import _ from "lodash";
import { put } from '../../../helper/baseFetch';
import { useStore } from "react-redux";
import { GlobalDialog } from "../../../Component/GlobalDialog";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import authProvider from "../../../authProvider";

const resource = "password"

export const ResetPassword = () => {

  const [password, setPassword] = useState({newPassword: "", verifyNewPassword: ""});
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(passwordValidation.getVersion());
  const store = useStore(); 
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });

  const tempEditList = [
    // {
    //   className: "edit-create-input",
    //   size: constants.SIZE.SMALL,
    //   label: { resource: resource, key: "oldPassword" },
    //   value: password.oldPassword,
    //   name: "oldPassword",
    //   isEndOfLine: true,
    //   disabled: false,
    //   required: true,
    //   helperTextIcon: true,
    //   helperText: passwordValidation.getErrorMessages(passwordValidationConstants.KEY_OLD_PASSWORD),
    //   FormHelperTextProps: { className: helperTextStylesClasses.root },
    // },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "newPassword" },
      value: password.newPassword,
      name: "newPassword",
      type: "password",
      isEndOfLine: true,
      disabled: false,
      required: true,
      helperTextIcon: true,
      helperText: passwordValidation.getErrorMessages(passwordValidationConstants.KEY_NEW_PASSWORD),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enterAgainPassword" },
      value: password.verifyNewPassword,
      name: "verifyNewPassword",
      type: "password",
      isEndOfLine: true,
      disabled: false,
      required: true,
      helperTextIcon: true,
      helperText: passwordValidation.getErrorMessages(passwordValidationConstants.KEY_VERIFY_PASSWORD),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]

  function onChangeInput(name, e) {
    const temptPassword = _.cloneDeep(password);
    switch(name){
      default:
        temptPassword[`${name}`] = e;
        setPassword(temptPassword);
    }
  }

  const content = [
    {
      tab: tempEditList.map((inputSetting, index) => {
        let content = getContent(inputSetting, index, onChangeInput)
        return <div className="edit-display-wrapper" key={index}>
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: true
    },
  ]

  const onClickBackToEdit = () => { 
    authProvider.logout()
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };

  function onClickSave() {
    passwordValidation.validateEditPassword(password);
    if (passwordValidation.isValid()) { 
      put(constants.PATH.PASSWORD.RESET_PASSWORD, {'password': password.newPassword}, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
          ]
        })
        setClear(false)
      }, undefined, store);
      passwordValidation.resetErrorMessages();
    } 
    else {
      setErrorVersion(passwordValidation.getVersion());
    }
  }

  function onClickClear() {
    setPassword({oldPassword: "", newPassword: "", verifyNewPassword: ""})
  }

  const bottomBtns = getDefaultBottomBtnList(onClickSave, onClickClear);

  useEffect(() => {
    if (clear === true) {
      setPassword({newPassword: "", verifyNewPassword: ""})
    }
    passwordValidation.resetErrorMessages();
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <EditCreateDisplay
        code={""}
        resource={resource}
        tabs={false}
        content={content}
        onChangeInput={onChangeInput}
        display={"none"}
        bottomBtns={bottomBtns}
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
}
