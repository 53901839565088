import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Drawer } from "@mui/material";
import { Card } from '@mui/material';
import Paper from '@mui/material/Paper';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { post } from "../../helper/baseFetch";
import { setLoading } from "../../actions/systemAction";
import { setFolderMap } from "../../actions/folderMapAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempBtn } from "../../Component/Input/TempBtn"
import { FolderList } from "./FolderList";
import { RestaurantList } from "../Restaurant/RestaurantList";
import { GlobalDialog } from "../../Component/GlobalDialog";
import FolderMapValidation from "../../validations/folderMapValidation";
import { folderMapValidationConstants } from '../../Constants/validationConstants';

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const FolderMapCreate = ({ createClear, toolBar }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const savedFolder = useSelector(state => state.folder.ui.folder)
  const savedFolderMap = useSelector(state => state.folderMap.ui.folderMap)
  const [clear, setClear] = useState(false);
  const [folderMapType, setFolderMapType] = useState(undefined);
  const [previewSelectedList, setPreviewSelectedList] = useState([]);
  const [selectedIdList, setSelectedIdList] = useState([]);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const [showFolderMapTextPanel, setShowFolderMapTextPanel] = useState(false);
  const resource = "FolderMap";
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(FolderMapValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.FOLDER_MAP_TAB.INFO
    }
  ]

  const handleFolderMap = () => {
    dispatch(setLoading(true));
    let tempSavedFolderMapList = _.cloneDeep(savedFolderMap.filter((tempSavedFolderMap) => tempSavedFolderMap["type"] !== folderMapType || selectedIdList.includes(tempSavedFolderMap["refCode"])));
    let filterPreviewSelectedList = _.cloneDeep(previewSelectedList.map((previewSelected) => {
      let selected = savedFolderMap.map((tempSavedFolderMap) => tempSavedFolderMap["refCode"]);
      return !selected.includes(previewSelected.refCode) && previewSelected
    }).filter(Boolean));

    let newTempFolderMapList = filterPreviewSelectedList.map((item) => {
      return {
        refCode: item.refCode,
        type: item.type,
        enName: item.enName,
        tcName: item.tcName,
        scName: item.scName,
        sequence: item.sequence,
      }
    })

    tempSavedFolderMapList = tempSavedFolderMapList.concat(newTempFolderMapList)

    setShowFolderMapTextPanel(false);
    dispatch(setFolderMap(tempSavedFolderMapList));
    dispatch(setLoading(false));
  }

  function handeleDelete(item) {
    let tempSavedFolderMap = _.cloneDeep(savedFolderMap);
    setSelectedIdList(selectedIdList.filter((previewSelected) => previewSelected !== item.refCode));
    setPreviewSelectedList(previewSelectedList.filter((previewSelected) => previewSelected.refCode !== item.refCode));
    tempSavedFolderMap = tempSavedFolderMap.filter((tempSavedFolderMap) => tempSavedFolderMap["refCode"] !== item.refCode);
    dispatch(setFolderMap(tempSavedFolderMap));
  }

  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  const dalogBottomBtnList = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      disabled: false,
      onClick: handleFolderMap,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "close" },
      disabled: false,
      onClick: handleFolderMapCloseClick,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  const content = [
    {
      tab: <>
        {FolderMapValidation.getErrorMessages(folderMapValidationConstants.KEY_FOLDER_MAP_ERROR) &&
          FolderMapValidation.getErrorMessages(folderMapValidationConstants.KEY_FOLDER_MAP_ERROR).length > 0 &&
          <div className="error-message">
            <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
            {FolderMapValidation.getErrorMessages(folderMapValidationConstants.KEY_FOLDER_MAP_ERROR)}
          </div>
        }
        <TempBtn
          btnSetting=
          {{
            className: "margin-left-16",
            onClick: () => { handleAddFolderMapList("RESTAURANT") },
            variant: constants.STYLE.VARIANT.CONTAINED,
            color: constants.STYLE.PRIMARY,
            label: { resource: "restaurant", key: "addFolderMap" },
            icon: <AddIcon />,
          }}
        >
          <AddIcon />
        </TempBtn>
        <TempBtn
          btnSetting=
          {{
            className: "margin-left-16",
            onClick: () => { handleAddFolderMapList("FOLDER") },
            variant: constants.STYLE.VARIANT.CONTAINED,
            color: constants.STYLE.PRIMARY,
            label: { resource: "Folder", key: "addFolderMap" },
            icon: <AddIcon />,
          }}
        >
          <AddIcon />
        </TempBtn>
        {savedFolderMap && savedFolderMap.length > 0 && savedFolderMap.map((item, index) => {
          const newTempFolderMapList = [
            {
              className: "edit-create-input",
              variant: constants.STYLE.VARIANT.STANDARD,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "refCode" },
              value: item.refCode,
              name: "refCode",
              isEndOfLine: false,
              disabled: true,
            },
            {
              className: "edit-create-input",
              variant: constants.STYLE.VARIANT.STANDARD,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "type" },
              value: item.type,
              name: "type",
              isEndOfLine: true,
              disabled: true,
            },
            {
              className: "edit-create-input",
              variant: constants.STYLE.VARIANT.STANDARD,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "refEnName" },
              value: item.enName,
              name: "enName",
              isEndOfLine: false,
              multiple: true,
              disabled: true,
            },
            {
              className: "edit-create-input",
              variant: constants.STYLE.VARIANT.STANDARD,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "refTcName" },
              value: item.tcName,
              name: "tcName",
              isEndOfLine: false,
              multiple: true,
              disabled: true,
            },
            {
              className: "edit-create-input",
              variant: constants.STYLE.VARIANT.STANDARD,
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "refScName" },
              value: item.scName,
              name: "scName",
              isEndOfLine: true,
              multiple: true,
              disabled: true,
            },
            {
              className: "edit-create-input",
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "sequence" },
              value: item.sequence,
              name: "sequence",
              type: constants.TYPE.NUMBER,
              isEndOfLine: false,
              disabled: false,
              disableClearable: true,
              helperTextIcon: true,
              helperText: FolderMapValidation.getErrorMessages(folderMapValidationConstants.KEY_SEQUENCE, item.refCode),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
            },
          ]

          return <Card key={index} className="image-list">
            <>
              <div className="delete-icon">
                <TempBtn btnSetting={{
                  className: "edit-create-btn",
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: () => handeleDelete(item, index),
                  label: { key: "deleteBtn" },
                  icon: <DeleteIcon />,
                }} />
              </div>
              {newTempFolderMapList.map((inputSetting, newTempFolderMapListIndex) => {
                return <div className="edit-display-wrapper" key={newTempFolderMapListIndex}>
                  <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} index={index} />
                  {inputSetting.isEndOfLine && <div></div>}
                </div>
              })}
            </>
          </Card>
        })}
        <Drawer
          classes={{ paper: "drawer-margin-left" }}
          anchor="right"
          open={showFolderMapTextPanel}
          onClose={handleFolderMapCloseClick}
        >
          <Paper>
            {folderMapType === "RESTAURANT" &&
              <RestaurantList
                pagenName={resource}
                editBtn={false}
                createBtn={false}
                btnList={false}
                mutipleSelect={true}
                setSelectedIdList={setSelectedIdList}
                selectedIdList={selectedIdList}
                setPreviewSelectedList={setPreviewSelectedList}
                previewSelectedList={previewSelectedList.filter((tempSavedFolderMap) => tempSavedFolderMap.type === "RESTAURANT")}
                newPath={savedFolder.id ? constants.PATH.FOLDER.FOLDER_RESTAURANT : null}
                newFilter={
                  savedFolderMap.filter((tempSavedFolderMap) => tempSavedFolderMap["type"] !== "FOLDER").length > 0 ? {
                    folderId: savedFolder.id,
                    selected: {
                      column: 'restUrlId',
                      valueList: savedFolderMap.map((tempSavedFolderMap) => { return tempSavedFolderMap["type"] === "RESTAURANT" ? tempSavedFolderMap["refCode"] : "" }).filter(Boolean)
                    }
                  } : { folderId: savedFolder.id }}
                savedData={savedFolderMap.filter((tempSavedFolderMap) => tempSavedFolderMap.type === "RESTAURANT")}
                dialogFooterBtn={
                  <div className="edit-footer-wrapper toolbar">
                    {dalogBottomBtnList.map((btnSetting, index) => {
                      return <TempBtn key={index} btnSetting={btnSetting} />
                    })}
                  </div>}
              />
            }
            {folderMapType === "FOLDER" &&
              <FolderList
                editBtn={false}
                createBtn={false}
                mutipleSelect={true}
                setSelectedIdList={setSelectedIdList}
                selectedIdList={selectedIdList}
                setPreviewSelectedList={setPreviewSelectedList}
                previewSelectedList={previewSelectedList.filter((tempSavedFolderMap) => tempSavedFolderMap.type === "FOLDER")}
                newPath={savedFolder.id ? constants.PATH.FOLDER.FOLDER_FOLDER : null}
                savedData={savedFolderMap.filter((tempSavedFolderMap) => tempSavedFolderMap.type === "FOLDER")}
                newFilter={
                  savedFolderMap.filter((tempSavedFolderMap) => tempSavedFolderMap["type"] !== "RESTAURANT").length > 0 ? {
                    folderId: savedFolder.id,
                    excludedId: savedFolder.id,
                    selected: {
                      column: 'folderCode',
                      valueList: savedFolderMap.map((tempSavedFolderMap) => { return tempSavedFolderMap["type"] === "FOLDER" ? tempSavedFolderMap["refCode"] : "" }).filter(Boolean)
                    }
                  } : { folderId: savedFolder.id, excludedId: savedFolder.id }}
                dialogFooterBtn={
                  <div className="edit-footer-wrapper toolbar">
                    {dalogBottomBtnList.map((btnSetting, index) => {
                      return <TempBtn key={index} btnSetting={btnSetting} />
                    })}
                  </div>}
              />
            }
          </Paper>
        </Drawer>
      </>,
      btn: toolBar !== false ? true : false
    }
  ]

  function onChangeInput(name, e, index) {
    let tempSavedFolderMap = _.cloneDeep(savedFolderMap);
    tempSavedFolderMap.map((tempSavedFolderMapData, tempSavedFolderMapIndex) => {
      if (name === "sequence") {
        e = Number.isInteger(Number(e)) ? Number(e) : 0
      }
      return tempSavedFolderMapIndex === index && (tempSavedFolderMapData[name] = e);
    })
    dispatch(setFolderMap(tempSavedFolderMap));
  }

  function handleAddFolderMapList(folderMapType) {
    dispatch(setLoading(true));
    setFolderMapType(folderMapType);
    setShowFolderMapTextPanel(true);
    dispatch(setLoading(false));
  }

  function handleFolderMapCloseClick() {
    dispatch(setLoading(true));
    setShowFolderMapTextPanel(false);
    setSelectedIdList(_.cloneDeep(savedFolderMap.map((item) => item.refCode)));
    // console.log('map', savedFolderMap, previewSelectedList)
    setPreviewSelectedList(previewSelectedList.filter((item)=>{
      return savedFolderMap.find((savedItem)=>savedItem.refCode === item.refCode)
    }))
    dispatch(setLoading(false));
  };

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.FOLDER.FOLDER + "/" + savedFolder.id + "/" + constants.FOLDER_TAB.FOLDER_MAP_LIST);
  };

  function onClickSave() {
    let tempSavedFolderMap = _.cloneDeep(savedFolderMap);
    FolderMapValidation.validateCreateFolderMap(tempSavedFolderMap);
    if (FolderMapValidation.isValid()) {
      tempSavedFolderMap = tempSavedFolderMap.map((item) => {
        return {
          type: item.type,
          sequence: item.sequence,
          refCode: item.refCode,
        };
      });
      post(constants.PATH.FOLDER.FOLDER_MAP_LIST, { folderId: savedFolder.id, folderMapList: tempSavedFolderMap }, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        dispatch(setFolderMap([]));
        setClear(false)
      }, undefined, store);
      FolderMapValidation.resetErrorMessages();
    } else {
      setErrorVersion(FolderMapValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    setSelectedIdList([]);
    dispatch(setFolderMap([]));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(setLoading(true));
    setPreviewSelectedList([]);
    setSelectedIdList([]);
    setClear(false)
    dispatch(setLoading(false));
    FolderMapValidation.resetErrorMessages();
  }, [createClear === true || clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <EditCreateDisplay
        code={""}
        resource={resource}
        tabs={toolBar !== false ? tabs : ""}
        content={content}
        bottomBtns={bottomBtns}
        onChangeInput={onChangeInput}
        mapName={{ resource: "Folder", key: "folderCode" }}
        mapCode={toolBar !== false ? savedFolder.folderCode : ""}
        display={"create"}
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};