import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CancelIcon from '@mui/icons-material/Cancel'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive'
import { makeStyles } from '@mui/styles'

import { constants as co } from '../../Constants/constants'
import { getList, getOne, put, post } from '../../helper/baseFetch'
import { setReservationConfig, setReservationConfigValue } from '../../actions/reservationConfigAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'
import { TempCustomCheckBox } from '../../Component/Custom/TempCustomCheckBox'
import { getString, getScDisabled } from '../../helper/util'
import { GlobalDialog } from '../../Component/GlobalDialog'
import ReservationConfigValidation from '../../validations/reservationConfigValidation'
import { reservationConfigValidationConstants as validationCo } from '../../Constants/validationConstants'
import { BOOLEAN_OPTIONS } from '../../Constants/type'

const helperTextStyles = makeStyles((theme) => ({
  root: {
    color: 'red !important',
  },
}))

export const ReservationConfigEdit = () => {
  const dispatch = useDispatch()
  const store = useStore()
  const history = useHistory()
  const params = useParams()
  const helperTextStylesClasses = helperTextStyles()
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(true)
  const [chargeCodeList, setChargeCodeList] = useState([])
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const resource = 'ReservationConfig'
  const savedReservationConfig = useSelector((state) => state.reservationConfig.ui.reservationConfig)
  const [reservationConfigInputList, setReservationConfigInputList] = useState(_.cloneDeep(savedReservationConfig))
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)
  // eslint-disable-next-line no-unused-vars
  const [errorVersion, setErrorVersion] = useState(ReservationConfigValidation.getVersion())

  const fields = [
    {
      type: co.TYPE.TITLE,
      value: { resource: resource, key: 'message' },
      className: 'first-sub-title',
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'showRemarks' },
      value: savedReservationConfig.showRemarks,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.RESERVATION_CONFIG.SHOW_REMARKS,
      name: 'showRemarks',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'remarksEnMessage' },
      value: savedReservationConfig.remarksEnMessage,
      name: 'remarksEnMessage',
      custom: true,
      customName: { resource: resource, key: 'remarksMessageCustomize' },
      customValue: savedReservationConfig.remarksScMessageDisable,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'remarksTcMessage' },
      value: savedReservationConfig.remarksTcMessage,
      name: 'remarksTcMessage',
      translateName: savedReservationConfig.remarksScMessageDisable ? ['remarksScMessage'] : null,
      inputList: reservationConfigInputList,
      setInputList: setReservationConfigInputList,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'remarksScMessage' },
      value: savedReservationConfig.remarksScMessage,
      name: 'remarksScMessage',
      inputList: reservationConfigInputList,
      setInputList: setReservationConfigInputList,
      multiline: true,
      isEndOfLine: true,
      disabled: savedReservationConfig.remarksScMessageDisable,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'disableEnMessage' },
      value: savedReservationConfig.disableEnMessage,
      name: 'disableEnMessage',
      custom: true,
      customName: { resource: resource, key: 'disableMessageCustomize' },
      customValue: savedReservationConfig.disableScMessageDisable,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'disableTcMessage' },
      value: savedReservationConfig.disableTcMessage,
      name: 'disableTcMessage',
      translateName: savedReservationConfig.disableScMessageDisable ? ['disableScMessage'] : null,
      inputList: reservationConfigInputList,
      setInputList: setReservationConfigInputList,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'disableScMessage' },
      value: savedReservationConfig.disableScMessage,
      name: 'disableScMessage',
      inputList: reservationConfigInputList,
      setInputList: setReservationConfigInputList,
      multiline: true,
      isEndOfLine: true,
      disabled: savedReservationConfig.disableScMessageDisable,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'reminderEnMessage' },
      value: savedReservationConfig.reminderEnMessage,
      name: 'reminderEnMessage',
      custom: true,
      customName: { resource: resource, key: 'reminderMessageCustomize' },
      customValue: savedReservationConfig.reminderScMessageDisable,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'reminderTcMessage' },
      value: savedReservationConfig.reminderTcMessage,
      name: 'reminderTcMessage',
      translateName: savedReservationConfig.reminderScMessageDisable ? ['reminderScMessage'] : null,
      inputList: reservationConfigInputList,
      setInputList: setReservationConfigInputList,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'reminderScMessage' },
      value: savedReservationConfig.reminderScMessage,
      name: 'reminderScMessage',
      inputList: reservationConfigInputList,
      setInputList: setReservationConfigInputList,
      multiline: true,
      isEndOfLine: true,
      disabled: savedReservationConfig.reminderScMessageDisable,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'noTimeSectionEnMessage' },
      value: savedReservationConfig.noTimeSectionEnMessage,
      name: 'noTimeSectionEnMessage',
      custom: true,
      customName: { resource: resource, key: 'noTimeSectionMessageCustomize' },
      customValue: savedReservationConfig.noTimeSectionScMessageDisable,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'noTimeSectionTcMessage' },
      value: savedReservationConfig.noTimeSectionTcMessage,
      name: 'noTimeSectionTcMessage',
      translateName: savedReservationConfig.noTimeSectionScMessageDisable ? ['noTimeSectionScMessage'] : null,
      inputList: reservationConfigInputList,
      setInputList: setReservationConfigInputList,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'noTimeSectionScMessage' },
      value: savedReservationConfig.noTimeSectionScMessage,
      name: 'noTimeSectionScMessage',
      inputList: reservationConfigInputList,
      setInputList: setReservationConfigInputList,
      multiline: true,
      isEndOfLine: true,
      disabled: savedReservationConfig.noTimeSectionScMessageDisable,
    },
    {
      type: co.TYPE.TITLE,
      value: { resource: resource, key: 'charge' },
      className: 'sub-title',
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'haveCharge' },
      value: savedReservationConfig.haveCharge,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.RESERVATION_CONFIG.HAVE_CHARGE,
      name: 'haveCharge',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'chargeType' },
      value: savedReservationConfig.chargeType,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.RESERVATION_CONFIG.CHARGE_TYPE,
      name: 'chargeType',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'chargeByDefault' },
      value: savedReservationConfig.chargeByDefault,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.RESERVATION_CONFIG.CHARGE_BY_DEFAULT,
      name: 'chargeByDefault',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'chargeCode' },
      value: savedReservationConfig.chargeCode,
      type: co.TYPE.SELECT,
      selectValue: chargeCodeList,
      name: 'chargeCode',
      disableClearable: true,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'defaultCharge' },
      value: savedReservationConfig.defaultCharge,
      name: 'defaultCharge',
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: ReservationConfigValidation.getErrorMessages(validationCo.KEY_DEFAULT_CHARGE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'chargeEnDescription' },
      value: savedReservationConfig.chargeEnDescription,
      name: 'chargeEnDescription',
      custom: true,
      customName: { resource: resource, key: 'chargeDescriptionCustomize' },
      customValue: savedReservationConfig.chargeScDescriptionDisable,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'chargeTcDescription' },
      value: savedReservationConfig.chargeTcDescription,
      name: 'chargeTcDescription',
      translateName: savedReservationConfig.chargeScDescriptionDisable ? ['chargeScDescription'] : null,
      inputList: reservationConfigInputList,
      setInputList: setReservationConfigInputList,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'chargeScDescription' },
      value: savedReservationConfig.chargeScDescription,
      name: 'chargeScDescription',
      inputList: reservationConfigInputList,
      setInputList: setReservationConfigInputList,
      multiline: true,
      isEndOfLine: true,
      disabled: savedReservationConfig.chargeScDescriptionDisable,
    },
    {
      type: co.TYPE.TITLE,
      value: { resource: resource, key: 'other' },
      className: 'sub-title',
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'afterDay' },
      value: savedReservationConfig.afterDay,
      name: 'afterDay',
      isEndOfLine: false,
      type: co.TYPE.NUMBER,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'reserveDay' },
      value: savedReservationConfig.reserveDay,
      name: 'reserveDay',
      isEndOfLine: false,
      type: co.TYPE.NUMBER,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'timeInterval' },
      value: savedReservationConfig.timeInterval,
      name: 'timeInterval',
      isEndOfLine: false,
      type: co.TYPE.NUMBER,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'haveConfirm' },
      value: savedReservationConfig.haveConfirm,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.RESERVATION_CONFIG.HAVE_CONFIRM,
      name: 'haveConfirm',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'adminNotice' },
      value: savedReservationConfig.adminNotice,
      type: co.TYPE.SELECT,
      selectValue: BOOLEAN_OPTIONS,
      name: 'adminNotice',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'showTableNumber' },
      value: savedReservationConfig.showTableNumber,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.RESERVATION_CONFIG.SHOW_TABLE_NUMBER,
      name: 'showTableNumber',
      disableClearable: true,
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'maxConcurrentReservation' },
      value: savedReservationConfig.maxConcurrentReservation === -1 ? '' : savedReservationConfig.maxConcurrentReservation,
      placeholder: savedReservationConfig.maxConcurrentReservation === -1 ? 'Unlimited' : '',
      onClickEndAdornmentIconButton: () => {
        onClickUnlimited('maxConcurrentReservation')
      },
      endAdornmentIconButton: <AllInclusiveIcon fontSize={co.SIZE.SMALL} />,
      type: co.TYPE.NUMBER,
      name: 'maxConcurrentReservation',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'groupedSectionLimit' },
      value: savedReservationConfig.groupedSectionLimit,
      type: co.TYPE.NUMBER,
      name: 'groupedSectionLimit',
      disableClearable: true,
      isEndOfLine: true,
      helperText: ReservationConfigValidation.getErrorMessages(validationCo.KEY_GROUPED_SECTION_LIMIT),
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'startTimestamp' },
      value: savedReservationConfig.startTimestamp === 0 ? '' : savedReservationConfig.startTimestamp,
      name: 'startTimestamp',
      type: co.TYPE.DATETIME_LOCAL,
      clear: true,
      step: 1,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'expiredTimestamp' },
      value: savedReservationConfig.expiredTimestamp === 0 ? '' : savedReservationConfig.expiredTimestamp,
      name: 'expiredTimestamp',
      clear: true,
      type: co.TYPE.DATETIME_LOCAL,
      step: 1,
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'notificationEmail' },
      value: savedReservationConfig.notificationEmail,
      name: 'notificationEmail',
      multiline: true,
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: ReservationConfigValidation.getErrorMessages(validationCo.KEY_EMAIL),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'sendQrcodeToEmail' },
      value: savedReservationConfig.sendQrcodeToEmail,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.RESERVATION_CONFIG.SEND_QRCODE_TO_EMAIL,
      name: 'sendQrcodeToEmail',
      disableClearable: true,
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'reserveStatus' },
      value: savedReservationConfig.reserveStatus,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.RESERVATION_CONFIG.RESERVE_STATUS,
      name: 'reserveStatus',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'editable' },
      value: savedReservationConfig.editable,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.RESERVATION_CONFIG.EDITABLE,
      name: 'editable',
      disableClearable: true,
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'deliveryType' },
      value: savedReservationConfig.deliveryType,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.RESERVATION_CONFIG.DELIVERY_TYPE,
      name: 'deliveryType',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'requiredOtp' },
      value: savedReservationConfig.requiredOtp,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.RESERVATION_CONFIG.REQUIRED_OTP,
      name: 'requiredOtp',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'mode' },
      value: savedReservationConfig.mode,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.RESERVATION_CONFIG.RESERVATION_MODE,
      name: 'mode',
      disableClearable: true,
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'webShare' },
      value: savedReservationConfig.webShare,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.RESERVATION_CONFIG.WEB_SHARE,
      name: 'webShare',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'webMultiReserve' },
      value: savedReservationConfig.webMultiReserve,
      type: co.TYPE.SELECT,
      selectValue: co.CHOICES.RESERVATION_CONFIG.WEB_MULTI_RESERVE,
      name: 'webMultiReserve',
      disableClearable: true,
      isEndOfLine: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'checkInBeforeMinute' },
      value: savedReservationConfig.checkInBeforeMinute,
      name: 'checkInBeforeMinute',
      isEndOfLine: false,
      type: co.TYPE.NUMBER,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'checkInLateMinute' },
      value: savedReservationConfig.checkInLateMinute,
      name: 'checkInLateMinute',
      isEndOfLine: false,
      type: co.TYPE.NUMBER,
    },
  ]
  const content = [
    {
      tab: (
        <>
          {ReservationConfigValidation.getErrorMessages(validationCo.KEY_REST_URL_ID) &&
            ReservationConfigValidation.getErrorMessages(validationCo.KEY_REST_URL_ID).length > 0 && (
              <div className="error-message">
                <CancelIcon className="error-message-icon" fontSize={co.SIZE.SMALL} />
                {ReservationConfigValidation.getErrorMessages(validationCo.KEY_REST_URL_ID)}
              </div>
            )}
          {fields.map((inputSetting, index) => {
            let content
            switch (inputSetting.type) {
              case co.TYPE.SELECT:
                content = <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
                break
              case co.TYPE.FUNCTION:
                content = inputSetting.value
                break
              case co.TYPE.TITLE:
                content = (
                  <div className={`${inputSetting.className + ' sub-title'}`}>
                    {getString(lang, inputSetting.value.resource, inputSetting.value.key, theme)}
                  </div>
                )
                break
              default:
                content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            }
            return (
              <div className="edit-display-wrapper" key={index}>
                {inputSetting.custom && (
                  <TempCustomCheckBox
                    className="customize-btn"
                    value={inputSetting.customValue}
                    customName={inputSetting.customName}
                    onSelectCheckbox={onSelectCheckbox}
                  />
                )}
                {content}
                {inputSetting.isEndOfLine && <div></div>}
              </div>
            )
          })}
        </>
      ),
      btn: true,
    },
  ]

  const bottomBtns = [
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + co.PATH.SHOP.SYSTEM_GROUP_RESTAURANT)
  }

  function onSelectCheckbox(value, customName) {
    switch (customName.key) {
      case 'remarksMessageCustomize':
        dispatch(setReservationConfigValue('remarksScMessageDisable', savedReservationConfig, '', value))
        break
      case 'disableMessageCustomize':
        dispatch(setReservationConfigValue('disableScMessageDisable', savedReservationConfig, '', value))
        break
      case 'reminderMessageCustomize':
        dispatch(setReservationConfigValue('reminderScMessageDisable', savedReservationConfig, '', value))
        break
      case 'noTimeSectionMessageCustomize':
        dispatch(setReservationConfigValue('noTimeSectionScMessageDisable', savedReservationConfig, '', value))
        break
      case 'chargeDescriptionCustomize':
        dispatch(setReservationConfigValue('chargeScDescriptionDisable', savedReservationConfig, '', value))
        break
      default:
    }
  }

  const onClickUnlimited = (name) => {
    onChangeInput(name, -1)
  }

  function onChangeInput(name, e) {
    if (e === '') {
      e = null
    }
    if (name === 'remarksTcMessage' && savedReservationConfig.remarksScMessageDisable === true) {
      dispatch(setReservationConfigValue('CHANGE_TC_REMARKS_MESSAGE_AND_SC_REMARKS_MESSAGE', savedReservationConfig, '', e))
    } else if (name === 'disableTcMessage' && savedReservationConfig.disableScMessageDisable === true) {
      dispatch(setReservationConfigValue('CHANGE_TC_DISABLE_MESSAGE_AND_SC_DISABLE_MESSAGE', savedReservationConfig, '', e))
    } else if (name === 'reminderTcMessage' && savedReservationConfig.reminderScMessageDisable === true) {
      dispatch(setReservationConfigValue('CHANGE_TC_REMINDER_MESSAGE_AND_SC_REMINDER_MESSAGE', savedReservationConfig, '', e))
    } else if (name === 'noTimeSectionTcMessage' && savedReservationConfig.noTimeSectionScMessageDisable === true) {
      dispatch(setReservationConfigValue('CHANGE_TC_NO_TIME_SECTION_MESSAGE_AND_SC_NO_TIME_SECTION_MESSAGE', savedReservationConfig, '', e))
    } else if (name === 'chargeTcDescription' && savedReservationConfig.chargeScDescriptionDisable === true) {
      dispatch(setReservationConfigValue('CHANGE_TC_CHARGE_DESCRIPTION_AND_SC_CHARGE_DESCRIPTION_MESSAGE', savedReservationConfig, '', e))
    } else if (name === 'startTimestamp' || name === 'expiredTimestamp') {
      dispatch(setReservationConfigValue(name, savedReservationConfig, '', e ? Number(moment(e).format('x')) : null))
    } else if (name === 'afterDay' || name === 'reserveDay' || name === 'timeInterval') {
      dispatch(setReservationConfigValue(name, savedReservationConfig, '', !e ? e : Number.isInteger(Number(e)) ? Number(e) : 0))
    } else {
      dispatch(setReservationConfigValue(name, savedReservationConfig, '', e))
    }
  }

  function onClickSave() {
    let newSavedReservationConfig = _.cloneDeep(savedReservationConfig)
    ReservationConfigValidation.validateReservationConfig(newSavedReservationConfig)
    if (ReservationConfigValidation.isValid()) {
      if (savedReservationConfig.id) {
        put(
          co.PATH.SHOP.RESERVATION.RESERVATION_CONFIG + '/' + params.id,
          newSavedReservationConfig,
          () => {
            setDialog({
              open: true,
              onClose: onClickBackToEdit,
              dialogContentText: { key: 'editSuccess' },
              dialogActions: [
                { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
                { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
              ],
            })
            setClear(false)
          },
          undefined,
          store
        )
        ReservationConfigValidation.resetErrorMessages()
      } else {
        newSavedReservationConfig.restUrlId = params.id
        post(
          co.PATH.SHOP.RESERVATION.RESERVATION_CONFIG,
          newSavedReservationConfig,
          () => {
            setDialog({
              open: true,
              onClose: onClickBackToEdit,
              dialogContentText: { key: 'editSuccess' },
              dialogActions: [
                { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
                { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
              ],
            })
            setClear(false)
          },
          undefined,
          store
        )
        ReservationConfigValidation.resetErrorMessages()
      }
    } else {
      setErrorVersion(ReservationConfigValidation.getVersion())
    }
  }

  function onClickClear() {
    setClear(true)
  }

  function onChangeChargeCode() {
    getList(
      co.PATH.SHOP.RESERVATION.RESERVATION_CHARGE_CODE_OPTIONS,
      undefined,
      (payload) => {
        let newChargeCodeList = []
        payload.map((item) => {
          newChargeCodeList.push({ value: item.chargeCode, name: '[' + item.chargeCode + ']  ' + item.tcDescription })
          return item
        })
        setChargeCodeList(newChargeCodeList)
        setReady(true)
      },
      undefined,
      store
    )
  }

  useEffect(() => {
    if (clear === true) {
      getOne(
        co.PATH.SHOP.RESERVATION.RESERVATION_CONFIG + '/' + params.id,
        undefined,
        (payload) => {
          payload.remarksScMessageDisable = getScDisabled(payload.remarksTcMessage, payload.remarksScMessage)
          payload.disableScMessageDisable = getScDisabled(payload.disableTcMessage, payload.disableScMessage)
          payload.reminderScMessageDisable = getScDisabled(payload.reminderTcMessage, payload.reminderScMessage)
          payload.noTimeSectionScMessageDisable = getScDisabled(payload.noTimeSectionTcMessage, payload.noTimeSectionScMessage)
          payload.chargeScDescriptionDisable = getScDisabled(payload.chargeTcDescription, payload.chargeScDescription)

          if (payload.adminNotice === undefined) {
            payload.adminNotice = true
          }

          dispatch(setReservationConfig(_.cloneDeep(payload)))
          setReservationConfigInputList(_.cloneDeep(payload))

          if (!ready) {
            onChangeChargeCode()
          }

          setClear(false)
        },
        undefined,
        store
      )
    }
    ReservationConfigValidation.resetErrorMessages()
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log('savedReservationConfig', savedReservationConfig)
  }, [savedReservationConfig])

  return (
    ready && (
      <>
        <EditCreateDisplay
          code={params.id ? params.id : ''}
          resource={resource}
          tabs={false}
          content={content}
          onChangeInput={onChangeInput}
          bottomBtns={bottomBtns}
          display="none"
        />
        {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
      </>
    )
  )
}
