import Popover from '@mui/material/Popover'
import { useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import _ from 'lodash'
import { Lens } from '@mui/icons-material'
import { Box } from '@mui/material'

export default function ColorPicker({ color = '#fff', onChangeColor }) {
  const [anchorEl, setAnchorEl] = useState(null)

  const onClose = () => {
    setAnchorEl(null)
  }

  const onOpen = (e) => {
    setAnchorEl(e.currentTarget)
  }

  return (
    <>
      <Lens
        sx={{
          color: color,
          filter: `drop-shadow(0 0 4px #adadad)`,
          p: '20px',
        }}
        onClick={onOpen}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ p: 2 }}>
          <HexColorPicker color={color} onChange={_.debounce(onChangeColor, 150)} />
        </Box>
      </Popover>
    </>
  )
}
