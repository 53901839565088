import { useEffect, useState } from 'react'
import { getOne, put } from '../../helper/baseFetch'
import { useSelector, useStore } from 'react-redux'
import { useParams } from 'react-router-dom'
import MUISelectField from '../../Component/common/field/MUISelectField'
import ThreeItemsGridContainer from '../../Component/common/field/ThreeItemsGridContainer'
import { Box, Button, Card, CardContent } from '@mui/material'
import { ArrowBack, Clear, Save } from '@mui/icons-material'
import SharedDialog from '../../Component/common/dialog/SharedDialog'
import { getString } from '../../helper/util'
import { BOOLEAN_OPTIONS } from '../../Constants/option'

export default function ReportConfig() {
  const store = useStore()
  const [data, setData] = useState({})
  const [isInitiated, setIsInitiated] = useState(false)
  const { id: restUrlId } = useParams()
  const [openDialog, setOpenDialog] = useState(false)
  const lang = useSelector((state) => state.lang.ui.lang)

  const getReportConfig = () => {
    getOne(
      `shop/report/report_config/${restUrlId}`,
      undefined,
      (payload) => {
        setData(payload)
        setIsInitiated(true)
      },
      undefined,
      store
    )
  }

  const onChangeInput = (key, value) => {
    setData({ ...data, [key]: value })
  }

  const onClickSave = () => {
    put(
      `shop/report/report_config/${restUrlId}`,
      data,
      () => {
        setOpenDialog(true)
      },
      undefined,
      store
    )
  }

  const onClickClear = () => {
    getReportConfig()
  }

  useEffect(() => {
    getReportConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    isInitiated && (
      <>
        <Card>
          <CardContent>
            <ThreeItemsGridContainer>
              <MUISelectField
                onChange={(option) => onChangeInput('queue', option.value)}
                label="Queue"
                name="queue"
                value={data.queue}
                options={BOOLEAN_OPTIONS}
              />
              <MUISelectField
                onChange={(option) => onChangeInput('reservation', option.value)}
                label="Reservation"
                name="reservation"
                value={data.reservation}
                options={BOOLEAN_OPTIONS}
              />
              <MUISelectField
                onChange={(option) => onChangeInput('appointment', option.value)}
                label="Appointment"
                name="appointment"
                value={data.appointment}
                options={BOOLEAN_OPTIONS}
              />
              <MUISelectField
                onChange={(option) => onChangeInput('product', option.value)}
                label="Product"
                name="product"
                value={data.product}
                options={BOOLEAN_OPTIONS}
              />
              <MUISelectField
                onChange={(option) => onChangeInput('takeaway', option.value)}
                label="Takeaway"
                name="takeaway"
                value={data.takeaway}
                options={BOOLEAN_OPTIONS}
              />
              <Box sx={{ gridColumn: '1 / -1', display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <Button variant="contained" color="primary" startIcon={<Save />} onClick={onClickSave}>
                  {getString(lang, '', 'save')}
                </Button>
                <Button variant="contained" color="primary" startIcon={<Clear />} onClick={onClickClear}>
                  {getString(lang, '', 'clear')}
                </Button>
              </Box>
            </ThreeItemsGridContainer>
          </CardContent>
        </Card>
        {openDialog && (
          <SharedDialog
            content="Successfully saved!"
            actions={
              <Button variant="contained" onClick={() => setOpenDialog(false)} startIcon={<ArrowBack />}>
                {getString(lang, 'reportConfig', 'backToEdit')}
              </Button>
            }
            open={openDialog}
          />
        )}
      </>
    )
  )
}
