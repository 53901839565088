import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import SplitPane from 'react-split-pane';
import { Allotment } from 'allotment'
import 'allotment/dist/style.css'
import { makeStyles } from '@mui/styles';

import { constants, RESERVATION_TABLE_TYPE_LIST_INPUT } from '../../Constants/constants';
import { post } from "../../helper/baseFetch";
import { setReservationTemplate, setReservationTemplateTimeSectionValue, setReservationTemplateTableTypeValue } from "../../actions/reservationTemplateAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { ReservationTemplateTimeSection } from "./ReservationTimeSection";
import { ReservationTemplateTimeSectionList } from "./ReservationTimeSectionList";
import { ReservationTemplateTableType } from "./ReservationTemplateTableType";
import ReservationTemplateValidation from "../../validations/reservationTemplateValidation";
import { GlobalDialog } from "../../Component/GlobalDialog";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const ReservationTemplateCreate = ({ toolBar, mapName, mapCode, tab }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const savedReservationTemplate = useSelector(state => state.reservationTemplate.ui.reservationTemplate);
  const savedRestaurant = useSelector(state => state.restaurant.ui.restaurant);
  const [clear, setClear] = useState(false);
  const [reservationTemplateInputList, setReservationTemplateInputList] = useState({
    description: "",
  });
  const [customIdNumber, setCustomIdNumber] = useState(0);
  const [selectedType, setSelectedType] = useState("");
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(ReservationTemplateValidation.getVersion());

  // eslint-disable-next-line no-unused-vars
  const [splitPane, setSplitPane] = useState(500);
  const resource = "ReservationTemplate";
  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.RESERVATION_TEMPLATE_TAB.INFO
    }
  ]

  // const handlePanelSize = (newSize) => {
  //   setSplitPane(newSize);
  // };


  const content = [
    {
      tab: <>
        <div>
          <TempTextInput
            inputSetting={{
              className: "edit-create-input",
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: "description" },
              value: reservationTemplateInputList.description,
              name: "description",
              helperTextIcon: true,
              helperText: ReservationTemplateValidation.getErrorMessages("description"),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
            }}
            handleChange={handleDescription}
          />
        </div>
        <Allotment defaultSizes={[100, 200]}>
        {/* <SplitPane
          split="vertical"
          size={splitPane}
          style={{ position: 'initial' }}
          onDragFinished={(newSize) => {
            handlePanelSize(newSize);
          }}
        > */}
          <ReservationTemplateTimeSectionList resource={resource} setSelectedType={setSelectedType} setCustomIdNumber={setCustomIdNumber} customIdNumber={customIdNumber} />
          <div className="queue-template-flex-right">
            {selectedType === "timeSection" ?
              <ReservationTemplateTimeSection setCustomIdNumber={setCustomIdNumber} customIdNumber={customIdNumber} />
              : selectedType === "tableType" ?
                <ReservationTemplateTableType setCustomIdNumber={setCustomIdNumber} customIdNumber={customIdNumber} />
                : <></>}
          </div>
        {/* </SplitPane> */}
        </Allotment>
      </>,
      btn: true,
    },
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />,
      disabled: !savedReservationTemplate || savedReservationTemplate.length === 0,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  function handleDescription(name, e) {
    let tempReservationTemplateInputList = _.cloneDeep(reservationTemplateInputList);
    tempReservationTemplateInputList.description = e;
    setReservationTemplateInputList(tempReservationTemplateInputList);
  }

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + "/" + savedRestaurant.restUrlId + "/" + constants.RESTAURANT_TAB.RESERVATION + "/" + constants.RESTAURANT_RESERVATION_SUB_TAB.RESERVATION_TEMPLATE);
  };

  function onClickSave() {
    ReservationTemplateValidation.validateTemplate(savedReservationTemplate, reservationTemplateInputList.description);
    if (ReservationTemplateValidation.isValid()) {
      let tempsavedReservationTemplate = _.cloneDeep(savedReservationTemplate);
      let newTicketTypeList = []
      tempsavedReservationTemplate.forEach((tempsavedReservationTemplateValue) => {
        let tempticketTypeList = _.cloneDeep(tempsavedReservationTemplateValue.tableTypeList);
        delete tempsavedReservationTemplateValue.tableTypeList;
        tempticketTypeList.forEach((item) => newTicketTypeList.push(Object.assign(item, tempsavedReservationTemplateValue)))
      })
      post(constants.PATH.SHOP.RESERVATION.RESERVATION_TEMPLATE, {
        restUrlId: savedRestaurant.restUrlId,
        description: reservationTemplateInputList.description,
        timeSectionList: newTicketTypeList,
      }, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: "createSuccess" },
          maxWidth: "md",
          dialogActions: [
            { label: { key: "backCreate" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
          ]
        })
        setClear(false)
      }, undefined, store);
      ReservationTemplateValidation.resetErrorMessages();
    } else {
      setErrorVersion(ReservationTemplateValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    dispatch(setReservationTemplate(constants.RESERVATION_TEMPLATE_INPUT_LIST));
    dispatch(setReservationTemplateTimeSectionValue(constants.RESERVATION_TEMPLATE_INPUT));
    dispatch(setReservationTemplateTableTypeValue(RESERVATION_TABLE_TYPE_LIST_INPUT));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(setReservationTemplate(constants.RESERVATION_TEMPLATE_INPUT_LIST));
    dispatch(setReservationTemplateTimeSectionValue(constants.RESERVATION_TEMPLATE_INPUT));
    dispatch(setReservationTemplateTableTypeValue(RESERVATION_TABLE_TYPE_LIST_INPUT));
    setReservationTemplateInputList({ description: "" });
    setSelectedType("");
    ReservationTemplateValidation.resetErrorMessages();
    setClear(false)
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <EditCreateDisplay
        code={""}
        resource={resource}
        tabs={toolBar !== false ? tabs : ""}
        content={content}
        bottomBtns={bottomBtns}
        mapName={{ resource: "restaurant", key: "restUrlId" }}
        mapCode={savedRestaurant.restUrlId}
        display="create"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};