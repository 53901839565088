import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';

import { constants, PICTURE_SIZE } from '../../Constants/constants';
import { post } from "../../helper/baseFetch";
import { setFolder, setFolderValue } from "../../actions/folderAction";
import { setFolderMap } from "../../actions/folderMapAction";
import { setBannerList } from "../../actions/bannerAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { TempCustomCheckBox } from "../../Component/Custom/TempCustomCheckBox";
import { FolderMapCreate } from "./FolderMapCreate";
import { FolderBannerCreate } from "./FolderBannerCreate";
import { UploadImage } from "../../Component/UploadImage/UploadImage";
import { getString } from "../../helper/util";
import { GlobalDialog } from "../../Component/GlobalDialog";
import FolderValidation from "../../validations/folderValidation";
import { folderValidationConstants } from '../../Constants/validationConstants';
import BannerValidation from "../../validations/bannerValidation";
import FolderMapValidation from "../../validations/folderMapValidation";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

const LOCAL_CONSTANT = {
  EN_IMAGE: 'enImage',
  TC_IMAGE: 'tcImage',
  SC_IMAGE: 'scImage',
}

export const FolderCreate = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const helperTextStylesClasses = helperTextStyles();
  const [clear, setClear] = useState(false);
  const [ready, setReady] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "Folder";
  const savedFolder = useSelector(state => state.folder.ui.folder);
  const savedFolderMap = useSelector(state => state.folderMap.ui.folderMap);
  const savedBannerList = useSelector(state => state.banner.ui.bannerList);
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);
  const [folderInputList, setFolderInputList] = useState(_.cloneDeep(constants.FOLDER_INPUT));
  const [errorVersion, setErrorVersion] = useState(FolderValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.FOLDER_TAB.INFO
    }
  ]
  const tempFolderCreateList = [
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "name" },
      className: "first-sub-title",
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "folderCode" },
      value: savedFolder.folderCode,
      name: "folderCode",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: FolderValidation.getErrorMessages(folderValidationConstants.KEY_FOLDER_CODE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "type" },
      value: savedFolder.type,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.FOLDER.TYPE,
      name: "type",
      isEndOfLine: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "showHidden" },
      value: savedFolder.showHidden,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.FOLDER.SHOW_HIDDEN,
      name: "showHidden",
      isEndOfLine: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enName" },
      value: savedFolder.enName,
      name: "enName",
      custom: true,
      customName: { key: "nameCustomize" },
      customValue: savedFolder.scNameDisable,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: FolderValidation.getErrorMessages(folderValidationConstants.KEY_EN_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcName" },
      value: savedFolder.tcName,
      name: "tcName",
      translateName: savedFolder.scNameDisable ? ["scName"] : null,
      inputList: folderInputList,
      setInputList: setFolderInputList,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: FolderValidation.getErrorMessages(folderValidationConstants.KEY_TC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scName" },
      value: savedFolder.scName,
      name: "scName",
      inputList: folderInputList,
      setInputList: setFolderInputList,
      isEndOfLine: true,
      disabled: savedFolder.scNameDisable,
      required: true,
      helperTextIcon: true,
      helperText: FolderValidation.getErrorMessages(folderValidationConstants.KEY_SC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enDescriptionHtml" },
      value: savedFolder.enDescriptionHtml,
      name: "enDescriptionHtml",
      custom: true,
      customName: { resource: resource, key: "descriptionCustomize" },
      customValue: savedFolder.scDescriptionDisable,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcDescriptionHtml" },
      value: savedFolder.tcDescriptionHtml,
      name: "tcDescriptionHtml",
      translateName: savedFolder.scDescriptionDisable ? ["scDescriptionHtml"] : null,
      inputList: folderInputList,
      setInputList: setFolderInputList,
      multiline: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scDescriptionHtml" },
      value: savedFolder.scDescriptionHtml,
      name: "scDescriptionHtml",
      inputList: folderInputList,
      setInputList: setFolderInputList,
      multiline: true,
      isEndOfLine: true,
      disabled: savedFolder.scDescriptionDisable,
    },
    {
      picture: savedFolder.enImage,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enImage" },
      name: "enImage",
      type: constants.TYPE.IMAGE,
      uploadImagePath: constants.PATH.FOLDER.UPLOAD_FOLDER_IMAGE,
      saveImage: handleSaveImage,
      isEndOfLine: false,
      required: true,
    },
    {
      picture: savedFolder.tcImage,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcImage" },
      name: "tcImage",
      type: constants.TYPE.IMAGE,
      uploadImagePath: constants.PATH.FOLDER.UPLOAD_FOLDER_IMAGE,
      saveImage: handleSaveImage,
      isEndOfLine: false,
      required: true,
    },
    {
      picture: savedFolder.scImage,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scImage" },
      name: "scImage",
      type: constants.TYPE.IMAGE,
      uploadImagePath: constants.PATH.FOLDER.UPLOAD_FOLDER_IMAGE,
      saveImage: handleSaveImage,
      isEndOfLine: true,
      required: true,
    },
  ]
  const content = [
    {
      tab: <>
        {tempFolderCreateList.map((inputSetting, index) => {
          let content;
          switch (inputSetting.type) {
            case constants.TYPE.IMAGE:
              content =
                <div className="image">
                  <UploadImage
                    picture={inputSetting.picture}
                    title={inputSetting.label}
                    name={inputSetting.name}
                    uploadImagePath={inputSetting.uploadImagePath}
                    saveImage={inputSetting.saveImage}
                    required={inputSetting.required} 
                    showApplyToAll={true}
                    size= {PICTURE_SIZE.MID}/>
                </div>
              break;
            case constants.TYPE.SELECT:
              content =
                <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
              break;
            case constants.TYPE.TITLE:
              content = <div className={`${inputSetting.className + " sub-title"}`}>{getString(lang, inputSetting.value.resource, inputSetting.value.key, theme)}</div>
              break;
            default:
              content =
                <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
          }
          return <div className="edit-display-wrapper" key={index}>
            {inputSetting.custom && <TempCustomCheckBox className="customize-btn" value={inputSetting.customValue} customName={inputSetting.customName} onSelectCheckbox={onSelectCheckbox} />}
            {inputSetting.name === "enImage" &&
              (
                <>
                  <>
                    {FolderValidation.getErrorMessages(folderValidationConstants.KEY_TC_IMAGE) &&
                      FolderValidation.getErrorMessages(folderValidationConstants.KEY_TC_IMAGE).length > 0 &&
                      <div className="error-message">
                        <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                        {FolderValidation.getErrorMessages(folderValidationConstants.KEY_TC_IMAGE)}
                      </div>
                    }
                  </>
                  <>
                    {FolderValidation.getErrorMessages(folderValidationConstants.KEY_SC_IMAGE) &&
                      FolderValidation.getErrorMessages(folderValidationConstants.KEY_SC_IMAGE).length > 0 &&
                      <div className="error-message">
                        <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                        {FolderValidation.getErrorMessages(folderValidationConstants.KEY_SC_IMAGE)}
                      </div>
                    }
                  </>
                  <>
                    {FolderValidation.getErrorMessages(folderValidationConstants.KEY_EN_IMAGE) &&
                      FolderValidation.getErrorMessages(folderValidationConstants.KEY_EN_IMAGE).length > 0 &&
                      <div className="error-message">
                        <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                        {FolderValidation.getErrorMessages(folderValidationConstants.KEY_EN_IMAGE)}
                      </div>
                    }
                  </>
                </>
              )
            }
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        })}
        <FolderMapCreate toolBar={false} createClear={clear} />
        <FolderBannerCreate toolBar={false} createClear={clear} />
      </>,
      btn: true
    }
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  function handleSaveImage(name, image, index, applyToAll) {

    const tempFolder = _.cloneDeep(savedFolder);

    if(applyToAll){
      tempFolder[LOCAL_CONSTANT.EN_IMAGE] = image;
      tempFolder[LOCAL_CONSTANT.SC_IMAGE] = image;
      tempFolder[LOCAL_CONSTANT.TC_IMAGE] = image;
      dispatch(setFolder(tempFolder));
      return;
    }

    dispatch(setFolderValue(name, savedFolder, "", image));
  }

  function onSelectCheckbox(value, customName) {
    switch (customName.key) {
      case "nameCustomize":
        dispatch(setFolderValue("scNameDisable", savedFolder, "", value));
        break;
      case "descriptionCustomize":
        dispatch(setFolderValue("scDescriptionDisable", savedFolder, "", value));
        break;
      default:
    }
  }

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.FOLDER.FOLDER);
  };

  function onChangeInput(name, e) {
    if (savedFolder.scNameDisable === true && name === "tcName") {
      dispatch(setFolderValue("CHANGE_TC_NAME_AND_SC_NAME", savedFolder, "", e));
    } else if (savedFolder.scDescriptionDisable === true && name === "tcDescriptionHtml") {
      dispatch(setFolderValue("CHANGE_TC_NAME_AND_SC_DESCRIPTION", savedFolder, "", e));
    } else {
      dispatch(setFolderValue(name, savedFolder, "", e));
    }
  }

  function onClickSave() {
    let tempSavedFolder = _.cloneDeep(savedFolder);
    let tempSavedFolderMap = _.cloneDeep(savedFolderMap);
    let tempsavedBanner = _.cloneDeep(savedBannerList);
    tempsavedBanner.map((item) => {
      item.refCode = tempSavedFolder.folderCode;
      return item;
    });
    FolderValidation.validateCreateFolder(savedFolder, tempsavedBanner);
    if (tempsavedBanner.length > 0) {
      BannerValidation.validateCreateBanner(tempsavedBanner, true);
    }
    if (tempSavedFolderMap.length > 0) {
      FolderMapValidation.validateCreateFolderMap(tempSavedFolderMap);
    }
    if (FolderValidation.isValid() && (tempsavedBanner.length > 0 ? BannerValidation.isValid() : true) && (tempSavedFolderMap.length > 0 ? FolderMapValidation.isValid() : true)) {
      tempSavedFolderMap = tempSavedFolderMap.map((item) => {
        return {
          type: item.type,
          sequence: item.sequence,
          refCode: item.refCode,
        };
      });
      tempSavedFolder.folderMapList = tempSavedFolderMap;
      tempSavedFolder.bannerList = tempsavedBanner;
      post(constants.PATH.FOLDER.FOLDER, tempSavedFolder, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: "createSuccess" },
          dialogActions: [
            { label: { key: "backCreate" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      FolderValidation.resetErrorMessages();
      BannerValidation.resetErrorMessages();
      FolderMapValidation.resetErrorMessages();
    } else {
      if (errorVersion !== FolderValidation.getVersion()) {
        setErrorVersion(FolderValidation.getVersion());
      } else if (errorVersion !== BannerValidation.getVersion()) {
        setErrorVersion(BannerValidation.getVersion());
      } else if (errorVersion !== FolderMapValidation.getVersion()) {
        setErrorVersion(FolderMapValidation.getVersion());
      }
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    dispatch(setFolder(_.cloneDeep(constants.FOLDER_INPUT)));
    dispatch(setFolderMap([]));
    dispatch(setFolderMap([]));
    dispatch(setBannerList([]));
    setClear(false);
    setReady(true);
    FolderValidation.resetErrorMessages();
    BannerValidation.resetErrorMessages();
    FolderMapValidation.resetErrorMessages();
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <EditCreateDisplay
        code=""
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="create"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};