import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { constants } from '../../Constants/constants'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { GlobalDialog } from '../../Component/GlobalDialog'
import { post } from '../../helper/baseFetch'
import { getContent } from '../../Component/EditCreateDisplay/EditUtil'
import StaffValidation from '../../validations/staffValidation'
import { staffValidationConstants } from '../../Constants/validationConstants'
import { setStaff, setStaffValue } from '../../actions/staffAction'
import { helperTextStyles } from '../../helper/util'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { ProfileCodeSection } from './StaffProfileCodeSection'
import useRestaurant from '../../hooks/Restaurant/useRestaurant'
import { TempCustomCheckBox } from '../../Component/Custom/TempCustomCheckBox'
import { Box } from '@mui/material'

const resource = 'Staff'

export const StaffCreate = () => {
  const dispatch = useDispatch()
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const helperTextStylesClasses = helperTextStyles()
  const [clear, setClear] = useState(true)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })

  const savedStaff = useSelector((state) => state.staff.ui.staff)
  const [, setErrorVersion] = useState(StaffValidation.getVersion())
  const restUrlId = params.restUrlId
  const { getRestaurantIdAndName } = useRestaurant(restUrlId)
  const mapCode = getRestaurantIdAndName()

  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: constants.MERCHANT_USER_EDIT_TAB.INFO,
    },
  ]
  const tempMerchantUserEditList = [
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'username' },
      value: savedStaff.username,
      name: 'username',
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: StaffValidation.getErrorMessages(`${staffValidationConstants.KEY_NAME}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'password' },
      value: savedStaff.password,
      name: 'password',
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: StaffValidation.getErrorMessages(`${staffValidationConstants.KEY_PASSWORD}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input`,
      label: { resource: resource, key: 'staffRole' },
      value: savedStaff.staffRole,
      name: 'staffRole',
      type: constants.TYPE.SELECT,
      disableClearable: true,
      selectValue: constants.CHOICES.STAFF.USER_ROLE,
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: StaffValidation.getErrorMessages(`${staffValidationConstants.KEY_STAFF_ROLE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      type: constants.TYPE.FUNCTION,
      value: <ProfileCodeSection onChangeInput={onChangeInput} restUrlId={params.restUrlId} displayData={savedStaff} showQueueTransferProfileCode />,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'refCode' },
      value: savedStaff.refCode,
      name: 'refCode',
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: StaffValidation.getErrorMessages(`${staffValidationConstants.KEY_REF_CODE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'syncNameFieldsWithUsername' },
      value: savedStaff.syncNameFieldsWithUsername,
      name: 'syncNameFieldsWithUsername',
      custom: true,
      customName: { resource: resource, key: 'syncNameFieldsWithUsername' },
      customValue: savedStaff.syncNameFieldsWithUsername,
      isEndOfLine: true,
      type: constants.TYPE.CHECKBOX,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'surname' },
      value: savedStaff.surname,
      name: 'surname',
      isEndOfLine: false,
      helperTextIcon: false,
      helperText: StaffValidation.getErrorMessages(`${staffValidationConstants.KEY_SURNAME}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'firstName' },
      value: savedStaff.firstName,
      name: 'firstName',
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: StaffValidation.getErrorMessages(`${staffValidationConstants.KEY_FIRST_NAME}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'displayName' },
      value: savedStaff.displayName,
      name: 'displayName',
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: StaffValidation.getErrorMessages(`${staffValidationConstants.KEY_DISPLAY_NAME}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'email' },
      value: savedStaff.email,
      name: 'email',
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: StaffValidation.getErrorMessages(`${staffValidationConstants.KEY_EMAIL}`),
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'countryCode' },
      value: savedStaff.countryCode,
      name: 'countryCode',
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: StaffValidation.getErrorMessages(`${staffValidationConstants.KEY_COUNTRY_CODE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'mobile' },
      value: savedStaff.mobile,
      name: 'mobile',
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: StaffValidation.getErrorMessages(`${staffValidationConstants.KEY_MOBILE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]
  const content = [
    {
      tab: tempMerchantUserEditList.map((inputSetting, index) => {
        let content = getContent(inputSetting, index, onChangeInput)

        if (inputSetting.type === constants.TYPE.CHECKBOX) {
          content = (
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <TempCustomCheckBox value={inputSetting.customValue} customName={inputSetting.customName} onSelectCheckbox={onSelectCheckbox} />
            </Box>
          )
        }

        return (
          <div className="edit-display-wrapper" key={index}>
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        )
      }),
      btn: true,
    },
  ]

  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push(
      `/${constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT}/${restUrlId}/${constants.RESTAURANT_TAB.MERCHANT}/${constants.RESTAURANT_MERCHANT_SUB_TAB.STAFF}`
    )
  }

  function onSelectCheckbox(value, customName) {
    switch (customName.key) {
      case 'syncNameFieldsWithUsername':
        dispatch(setStaffValue(customName.key, savedStaff, '', value))
        break
      default:
    }
  }

  function onChangeInput(name, e) {
    dispatch(setStaffValue(name, savedStaff, '', e))
  }

  function onClickSave() {
    StaffValidation.validateSavedData(savedStaff, true)
    if (StaffValidation.isValid()) {
      post(
        constants.PATH.MERCHANT.STAFF,
        savedStaff,
        () => {
          setDialog({
            open: true,
            onClose: onClickBackToCreate,
            dialogContentText: { key: 'createSuccess' },
            dialogActions: [
              { label: { key: 'backCreate' }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
      StaffValidation.resetErrorMessages()
    } else {
      setErrorVersion(StaffValidation.getVersion())
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      dispatch(setStaff({ ...constants.STAFF_INPUT, password: null, restUrlId: restUrlId }))
      setClear(false)
      StaffValidation.resetErrorMessages()
    }
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   console.log('savedStaff', savedStaff)
  // }, [savedStaff])

  return (
    savedStaff && (
      <>
        <EditCreateDisplay
          mapName={{ resource: 'restaurant', key: 'restUrlId' }}
          mapCode={mapCode}
          resource={resource}
          tabs={tabs}
          content={content}
          onChangeInput={onChangeInput}
          bottomBtns={bottomBtns}
          display="create"
        />
        {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
      </>
    )
  )
}
