import _ from 'lodash'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import store from '../../store'
import { constants as co } from '../../Constants/constants'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { GlobalDialog } from '../../Component/GlobalDialog'
import { getOne, put } from '../../helper/baseFetch'
import { ArrowBack, LowPriority } from '@mui/icons-material'
import { getDefaultBottomBtnList } from '../../Component/EditCreateDisplay/EditUtil'
import CounterProfileValidation from '../../validations/counterProfileValidation'
import { counterProfileValidationConstants } from '../../Constants/validationConstants'
import { TempCustomCheckBox } from '../../Component/Custom/TempCustomCheckBox'
import { setCounterProfile, setCounterProfileDefault, setCounterProfileValue } from '../../actions/counterProfileAction'

import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'
import { sify } from 'chinese-conv'
import CounterProfileDragAndDrop from './CounterProfileDragAndDrop'
import { ErrorMsg } from '../../Component/ErrorMsg/ErrorMsg'
import useRestaurant from '../../hooks/Restaurant/useRestaurant'

export default function CounterProfileEdit() {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const { restUrlId } = params
  const [clear, setClear] = useState(false)
  const [dialogInfo, setDialogInfo] = useState({
    open: false,
  })
  const savedData = useSelector((state) => state.counterProfile.ui.counterProfile)
  const [idListFromAPI, setIdListFromAPI] = useState([])
  const [idList, setIdList] = useState([])
  const [objList, setObjList] = useState([])
  const [ready, setReady] = useState(false)

  const { getRestaurantIdAndName } = useRestaurant(restUrlId)

  const resource = 'CounterProfile'

  let validation = CounterProfileValidation,
    validationConstants = counterProfileValidationConstants,
    setDispatch = (counter) => dispatch(setCounterProfile(counter)),
    setDispatchDefault = () => dispatch(setCounterProfileDefault()),
    setDispatchValue = (fieldName, originalObj, key, value) => dispatch(setCounterProfileValue(fieldName, originalObj, key, value)),
    apiPath = `${co.PATH.MERCHANT.COUNTER_PROFILE}/${params.id}`,
    listPath = `/${co.PATH.SHOP.SYSTEM_GROUP_RESTAURANT}/${restUrlId}/${co.RESTAURANT_TAB.MERCHANT}/${co.RESTAURANT_MERCHANT_SUB_TAB.COUNTER_PROFILE}`

  // eslint-disable-next-line no-unused-vars
  const [errorVersion, setErrorVersion] = useState(validation.getVersion())

  const onCloseDialog = () => {
    setDialogInfo({
      open: false,
    })
  }

  const onChangeInput = (name, e) => {
    setDispatchValue(name, savedData, '', e)
  }

  const onChangeScNameDisable = (value) => {
    setDispatchValue('scNameDisable', savedData, '', value)
  }

  const onRedirectToCreateEdit = () => {
    onCloseDialog()
    onClickClear()
  }

  const onRedirectToList = () => {
    setDialogInfo({
      open: false,
    })
    history.push(listPath)
  }

  const changeObjList = (objList, idListFromAPI) => {
    // console.log('objList', objList)
    let newIdListFromAPI = _.cloneDeep(idListFromAPI)
    let newObjList = objList.map((objItem, objI) => {
      objItem.counterId = objItem.id
      delete objItem.id
      const matchItem = idListFromAPI.find((apiItem) => apiItem.counterId === objItem.counterId)
      if (matchItem) {
        objItem.id = matchItem.id
      }

      return {
        id: objItem.id,
        counterId: objItem.counterId,
        sequence: objList.length - objI,
        status: 'A',
      }
    })

    let deletedList = newIdListFromAPI
      .filter((apiItem) => !newObjList.some((objItem) => objItem.counterId === apiItem.counterId))
      .map((item) => {
        item.status = 'D'
        return item
      })

    // console.log('newObjList', newObjList)
    // console.log('newIdListFromAPI', newIdListFromAPI)
    // console.log('deletedList', deletedList)
    return [...newObjList, ...deletedList]
  }

  const onClickSave = () => {
    validation.validateSavedData(savedData)
    if (validation.isValid()) {
      put(
        apiPath,
        {
          id: params.id,
          enName: savedData.enName,
          tcName: savedData.tcName,
          scName: savedData.scName,
          status: savedData.status,
          counterList: changeObjList(objList, idListFromAPI),
          // refCounterList: savedData.refCounterList,
        },
        () => {
          setDialogInfo({
            open: true,
            onClose: onRedirectToCreateEdit,
            dialogContentText: { key: 'editSuccess' },
            maxWidth: 'md',
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onRedirectToCreateEdit, icon: <ArrowBack /> },
              {
                label: { key: 'backList' },
                onClick: onRedirectToList,
                icon: <LowPriority />,
              },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
    } else {
      setErrorVersion(validation.getVersion())
    }
  }

  const onClickClear = () => {
    setClear(true)
  }

  const reorder = (list, startI, endI) => {
    const result = Array.from(list)
    const [removed] = result.splice(startI, 1)
    result.splice(endI, 0, removed)
    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination) return
    if (result.destination.index === result.source.index) return
    const newObjList = reorder(objList, result.source.index, result.destination.index)
    // console.log('newObjList', newObjList)
    setObjList(newObjList)
  }

  const onDeleteDragItem = (name, id) => {
    const newObjList = objList.filter((item) => item.id !== id)
    const newIdList = idList.filter((item) => item !== id)
    setObjList(newObjList)
    setIdList(newIdList)
  }

  const content = [
    {
      tab: (
        <>
          <div className="edit-display-wrapper">
            {/* <div>
              <GetRefCounterList
                createEditType={'edit'}
                idList={idList}
                setIdList={setIdList}
                objList={objList}
                setObjList={setObjList}
                refCounterList={savedData.refCounterList}
              />
            </div> */}
            <TempCustomCheckBox
              className="customize-btn"
              value={savedData.scNameDisable}
              customName={{ resource: resource, key: 'nameCustomize' }}
              onSelectCheckbox={onChangeScNameDisable}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'enName' },
                value: savedData.enName,
                name: 'enName',
                helperText: validation.getErrMsg(validationConstants.KEY_EN_NAME),
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'tcName' },
                value: savedData.tcName,
                name: 'tcName',
                helperText: validation.getErrMsg(validationConstants.KEY_TC_NAME),
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'scName' },
                value: savedData.scName,
                name: 'scName',
                helperText: validation.getErrMsg(validationConstants.KEY_SC_NAME),
                disabled: savedData.scNameDisable,
              }}
              handleChange={onChangeInput}
            />
            <TempAutoSelectInput
              inputSetting={{
                label: { resource: resource, key: 'status' },
                value: savedData.status,
                selectValue: co.CHOICES.COUNTER_PROFILE.STATUS,
                name: 'status',
                disableClearable: true,
              }}
              handleChange={onChangeInput}
            />
            <CounterProfileDragAndDrop
              createEditType={'edit'}
              idList={idList}
              setIdList={setIdList}
              objList={objList}
              setObjList={setObjList}
              onDragEnd={onDragEnd}
              onDeleteDragItem={onDeleteDragItem}
            />
            {validation.getErrMsg(validationConstants.KEY_COUNTER_LIST).length > 0 && validation.getErrMsg(validationConstants.KEY_COUNTER_LIST) && (
              <ErrorMsg errorMsg={validation.getErrMsg(validationConstants.KEY_COUNTER_LIST)} showErrorIcon={true} />
            )}
          </div>
        </>
      ),
      btn: true,
    },
  ]

  useEffect(() => {
    getOne(
      apiPath,
      {
        restUrlId: restUrlId,
      },
      (payload) => {
        // console.log('payload', payload)
        setIdListFromAPI(payload.counterList)
        setDispatch({
          id: payload.id,
          profileCode: payload.profileCode,
          enName: payload.enName,
          tcName: payload.tcName,
          scName: payload.scName,
          status: payload.status,
          // counterList: payload.counterList.sort((a, b) => a.sequence - b.sequence),
          counterList: payload.counterList,
          refCounterList: payload.refCounterList,
          scNameDisable: sify(payload.tcName) === payload.scName,
        })
        let newIdList = payload.counterList.map((item) => item.counterId)
        // let newObjList = payload.refCounterList.filter((payloadItem) => newIdList.some((idListItem) => idListItem === payloadItem.id))
        // let newObjList = payload.counterList.map((counterItem) => {
        //   payload.refCounterList.map((refCounterItem) => {
        //     let matchList = []
        //     if (counterItem.counterId === refCounterItem.id) {
        //       matchList.push(refCounterItem)
        //     }
        //     return matchList
        //   })
        // })
        let newObjList = payload.counterList.map((counterItem) => {
          let refCounterItem = payload.refCounterList.find((refCounterItem) => refCounterItem.id === counterItem.counterId)
          return refCounterItem
            ? { ...refCounterItem, warning: false }
            : {
                id: counterItem.counterId,
                restUrlId: '',
                name: '',
                displayTcName: '',
                displayEnName: '',
                displayScName: '',
                status: '',
                warning: true,
              }
        })
        setIdList(newIdList)
        setObjList(newObjList)
        setClear(false)
        setReady(true)
      },
      undefined,
      store
    )
    validation.resetErrMsg()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear === true])

  useEffect(() => {
    setDispatchDefault()
    validation.resetErrMsg()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const mapCode = getRestaurantIdAndName(restUrlId)

  return (
    <>
      {ready && (
        <EditCreateDisplay
          resource={resource}
          content={content}
          mapName={{ resource: 'restaurant', key: 'restUrlId' }}
          mapCode={mapCode}
          display="edit"
          code={savedData.profileCode}
          bottomBtns={getDefaultBottomBtnList(onClickSave, onClickClear)}
        />
      )}
      {dialogInfo.open && <GlobalDialog dialogInfo={dialogInfo} />}
    </>
  )
}
