import _ from 'lodash'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import store from '../../store'
import { constants } from '../../Constants/constants'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { GlobalDialog } from '../../Component/GlobalDialog'
import { getList, getOne, put } from '../../helper/baseFetch'
import { ArrowBack, LowPriority } from '@mui/icons-material'
import { getDefaultBottomBtnList } from '../../Component/EditCreateDisplay/EditUtil'
import CounterValidation from '../../validations/counterValidation'
import { counterValidationConstants } from '../../Constants/validationConstants'
import { setCounter, setCounterDefault, setCounterValue } from '../../actions/counterAction'
import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'
import GetSoundTemplateCode from './GetSoundTemplateCode'
import useRestaurant from '../../hooks/Restaurant/useRestaurant'

export default function CounterEdit() {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const [clear, setClear] = useState(true)
  const [dialogInfo, setDialogInfo] = useState({
    open: false,
  })

  const [ready, setReady] = useState(false)
  const [soundTemplateCodeWithoutDynamicChar, setSoundTemplateCodeWithoutDynamicChar] = useState([])

  const restUrlId = params.restUrlId

  const resource = 'Counter'

  const savedData = useSelector((state) => state.counter.ui.counter)

  const { getRestaurantIdAndName } = useRestaurant(restUrlId)

  let validation = CounterValidation,
    validationConstants = counterValidationConstants,
    setDispatchDefault = () => dispatch(setCounterDefault()),
    setDispatch = (counter) => dispatch(setCounter(counter)),
    setDispatchValue = (fieldName, originalObj, key, value) => dispatch(setCounterValue(fieldName, originalObj, key, value)),
    apiPath = `${constants.PATH.MERCHANT.COUNTER}/${params.id}`,
    listPath = `/${constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT}/${restUrlId}/${constants.RESTAURANT_TAB.MERCHANT}/${constants.RESTAURANT_MERCHANT_SUB_TAB.COUNTER}`

  // eslint-disable-next-line no-unused-vars
  const [errorVersion, setErrorVersion] = useState(validation.getVersion())

  const onCloseDialog = () => {
    setDialogInfo({
      open: false,
    })
  }

  const onChangeInput = (name, e) => {
    setDispatchValue(name, savedData, '', e)
  }

  const onRedirectToCreateEdit = () => {
    onCloseDialog()
    onClickClear()
  }

  const onRedirectToList = () => {
    setDialogInfo({
      open: false,
    })
    history.push(listPath)
  }

  const onClickSave = () => {
    validation.validateSavedData({ name: savedData.name, templateCode: savedData.templateCode })

    if (validation.isValid()) {
      put(
        apiPath,
        {
          name: savedData.name === null ? '' : savedData.name,
          templateCode: savedData.templateCode,
          status: savedData.status,
        },
        () => {
          setDialogInfo({
            open: true,
            onClose: onRedirectToCreateEdit,
            dialogContentText: { key: 'editSuccess' },
            maxWidth: 'md',
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onRedirectToCreateEdit, icon: <ArrowBack /> },
              {
                label: { key: 'backList' },
                onClick: onRedirectToList,
                icon: <LowPriority />,
              },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
    } else {
      setErrorVersion(validation.getVersion())
    }
  }

  const onClickClear = () => {
    setClear(true)
  }

  const content = [
    {
      tab: (
        <>
          <div className="edit-display-wrapper">
            <div>
              <GetSoundTemplateCode />
            </div>
            {!soundTemplateCodeWithoutDynamicChar.includes(savedData.templateCode) && (
              <TempTextInput
                inputSetting={{
                  label: { resource: resource, key: 'name' },
                  value: savedData.name,
                  name: 'name',
                  helperText: validation.getErrMsg(validationConstants.KEY_NAME),
                }}
                handleChange={onChangeInput}
              />
            )}
            <TempAutoSelectInput
              inputSetting={{
                label: { resource: resource, key: 'status' },
                value: savedData.status,
                selectValue: constants.CHOICES.QUEUE_PROFILE.STATUS,
                name: 'status',
                disableClearable: true,
                helperText: validation.getErrMsg(validationConstants.KEY_STATUS),
              }}
              handleChange={onChangeInput}
            />
          </div>
        </>
      ),
      btn: true,
    },
  ]

  useEffect(() => {
    setDispatchDefault()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setDispatchDefault()
    setClear(false)
    validation.resetErrMsg()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear === true])

  // useEffect(() => {
  //   console.log('savedData', savedData)
  // }, [savedData])

  useEffect(() => {
    if (soundTemplateCodeWithoutDynamicChar.includes(savedData.templateCode)) {
      let newSavedData = _.cloneDeep(savedData)
      newSavedData.name = ''
      setDispatch(newSavedData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedData.templateCode])

  useEffect(() => {
    getList(
      constants.PATH.MERCHANT.SOUND_TEMPLATE,
      {
        sort: { field: 'updateTimestamp', order: 'ASC' },
        filter: { type: 'COUNTER' },
        pagination: { page: 1, perPage: 10000 },
      },
      (payload) => {
        const l = payload.filter((item) => !item.enDescription.includes('{0}')).map((item) => item.code)
        // console.log('l', l)
        setSoundTemplateCodeWithoutDynamicChar(l)
      },
      undefined,
      store
    )

    getOne(
      apiPath,
      undefined,
      (payload) => {
        setDispatch({
          name: payload.name,
          templateCode: payload.templateCode,
          status: payload.status,
        })
        setClear(false)
        setReady(true)
      },
      undefined,
      store
    )
    validation.resetErrMsg()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear === true])

  useEffect(() => {
    setDispatchDefault()
    validation.resetErrMsg()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const mapCode = getRestaurantIdAndName(restUrlId)

  return (
    <>
      {ready && (
        <EditCreateDisplay
          resource={resource}
          content={content}
          mapName={{ resource: 'restaurant', key: 'restUrlId' }}
          mapCode={mapCode}
          display="edit"
          bottomBtns={getDefaultBottomBtnList(onClickSave, onClickClear)}
        />
      )}
      {dialogInfo.open && <GlobalDialog dialogInfo={dialogInfo} />}
    </>
  )
}
