import { Box, Button, Drawer, IconButton, Stack } from '@mui/material'
import SharedDialog from '../../Component/common/dialog/SharedDialog'
import MUITextField from '../../Component/common/field/MUITextField'
import ThreeItemsGridContainer from '../../Component/common/field/ThreeItemsGridContainer'
import { useEffect, useRef, useState } from 'react'
import MultiLangTextField from '../../Component/common/field/MultiLangTextField'
import { getOne, newPut, post, put } from '../../helper/baseFetch'
import { API_PATH } from '../../Constants/path'
import { useStore } from 'react-redux'
import { Clear, Save } from '@mui/icons-material'
import MenuButton from '../../Component/common/button/MenuButton'
import { LANG } from '../../Constants/lang'
import RegionList from './RegionList'
import Iconify from '../../Component/common/iconify/Iconify'
import { ICONIFY } from '../../Constants/iconify'
import MUISelectField from '../../Component/common/field/MUISelectField'
import { STATUS_OPTIONS } from '../../Constants/option'

const CITY_DEFAULT = { regionCode: '', name: LANG, sequence: '', status: 'A' }

export default function City({ id, isCreateMode, showCity, setShowCity, getCityList, setShowSaveDialog }) {
  const store = useStore()
  const [showStatusDialog, setShowStatusDialog] = useState(false)
  const [showRegionDrawer, setShowRegionDrawer] = useState(false)
  const [city, setCity] = useState(CITY_DEFAULT)
  const statusRef = useRef()

  const [error, setError] = useState({})

  const onChangeInput = (key, value) => {
    setCity({ ...city, [key]: value })
  }

  const onChangeMultiLangInput = (objKey, key, value) => {
    setCity({ ...city, [objKey]: { ...city[objKey], [key]: value } })
  }

  const onClickSave = () => {
    if (isCreateMode) {
      post(
        API_PATH.city,
        city,
        () => {
          setShowSaveDialog(true)
          setShowCity(false)
          getCityList()
        },
        undefined,
        store
      )
    } else {
      put(
        `${API_PATH.city}/${id}`,
        city,
        () => {
          setShowSaveDialog(true)
          setShowCity(false)
          getCityList()
        },
        undefined,
        store
      )
    }
  }

  const onClickClear = () => {
    if (isCreateMode) {
      setCity(CITY_DEFAULT)
    } else {
      getCity()
    }
  }

  const onClickClose = () => {
    setShowCity(false)
    setCity(CITY_DEFAULT)
  }

  const getCity = () => {
    getOne(
      `${API_PATH.city}/${id}`,
      undefined,
      (payload) => {
        setCity(payload)
      },
      undefined,
      store
    )
  }

  const validateCity = () => {
    let newError = {}

    if (!city.regionCode) {
      newError.regionCode = 'Required'
    }

    if (!city.sequence) {
      newError.sequence = 'Required'
    }

    setError(newError)
  }

  useEffect(() => {
    validateCity()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city])

  useEffect(() => {
    if (!isCreateMode) {
      getCity()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClickRegionRow = (row) => {
    setCity({ ...city, regionCode: row.regionCode })
    setShowRegionDrawer(false)
  }

  return (
    <>
      <SharedDialog
        open={showCity}
        onClose={onClickClose}
        title={`City ${isCreateMode ? 'Create' : 'Edit'}`}
        content={
          <ThreeItemsGridContainer>
            <MUITextField
              label="Region Code"
              value={city.regionCode}
              name="regionCode"
              onChange={(e) => onChangeInput('sequence', e.target.value)}
              endAdornment={
                <IconButton onClick={() => setShowRegionDrawer(true)}>
                  <Iconify iconify={ICONIFY.edit} />
                </IconButton>
              }
              error={error.regionCode}
            />
            {!isCreateMode && <MUITextField label="City Code" value={city.cityCode} name="cityCode" disabled />}
            <MultiLangTextField langObj={city.name} onChangeMultiLangInput={onChangeMultiLangInput} objKey="name" label="Name" />
            <MUITextField
              label="Sequence"
              value={city.sequence}
              onChange={(e) => onChangeInput('sequence', e.target.value)}
              name="sequence"
              error={error.sequence}
            />
            {isCreateMode && (
              <MUISelectField
                onChange={(option) => onChangeInput('status', option.value)}
                label="Status"
                name="status"
                value={city.status}
                options={STATUS_OPTIONS}
              />
            )}
          </ThreeItemsGridContainer>
        }
        actions={
          <Stack justifyContent={isCreateMode ? 'flex-end' : 'space-between'} direction="row" sx={{ width: '100%' }}>
            {!isCreateMode && (
              <MenuButton
                button={{ variant: 'contained', label: `Switch Status: ${city.status}` }}
                options={[
                  { label: 'Enable (A)', value: 'A' },
                  { label: 'Disable (D)', value: 'D' },
                  { label: 'Pending (P)', value: 'P' },
                ]}
                onChange={(value) => {
                  statusRef.current = value
                  setShowStatusDialog(true)
                }}
                value={city.status}
              />
            )}
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
              <Button variant="contained" onClick={onClickSave} startIcon={<Save />} disabled={Object.keys(error).length > 0}>
                Save
              </Button>
              <Button variant="contained" onClick={onClickClear} startIcon={<Clear />}>
                Clear
              </Button>
            </Box>
          </Stack>
        }
        maxWidth="lg"
      />
      <SharedDialog
        open={showStatusDialog}
        onClose={() => setShowStatusDialog(false)}
        title=""
        content={<Box sx={{ display: 'flex', alignItems: 'center' }}>Are you sure to change the status?</Box>}
        maxWidth="md"
        actions={
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Button
              variant="contained"
              onClick={() => {
                newPut({
                  path: `${API_PATH.cityStatus}/${id}`,
                  body: {
                    status: statusRef.current,
                  },
                  successCallback: () => {
                    setShowStatusDialog(false)
                    setCity({ ...city, status: statusRef.current })
                    getCityList()
                  },
                  store: store,
                })
              }}
            >
              OK
            </Button>
            <Button variant="contained" onClick={() => setShowStatusDialog(false)}>
              Discard
            </Button>
          </Box>
        }
      />
      <Drawer open={showRegionDrawer} onClose={() => setShowRegionDrawer(false)} anchor="right">
        <Box sx={{ p: 2 }}>
          <RegionList onClickRegionRow={onClickRegionRow} />
        </Box>
      </Drawer>
    </>
  )
}
