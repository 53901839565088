import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { getList, post } from "../../helper/baseFetch";
import { setPayment, setPaymentValue } from "../../actions/paymentAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { UploadImage } from "../../Component/UploadImage/UploadImage";
import { GlobalDialog } from "../../Component/GlobalDialog";
import PaymentValidation from "../../validations/paymentValidation";
import { paymentValidationConstants } from '../../Constants/validationConstants';

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const PaymentCreate = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const [clear, setClear] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [paymentRecordOptions, setPaymentRecordOptions] = useState([]);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const restaurantRecord = useSelector(state => state.restaurantApprove.ui.restaurantApprove);
  const resource = "payment";
  const savedPayment = useSelector(state => state.payment.ui.payment);
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(PaymentValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.PAYMENT_EDIT_TAB.INFO
    }
  ]
  const tempPaymentEditList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "servicePlan" },
      value: savedPayment.planCode,
      type: constants.TYPE.SELECT,
      selectValue: paymentRecordOptions,
      name: "planCode",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: PaymentValidation.getErrorMessages(paymentValidationConstants.KEY_PLAN_CODE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "paymentMethod" },
      value: savedPayment.paymentCode,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.PAYMENT_CREATE.PAYMENT,
      name: "paymentCode",
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: PaymentValidation.getErrorMessages(paymentValidationConstants.KEY_PAYMENT_CODE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    savedPayment.paymentCode === "CHEQUE" &&
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      type: constants.TYPE.TEXT,
      label: { resource: resource, key: "chequeNumber" },
      value: savedPayment.chequeNumber,
      name: "chequeNumber",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: PaymentValidation.getErrorMessages(paymentValidationConstants.KEY_CHEQUE_NUMBER),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    savedPayment.paymentCode === "CHEQUE" &&
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "issueChequeBank" },
      value: savedPayment.bankCode,
      type: constants.TYPE.SELECT,
      selectValue: bankList,
      name: "bankCode",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: PaymentValidation.getErrorMessages(paymentValidationConstants.KEY_BANK_CODE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    savedPayment.paymentCode === "CHEQUE" &&
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      type: constants.TYPE.TEXT,
      label: { resource: resource, key: "bankAccountNumber" },
      value: savedPayment.bankAccountNumber,
      name: "bankAccountNumber",
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: PaymentValidation.getErrorMessages(paymentValidationConstants.KEY_BANK_ACCOUNT_NUMBER),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    savedPayment.paymentCode === "CHEQUE" &&
    {
      picture: savedPayment.chequeImage,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "chequeImage" },
      name: "chequeImage",
      type: constants.TYPE.IMAGE,
      uploadImagePath: constants.PATH.APPROVAL.UPLOAD_PAYMENT_IMAGE,
      saveImage: onChangeInput,
      isEndOfLine: false,
      required: true,
    },
  ]
  const content = [
    {
      tab: tempPaymentEditList.map((inputSetting, index) => {
        let content;
        switch (inputSetting.type) {
          case constants.TYPE.SELECT:
            content =
              <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break;
          case constants.TYPE.TEXT:
            content =
              <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break;
          case constants.TYPE.IMAGE:
            content =
              <div className="image">
                <UploadImage
                  picture={inputSetting.picture}
                  title={inputSetting.label}
                  name={inputSetting.name}
                  uploadImagePath={inputSetting.uploadImagePath}
                  saveImage={inputSetting.saveImage}
                  required={inputSetting.required} />

              </div>
            break;
          default:
            content = <></>
        }
        return <div className="edit-display-wrapper" key={index}>
          {
            inputSetting.name === "chequeImage" &&
            PaymentValidation.getErrorMessages(paymentValidationConstants.KEY_CHEQUE_IMAGE) &&
            PaymentValidation.getErrorMessages(paymentValidationConstants.KEY_CHEQUE_IMAGE).length > 0 &&
            <div className="error-message">
              <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
              {PaymentValidation.getErrorMessages(paymentValidationConstants.KEY_CHEQUE_IMAGE)}
            </div>
          }
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: true
    }
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    onClickClear();
  };

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push('/' + constants.PATH.APPROVAL.RESTAURANT + '/' + restaurantRecord.restUrlId + "/" + constants.RESTAURANT_APPROVE_EDIT_TAB.PAYMENT_LIST);
  };

  function onChangeInput(name, e) {
    dispatch(setPaymentValue(name, savedPayment, "", e));
  }

  function onClickSave() {
    PaymentValidation.validateCreatePayment(savedPayment);
    if (PaymentValidation.isValid()) {
      post(constants.PATH.APPROVAL.CREATE_PAYMENT_RECORD, savedPayment, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: "createSuccess" },
          dialogActions: [
            { label: { key: "backCreate" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      PaymentValidation.resetErrorMessages();
    } else {
      setErrorVersion(PaymentValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    getList(constants.PATH.MERCHANT.PAYMENT_RECORD_OPTIONS, undefined, (payload) => {
      let newPaymentRecordOptions = [];
      for (let i = 0; i < payload.servicePlanList.length; i++) {
        newPaymentRecordOptions.push({ value: payload.servicePlanList[i].planCode, name: payload.servicePlanList[i].name })
      }
      setPaymentRecordOptions(newPaymentRecordOptions)

      let newBankList = [];
      for (let i = 0; i < payload.bankList.length; i++) {
        newBankList.push({ value: payload.bankList[i].bankCode, name: "(" + payload.bankList[i].bankCode + ") " + payload.bankList[i].bankName })
      }
      setBankList(newBankList);
    }, undefined, store);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let newCreate = _.cloneDeep(constants.PAYMENT_CREATE_INPUT);
    newCreate.restUrlId = restaurantRecord.restUrlId;
    dispatch(setPayment(newCreate));
    setClear(false);
    PaymentValidation.resetErrorMessages();
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <EditCreateDisplay
        code=""
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        mapName={{ resource: "restaurant", key: "restUrlId" }}
        mapCode={restaurantRecord.restUrlId || ''}
        display="create"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};