import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector, useStore } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Drawer } from '@mui/material'
import { makeStyles } from '@mui/styles'
import TextField from '@mui/material/TextField'

import { constants, RESERVATION_TEMPLATE_MAP_TYPE } from '../../Constants/constants'
import { getOne, put } from '../../helper/baseFetch'
import { setReservationTemplateMap } from '../../actions/reservationTemplateMapAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'
import { TempBtn } from '../../Component/Input/TempBtn'
import { ReservationTemplateList } from '../ReservationTemplate/ReservationTemplateList'
import ReservationTemplateMapValidation from '../../validations/reservationTemplateMapValidation'
import { reservationTemplateMapValidationConstants } from '../../Constants/validationConstants'
import { GlobalDialog } from '../../Component/GlobalDialog'
import { getString } from '../../helper/util'

const helperTextStyles = makeStyles((theme) => ({
  root: {
    color: 'red !important',
  },
}))

export const ReservationTemplateMapEdit = () => {
  const dispatch = useDispatch()
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(true)
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)
  const savedReservationTemplateMap = useSelector((state) => state.reservationTemplateMap.ui.reservationTemplateMap)
  const [showTemplateCodePanel, setShowTemplateCodePanel] = useState(false)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const helperTextStylesClasses = helperTextStyles()
  const [, setErrorVersion] = useState(ReservationTemplateMapValidation.getVersion())

  const resource = 'ReservationTemplateMap'
  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: constants.RESERVATION_TEMPLATE_MAP_TAB.INFO,
    },
  ]

  const content = [
    {
      tab: (
        <>
          <TempTextInput
            inputSetting={{
              className: 'edit-create-input',
              size: constants.SIZE.SMALL,
              label: { resource: resource, key: 'description' },
              value: savedReservationTemplateMap.description,
              name: 'description',
              multiline: true,
            }}
            handleChange={onChangeInput}
          />
          <div>
            <TempTextInput
              inputSetting={{
                className: 'edit-create-input',
                variant: constants.STYLE.VARIANT.STANDARD,
                size: constants.SIZE.SMALL,
                label: { resource: resource, key: 'type' },
                value: savedReservationTemplateMap.type,
                name: 'type',
                disabled: true,
                helperTextIcon: true,
                helperText: ReservationTemplateMapValidation.getErrorMessages(
                  reservationTemplateMapValidationConstants.KEY_TYPE,
                  savedReservationTemplateMap.id
                ).map((error) => {
                  return error
                }),
                FormHelperTextProps: { className: helperTextStylesClasses.root },
              }}
              handleChange={onChangeInput}
            />
            {savedReservationTemplateMap.type === RESERVATION_TEMPLATE_MAP_TYPE.DAY_OF_WEEK && (
              <TempAutoSelectInput
                inputSetting={{
                  className: 'edit-create-input',
                  size: constants.SIZE.SMALL,
                  label: { resource: resource, key: 'dayOfWeek' },
                  value: savedReservationTemplateMap.dayOfWeek,
                  type: constants.TYPE.SELECT,
                  selectValue: constants.CHOICES.RESERVATION_TEMPLATE_MAP.DAY_OF_WEEK,
                  disableClearable: true,
                  name: 'dayOfWeek',
                  helperTextIcon: true,
                  helperText: ReservationTemplateMapValidation.getErrorMessages(
                    reservationTemplateMapValidationConstants.KEY_DAY_OF_WEEK,
                    savedReservationTemplateMap.id
                  ).map((error) => {
                    return error
                  }),
                  FormHelperTextProps: { className: helperTextStylesClasses.root },
                }}
                handleChange={onChangeInput}
              />
            )}
            {savedReservationTemplateMap.type === RESERVATION_TEMPLATE_MAP_TYPE.DATE_RANGE && (
              <>
                <TempTextInput
                  inputSetting={{
                    className: 'edit-create-input',
                    label: { resource: resource, key: 'startDate' },
                    value: savedReservationTemplateMap.startDate ? moment(savedReservationTemplateMap.startDate).format('YYYY-MM-DD') : '',
                    name: 'startDate',
                    type: constants.TYPE.DATE,
                    size: constants.SIZE.SMALL,
                    helperTextIcon: true,
                    helperText: ReservationTemplateMapValidation.getErrorMessages(
                      reservationTemplateMapValidationConstants.KEY_START_DATE,
                      savedReservationTemplateMap.id
                    ).map((error) => {
                      return error
                    }),
                    FormHelperTextProps: { className: helperTextStylesClasses.root },
                  }}
                  handleChange={onChangeInput}
                />
                <TempTextInput
                  inputSetting={{
                    className: 'edit-create-input',
                    label: { resource: resource, key: 'endDate' },
                    value: savedReservationTemplateMap.endDate ? moment(savedReservationTemplateMap.endDate).format('YYYY-MM-DD') : '',
                    name: 'endDate',
                    type: constants.TYPE.DATE,
                    size: constants.SIZE.SMALL,
                    helperTextIcon: true,
                    helperText: ReservationTemplateMapValidation.getErrorMessages(
                      reservationTemplateMapValidationConstants.KEY_END_DATE,
                      savedReservationTemplateMap.id
                    ).map((error) => {
                      return error
                    }),
                    FormHelperTextProps: { className: helperTextStylesClasses.root },
                  }}
                  handleChange={onChangeInput}
                />
              </>
            )}
          </div>
          <div>
            <TempTextInput
              inputSetting={{
                className: 'edit-create-input',
                size: constants.SIZE.SMALL,
                label: { resource: resource, key: 'templateCode' },
                value: savedReservationTemplateMap.templateCode,
                onClickEndAdornmentIconButton: () => {
                  handleTemplateCodeClick()
                },
                name: 'templateCode',
                disabled: true,
                multiline: true,
                helperTextIcon: true,
                helperText: ReservationTemplateMapValidation.getErrorMessages(
                  reservationTemplateMapValidationConstants.KEY_TEMPLATE_CODE,
                  savedReservationTemplateMap.id
                ).map((error) => {
                  return error
                }),
                FormHelperTextProps: { className: helperTextStylesClasses.root },
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                className: 'edit-create-input',
                variant: constants.STYLE.VARIANT.STANDARD,
                size: constants.SIZE.SMALL,
                label: { resource: resource, key: 'templateDescription' },
                value: savedReservationTemplateMap.templateDescription,
                name: 'templateDescription',
                disabled: true,
                multiline: true,
              }}
              handleChange={onChangeInput}
            />
          </div>
        </>
      ),
      btn: true,
    },
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: () => handleEffectiveDateDialog(),
      className: 'margin-left-16',
      icon: <SaveIcon />,
      disabled: !params.id,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  const onClickListRow = (data) => {
    onChangeInput('templateCode', data)
    setShowTemplateCodePanel(false)
  }

  const handleTemplateCodeClick = () => {
    setShowTemplateCodePanel(true)
  }

  const handleTemplateCodeCloseClick = () => {
    setShowTemplateCodePanel(false)
  }

  function onChangeInput(name, e) {
    const tempReservationTemplateMap = _.cloneDeep(savedReservationTemplateMap)
    switch (name) {
      case 'startDate':
      case 'endDate':
        tempReservationTemplateMap[`${name}`] = e ? Number(moment(e).format('x')) : null
        break
      case 'type':
        tempReservationTemplateMap[`${name}`] = e
        tempReservationTemplateMap['dayOfWeek'] = null
        tempReservationTemplateMap['startDate'] = null
        tempReservationTemplateMap['endDate'] = null
        break
      case 'templateCode':
        tempReservationTemplateMap[`${name}`] = e.templateCode
        tempReservationTemplateMap['templateDescription'] = e.description
        break
      default:
        tempReservationTemplateMap[`${name}`] = e
    }
    dispatch(setReservationTemplateMap(tempReservationTemplateMap))
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push(
      '/' +
        constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT +
        '/' +
        params.id +
        '/' +
        constants.RESTAURANT_TAB.RESERVATION +
        '/' +
        constants.RESTAURANT_RESERVATION_SUB_TAB.RESERVATION_TEMPLATE_MAP
    )
  }

  function handleEffectiveDateDialog() {
    let date = new Date()
    let effectiveDate = moment(date).format('x')
    setDialog({
      open: true,
      onClose: onClickBackToEdit,
      dialogTitle: { key: 'dialogTitle' },
      dialogContent: (
        <div className="draft-name-wrapper">
          <TextField
            className="draft-name-input"
            label={getString(lang, resource, 'effectiveDate', theme)}
            variant="outlined"
            defaultValue={moment(date).format('YYYY-MM-DD')}
            type={constants.TYPE.DATE}
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: moment(date).format('YYYY-MM-DD') }}
            onChange={(draftName) => (effectiveDate = moment(draftName.target.value).format('x'))}
            required
          />
        </div>
      ),
      dialogActions: [
        { label: { key: 'backEdit' }, onClick: () => onClickBackToEdit(), icon: <ArrowBackIcon /> },
        { label: { key: 'save' }, onClick: () => onClickSave(effectiveDate), icon: <SaveIcon /> },
      ],
    })
  }

  function onClickSave(effectiveDate) {
    let tempsavedReservationTemplateMap = _.cloneDeep(savedReservationTemplateMap)
    tempsavedReservationTemplateMap.restUrlId = params.id
    tempsavedReservationTemplateMap.effectiveDate = effectiveDate
    ReservationTemplateMapValidation.validateTemplate([tempsavedReservationTemplateMap])
    if (ReservationTemplateMapValidation.isValid()) {
      put(
        constants.PATH.SHOP.RESERVATION.RESERVATION_TEMPLATE_MAP + '/' + params.id,
        tempsavedReservationTemplateMap,
        (payload) => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
      ReservationTemplateMapValidation.resetErrorMessages()
    } else {
      setErrorVersion(ReservationTemplateMapValidation.getVersion())
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(
        constants.PATH.SHOP.RESERVATION.RESERVATION_TEMPLATE_MAP + '/' + params.id,
        undefined,
        (payload) => {
          dispatch(setReservationTemplateMap(payload))
          setReady(true)
          setClear(false)
        },
        undefined,
        store
      )
    }
    ReservationTemplateMapValidation.resetErrorMessages()
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready && (
      <>
        <EditCreateDisplay
          code={savedReservationTemplateMap && savedReservationTemplateMap.id ? savedReservationTemplateMap.id : ''}
          resource={resource}
          tabs={tabs}
          content={content}
          bottomBtns={bottomBtns}
          mapName={{ resource: 'restaurant', key: 'restUrlId' }}
          mapCode={params.id}
          display="edit"
        />
        <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={showTemplateCodePanel} onClose={handleTemplateCodeCloseClick}>
          <ReservationTemplateList
            editBtn={false}
            createBtn={false}
            btnList={false}
            onClickListRow={(item) => onClickListRow(item)}
            dialogFooterBtn={
              <>
                <TempBtn
                  btnSetting={{
                    variant: constants.STYLE.VARIANT.CONTAINED,
                    color: constants.STYLE.PRIMARY,
                    onClick: handleTemplateCodeCloseClick,
                    icon: <ClearIcon />,
                    label: { key: 'close' },
                  }}
                />
              </>
            }
          />
        </Drawer>
        {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
      </>
    )
  )
}
