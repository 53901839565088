import { useEffect, useState } from 'react'
import ThreeItemsGridContainer from '../../Component/common/field/ThreeItemsGridContainer'
import { getOne, post, put } from '../../helper/baseFetch'
import { useSelector, useStore } from 'react-redux'
import CardTitle from '../../Component/common/card/CardTitle'
import MUITextField from '../../Component/common/field/MUITextField'
import MUISelectField from '../../Component/common/field/MUISelectField'
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Drawer,
    IconButton,
    Stack,
} from '@mui/material'
import { TempBtn } from '../../Component/Input/TempBtn'
import { ArrowBack, Clear, Edit, GetApp, List, Save } from '@mui/icons-material'
import { ProductCategoryList } from '../../Component/ProductCreate/Category/ProductCategoryList'
import { ProductList } from '../../Component/ProductCreate/Product/ProductList'
import { FolderList } from '../Folder/FolderList'
import { RestaurantList } from '../Restaurant/RestaurantList'
import { BrandList } from '../Brand/BrandList'
import SharedDialog from '../../Component/common/dialog/SharedDialog'
import { useHistory, useParams } from 'react-router-dom'
import {
    ACTION_OPTION,
    ACTION_TYPE,
    ACTION_TYPE_OPTION,
    AGENT_TYPE_OPTION,
    CODE_TYPE_OPTION,
    DATA_DEFAULT,
    DOWNLOAD_HTML_URL,
    FOLDER_TYPE_OPTION,
    FROM_OPTION,
    GIFT_SERVICE_TYPE_OPTION,
    OTHER_DATA_DEFAULT,
    Q_HK_WALLET_URL,
    QUERY_OPTION,
    RACK_PRODUCT_TYPE_OPTION,
    REST_URL_TYPE,
    REST_URL_TYPE_OPTION,
    SERVICE_TYPE_OPTION,
    WEB_VIEW_TYPE_OPTION,
} from '../../Constants/urlMap'
import QRCode from 'qrcode.react'
import { getRestaurantUrlType, getRestUrlIdFromUrl } from '../../helper/urlUtil'
import { API_PATH, ROUTER_PATH } from '../../Constants/path'

export default function UrlMap({ isCreateMode = true }) {
    const store = useStore()
    const [otherDataFieldList, setOtherDataFieldList] = useState([])

    // Static Data
    const [data, setData] = useState(DATA_DEFAULT)
    const [url, setUrl] = useState('')

    // Other Data - will be cleared when action type changed
    const [otherData, setOtherData] = useState(OTHER_DATA_DEFAULT)
    const [openDrawer, setOpenDrawer] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [showQrCode, setShowQrCode] = useState(false)

    const history = useHistory()
    const lang = useSelector((state) => state.lang.ui.lang)

    const [error, setError] = useState({})

    const params = useParams()

    const resetOtherDataFieldList = (actionType) => {
        switch (actionType) {
            case ACTION_TYPE.WEB_VIEW:
                setOtherDataFieldList(['action', 'title', 'type'])
                break
            case ACTION_TYPE.LINK:
                setOtherDataFieldList(['action'])
                break
            case ACTION_TYPE.VIDEO:
                setOtherDataFieldList(['action'])
                break
            case ACTION_TYPE.PRODUCT:
                setOtherDataFieldList(['productCode'])
                break
            case ACTION_TYPE.PRODUCT_CATEGORY:
                setOtherDataFieldList(['categoryCode'])
                break
            case ACTION_TYPE.PRODUCT_BRAND:
                setOtherDataFieldList(['brandCode'])
                break
            case ACTION_TYPE.E_COUPON:
                setOtherDataFieldList(['eCouponCode'])
                break
            case ACTION_TYPE.E_COUPON_CATEGORY:
                setOtherDataFieldList(['categoryCode'])
                break
            case ACTION_TYPE.E_COUPON_BRAND:
                setOtherDataFieldList(['brandCode'])
                break
            case ACTION_TYPE.FOLDER:
                setOtherDataFieldList(['folderCode', 'folderType'])
                break
            case ACTION_TYPE.REST:
                setOtherDataFieldList(['action', 'queryRestUrlId'])
                break
            case ACTION_TYPE.SEARCH:
                setOtherDataFieldList(['name', 'query'])
                break
            case ACTION_TYPE.BOOKMARK:
                setOtherDataFieldList([])
                break
            case ACTION_TYPE.SEARCH_NEAR_BY:
                setOtherDataFieldList([])
                break
            case ACTION_TYPE.MY_TICKET:
                setOtherDataFieldList(['refId', 'serviceType'])
                break
            case ACTION_TYPE.TRANSFORM_TICKET_FROM_PAPER:
                setOtherDataFieldList(['ticketId'])
                break
            case ACTION_TYPE.TRANSFORM_ECOUPON_FROM_PAPER:
                setOtherDataFieldList(['infoId'])
                break
            case ACTION_TYPE.EXPRESS_TICKET_VOUCHER:
                setOtherDataFieldList(['groupCode'])
                break
            case ACTION_TYPE.SHARE:
                setOtherDataFieldList(['serviceType', 'refId'])
                break
            case ACTION_TYPE.GIFT:
                setOtherDataFieldList(
                    otherData.serviceType === 'RACK_PRODUCT'
                        ? ['serviceType', 'refId', 'code', 'type']
                        : ['serviceType', 'refId', 'code']
                )
                break
            case ACTION_TYPE.LANDING:
                setOtherDataFieldList([])
                break
            case ACTION_TYPE.DOWNLOAD:
                setOtherDataFieldList([])
                break
            case ACTION_TYPE.SCAN_QRCODE:
                setOtherDataFieldList([])
                break
            case ACTION_TYPE.CUSTOM_URL:
                setOtherDataFieldList(['customUrl'])
                break
            default:
                setOtherDataFieldList([])
                break
        }
    }

    const resetUrl = (data, otherData) => {
        const fromQuery = `${otherData.from ? `&from=${otherData.from}` : ''}`

        switch (data.actionType) {
            case ACTION_TYPE.WEB_VIEW:
                setUrl(
                    `${DOWNLOAD_HTML_URL}?web=action=${otherData.action}${
                        otherData.type ? `&type=${otherData.type}` : ''
                    }${otherData.title ? `&title=${otherData.title}` : ''}${fromQuery}`
                )
                break
            case ACTION_TYPE.LINK:
                setUrl(`${DOWNLOAD_HTML_URL}?link=action=${otherData.action}${fromQuery}`)
                break
            case ACTION_TYPE.VIDEO:
                setUrl(`${DOWNLOAD_HTML_URL}?web=action=${otherData.action}${fromQuery}`)
                break
            case ACTION_TYPE.PRODUCT:
                let codeTypeQuery = ''

                if (otherData.codeType === 'CATEGORY') {
                    codeTypeQuery = `&categoryCode=${otherData.categoryCode}`
                } else if (otherData.codeType === 'BRAND') {
                    codeTypeQuery = `&brandCode=${otherData.brandCode}`
                }

                setUrl(
                    `${DOWNLOAD_HTML_URL}?product=productCode=${otherData.productCode}${codeTypeQuery}${fromQuery}`
                )
                break
            case ACTION_TYPE.PRODUCT_CATEGORY:
                setUrl(
                    `${DOWNLOAD_HTML_URL}?product=categoryCode=${otherData.categoryCode}${fromQuery}`
                )
                break
            case ACTION_TYPE.PRODUCT_BRAND:
                setUrl(`${DOWNLOAD_HTML_URL}?product=brandCode=${otherData.brandCode}${fromQuery}`)
                break
            case ACTION_TYPE.E_COUPON:
                setUrl(
                    `${DOWNLOAD_HTML_URL}?e-coupon=ecouponCode=${otherData.eCouponCode}${
                        otherData.codeType
                            ? otherData.codeType === 'CATEGORY'
                                ? `&categoryCode=${otherData.categoryCode}`
                                : `&brandCode=${otherData.brandCode}`
                            : ''
                    }${fromQuery}`
                )
                break
            case ACTION_TYPE.E_COUPON_CATEGORY:
                setUrl(
                    `${DOWNLOAD_HTML_URL}?e-coupon=categoryCode=${otherData.categoryCode}${fromQuery}`
                )
                break
            case ACTION_TYPE.E_COUPON_BRAND:
                setUrl(`${DOWNLOAD_HTML_URL}?e-coupon=brandCode=${otherData.brandCode}${fromQuery}`)
                break
            case ACTION_TYPE.FOLDER:
                setUrl(
                    `${DOWNLOAD_HTML_URL}?redirect_folder=folderCode=${otherData.folderCode}${
                        otherData.folderType ? `&folderType=${otherData.folderType}` : ''
                    }${fromQuery}`
                )
                break
            case ACTION_TYPE.REST:
                if (data.restUrlType === REST_URL_TYPE.universal) {
                    setUrl(
                        `${process.env.REACT_APP_GULU_WEB_BASE}app/shop${
                            otherData.queryRestUrlId ? `/${otherData.queryRestUrlId}` : ''
                        }`
                    )
                }

                if (data.restUrlType === REST_URL_TYPE.downloadHtml) {
                    setUrl(
                        `${DOWNLOAD_HTML_URL}?redirect_rest=${
                            otherData.queryRestUrlId ? `restUrlId=${otherData.queryRestUrlId}` : ''
                        }${otherData.action ? `&action=${otherData.action}` : ''}${fromQuery}`
                    )
                }

                if (data.restUrlType === REST_URL_TYPE.other) {
                    setUrl(otherData.customUrl)
                }

                break
            case ACTION_TYPE.SEARCH:
                setUrl(
                    `${DOWNLOAD_HTML_URL}?search=${
                        otherData.name ? `&name=${otherData.name}` : ''
                    }${otherData.query ? `&query=${otherData.query}` : ''}${fromQuery}`
                )
                break
            case ACTION_TYPE.BOOKMARK:
                setUrl(`${DOWNLOAD_HTML_URL}?bookmark=${fromQuery}`)
                break
            case ACTION_TYPE.SEARCH_NEAR_BY:
                setUrl(`${DOWNLOAD_HTML_URL}?searchnearby=${fromQuery}`)
                break
            case ACTION_TYPE.MY_TICKET:
                setUrl(
                    `${DOWNLOAD_HTML_URL}?myticket=refId=${otherData.refId}&serviceType=${otherData.serviceType}${fromQuery}`
                )
                break
            case ACTION_TYPE.TRANSFORM_TICKET_FROM_PAPER:
                setUrl(
                    `${DOWNLOAD_HTML_URL}?transformticketfrompaper=ticketId=${otherData.ticketId}${fromQuery}`
                )
                break
            case ACTION_TYPE.TRANSFORM_ECOUPON_FROM_PAPER:
                setUrl(
                    `${DOWNLOAD_HTML_URL}?transformecouponfrompaper=infoId=${otherData.infoId}${fromQuery}`
                )
                break
            case ACTION_TYPE.EXPRESS_TICKET_VOUCHER:
                setUrl(
                    `${DOWNLOAD_HTML_URL}?expressticketvoucher=groupCode=${otherData.groupCode}${fromQuery}`
                )
                break
            case ACTION_TYPE.SHARE:
                setUrl(
                    `${DOWNLOAD_HTML_URL}?share=refId=${otherData.refId}&serviceType=${otherData.serviceType}${fromQuery}`
                )
                break
            case ACTION_TYPE.GIFT:
                setUrl(
                    `${DOWNLOAD_HTML_URL}?gift=refId=${otherData.refId}&code=${
                        otherData.code
                    }&serviceType=${otherData.serviceType}${
                        otherData.serviceType === 'RACK_PRODUCT' ? `&type=${otherData.type}` : ''
                    }${fromQuery}`
                )
                break
            case ACTION_TYPE.LANDING:
                setUrl(`${DOWNLOAD_HTML_URL}?landing=${fromQuery}`)
                break
            case ACTION_TYPE.DOWNLOAD:
                setUrl(`${DOWNLOAD_HTML_URL}?download=${fromQuery}`)
                break
            case ACTION_TYPE.SCAN_QRCODE:
                setUrl(`${DOWNLOAD_HTML_URL}?scanqrcode=${fromQuery}`)
                break
            case ACTION_TYPE.CUSTOM_URL:
                setUrl(`${otherData.customUrl}${fromQuery}`)
                break
            default:
                break
        }
    }

    const onChangeInput = (key, value, dataType = 'default') => {
        // console.log('key', key)
        // console.log('value', value)
        // console.log('dataType', dataType)

        if (showQrCode) {
            setShowQrCode(false)
        }

        switch (dataType) {
            case 'default':
                const newData = { ...data, [key]: value }

                if (key === 'actionType') {
                    setData(newData)
                    setOtherData(OTHER_DATA_DEFAULT)
                    resetUrl(newData, OTHER_DATA_DEFAULT)
                    return
                }

                if (key === 'restUrlType') {
                    setData(newData)
                    setOtherData(OTHER_DATA_DEFAULT)
                    resetUrl(newData, OTHER_DATA_DEFAULT)
                    return
                }

                if (key === 'agentType' && value === 'weChat') {
                    setData({
                        ...data,
                        [key]: value,
                    })
                    setOtherData(OTHER_DATA_DEFAULT)
                    setUrl(`${Q_HK_WALLET_URL}${otherData.weChat ? `?${otherData.weChat}` : ''}`)
                    return
                }

                setData(newData)
                resetUrl(newData, otherData)
                break
            default:
                const newOtherData = { ...otherData, [key]: value }
                setOtherData(newOtherData)
                resetUrl(data, newOtherData)
                break
        }
    }

    const onClickListRow = (key, value) => {
        setOtherData({ ...otherData, [key]: value })
        resetUrl(data, { ...otherData, [key]: value })
        onCloseDrawer()
    }

    const onOpenDrawer = () => {
        setOpenDrawer(true)
    }

    const onCloseDrawer = () => {
        setOpenDrawer(false)
    }

    const onOpenDialog = () => {
        setOpenDialog(true)
    }

    const onCloseDialog = () => {
        setOpenDialog(false)
    }

    const validateUrlMap = (data, otherData) => {
        const newError = {}

        const requiredErrorMsg = 'Required'

        if (!data.agentType) {
            newError.agentType = requiredErrorMsg
        }

        if (!data.mapCode) {
            newError.mapCode = requiredErrorMsg
        }

        if (data.mapCode && !data.mapCode.match('^[A-Z0-9_]+$')) {
            newError.mapCode = 'Only uppercase letters, numbers and underscore allowed'
        }

        if (data.agentType === 'web' && !data.actionType) {
            newError.actionType = requiredErrorMsg
        } else {
            switch (data.actionType) {
                case ACTION_TYPE.MY_TICKET:
                    if (!otherData.serviceType) {
                        newError.serviceType = requiredErrorMsg
                    }

                    if (otherData.serviceType === 'RACK_PRODUCT' && !otherData.type) {
                        newError.type = requiredErrorMsg
                    }

                    if (!otherData.refId) {
                        newError.refId = requiredErrorMsg
                    }
                    break
                case ACTION_TYPE.TRANSFORM_ECOUPON_FROM_PAPER:
                    if (!otherData.infoId) {
                        newError.infoId = requiredErrorMsg
                    }
                    break
                case ACTION_TYPE.TRANSFORM_TICKET_FROM_PAPER:
                    if (!otherData.ticketId) {
                        newError.ticketId = requiredErrorMsg
                    }
                    break
                case ACTION_TYPE.EXPRESS_TICKET_VOUCHER:
                    if (!otherData.groupCode) {
                        newError.groupCode = requiredErrorMsg
                    }
                    break
                case ACTION_TYPE.GIFT:
                    if (!otherData.refId) {
                        newError.refId = requiredErrorMsg
                    }

                    if (!otherData.code) {
                        newError.code = requiredErrorMsg
                    }

                    if (!otherData.serviceType) {
                        newError.serviceType = requiredErrorMsg
                    }

                    if (otherData.serviceType === 'RACK_PRODUCT' && !otherData.type) {
                        newError.type = requiredErrorMsg
                    }

                    break
                case ACTION_TYPE.SHARE:
                    if (!otherData.refId) {
                        newError.refId = requiredErrorMsg
                    }

                    if (!otherData.serviceType) {
                        newError.serviceType = requiredErrorMsg
                    }

                    break
                case ACTION_TYPE.WEB_VIEW:
                case ACTION_TYPE.LINK:
                case ACTION_TYPE.VIDEO:
                    if (!otherData.action) {
                        newError.action = requiredErrorMsg
                    }
                    break
                case ACTION_TYPE.PRODUCT:
                    if (!otherData.productCode) {
                        newError.productCode = requiredErrorMsg
                    }

                    if (otherData.codeType === 'BRAND' && !otherData.brandCode) {
                        newError.brandCode = requiredErrorMsg
                    }

                    if (otherData.codeType === 'CATEGORY' && !otherData.categoryCode) {
                        newError.categoryCode = requiredErrorMsg
                    }

                    break
                case ACTION_TYPE.PRODUCT_CATEGORY:
                case ACTION_TYPE.E_COUPON_CATEGORY:
                    if (!otherData.categoryCode) {
                        newError.categoryCode = requiredErrorMsg
                    }
                    break
                case ACTION_TYPE.PRODUCT_BRAND:
                case ACTION_TYPE.E_COUPON_BRAND:
                    if (!otherData.brandCode) {
                        newError.brandCode = requiredErrorMsg
                    }
                    break
                case ACTION_TYPE.E_COUPON:
                    if (!otherData.eCouponCode) {
                        newError.eCouponCode = requiredErrorMsg
                    }

                    if (otherData.codeType === 'BRAND' && !otherData.brandCode) {
                        newError.brandCode = requiredErrorMsg
                    }

                    if (otherData.codeType === 'CATEGORY' && !otherData.categoryCode) {
                        newError.categoryCode = requiredErrorMsg
                    }

                    break
                case ACTION_TYPE.FOLDER:
                    if (!otherData.folderCode) {
                        newError.folderCode = requiredErrorMsg
                    }
                    break
                case ACTION_TYPE.REST:
                    if (!data.restUrlType) {
                        newError.restUrlType = requiredErrorMsg
                    }

                    if (
                        (data.restUrlType === REST_URL_TYPE.downloadHtml ||
                            data.restUrlType === REST_URL_TYPE.universal) &&
                        !otherData.queryRestUrlId
                    ) {
                        newError.queryRestUrlId = requiredErrorMsg
                    }
                    break
                case ACTION_TYPE.CUSTOM_URL:
                    if (!otherData.customUrl) {
                        newError.customUrl = requiredErrorMsg
                    }
                    break
                default:
            }
        }

        setError(newError)
    }

    const renderDrawerList = () => {
        let component

        const dialogFooterBtn = (
            <TempBtn
                btnSetting={{
                    onClick: onCloseDrawer,
                    icon: <Clear />,
                    label: { key: 'close' },
                }}
            />
        )

        switch (data.actionType) {
            case ACTION_TYPE.PRODUCT:
                component = (
                    <ProductList
                        newFilter={{
                            excludedTypeList: ['PACKAGE_SUB_ITEM', 'FREE_ECOUPON_ITEM'],
                        }}
                        editBtn={false}
                        createBtn={false}
                        onClickListRow={(item) => onClickListRow('productCode', item.productCode)}
                        dialogFooterBtn={dialogFooterBtn}
                    />
                )

                if (otherData.codeType) {
                    component =
                        otherData.codeType === 'CATEGORY' ? (
                            <ProductCategoryList
                                newFilter={{
                                    typeList: ['PRODUCT', 'ALL'],
                                }}
                                editBtn={false}
                                createBtn={false}
                                onClickListRow={(item) =>
                                    onClickListRow('categoryCode', item.categoryCode)
                                }
                                dialogFooterBtn={dialogFooterBtn}
                            />
                        ) : (
                            <BrandList
                                editBtn={false}
                                createBtn={false}
                                onClickListRow={(item) =>
                                    onClickListRow('brandCode', item.brandCode)
                                }
                                dialogFooterBtn={dialogFooterBtn}
                            />
                        )
                }

                break
            case ACTION_TYPE.PRODUCT_CATEGORY:
                component = (
                    <ProductCategoryList
                        newFilter={{
                            typeList: ['PRODUCT', 'ALL'],
                        }}
                        editBtn={false}
                        createBtn={false}
                        onClickListRow={(item) => onClickListRow('categoryCode', item.categoryCode)}
                        dialogFooterBtn={dialogFooterBtn}
                    />
                )

                break
            case ACTION_TYPE.E_COUPON:
                component = (
                    <ProductList
                        newFilter={{
                            type: 'FREE_ECOUPON_ITEM',
                            excludedTypeList: ['PACKAGE_SUB_ITEM'],
                        }}
                        editBtn={false}
                        createBtn={false}
                        onClickListRow={(item) => onClickListRow('eCouponCode', item.productCode)}
                        dialogFooterBtn={dialogFooterBtn}
                    />
                )

                if (otherData.codeType) {
                    component =
                        otherData.codeType === 'CATEGORY' ? (
                            <ProductCategoryList
                                newFilter={{
                                    typeList: ['FREE_ECOUPON', 'ALL'],
                                }}
                                editBtn={false}
                                createBtn={false}
                                onClickListRow={(item) =>
                                    onClickListRow('categoryCode', item.categoryCode)
                                }
                                dialogFooterBtn={dialogFooterBtn}
                            />
                        ) : (
                            <BrandList
                                editBtn={false}
                                createBtn={false}
                                onClickListRow={(item) =>
                                    onClickListRow('brandCode', item.brandCode)
                                }
                                dialogFooterBtn={dialogFooterBtn}
                            />
                        )
                }

                break
            case ACTION_TYPE.E_COUPON_CATEGORY:
                component = (
                    <ProductCategoryList
                        newFilter={{
                            typeList: ['FREE_ECOUPON', 'ALL'],
                        }}
                        editBtn={false}
                        createBtn={false}
                        onClickListRow={(item) => onClickListRow('categoryCode', item.categoryCode)}
                        dialogFooterBtn={dialogFooterBtn}
                    />
                )
                break
            case ACTION_TYPE.PRODUCT_BRAND:
            case ACTION_TYPE.E_COUPON_BRAND:
                component = (
                    <BrandList
                        editBtn={false}
                        createBtn={false}
                        onClickListRow={(item) => onClickListRow('brandCode', item.brandCode)}
                        dialogFooterBtn={dialogFooterBtn}
                    />
                )
                break
            case ACTION_TYPE.PRODUCT_COUPON_CATEGORY_MAP:
                component = (
                    <ProductCategoryList
                        newFilter={{
                            productCode: otherData.productCode || otherData.eCouponCode,
                        }}
                        editBtn={false}
                        createBtn={false}
                        onClickListRow={(item) => onClickListRow('categoryCode', item.categoryCode)}
                        dialogFooterBtn={dialogFooterBtn}
                    />
                )
                break
            case ACTION_TYPE.PRODUCT_COUPON_BRAND_MAP:
                component = (
                    <BrandList
                        editBtn={false}
                        createBtn={false}
                        newFilter={{
                            productCode: otherData.productCode || otherData.eCouponCode,
                        }}
                        onClickListRow={(item) => onClickListRow('productCode', item.productCode)}
                        dialogFooterBtn={dialogFooterBtn}
                    />
                )
                break
            case ACTION_TYPE.FOLDER:
                component = (
                    <FolderList
                        editBtn={false}
                        createBtn={false}
                        onClickListRow={(item) => onClickListRow('folderCode', item.folderCode)}
                        dialogFooterBtn={dialogFooterBtn}
                    />
                )
                break
            case ACTION_TYPE.REST:
                component = (
                    <RestaurantList
                        editBtn={false}
                        createBtn={false}
                        btnList={false}
                        onClickListRow={(item) => onClickListRow('queryRestUrlId', item.restUrlId)}
                        dialogFooterBtn={dialogFooterBtn}
                    />
                )
                break
            default:
                component = <></>
                break
        }

        return <>{component}</>
    }

    const OpenDrawerEndAdornment = () => {
        return (
            <IconButton onClick={onOpenDrawer} color='primary'>
                <Edit sx={{ width: '20px', height: '20px' }} />
            </IconButton>
        )
    }

    const onClickSave = () => {
        const body = {
            actionType: data.actionType,
            mapCode: data.mapCode,
            mapValue: data.mapValue,
            url: url,
            qrCodeUrl: data.qrCodeUrl,
            agentType: data.agentType,
            restUrlId: data.restUrlId,
        }

        isCreateMode
            ? post(
                  API_PATH.urlMap,
                  body,
                  () => {
                      onOpenDialog()
                  },
                  undefined,
                  store
              )
            : put(
                  `${API_PATH.urlMap}/${params.id}`,
                  body,
                  () => {
                      onOpenDialog()
                  },
                  undefined,
                  store
              )
    }

    const onClickClear = () => {
        if (isCreateMode) {
            setData(DATA_DEFAULT)
            setOtherData(OTHER_DATA_DEFAULT)
        } else {
            getUrlMap()
            setShowQrCode(true)
        }
    }

    const onClickBackToCreateEdit = () => {
        onCloseDialog()
    }

    const onClickBackToList = () => {
        onCloseDialog()
        history.push(ROUTER_PATH.urlMap)
    }

    const getQueryObj = (url) => {
        if (!url) {
            return {}
        }

        // eslint-disable-next-line no-unused-vars
        const [_, queryStr] = url.split(/=(.+)/)

        if (!queryStr) {
            return {}
        }

        const queryObj = queryStr.split('&').reduce((result, pair) => {
            const [key, value] = pair.split('=')
            result[key] = value
            return result
        }, {})

        return queryObj
    }

    const getUrlMap = () => {
        getOne(
            `${API_PATH.urlMap}/${params.id}`,
            undefined,
            (payload) => {
                let newData = { ...DATA_DEFAULT }
                let newOtherData = { ...OTHER_DATA_DEFAULT }

                newData = {
                    ...newData,
                    mapCode: payload.mapCode,
                    mapValue: payload.mapValue,
                    actionType: payload.actionType,
                    agentType: payload.agentType,
                    restUrlId: payload.restUrlId,
                    status: payload.status,
                    qrCodeUrl: payload.qrCodeUrl,
                }

                if (payload.agentType === 'weChat') {
                    newOtherData = {
                        ...newOtherData,
                        weChat: payload.url.split('?')[1],
                    }
                } else if (payload.actionType === ACTION_TYPE.REST) {
                    const restUrlType = getRestaurantUrlType(payload.url)
                    const queryRestUrlId = getRestUrlIdFromUrl(payload.url)

                    newData = {
                        ...newData,
                        restUrlType: restUrlType,
                    }

                    console.log('restUrlType', restUrlType)

                    if (restUrlType === REST_URL_TYPE.other) {
                        newOtherData = {
                            ...newOtherData,
                            customUrl: payload.url,
                        }
                    } else {
                        const queryObj = getQueryObj(payload.url)

                        Object.entries(queryObj).forEach(([key, value]) => {
                            newOtherData[key] = value
                        })

                        newOtherData = {
                            ...newOtherData,
                            queryRestUrlId: queryRestUrlId,
                        }
                    }
                } else {
                    const queryObj = getQueryObj(payload.url)

                    Object.entries(queryObj).forEach(([key, value]) => {
                        newOtherData[key] = value
                    })
                }

                setUrl(payload.url)
                setData(newData)
                setOtherData(newOtherData)
            },
            undefined,
            store
        )
    }

    const onClickDownloadQrCode = () => {
        const svg = document.getElementById('qrCode')
        const s = new XMLSerializer().serializeToString(svg)
        const src = `data:image/svg+xml;base64,${window.btoa(s)}`
        const img = new Image()
        img.src = src
        img.onload = () => {
            const canvas = document.createElement('canvas')
            canvas.width = 1000
            canvas.height = 1000
            const context = canvas.getContext('2d')
            context.drawImage(img, 0, 0, 1000, 1000)
            const ImgBase64 = canvas.toDataURL('image/png')
            let downloadLink = document.createElement('a')
            downloadLink.href = ImgBase64
            downloadLink.download = `${window.btoa(s)}.png`
            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
        }
    }

    // useEffect(() => {
    //   console.log('otherDataFieldList', otherDataFieldList)
    // }, [otherDataFieldList])

    useEffect(() => {
        console.log('otherData', otherData)
    }, [otherData])

    useEffect(() => {
        console.log('data', data)
    }, [data])

    // useEffect(() => {
    //   console.log('error', error)
    // }, [error])

    useEffect(() => {
        validateUrlMap(data, otherData)
    }, [data, otherData])

    useEffect(() => {
        resetOtherDataFieldList(data.actionType)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.actionType])

    useEffect(() => {
        if (!isCreateMode) {
            getUrlMap()
            setShowQrCode(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Card sx={{ p: 2 }}>
                <CardHeader
                    title={<CardTitle>{`Url Map ${isCreateMode ? 'Create' : 'Edit'}`} </CardTitle>}
                />
                <CardContent>
                    <Stack gap={1}>
                        <ThreeItemsGridContainer>
                            {!isCreateMode && (
                                <MUITextField label='Status' value={data.status} disabled />
                            )}
                            <MUITextField
                                label='Map Code'
                                value={data.mapCode}
                                onChange={(e) => onChangeInput('mapCode', e.target.value)}
                                disabled={!isCreateMode}
                                error={error.mapCode}
                                required
                            />
                            <MUITextField
                                label='Map Value'
                                value={data.mapValue}
                                onChange={(e) => onChangeInput('mapValue', e.target.value)}
                                disabled={
                                    data.actionType === ACTION_TYPE.PRODUCT ||
                                    data.actionType === ACTION_TYPE.PRODUCT_BRAND ||
                                    data.actionType === ACTION_TYPE.PRODUCT_CATEGORY ||
                                    data.actionType === ACTION_TYPE.E_COUPON ||
                                    data.actionType === ACTION_TYPE.E_COUPON_CATEGORY ||
                                    data.actionType === ACTION_TYPE.E_COUPON_BRAND ||
                                    data.actionType === ACTION_TYPE.FOLDER ||
                                    data.actionType === ACTION_TYPE.REST ||
                                    data.actionType === ACTION_TYPE.MY_TICKET ||
                                    data.actionType === ACTION_TYPE.TRANSFORM_TICKET_FROM_PAPER ||
                                    data.actionType === ACTION_TYPE.TRANSFORM_ECOUPON_FROM_PAPER ||
                                    data.actionType === ACTION_TYPE.EXPRESS_TICKET_VOUCHER ||
                                    data.actionType === ACTION_TYPE.SHARE ||
                                    data.actionType === ACTION_TYPE.GIFT
                                }
                            />
                            <MUITextField
                                label='Rest Url Id (反掃用)'
                                value={data.restUrlId}
                                onChange={(e) => onChangeInput('restUrlId', e.target.value)}
                            />
                            <MUISelectField
                                label='Agent Type'
                                value={data.agentType}
                                onChange={(option) => onChangeInput('agentType', option?.value)}
                                options={AGENT_TYPE_OPTION}
                                required
                                error={error.agentType}
                            />
                            {data.agentType === 'web' && (
                                <>
                                    <MUISelectField
                                        label='Action Type'
                                        value={data.actionType}
                                        onChange={(option) =>
                                            onChangeInput('actionType', option?.value)
                                        }
                                        options={ACTION_TYPE_OPTION}
                                        required
                                        showClearAdornment
                                        error={error.actionType}
                                    />
                                    {/* Other Data */}
                                    {otherDataFieldList.includes('action') &&
                                        (data.actionType === ACTION_TYPE.REST ? (
                                            data.restUrlType === REST_URL_TYPE.downloadHtml && (
                                                <MUISelectField
                                                    label='Action'
                                                    value={otherData.action}
                                                    onChange={(option) =>
                                                        onChangeInput(
                                                            'action',
                                                            option?.value,
                                                            'other'
                                                        )
                                                    }
                                                    options={ACTION_OPTION}
                                                    showClearAdornment
                                                    error={error.action}
                                                />
                                            )
                                        ) : (
                                            <MUITextField
                                                label='Action'
                                                value={otherData.action}
                                                onChange={(e) =>
                                                    onChangeInput('action', e.target.value, 'other')
                                                }
                                                required
                                                error={error.action}
                                            />
                                        ))}
                                    {otherDataFieldList.includes('title') && (
                                        <MUITextField
                                            label='Title'
                                            value={otherData.title}
                                            onChange={(e) =>
                                                onChangeInput('title', e.target.value, 'other')
                                            }
                                            showClearAdornment
                                        />
                                    )}
                                    {data.actionType === ACTION_TYPE.REST && (
                                        <Box
                                            sx={{
                                                gridColumn: '1 / -1',
                                                display: 'grid',
                                                gridTemplateColumns: 'repeat(3, 1fr)',
                                                gap: 1,
                                            }}
                                        >
                                            <MUISelectField
                                                label='Restaurant URL Type'
                                                value={data.restUrlType}
                                                onChange={(option) =>
                                                    onChangeInput('restUrlType', option.value)
                                                }
                                                options={REST_URL_TYPE_OPTION}
                                                required
                                                error={error.restUrlType}
                                            />
                                            {data.restUrlType !== REST_URL_TYPE.other && (
                                                <MUITextField
                                                    label='Rest Url Id'
                                                    value={otherData.queryRestUrlId}
                                                    onChange={(e) =>
                                                        onChangeInput(
                                                            'queryRestUrlId',
                                                            e.target.value,
                                                            'other'
                                                        )
                                                    }
                                                    required
                                                    endAdornment={<OpenDrawerEndAdornment />}
                                                    error={error.queryRestUrlId}
                                                />
                                            )}
                                        </Box>
                                    )}
                                    {otherDataFieldList.includes('name') && (
                                        <MUITextField
                                            label='Name'
                                            value={otherData.name}
                                            onChange={(e) =>
                                                onChangeInput('name', e.target.value, 'other')
                                            }
                                            showClearAdornment
                                        />
                                    )}
                                    {otherDataFieldList.includes('query') && (
                                        <MUISelectField
                                            label='Query'
                                            value={otherData.query}
                                            groupCode
                                            onChange={(option) =>
                                                onChangeInput('query', option?.value, 'other')
                                            }
                                            options={QUERY_OPTION}
                                            showClearAdornment
                                        />
                                    )}
                                    {otherDataFieldList.includes('ticketId') && (
                                        <MUITextField
                                            label='Ticket Id'
                                            value={otherData.ticketId}
                                            onChange={(e) =>
                                                onChangeInput('ticketId', e.target.value, 'other')
                                            }
                                            required
                                            error={error.ticketId}
                                        />
                                    )}
                                    {otherDataFieldList.includes('infoId') && (
                                        <MUITextField
                                            label='Info Id'
                                            value={otherData.infoId}
                                            onChange={(e) =>
                                                onChangeInput('infoId', e.target.value, 'other')
                                            }
                                            required
                                            error={error.infoId}
                                        />
                                    )}
                                    {otherDataFieldList.includes('groupCode') && (
                                        <MUITextField
                                            label='Group Code'
                                            value={otherData.groupCode}
                                            onChange={(e) =>
                                                onChangeInput('groupCode', e.target.value, 'other')
                                            }
                                            required
                                            error={error.groupCode}
                                        />
                                    )}
                                    {otherDataFieldList.includes('refId') && (
                                        <MUITextField
                                            label='Ref Id'
                                            value={otherData.refId}
                                            onChange={(e) =>
                                                onChangeInput('refId', e.target.value, 'other')
                                            }
                                            required
                                            error={error.refId}
                                        />
                                    )}
                                    {otherDataFieldList.includes('code') && (
                                        <MUITextField
                                            label='Code'
                                            value={otherData.code}
                                            onChange={(e) =>
                                                onChangeInput('code', e.target.value, 'other')
                                            }
                                            required
                                            error={error.code}
                                        />
                                    )}
                                    {otherDataFieldList.includes('serviceType') && (
                                        <MUISelectField
                                            label='Service Type'
                                            value={otherData.serviceType}
                                            onChange={(option) =>
                                                onChangeInput('serviceType', option.value, 'other')
                                            }
                                            options={
                                                data.actionType === ACTION_TYPE.GIFT
                                                    ? GIFT_SERVICE_TYPE_OPTION
                                                    : SERVICE_TYPE_OPTION
                                            }
                                            required
                                            error={error.serviceType}
                                        />
                                    )}
                                    {otherDataFieldList.includes('productCode') && (
                                        <MUITextField
                                            label='Product Code'
                                            value={otherData.productCode}
                                            onChange={(e) =>
                                                onChangeInput(
                                                    'productCode',
                                                    e.target.value,
                                                    'other'
                                                )
                                            }
                                            required
                                            endAdornment={<OpenDrawerEndAdornment />}
                                            error={error.productCode}
                                        />
                                    )}
                                    {otherDataFieldList.includes('eCouponCode') && (
                                        <MUITextField
                                            label='E-Coupon Code'
                                            value={otherData.eCouponCode}
                                            onChange={(e) =>
                                                onChangeInput(
                                                    'eCouponCode',
                                                    e.target.value,
                                                    'other'
                                                )
                                            }
                                            required
                                            endAdornment={<OpenDrawerEndAdornment />}
                                            error={error.eCouponCode}
                                        />
                                    )}
                                    {(otherData.productCode || otherData.eCouponCode) && (
                                        <MUISelectField
                                            label='Brand / Category'
                                            value={otherData.codeType}
                                            onChange={(option) =>
                                                onChangeInput('codeType', option?.value, 'other')
                                            }
                                            options={CODE_TYPE_OPTION}
                                            showClearAdornment
                                        />
                                    )}
                                    {otherDataFieldList.includes('type') && (
                                        <MUISelectField
                                            label='Type'
                                            value={otherData.type}
                                            onChange={(option) =>
                                                onChangeInput('type', option?.value, 'other')
                                            }
                                            options={
                                                otherData.serviceType === 'RACK_PRODUCT'
                                                    ? RACK_PRODUCT_TYPE_OPTION
                                                    : WEB_VIEW_TYPE_OPTION
                                            }
                                            error={error.type}
                                            required={otherData.serviceType === 'RACK_PRODUCT'}
                                            showClearAdornment={
                                                data.actionType === ACTION_TYPE.WEB_VIEW
                                            }
                                        />
                                    )}
                                    {(otherDataFieldList.includes('categoryCode') ||
                                        otherData.codeType === 'CATEGORY') && (
                                        <MUITextField
                                            label='Category Code'
                                            value={otherData.categoryCode}
                                            onChange={(e) =>
                                                onChangeInput(
                                                    'categoryCode',
                                                    e.target.value,
                                                    'other'
                                                )
                                            }
                                            required
                                            endAdornment={<OpenDrawerEndAdornment />}
                                            error={error.categoryCode}
                                        />
                                    )}
                                    {(otherDataFieldList.includes('brandCode') ||
                                        otherData.codeType === 'BRAND') && (
                                        <MUITextField
                                            label='Brand Code'
                                            value={otherData.brandCode}
                                            onChange={(e) =>
                                                onChangeInput('brandCode', e.target.value, 'other')
                                            }
                                            required
                                            endAdornment={<OpenDrawerEndAdornment />}
                                            error={error.brandCode}
                                        />
                                    )}
                                    {otherDataFieldList.includes('folderCode') && (
                                        <MUITextField
                                            label='Folder Code'
                                            value={otherData.folderCode}
                                            onChange={(e) =>
                                                onChangeInput('folderCode', e.target.value, 'other')
                                            }
                                            required
                                            endAdornment={<OpenDrawerEndAdornment />}
                                            error={error.folderCode}
                                        />
                                    )}
                                    {otherDataFieldList.includes('folderType') && (
                                        <MUISelectField
                                            label='Folder Type'
                                            value={data.folderType}
                                            onChange={(option) =>
                                                onChangeInput('folderType', option?.value, ' other')
                                            }
                                            options={FOLDER_TYPE_OPTION}
                                            showClearAdornment
                                        />
                                    )}
                                    {data.restUrlType !== REST_URL_TYPE.universal &&
                                        data.restUrlType !== REST_URL_TYPE.other && (
                                            <Box
                                                sx={{
                                                    gridColumn: '1 / -1',
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(3, 1fr)',
                                                    gap: 1,
                                                }}
                                            >
                                                <MUISelectField
                                                    label='From'
                                                    value={otherData.from}
                                                    onChange={(option) =>
                                                        onChangeInput(
                                                            'from',
                                                            option?.value,
                                                            'other'
                                                        )
                                                    }
                                                    options={FROM_OPTION}
                                                    showClearAdornment
                                                />
                                            </Box>
                                        )}
                                    {(otherDataFieldList.includes('customUrl') ||
                                        data.restUrlType === REST_URL_TYPE.other) && (
                                        <MUITextField
                                            label='Custom URL'
                                            value={otherData.customUrl}
                                            onChange={(e) =>
                                                onChangeInput('customUrl', e.target.value, 'other')
                                            }
                                            required
                                            sx={{ gridColumn: '1 / -1' }}
                                            error={error.customUrl}
                                        />
                                    )}
                                </>
                            )}
                            {data.agentType === 'weChat' && (
                                <MUITextField
                                    label='WeChat'
                                    value={otherData.weChat}
                                    onChange={(e) => {
                                        onChangeInput('weChat', e.target.value, 'other')
                                        setUrl(
                                            `${Q_HK_WALLET_URL}${
                                                e.target.value ? `?${e.target.value}` : ''
                                            }`
                                        )
                                    }}
                                    showClearAdornment
                                />
                            )}
                            <MUITextField
                                label='URL'
                                value={url}
                                disabled
                                sx={{ gridColumn: '1 / -1' }}
                            />
                        </ThreeItemsGridContainer>
                        {!isCreateMode && showQrCode && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, gap: 2 }}>
                                <QRCode
                                    id='qrCode'
                                    size={150}
                                    value={data.qrCodeUrl}
                                    level={'L'}
                                    renderAs={'svg'}
                                />
                                <Button
                                    variant='contained'
                                    onClick={onClickDownloadQrCode}
                                    startIcon={<GetApp />}
                                    sx={{ width: 'fit-content' }}
                                >
                                    Download QR Code
                                </Button>
                                <a target='_blank' rel='noopener noreferrer' href={data.qrCodeUrl}>
                                    {data.qrCodeUrl}
                                </a>
                            </Box>
                        )}
                    </Stack>
                </CardContent>
                <CardActions>
                    <Button
                        variant='contained'
                        onClick={onClickSave}
                        startIcon={<Save />}
                        disabled={Object.values(error).some((error) => error)}
                    >
                        Save
                    </Button>
                    <Button variant='contained' onClick={onClickClear} startIcon={<Clear />}>
                        Clear
                    </Button>
                </CardActions>
            </Card>
            <Drawer
                anchor='right'
                open={openDrawer}
                onClose={onCloseDrawer}
                PaperProps={{
                    sx: {
                        width: '80%',
                    },
                }}
            >
                {renderDrawerList()}
            </Drawer>
            {openDialog && (
                <SharedDialog
                    content={`${isCreateMode ? 'Create' : 'Edit'} Successful!`}
                    actions={
                        <>
                            <Button
                                variant='contained'
                                onClick={onClickBackToCreateEdit}
                                startIcon={<ArrowBack />}
                            >
                                {`Back To ${isCreateMode ? 'Create' : 'Edit'}`}
                            </Button>
                            <Button
                                variant='contained'
                                onClick={onClickBackToList}
                                startIcon={<List />}
                            >
                                Back To Listing
                            </Button>
                        </>
                    }
                    open={openDialog}
                />
            )}
        </>
    )
}
