
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import _ from "lodash";

import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { constants } from '../../../Constants/constants';
import { post } from "../../../helper/baseFetch";
import { getDefaultBottomBtnList } from "../../../Component/EditCreateDisplay/EditUtil";
import { setRestaurantPayment, setRestaurantPaymentValue } from "../../../actions/restaurantPaymentAction";
import { EditCreateDisplay } from '../../../Component/EditCreateDisplay/EditCreateDisplay';
import { GlobalDialog } from "../../../Component/GlobalDialog";
import RestaurantPaymentValidation from "../../../validations/restaurantPaymentValidation";
import { createContentList } from "../UIContentFactory/createContentList";
import { createRestaurantPaymentCreateDisplaySetting } from "../UIContentFactory/createContentDisplaySetting";
import useRestaurant from "../../../hooks/Restaurant/useRestaurant";


const resource = "RestaurantPayment";

const LOCAL_CONSTANT = {
  PAYMENT_CODE: 'paymentCode',
  CARD_TYPE: 'cardType',
  SYSTEM_GROUP_ID: 'systemGroupId',
}

const tabs = [
  {
    label: { resource: resource, key: "info" },
    tabIndex: constants.RESTAURANT_PAYMENT_EDIT_TAB.INFO
  },
]

export const RestaurantPaymentCreate = () => {

  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();
  const history = useHistory();
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const savedRestaurantPayment = useSelector(state => state.restaurantPayment.ui.restaurantPayment);
  const [, setErrorVersion] = useState(RestaurantPaymentValidation.getVersion());

  const content = createContentList(createRestaurantPaymentCreateDisplaySetting(resource, savedRestaurantPayment, onChangeInput, params.restUrlId), onChangeInput, true);

  const bottomBtns = getDefaultBottomBtnList(onClickSave, onClickClear);

  const { getRestaurantIdAndName } = useRestaurant(params.restUrlId)

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + "/" + params.restUrlId + "/" + constants.RESTAURANT_TAB.INFO + "/" + constants.RESTAURANT_SUB_TAB.PAYMENT);
  };

  function onChangeInput(name, e) {

    if(name === LOCAL_CONSTANT.PAYMENT_CODE){
      const tempt = _.cloneDeep(savedRestaurantPayment);
      tempt.paymentCode = e.paymentCode;
      tempt.paymentConfigId = e.id;
      tempt.paymentConfigName = e.paymentConfigName;
      tempt.systemGroupName = e.systemGroupName;
      tempt.systemGroupId = e.systemGroupId;
      tempt.cardType = null;
      dispatch(setRestaurantPayment(tempt));
      return;
    }

    dispatch(setRestaurantPaymentValue(name, savedRestaurantPayment, "", e));
  }

  function onClickSave() {

    RestaurantPaymentValidation.validateCreateRestaurantPayment(savedRestaurantPayment);
    if (RestaurantPaymentValidation.isValid()) {
      post(constants.PATH.SHOP.PAYMENT.PAYMENT_CONFIG_MAP, {...savedRestaurantPayment, restUrlId : params.restUrlId}, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: "createSuccess" },
          dialogActions: [
            { label: { key: "backCreate" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      RestaurantPaymentValidation.resetErrorMessages();
    } else {
      setErrorVersion(RestaurantPaymentValidation.getVersion());
    }

  }

  function onClickClear() {
    setClear(true);
  }

  useEffect(() => {
    if (clear === true) {
      let tempInput = _.cloneDeep(constants.RESTAURANT_PAYMENT);
      dispatch(setRestaurantPayment({...tempInput, status: "A"}));
      setClear(false)
    }
    RestaurantPaymentValidation.resetErrorMessages();
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  const mapCode = getRestaurantIdAndName()

  return (
    <>
      <EditCreateDisplay
        resource={resource}
        tabs={tabs}
        content={content}
        bottomBtns={bottomBtns}
        display="create"
        mapName={{ resource: "RestaurantPayment", key: "restUrlId" }}
        mapCode={mapCode}
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};
