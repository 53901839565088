import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import _ from "lodash";
import moment from 'moment';

import Paper from '@mui/material/Paper';
import { Drawer } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { getOne, put } from "../../helper/baseFetch";
import { setConfigMap } from "../../actions/configMapAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { TempBtn } from "../../Component/Input/TempBtn"
import { RestaurantList } from "../../Page/Restaurant/RestaurantList";
import { handleBannerDisableSaveButton } from "../../Component/Ticket/handleConfigMapDisableSaveButton";
import { GlobalDialog } from "../../Component/GlobalDialog";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const ConfigMapEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const store = useStore();
  const history = useHistory();
  const helperTextStylesClasses = helperTextStyles();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const savedBanner = useSelector(state => state.banner.ui.banner)
  const savedConfigMap = useSelector(state => state.configMap.ui.configMap)
  const resource = "ConfigMap";
  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.TICKET_BANNER_MAP_TAB.INFO
    }
  ]

  const content = [
    {
      tab: savedConfigMap && savedConfigMap.length > 0 && savedConfigMap.map((savedConfigMapData, index) => {
        const tempConfigMapEditList = [
          {
            className: "edit-create-input",
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: "serviceType" },
            value: savedConfigMapData.serviceType,
            type: constants.TYPE.SELECT,
            selectValue: constants.CHOICES.CONFIG_MAP.SERVICE_TYPE,
            name: "serviceType",
            isEndOfLine: false
          },
          {
            className: "edit-create-input",
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: "ticketStatus" },
            value: savedConfigMapData.ticketStatus,
            type: constants.TYPE.SELECT,
            selectValue: savedConfigMapData.serviceType ? constants.CHOICES.CONFIG_MAP[savedConfigMapData.serviceType].TICKET_STATUS : [],
            name: "ticketStatus",
            isEndOfLine: true,
            disabled: savedConfigMapData.serviceType ? false : true,
          },
          {
            className: "edit-create-input",
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: "startDisplayDate" },
            value: savedConfigMapData.startDisplayDate,
            name: "startDisplayDate",
            helperTextIcon: true,
            helperText: savedBanner.startDisplayDate > savedConfigMapData.startDisplayDate ? "Start Display Date is out of range!" : "",
            FormHelperTextProps: { className: helperTextStylesClasses.root },
            type: constants.TYPE.DATETIME_LOCAL,
            step: 1,
            isEndOfLine: false,
          },
          {
            className: "edit-create-input",
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: "endDisplayDate" },
            value: savedConfigMapData.endDisplayDate,
            name: "endDisplayDate",
            helperTextIcon: true,
            helperText: savedBanner.endDisplayDate < savedConfigMapData.endDisplayDate ? "End Display Date is out of range!" : "",
            FormHelperTextProps: { className: helperTextStylesClasses.root },
            type: constants.TYPE.DATETIME_LOCAL,
            step: 1,
            isEndOfLine: true,
          },
          {
            className: "edit-create-input",
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: "restUrlId" },
            value: savedConfigMapData.restUrlId,
            onClickEndAdornmentIconButton: () => setDrawerOpen(true),
            clear: () => onChangeInput("clearRestUrlId"),
            name: "restUrlId",
            isEndOfLine: true,
            disabled: true,
          },
          {
            className: "edit-create-input",
            variant: constants.STYLE.VARIANT.STANDARD,
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: "restEnName" },
            value: savedConfigMapData.restEnName,
            name: "restEnName",
            isEndOfLine: false,
            disabled: true,
          },
          {
            className: "edit-create-input",
            variant: constants.STYLE.VARIANT.STANDARD,
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: "restTcName" },
            value: savedConfigMapData.restTcName,
            name: "restTcName",
            isEndOfLine: false,
            disabled: true,
          },
          {
            className: "edit-create-input",
            variant: constants.STYLE.VARIANT.STANDARD,
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: "restScName" },
            value: savedConfigMapData.restScName,
            name: "restScName",
            isEndOfLine: true,
            disabled: true,
          },
          {
            className: "edit-create-input",
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: "channel" },
            value: savedConfigMapData.channel,
            type: constants.TYPE.SELECT,
            selectValue: constants.CHOICES.CONFIG_MAP.CHANNEL,
            name: "channel",
            isEndOfLine: false
          },
          {
            className: "edit-create-input",
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: "status" },
            value: savedConfigMapData.status,
            type: constants.TYPE.SELECT,
            selectValue: constants.CHOICES.CONFIG_MAP.STATUS,
            name: "status",
            isEndOfLine: true
          },
        ]
        return <>
          {tempConfigMapEditList.map((inputSetting, newTempConfigMapindex) => {
            let content;
            switch (inputSetting.type) {
              case constants.TYPE.SELECT:
                content =
                  <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
                break;
              case constants.TYPE.BUTTON:
                content = <TempBtn btnSetting={inputSetting} />
                break;
              default:
                content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            }
            return <div className="edit-display-wrapper" key={newTempConfigMapindex}>

              {content}
              {inputSetting.isEndOfLine && <div></div>}
            </div>
          })}
        </>
      }),
      btn: true
    }
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      disabled: handleBannerDisableSaveButton(savedConfigMap),
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.TICKET.TICKET_BANNER + "/" + savedBanner.id + "/" + constants.TICKET_BANNER_TAB.TICKET_BANNER_MAP_LIST);
  };

  function onChangeInput(name, e) {
    let tempConfigMap = _.cloneDeep(savedConfigMap);
    if (name === "serviceType") {
      tempConfigMap[0]["ticketStatus"] = "ALL";
    }
    if (name === "restUrlId" && e === "") {
      tempConfigMap[0]["restEnName"] = "";
      tempConfigMap[0]["restTcName"] = "";
      tempConfigMap[0]["restScName"] = "";
    }
    if (name === "clearRestUrlId") {
      tempConfigMap[0]["restUrlId"] = "";
      tempConfigMap[0]["restEnName"] = "";
      tempConfigMap[0]["restTcName"] = "";
      tempConfigMap[0]["restScName"] = "";
    } else if (name === "startDisplayDate" || name === "endDisplayDate") {
      tempConfigMap[0][`${name}`] = Number(moment(e).format("x"));
    } else {
      tempConfigMap[0][`${name}`] = e
    }
    dispatch(setConfigMap(tempConfigMap));
  }

  function onClickCloseDrawer() {
    setDrawerOpen(false)
  }

  function onClickListRow(data) {
    let tempConfigMap = _.cloneDeep(savedConfigMap);
    tempConfigMap[0]["restUrlId"] = data.restUrlId;
    tempConfigMap[0]["restEnName"] = data.enName;
    tempConfigMap[0]["restTcName"] = data.tcName;
    tempConfigMap[0]["restScName"] = data.scName;
    dispatch(setConfigMap(tempConfigMap));
    onClickCloseDrawer();
  }


  function onClickSave() {
    let tempsavedBanner = _.cloneDeep(savedConfigMap[0]);
    put(constants.PATH.TICKET.TICKET_BANNER_MAP + "/" + tempsavedBanner.id, tempsavedBanner, (payload) => {
      setDialog({
        open: true,
        onClose: onClickBackToEdit,
        dialogContentText: { key: "editSuccess" },
        dialogActions: [
          { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
          { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
        ]
      })
      setClear(false)
    }, undefined, store);
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(constants.PATH.TICKET.TICKET_BANNER_MAP + "/" + params.id, undefined, (payload) => {
        let data = [payload]
        dispatch(setConfigMap(data));
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedConfigMap && (savedConfigMap[0] && savedConfigMap[0].id ? savedConfigMap[0].id : "")}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        mapName={{ resource: "Banner", key: "bannerId" }}
        mapCode={savedBanner.bannerId}
        display="edit"
      />
      <Drawer
        classes={{ paper: "drawer-margin-left" }}
        anchor="right"
        open={drawerOpen}
        onClose={onClickCloseDrawer}
      >
        <Paper>
          <RestaurantList
            editBtn={false}
            createBtn={false}
            btnList={false}
            onClickListRow={(item) => onClickListRow(item)}
            dialogFooterBtn={<>
              <TempBtn btnSetting={{
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                onClick: onClickCloseDrawer,
                icon: <ClearIcon />,
                label: { key: "close" },
              }} />
            </>
            }
          />
        </Paper>
      </Drawer>
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};