import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import store from '../../store'

import { constants } from '../../Constants/constants'

import { TempTextInput } from '../../Component/Input/TempTextInput'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { GlobalDialog } from '../../Component/GlobalDialog'
import { post } from '../../helper/baseFetch'
import { ArrowBack, LowPriority } from '@mui/icons-material'
import { getDefaultBottomBtnList } from '../../Component/EditCreateDisplay/EditUtil'

import CounterProfileValidation from '../../validations/counterProfileValidation'

import { counterProfileValidationConstants } from '../../Constants/validationConstants'

import { TempCustomCheckBox } from '../../Component/Custom/TempCustomCheckBox'
import { setCounterProfileDefault, setCounterProfileValue } from '../../actions/counterProfileAction'

import { ErrorMsg } from '../../Component/ErrorMsg/ErrorMsg'
import CounterProfileDragAndDrop from './CounterProfileDragAndDrop'
import useRestaurant from '../../hooks/Restaurant/useRestaurant'

export default function CounterProfileCreate() {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const restUrlId = params.restUrlId || ''

  const { getRestaurantName } = useRestaurant(restUrlId)
  const restName = getRestaurantName()

  const [clear, setClear] = useState(false)
  const [dialogInfo, setDialogInfo] = useState({
    open: false,
  })

  const [idList, setIdList] = useState([])
  const [objList, setObjList] = useState([])

  const resource = 'CounterProfile'

  const savedData = useSelector((state) => state.counterProfile.ui.counterProfile)

  let validation = CounterProfileValidation,
    validationConstants = counterProfileValidationConstants,
    // setDispatch = (counter) => dispatch(setCounterProfile(counter))
    setDispatchDefault = () => dispatch(setCounterProfileDefault()),
    setDispatchValue = (fieldName, originalObj, key, value) => dispatch(setCounterProfileValue(fieldName, originalObj, key, value)),
    apiPath = constants.PATH.MERCHANT.COUNTER_PROFILE,
    listPath = `/${constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT}/${restUrlId}/${constants.RESTAURANT_TAB.MERCHANT}/${constants.RESTAURANT_MERCHANT_SUB_TAB.COUNTER_PROFILE}`

  // eslint-disable-next-line no-unused-vars
  const [errorVersion, setErrorVersion] = useState(validation.getVersion())

  const onCloseDialog = () => {
    setDialogInfo({
      open: false,
    })
  }

  const onChangeInput = (name, e) => {
    setDispatchValue(name, savedData, '', e)
  }

  const onChangeScNameDisable = (value) => {
    setDispatchValue('scNameDisable', savedData, '', value)
  }

  const onRedirectToCreateEdit = () => {
    onCloseDialog()
    onClickClear()
  }

  const onRedirectToList = () => {
    setDialogInfo({
      open: false,
    })
    history.push(listPath)
  }

  const onClickSave = () => {
    validation.validateSavedData(savedData)
    // console.log('validation.getErrorMessage(validationConstants.KEY_COUNTER_LIST)', validation.getErrorMessage(validationConstants.KEY_COUNTER_LIST))
    if (validation.isValid()) {
      post(
        apiPath,
        {
          enName: savedData.enName,
          tcName: savedData.tcName,
          scName: savedData.scName,
          counterList: objList.map((item, i) => {
            return { counterId: item.id, sequence: objList.length - i }
          }),
        },
        () => {
          setDialogInfo({
            open: true,
            onClose: onRedirectToCreateEdit,
            dialogContentText: { key: 'createSuccess' },
            maxWidth: 'md',
            dialogActions: [
              { label: { key: 'backCreate' }, onClick: onRedirectToCreateEdit, icon: <ArrowBack /> },
              {
                label: { key: 'backList' },
                onClick: onRedirectToList,
                icon: <LowPriority />,
              },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
    } else {
      setErrorVersion(validation.getVersion())
    }
  }

  const onClickClear = () => {
    setClear(true)
    setIdList([])
    setObjList([])
  }

  const onDragEnd = (result) => {
    const reorder = (list, startI, endI) => {
      const result = Array.from(list)
      const [removed] = result.splice(startI, 1)
      result.splice(endI, 0, removed)
      return result
    }

    if (!result.destination) return
    if (result.destination.index === result.source.index) return
    const l = reorder(objList, result.source.index, result.destination.index)
    setObjList(l)
  }

  const onDeleteDragItem = (name, id) => {
    const newObjList = objList.filter((item) => item.id !== id)
    const newIdList = idList.filter((item) => item !== id)
    setObjList(newObjList)
    setIdList(newIdList)
  }

  const content = [
    {
      tab: (
        <>
          <div className="edit-display-wrapper">
            {/* <div>
              <GetRefCounterList createEditType={'create'} idList={idList} setIdList={setIdList} objList={objList} setObjList={setObjList} />
            </div> */}
            <TempCustomCheckBox
              className="customize-btn"
              value={savedData.scNameDisable}
              customName={{ resource: resource, key: 'nameCustomize' }}
              onSelectCheckbox={onChangeScNameDisable}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'enName' },
                value: savedData.enName,
                name: 'enName',
                helperText: validation.getErrMsg(validationConstants.KEY_EN_NAME),
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'tcName' },
                value: savedData.tcName,
                name: 'tcName',
                helperText: validation.getErrMsg(validationConstants.KEY_TC_NAME),
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'scName' },
                value: savedData.scName,
                name: 'scName',
                helperText: validation.getErrMsg(validationConstants.KEY_SC_NAME),
                disabled: savedData.scNameDisable,
              }}
              handleChange={onChangeInput}
            />
            <CounterProfileDragAndDrop
              createEditType={'create'}
              idList={idList}
              setIdList={setIdList}
              objList={objList}
              setObjList={setObjList}
              onDragEnd={onDragEnd}
              onDeleteDragItem={onDeleteDragItem}
            />
            {validation.getErrMsg(validationConstants.KEY_COUNTER_LIST).length > 0 && validation.getErrMsg(validationConstants.KEY_COUNTER_LIST) && (
              <ErrorMsg errorMsg={validation.getErrMsg(validationConstants.KEY_COUNTER_LIST)} showErrorIcon={true} />
            )}
          </div>
        </>
      ),
      btn: true,
    },
  ]

  // useEffect(() => {
  //   console.log('validation.getErrorMessage(validationConstants.KEY_COUNTER_LIST)', validation.getErrorMessage(validationConstants.KEY_COUNTER_LIST))
  //   console.log('validation.getErrorMessage(validationConstants.KEY_SC_NAME)', validation.getErrorMessage(validationConstants.KEY_SC_NAME))
  // }, [validation])

  useEffect(() => {
    setDispatchDefault()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setDispatchDefault()
    setClear(false)
    validation.resetErrMsg()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear === true])

  // useEffect(() => {
  //   console.log('savedData', savedData)
  // }, [savedData])

  return (
    <>
      <EditCreateDisplay
        resource={resource}
        content={content}
        mapName={{ resource: 'restaurant', key: 'restUrlId' }}
        mapCode={`${restUrlId} - ${restName}`}
        display="create"
        bottomBtns={getDefaultBottomBtnList(onClickSave, onClickClear)}
      />
      {dialogInfo.open && <GlobalDialog dialogInfo={dialogInfo} />}
    </>
  )
}
