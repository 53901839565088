import { styled } from '@mui/material'

const BoldTitle = styled('span')(() => {
  return {
    fontSize: '17px',
    fontWeight: 700,
    fontFamily: 'Segoe UI',
  }
})

export default BoldTitle
