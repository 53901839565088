import { Box, Card, Divider, Fab, Snackbar, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material'
import MUILabel from '../../Component/common/label/MUILabel'
import { memo, useCallback, useEffect, useState } from 'react'
import { getOne } from '../../helper/baseFetch'
import { useStore } from 'react-redux'
import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import SharedDialog from '../../Component/common/dialog/SharedDialog'
import moment from 'moment'
import { ReactComponent as CallIcon } from '../../assets/icon/call.svg'
import MUITextField from '../../Component/common/field/MUITextField'
import ThreeItemsGridContainer from '../../Component/common/field/ThreeItemsGridContainer'
import { debounce } from 'lodash'
import MUISelectField from '../../Component/common/field/MUISelectField'
import MultiLangLabel from '../../Component/common/label/MultiLangLabel'
import Iconify from '../../Component/common/iconify/Iconify'
import { ArrowForward } from '@mui/icons-material'

const DEVICE_TYPE = {
  counterKiosk: 'counterKiosk',
  kiosk: 'kiosk',
  pr: 'pr',
  tv: 'tv',
  pickupTV: 'pickupTV',
  counterTV: 'counterTv',
  staffPR: 'STAFF_PR',
  campaignQueue: 'campaignQueue',
  tailorMade: 'tailorMade',
  mallKiosk: 'mallKiosk',
  slideshowPlayer: 'slideshowPlayer',
  fallback: 'fallback',
  mallTv: 'mallTv',
}

const DEVICE_OPTION = [
  { value: DEVICE_TYPE.counterKiosk, label: 'COUNTER KIOSK' },
  { value: DEVICE_TYPE.kiosk, label: 'KIOSK' },
  { value: DEVICE_TYPE.pr, label: 'PR' },
  { value: DEVICE_TYPE.tv, label: 'TV' },
  { value: DEVICE_TYPE.pickupTV, label: 'PICKUP TV' },
  { value: DEVICE_TYPE.counterTV, label: 'COUNTER TV' },
  { value: DEVICE_TYPE.staffPR, label: 'STAFF PR' },
  { value: DEVICE_TYPE.campaignQueue, label: 'CAMPAIGN QUEUE' },
  { value: DEVICE_TYPE.tailorMade, label: 'TAILOR MADE' },
  { value: DEVICE_TYPE.mallKiosk, label: 'MALL KIOSK' },
  { value: DEVICE_TYPE.slideshowPlayer, label: 'SLIDESHOW PLAYER' },
  { value: DEVICE_TYPE.fallback, label: 'FALLBACK' },
  { value: DEVICE_TYPE.mallTv, label: 'MALL TV' },
]

const DataPair = ({ iconify, icon, tooltip, children, childrenType = 'default', sx, iconifySx }) => {
  const Icon = () => {
    switch (childrenType) {
      case 'boolean':
        return (
          <>
            <Iconify
              iconify={children ? 'mdi:tick' : 'mdi:close'}
              sx={{
                width: '14px',
                height: '14px',
                color: '#fff',
                backgroundColor: children ? '#62ad54' : '#7a0e1c',
                position: 'absolute',
                top: 0,
                right: -7,
                borderRadius: '7px',
              }}
            />
            {icon || <Iconify icon={iconify} />}
          </>
        )
      case 'status':
        let backgroundColor = '#62ad54'
        if (children === 'P') {
          backgroundColor = '#f0ad4e'
        } else if (children === 'D') {
          backgroundColor = '#7a0e1c'
        }

        return (
          <>
            <Box
              sx={{
                width: '14px',
                height: '14px',
                color: '#fff',
                backgroundColor: backgroundColor,
                position: 'absolute',
                top: 0,
                right: -7,
                borderRadius: '7px',
                fontSize: '10px',
                fontWeight: 600,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {children}
            </Box>
            {icon || <Iconify iconify={iconify} sx={iconifySx} />}
          </>
        )
      default:
        return icon || <Iconify iconify={iconify} sx={iconifySx} />
    }
  }

  return (
    <Tooltip title={tooltip} followCursor>
      <Stack direction="row" gap={0.5} sx={{ height: '22px', color: '#a8a8a8', ...sx }} alignItems="center">
        <Box sx={{ position: 'relative' }}>
          <Icon />
        </Box>
        {childrenType === 'default' && <Box sx={{ fontSize: '13px', fontWeight: 500 }}>{children}</Box>}
      </Stack>
    </Tooltip>
  )
}

const TimeSectionList = ({ timeSectionList, displayType = 'default', autoRequestTicketStatus }) => {
  if (!timeSectionList) {
    return <Box sx={{ p: 2 }}>No Data</Box>
  }

  return (
    <>
      {timeSectionList.map((timeSection, idx) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderBottom: idx !== timeSectionList.length - 1 ? 'solid 1px #eee' : '',
            '&:hover': {
              backgroundColor: '#eef5fb',
            },
          }}
          key={uuidv4()}
        >
          <Tooltip title="Time Section" followCursor>
            <Stack direction="row" gap={1} sx={{ position: 'sticky', top: 0, backgroundColor: '#fff', p: 1, zIndex: 1100 }}>
              <Box sx={{ fontSize: '16px', fontWeight: 700, backgroundColor: '#eee', borderRadius: '5px', px: '10px', height: 'fit-content' }}>
                {timeSection.timeSectionId}
              </Box>
              <MultiLangLabel nameObj={timeSection.name} />
            </Stack>
          </Tooltip>
          <Stack direction="column" mx="40px" my={1}>
            {timeSection.tableTypeList.map((tableType) => (
              <Card
                key={uuidv4()}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: 1,
                  gap: 1,
                  backgroundColor: '#f9f9f9',
                }}
              >
                <Stack direction="row" gap={1} alignItems="center">
                  <Stack direction="row" gap={1} alignItems="center" sx={{ width: '110px' }}>
                    <Box>
                      <Tooltip title="Table Type" followCursor>
                        <Box
                          sx={{
                            py: '5px',
                            px: '10px',
                            fontSize: '11px',
                            fontWeight: 700,
                            backgroundColor: tableType.foregroundColor,
                            color: tableType.backgroundColor,
                            width: 'fit-content',
                            borderRadius: '5px',
                          }}
                        >
                          {tableType.tableType}
                        </Box>
                      </Tooltip>
                    </Box>
                    {tableType.parentTableType && (
                      <>
                        <ArrowForward sx={{ color: '#808080', width: '15px' }} />
                        <Box sx={{ width: '50px' }}>
                          <Tooltip title="Parent Table Type" followCursor>
                            <Box
                              sx={{
                                py: '5px',
                                px: '10px',
                                fontSize: '11px',
                                fontWeight: 700,
                                backgroundColor: tableType.foregroundColor,
                                color: tableType.backgroundColor,
                                width: 'fit-content',
                                borderRadius: '5px',
                              }}
                            >
                              {tableType.parentTableType}
                            </Box>
                          </Tooltip>
                        </Box>
                      </>
                    )}
                  </Stack>
                  {tableType.name && <MultiLangLabel nameObj={tableType.name} />}
                  {tableType.sizeSelectable && (
                    <DataPair tooltip="Size" iconify="mdi:people" sx={{ width: '60px' }}>
                      {tableType.minSize}-{tableType.maxSize}
                    </DataPair>
                  )}
                  {displayType === 'default' && (
                    <>
                      <DataPair tooltip="Status" childrenType="status" sx={{ width: '30px' }}>
                        {tableType.status}
                      </DataPair>
                      {autoRequestTicketStatus && (
                        <DataPair
                          tooltip="Auto Request Ticket Status"
                          iconify="icon-park-outline:tickets-checked"
                          childrenType="boolean"
                          sx={{ width: '30px' }}
                        >
                          {!tableType.manual}
                        </DataPair>
                      )}
                    </>
                  )}
                </Stack>
                {displayType === 'default' && (
                  <Stack direction="row" gap={2}>
                    <Stack direction="row" gap={1.5}>
                      <MUILabel variant="ghost" color="secondary">
                        Kiosk
                      </MUILabel>
                      <DataPair tooltip="Status" childrenType="status" sx={{ width: '30px' }}>
                        {tableType.kioskStatus}
                      </DataPair>
                      <DataPair tooltip="Ticket Display" childrenType="boolean" iconify="carbon:ticket" sx={{ width: '30px' }}>
                        {tableType.kioskTicketDisplay}
                      </DataPair>
                      <DataPair
                        tooltip="Tag Display"
                        childrenType="boolean"
                        icon={<CallIcon style={{ width: '22px', height: '22px' }} />}
                        sx={{ width: '30px' }}
                      >
                        {tableType.kioskTagDisplay}
                      </DataPair>
                    </Stack>
                    <Stack direction="row" gap={1.5}>
                      <MUILabel variant="ghost" color="secondary">
                        Mobile
                      </MUILabel>
                      <DataPair tooltip="Status" childrenType="status" sx={{ width: '30px' }}>
                        {tableType.mobileStatus}
                      </DataPair>
                      <DataPair tooltip="Ticket Display" childrenType="boolean" iconify="carbon:ticket" sx={{ width: '30px' }}>
                        {tableType.mobileTicketDisplay}
                      </DataPair>
                      <DataPair
                        tooltip="Tag Display"
                        childrenType="boolean"
                        icon={<CallIcon style={{ width: '22px', height: '22px' }} />}
                        sx={{ width: '30px' }}
                      >
                        {tableType.mobileTagDisplay}
                      </DataPair>
                    </Stack>
                  </Stack>
                )}
              </Card>
            ))}
          </Stack>
        </Box>
      ))}
    </>
  )
}

const ProfileModal = memo(({ queueTransferProfile, queueProfile, counterProfile, title, username, id, onCopyId }) => {
  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', p: 1, gap: 1 }}>
      {id ? (
        <Tooltip title={id} followCursor>
          <Box onClick={() => onCopyId(id)} sx={{ cursor: 'pointer' }}>
            <Box sx={{ fontSize: '16px', fontWeight: 700 }}>{title}</Box>
          </Box>
        </Tooltip>
      ) : (
        <Box sx={{ fontSize: '16px', fontWeight: 700 }}>{title}</Box>
      )}
      <Stack direction="column" gap={1}>
        {username && (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
            <MUILabel variant="ghost" color="primary">
              Username
            </MUILabel>
            {username}
          </Box>
        )}
        {queueProfile && (
          <Box sx={{ display: 'flex', flexDirection: 'column', borderBottom: 'solid 1px #eee', gap: 1 }}>
            <Stack gap={1} direction="row">
              <MUILabel variant="ghost" color="success">
                Queue Profile
              </MUILabel>
              <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>{queueProfile.profileCode}</Typography>
            </Stack>
            {queueProfile.timeSectionList && queueProfile.timeSectionList.length > 0 && (
              <TimeSectionList timeSectionList={queueProfile.timeSectionList} displayType="queueProfile" />
            )}
          </Box>
        )}
        {queueTransferProfile && (
          <Box sx={{ display: 'flex', flexDirection: 'column', borderBottom: 'solid 1px #eee', gap: 1 }}>
            <Stack gap={1} direction="row">
              <MUILabel variant="ghost" color="warning">
                Queue Transfer Profile
              </MUILabel>
              <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>{queueTransferProfile.profileCode}</Typography>
            </Stack>
            {queueTransferProfile.timeSectionList &&
              (queueTransferProfile.timeSectionList.length > 0 &&
              queueTransferProfile.timeSectionList.some((timeSection) => timeSection.tableTypeList.length > 0) ? (
                <TimeSectionList timeSectionList={queueTransferProfile.timeSectionList} displayType="queueTransferProfile" />
              ) : (
                <Box sx={{ fontSize: '14px', fontWeight: 700 }}>No queue for today</Box>
              ))}
          </Box>
        )}
        {counterProfile && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Stack direction="row" gap={1}>
              <MUILabel variant="ghost" color="secondary">
                Counter Profile
              </MUILabel>
              <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>{counterProfile.profileCode}</Typography>
            </Stack>
            <Stack direction="column" gap={0.5}>
              {counterProfile.counterList.map((counter) => (
                <MultiLangLabel nameObj={counter.name} />
              ))}
            </Stack>
          </Box>
        )}
      </Stack>
    </Card>
  )
})

const Device = memo(({ deviceIdx, device, deviceListLength, onClickDeviceSetting, onCopyId }) => {
  const { queueTransferProfile, queueProfile, counterProfile, staff, type, name, counterName, id, appVersion, buildNumber, setting } = device
  const isDeviceProfile = queueTransferProfile || counterProfile || queueProfile
  const isStaffProfile = staff
  // console.log('render device')
  return (
    <Box
      key={id}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        p: 1,
        borderBottom: deviceIdx !== deviceListLength ? 'solid 1px #eee' : '',
        gap: 1,
        '&:hover': {
          backgroundColor: '#eef5fb',
        },
      }}
    >
      <Stack direction="column" gap={1}>
        <Box
          component="span"
          sx={{
            fontSize: '16px',
            fontWeight: 700,
            whiteSpace: 'pre-wrap',
            width: '200px',
            wordBreak: 'break-word',
            display: 'inline-flex',
            gap: 0.5,
          }}
        >
          {type === DEVICE_TYPE.counterKiosk && (
            <Tooltip title="COUNTER KIOSK" followCursor>
              <Box sx={{ position: 'relative', width: '22px', height: '22px' }}>
                <Iconify iconify="fluent:kiosk-24-filled" sx={{ mr: 1 }} />
              </Box>
            </Tooltip>
          )}
          {type === DEVICE_TYPE.kiosk && (
            <Tooltip title="KIOSK" followCursor>
              <Box>
                <Iconify iconify="fluent:kiosk-24-filled" sx={{ mr: 1 }} />
              </Box>
            </Tooltip>
          )}
          {type === DEVICE_TYPE.pr && (
            <Tooltip title="PR" followCursor>
              <Box>
                <Iconify iconify="icon-park:ipad" sx={{ mr: 1 }} />
              </Box>
            </Tooltip>
          )}
          {type === DEVICE_TYPE.tv && (
            <Tooltip title="TV" followCursor>
              <Box>
                <Iconify iconify="icon-park:tv-one" sx={{ mr: 1 }} />
              </Box>
            </Tooltip>
          )}
          {type === DEVICE_TYPE.pickupTV && (
            <Tooltip title="PICKUP TV" followCursor>
              <Box sx={{ position: 'relative', width: '22px', height: '22px' }}>
                <Iconify iconify="icon-park:tv-one" sx={{ mr: 1 }} />
              </Box>
            </Tooltip>
          )}
          {type === DEVICE_TYPE.counterTV && (
            <Tooltip title="COUNTER TV" followCursor>
              <Box sx={{ position: 'relative', width: '22px', height: '22px' }}>
                <Iconify iconify="icon-park:tv-one" sx={{ mr: 1 }} />
              </Box>
            </Tooltip>
          )}
          {type === DEVICE_TYPE.staffPR && (
            <Tooltip title="STAFF PR" followCursor>
              <Box sx={{ mr: 1, position: 'relative' }}>
                <Iconify iconify="icon-park:ipad" />
                <Iconify
                  iconify="octicon:feed-person-16"
                  sx={{
                    width: '14px',
                    height: '14px',
                    color: '#171717',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: '#fff',
                    borderRadius: '7px',
                  }}
                />
              </Box>
            </Tooltip>
          )}
          <Tooltip title={id} followCursor>
            <Box component="span" sx={{ height: 'fit-content', cursor: 'pointer' }} onClick={() => onCopyId(id)}>
              {name}
            </Box>
          </Tooltip>
        </Box>
        <Stack>
          <DataPair tooltip="App Version" iconify="ic:outline-app-shortcut" childrenType="default" iconifySx={{ width: '16px', height: '16px' }}>
            {appVersion}
          </DataPair>
          <DataPair
            tooltip="Build Number"
            iconify="material-symbols:build-circle"
            childrenType="default"
            iconifySx={{ width: '15px', height: '15px' }}
          >
            {buildNumber}
          </DataPair>
          {setting && (
            <Tooltip title="Click To Show Device Setting" followCursor>
              <Box onClick={() => onClickDeviceSetting(JSON.parse(setting))}>
                <Iconify iconify="si:json-duotone" sx={{ width: '16px', height: '16px', cursor: 'pointer' }} />
              </Box>
            </Tooltip>
          )}
        </Stack>
      </Stack>
      <Stack direction="column" gap={0.5}>
        {counterName && (
          <Card sx={{ display: 'flex', flexDirection: 'row', p: 1, gap: 1 }}>
            <Box sx={{ fontSize: '16px', fontWeight: 700 }}>Counter Name</Box>
            <MultiLangLabel nameObj={counterName} />
          </Card>
        )}
        <Stack direction="row">
          {isDeviceProfile && (
            <ProfileModal
              queueTransferProfile={queueTransferProfile}
              queueProfile={queueProfile}
              counterProfile={counterProfile}
              title="Device"
              onCopyId={onCopyId}
            />
          )}
          {isStaffProfile && (
            <ProfileModal
              queueTransferProfile={staff.queueTransferProfile}
              queueProfile={staff.queueProfile}
              counterProfile={staff.counterProfile}
              title="Staff"
              username={staff.username}
              id={staff.id}
              onCopyId={onCopyId}
            />
          )}
        </Stack>
      </Stack>
    </Box>
  )
})

const DeviceList = memo(({ filteredDeviceList, onCopyId, onClickDeviceSetting }) => {
  if (!filteredDeviceList) {
    return <Box sx={{ p: 2 }}>No Data</Box>
  }

  return (
    <>
      {filteredDeviceList.map((device, deviceIdx) => (
        <Device
          key={device.id}
          device={device}
          deviceIdx={deviceIdx}
          deviceListLength={filteredDeviceList.length}
          onClickDeviceSetting={onClickDeviceSetting}
          onCopyId={onCopyId}
        />
      ))}
    </>
  )
})

export default function QueueDashboard() {
  const [tabIdx, setTabIdx] = useState(0)
  const store = useStore()
  const [data, setData] = useState({})
  const [isInitiated, setIsInitiated] = useState(false)
  const { id: restUrlId } = useParams()
  const [copied, setCopied] = useState(false)
  const [deviceSetting, setDeviceSetting] = useState({})
  const [filterDeviceName, setFilterDeviceName] = useState('')
  const [filterDeviceType, setFilterDeviceType] = useState('')
  const [filterStaff, setFilterStaff] = useState('')
  const [debouncedFilterDeviceName, setDebouncedFilterDeviceName] = useState(filterDeviceName)
  const [debouncedFilterDeviceType, setDebouncedFilterDeviceType] = useState(filterDeviceType)
  const [debouncedFilterStaff, setDebouncedFilterStaff] = useState(filterStaff)
  const [filteredDeviceList, setFilteredDeviceList] = useState([])

  const {
    queueStatus,
    heartbeat,
    autoTask,
    autoSwitchTimeSection,
    autoRequestTicketStatus,
    currentTimeSection,
    timeSectionList,
    deviceList,
    resetTimestamp,
  } = data

  const onClickDeviceSetting = useCallback((newDeviceSetting) => {
    setDeviceSetting(newDeviceSetting)
  }, [])

  const onCopyId = useCallback((id) => {
    navigator.clipboard.writeText(id)
    setCopied(true)
  }, [])

  const getDashboard = useCallback(async () => {
    getOne(
      `shop/queue/dashboard/${restUrlId}`,
      undefined,
      (payload) => {
        setIsInitiated(false)
        setData(payload)
        setIsInitiated(true)
      },
      undefined,
      store
    )
  }, [restUrlId, store])

  const onChangeTab = useCallback(
    async (e, newValue) => {
      setIsInitiated(false)
      await getDashboard()
      setTabIdx(newValue)
    },
    [getDashboard]
  )

  const onChangeFilterDeviceName = useCallback((e) => {
    setFilterDeviceName(e.target.value)
  }, [])

  const onChangeFilterDeviceType = useCallback((option) => {
    setFilterDeviceType(option?.value || '')
  }, [])

  const onChangeFilterStaff = useCallback((e) => {
    setFilterStaff(e.target.value)
  }, [])

  const onCloseSnackbar = useCallback(() => {
    setCopied(false)
  }, [])

  const onCloseDialog = useCallback(() => {
    setDeviceSetting({})
  }, [])

  const getFilteredDeviceList = useCallback(() => {
    const newFilteredDeviceList = deviceList?.filter((device) => {
      const deviceName = device?.name?.toLowerCase() || ''
      const deviceType = device?.type || ''
      const staffUsername = device?.staff?.username?.toLowerCase() || ''

      return (
        deviceName.includes(debouncedFilterDeviceName.toLowerCase()) &&
        (debouncedFilterDeviceType ? deviceType === debouncedFilterDeviceType : true) &&
        staffUsername.includes(debouncedFilterStaff.toLowerCase())
      )
    })

    setFilteredDeviceList(newFilteredDeviceList)
  }, [deviceList, debouncedFilterDeviceName, debouncedFilterDeviceType, debouncedFilterStaff])

  useEffect(() => {
    getDashboard()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handler = debounce(() => {
      setDebouncedFilterDeviceName(filterDeviceName)
      setDebouncedFilterDeviceType(filterDeviceType)
      setDebouncedFilterStaff(filterStaff)
    }, 200)

    handler()

    return () => {
      handler.cancel()
    }
  }, [filterDeviceName, filterDeviceType, filterStaff])

  useEffect(() => {
    getFilteredDeviceList()
  }, [getFilteredDeviceList, debouncedFilterDeviceName, debouncedFilterDeviceType, debouncedFilterStaff])

  const StatusCard = memo(
    ({ currentTimeSection, queueStatus, heartbeat, autoTask, autoSwitchTimeSection, autoRequestTicketStatus, resetTimestamp }) => {
      return (
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'row',
            p: 2,
            gap: 2,
            boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
            position: 'sticky',
            top: 0,
            zIndex: 1100,
            borderRadius: '8px',
          }}
        >
          {currentTimeSection && (
            <>
              <Stack direction="row" gap={2}>
                <Box sx={{ fontSize: '16px', fontWeight: 700 }}>Current Time Section</Box>
                <Tooltip title="Time Section Id" followCursor>
                  <Box sx={{ fontSize: '16px', fontWeight: 700, backgroundColor: '#eee', borderRadius: '5px', px: '10px' }}>
                    {currentTimeSection.timeSectionId}
                  </Box>
                </Tooltip>
                <MultiLangLabel nameObj={currentTimeSection.name} itemWidth="fit-content" />
              </Stack>
              <Divider orientation="vertical" flexItem />
            </>
          )}
          <Stack direction="row" gap={2}>
            <Box sx={{ fontSize: '16px', fontWeight: 700 }}>Status</Box>
            <Stack direction="row" gap={2.5}>
              <DataPair tooltip="Queue Status" iconify="mdi:human-queue" childrenType="status">
                {queueStatus}
              </DataPair>
              <DataPair tooltip="Heartbeat" iconify="tabler:heartbeat" childrenType="status">
                {heartbeat}
              </DataPair>
              <DataPair tooltip="Auto Task" iconify="fluent:clipboard-task-list-ltr-24-regular" childrenType="boolean">
                {autoTask}
              </DataPair>
              <DataPair tooltip="Auto Switch Time Section" iconify="mdi:tool-time" childrenType="boolean">
                {autoSwitchTimeSection}
              </DataPair>
              <DataPair tooltip="Auto Request Ticket Status" iconify="icon-park-outline:tickets-checked" childrenType="boolean">
                {autoRequestTicketStatus}
              </DataPair>
              <DataPair tooltip="Reset Timestamp" iconify="lucide:timer-reset">
                {resetTimestamp ? moment(resetTimestamp).format('YYYY-MM-DD HH:mm:ss') : ''}
              </DataPair>
            </Stack>
          </Stack>
        </Card>
      )
    }
  )

  return (
    <Box sx={{ height: '75vh', display: 'flex', flexDirection: 'column' }}>
      {/* <Fab color="primary" sx={{ position: 'fixed', right: 20, bottom: 0 }} onClick={scrollToTop}>
          <Iconify icon="qlementine-icons:go-top-16" sx={{ color: '#fff' }} />
        </Fab> */}
      <StatusCard
        currentTimeSection={currentTimeSection}
        queueStatus={queueStatus}
        heartbeat={heartbeat}
        autoTask={autoTask}
        autoSwitchTimeSection={autoSwitchTimeSection}
        autoRequestTicketStatus={autoRequestTicketStatus}
        resetTimestamp={resetTimestamp}
      />
      <Tabs value={tabIdx} onChange={onChangeTab} aria-label="Queue Dashboard Tabs" sx={{ position: 'sticky', top: '54px', backgroundColor: '#fff' }}>
        <Tab label="Time Section List" sx={{ fontSize: '14px', fontWeight: 700, textTransform: 'none' }} />
        <Tab label="Device List" sx={{ fontSize: '14px', fontWeight: 700, textTransform: 'none' }} />
      </Tabs>
      {tabIdx === 1 && isInitiated && (
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'row',
            p: 1,
            gap: 1,
            boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
            borderRadius: '8px',
            position: 'sticky',
            top: 0,
            zIndex: 100,
          }}
        >
          <ThreeItemsGridContainer>
            <MUISelectField
              label="Device Type"
              variant="outlined"
              size="small"
              onChange={onChangeFilterDeviceType}
              value={filterDeviceType}
              options={DEVICE_OPTION}
              showClearAdornment
            />
            <MUITextField
              label="Device Name"
              variant="outlined"
              size="small"
              onChange={onChangeFilterDeviceName}
              value={filterDeviceName}
              showClearAdornment
            />
            <MUITextField label="Staff" variant="outlined" size="small" onChange={onChangeFilterStaff} value={filterStaff} showClearAdornment />
          </ThreeItemsGridContainer>
        </Card>
      )}
      <Box sx={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
        {tabIdx === 0 && isInitiated && <TimeSectionList timeSectionList={timeSectionList} autoRequestTicketStatus={autoRequestTicketStatus} />}
        {tabIdx === 1 && isInitiated && (
          <DeviceList filteredDeviceList={filteredDeviceList} onCopyId={onCopyId} onClickDeviceSetting={onClickDeviceSetting} />
        )}
      </Box>
      <Snackbar open={copied} onClose={onCloseSnackbar} autoHideDuration={1500} message="Copied to clipboard" />
      {Object.keys(deviceSetting).length > 0 && (
        <SharedDialog
          onClose={onCloseDialog}
          title="Device Setting"
          open={true}
          maxWidth="sm"
          content={
            <Box sx={{ overflow: 'auto', whiteSpace: 'pre-wrap', wordBreak: 'break-word', width: '100%' }}>
              {Object.entries(deviceSetting).map(([key, value]) => (
                <Stack direction="row" gap={2} key={key}>
                  <Box sx={{ fontWeight: 600 }}>{key}</Box>
                  <Box>{`${value}`}</Box>
                </Stack>
              ))}
            </Box>
          }
        />
      )}
    </Box>
  )
}
