import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import moment from 'moment';

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CancelIcon from '@mui/icons-material/Cancel';
import CachedIcon from '@mui/icons-material/Cached';
import { Drawer } from "@mui/material";
import { Card } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { constants, QUEUE_TEMPLATE_MAP_TYPE } from '../../Constants/constants';
import { post } from "../../helper/baseFetch";
import { setQueueTemplateMapList } from "../../actions/queueTemplateMapAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { TempBtn } from "../../Component/Input/TempBtn"
import { QueueTemplateList } from "../QueueTemplate/QueueTemplateList";
import QueueTemplateMapValidation from "../../validations/queueTemplateMapValidation";
import { queueTemplateMapValidationConstants } from '../../Constants/validationConstants';
import { GlobalDialog } from "../../Component/GlobalDialog";
import { TempCheckBox } from "../../Component/Input/TempCheckBox";
import useRestaurant from "../../hooks/Restaurant/useRestaurant";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const QueueTemplateMapCreate = ({ toolBar, mapName, mapCode, tab }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const params = useParams();
  const [clear, setClear] = useState(false);
  const savedQueueTemplateMapList = useSelector(state => state.queueTemplateMap.ui.queueTemplateMapList);
  const [showTemplateCodePanel, setShowTemplateCodePanel] = useState(false);
  const [drawerIndex, setDrawerIndex] = useState("");
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const [customIdIndex, setCustomIdIndex] = useState(0);
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(QueueTemplateMapValidation.getVersion());
  const [ duplicateDayError, setDuplicateDayError ] = useState(new Map());

  const resource = "QueueTemplateMap";
  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.QUEUE_TEMPLATE_MAP_TAB.INFO
    }
  ]

  const handleSelectAll = (index) => {
    let tempQueueTemplateMap = _.cloneDeep(savedQueueTemplateMapList);
    tempQueueTemplateMap.forEach((tempQueueTemplateMapValue, tempQueueTemplateMapIndex) => {
      if (index === tempQueueTemplateMapIndex) {
        tempQueueTemplateMapValue.dayOfWeekList = constants.CHOICES.QUEUE_TEMPLATE_MAP.DAY_OF_WEEK.map((day)=>day.value);
      }})
    dispatch(setQueueTemplateMapList(tempQueueTemplateMap));
  }

  const handleClearAll = (index) => {
    let tempQueueTemplateMap = _.cloneDeep(savedQueueTemplateMapList);
    tempQueueTemplateMap.forEach((tempQueueTemplateMapValue, tempQueueTemplateMapIndex) => {
      if (index === tempQueueTemplateMapIndex) {
        tempQueueTemplateMapValue.dayOfWeekList = [];
      }})
    dispatch(setQueueTemplateMapList(tempQueueTemplateMap));
  }

  const handleAddWeekday = (index) => {
    let tempQueueTemplateMap = _.cloneDeep(savedQueueTemplateMapList);
    tempQueueTemplateMap.forEach((tempQueueTemplateMapValue, tempQueueTemplateMapIndex) => {
      if (index === tempQueueTemplateMapIndex) {
        tempQueueTemplateMapValue.dayOfWeekList = [...WEEKDAY];
      }})
    dispatch(setQueueTemplateMapList(tempQueueTemplateMap));
  }

  const handleAddHoliday = (index) => {
    let tempQueueTemplateMap = _.cloneDeep(savedQueueTemplateMapList);
    tempQueueTemplateMap.forEach((tempQueueTemplateMapValue, tempQueueTemplateMapIndex) => {
      if (index === tempQueueTemplateMapIndex) {
        tempQueueTemplateMapValue.dayOfWeekList = [...HOLIDAY];
      }})
    dispatch(setQueueTemplateMapList(tempQueueTemplateMap));
  }

  const generateDescription = (item, idx) => {
    const description =  moment(item.startDate).format('YYYY/MM/DD') + ' - ' + moment(item.endDate).format('YYYY/MM/DD');

    onChangeInput('description', description, idx)
  }

  const content = [
    {
      tab: <>
        <TempBtn btnSetting={{
          variant: constants.STYLE.VARIANT.CONTAINED,
          color: constants.STYLE.PRIMARY,
          label: { resource: resource, key: "addQueueTemplateMap" },
          disabled: false,
          onClick: () => handleAddQueueTemplateMap(),
          name: "handleAddQueueTemplateMap",
          type: constants.TYPE.BUTTON,
          icon: <AddIcon />,
        }} />
        {
          savedQueueTemplateMapList.map((item, index) => {
            return <Card className="card-wrapper card" key={index}>
              <div className="delete-icon-row">
                <TempBtn btnSetting={{
                  className: "edit-create-btn",
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: () => handleDelete(index),
                  label: { key: "deleteBtn" },
                  icon: <DeleteIcon />,
                }} />
              </div>
              <div className="delete-icon-row">
                <TempBtn btnSetting={{
                  className: "edit-create-btn",
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: () => handleClone(index),
                  label: { key: "Clone" },
                  icon: <FileCopyIcon />,
                }} />
              </div>
                            <div>
                <TempTextInput
                  inputSetting={{
                    className: "edit-create-input",
                    size: constants.SIZE.SMALL,
                    label: { resource: resource, key: "templateCode" },
                    value: item.templateCode,
                    onClickEndAdornmentIconButton: () => { handleTemplateCodeClick(); setDrawerIndex(index); },
                    name: "templateCode",
                    disabled: true,
                    multiline: true,
                    helperTextIcon: true,
                    helperText: QueueTemplateMapValidation.getErrorMessages(queueTemplateMapValidationConstants.KEY_TEMPLATE_CODE, item.customId).map((error) => { return error }),
                    FormHelperTextProps: { className: helperTextStylesClasses.root },
                  }}
                  handleChange={onChangeInput}
                  index={index}
                />
                <TempTextInput
                  inputSetting={{
                    className: "edit-create-input",
                    variant: constants.STYLE.VARIANT.STANDARD,
                    size: constants.SIZE.SMALL,
                    label: { resource: resource, key: "templateDescription" },
                    value: item.templateDescription,
                    name: "templateDescription",
                    disabled: true,
                    multiline: true,
                  }}
                  handleChange={onChangeInput}
                  index={index}
                />
              </div>
              <div>
                <TempAutoSelectInput
                  inputSetting={{
                    className: "edit-create-input",
                    size: constants.SIZE.SMALL,
                    label: { resource: resource, key: "type" },
                    value: item.type,
                    type: constants.TYPE.SELECT,
                    selectValue: constants.CHOICES.QUEUE_TEMPLATE_MAP.TYPE,
                    name: "type",
                    disableClearable: true,
                    helperTextIcon: true,
                    helperText: QueueTemplateMapValidation.getErrorMessages(queueTemplateMapValidationConstants.KEY_TYPE, item.customId).map((error) => { return error }),
                    FormHelperTextProps: { className: helperTextStylesClasses.root },
                  }}
                  handleChange={onChangeInput}
                  index={index}
                />
                {/* {item.type === QUEUE_TEMPLATE_MAP_TYPE.DAY_OF_WEEK &&
                  <TempAutoSelectInput
                    inputSetting={{
                      className: "edit-create-input",
                      size: constants.SIZE.SMALL,
                      label: { resource: resource, key: "dayOfWeek" },
                      value: item.dayOfWeek,
                      type: constants.TYPE.SELECT,
                      selectValue: constants.CHOICES.QUEUE_TEMPLATE_MAP.DAY_OF_WEEK,
                      disableClearable: true,
                      name: "dayOfWeek",
                      helperTextIcon: true,
                      helperText: QueueTemplateMapValidation.getErrorMessages(queueTemplateMapValidationConstants.KEY_DAY_OF_WEEK, item.customId).map((error) => { return error }),
                      FormHelperTextProps: { className: helperTextStylesClasses.root },
                    }}
                    handleChange={onChangeInput}
                    index={index}
                  />
                } */}
                {item.type === QUEUE_TEMPLATE_MAP_TYPE.DATE_RANGE &&
                  <>
                    <TempTextInput
                      inputSetting={{
                        className: "edit-create-input",
                        label: { resource: resource, key: "startDate" },
                        value: item.startDate ? moment(item.startDate).format('YYYY-MM-DD') : "",
                        name: "startDate",
                        type: constants.TYPE.DATE,
                        size: constants.SIZE.SMALL,
                        helperTextIcon: true,
                        helperText: QueueTemplateMapValidation.getErrorMessages(queueTemplateMapValidationConstants.KEY_START_DATE, item.customId).map((error) => { return error }),
                        FormHelperTextProps: { className: helperTextStylesClasses.root },
                      }}
                      handleChange={onChangeInput}
                      index={index}
                    />
                    <TempTextInput
                      inputSetting={{
                        className: "edit-create-input",
                        label: { resource: resource, key: "endDate" },
                        value: item.endDate ? moment(item.endDate).format('YYYY-MM-DD') : "",
                        name: "endDate",
                        type: constants.TYPE.DATE,
                        size: constants.SIZE.SMALL,
                        helperTextIcon: true,
                        helperText: QueueTemplateMapValidation.getErrorMessages(queueTemplateMapValidationConstants.KEY_END_DATE, item.customId).map((error) => { return error }),
                        FormHelperTextProps: { className: helperTextStylesClasses.root },
                      }}
                      handleChange={onChangeInput}
                      index={index}
                    />
                  </>
                }
              </div>
              <div>
              {item.type === QUEUE_TEMPLATE_MAP_TYPE.DAY_OF_WEEK &&
              <>
                {
                  duplicateDayError.has(item.customId) &&
                  <div className="error-message">
                    <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                    {duplicateDayError.get(item.customId)}
                  </div>
                }
                <div className="flex-display-wrapper">
                  {constants.CHOICES.QUEUE_TEMPLATE_MAP.DAY_OF_WEEK.map((day)=>{
                    return <TempCheckBox 
                        key={day.value}
                        checkBoxSetting={{
                          className: "edit-display-wrapper check-box-md",
                          name: "dayOfWeekList",
                          label: { resource: resource, key: day.name },
                          value: item.dayOfWeekList.includes(day.value),
                          key: day.value
                        }}
                        handleChange={onChangeInput}
                        index={index}
                      />
                    })}
                </div>
                <div className="flex-display-wrapper" style={{paddingTop: "20px"}}>
                    <TempBtn btnSetting={{
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: () => handleSelectAll(index),
                      icon: <AddIcon />,
                      label: { resource: resource, key: "selectAll" },
                      }} />
                    <TempBtn btnSetting={{
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: () => handleAddWeekday(index),
                      icon: <AddIcon />,
                      label: { resource: resource, key: "weekday" },
                      }} />
                    <TempBtn btnSetting={{
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: () => handleAddHoliday(index),
                      icon: <AddIcon />,
                      label: { resource: resource, key: "holidays" },
                      }} />
                    <TempBtn btnSetting={{
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: () => handleClearAll(index),
                      icon: <ClearIcon />,
                      label: { resource: resource, key: "clearAll" },
                      }} />
                </div>
                </>
                }
                {
                  item.type === QUEUE_TEMPLATE_MAP_TYPE.DATE_RANGE &&
                  <TempTextInput
                    inputSetting={{
                      className: "edit-create-input",
                      size: constants.SIZE.SMALL,
                      label: { resource: resource, key: "description" },
                      value: item.description,
                      name: "description",
                      multiline: true,
                      onClickEndAdornmentIconButton: ()=>generateDescription(item, index),
                      endAdornmentIconButton: <CachedIcon/>, 
                      disabledOnClickInputField: true,
                      disabledEndAdornmentIconButton: (!item.startDate || !item.endDate || item.type !== QUEUE_TEMPLATE_MAP_TYPE.DATE_RANGE),
                    }}
                    handleChange={onChangeInput}
                    index={index}
                  />
                }
              </div>
            </Card>
          })
        }
      </>,
      btn: true,
    },
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />,
      disabled: !params.restUrlId || savedQueueTemplateMapList.length === 0,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  const onClickListRow = (data) => {
    onChangeInput("templateCode", data, drawerIndex)
    setShowTemplateCodePanel(false);
  }

  const handleTemplateCodeClick = () => {
    setShowTemplateCodePanel(true);
  };

  const handleTemplateCodeCloseClick = () => {
    setShowTemplateCodePanel(false);
  };

  function handleAddQueueTemplateMap() {
    const tempQueueTemplateMap = _.cloneDeep(savedQueueTemplateMapList);
    const newQueueTemplateMap = _.cloneDeep(constants.QUEUE_TEMPLATE_MAP_INPUT);
    newQueueTemplateMap.customId = "QUEUE_TEMPLATE_MAP_" + customIdIndex;
    setCustomIdIndex(customIdIndex + 1)
    tempQueueTemplateMap.push(newQueueTemplateMap)
    dispatch(setQueueTemplateMapList(tempQueueTemplateMap));
  }

  function handleAddQueueTemplateMapDayOfWeek(dayOfWeek, type) {
    const tempQueueTemplateMap = [];
    const newQueueTemplateMap = _.cloneDeep(constants.QUEUE_TEMPLATE_MAP_INPUT);
    newQueueTemplateMap.customId = "QUEUE_TEMPLATE_MAP_" + customIdIndex;
    newQueueTemplateMap.type = type;
    if(dayOfWeek) {
      newQueueTemplateMap.dayOfWeekList = [dayOfWeek];
    }

    setCustomIdIndex(customIdIndex + 1)
    tempQueueTemplateMap.push(newQueueTemplateMap)
    dispatch(setQueueTemplateMapList(tempQueueTemplateMap));
  }

  function handleDelete(index) {
    let tempQueueTemplateMap = _.cloneDeep(savedQueueTemplateMapList);
    tempQueueTemplateMap = tempQueueTemplateMap.filter((tempQueueTemplateMapValue, tempQueueTemplateMapIndex) =>
      tempQueueTemplateMapIndex !== index
    )
    dispatch(setQueueTemplateMapList(tempQueueTemplateMap));
  }

  function handleClone(index) {
    let tempQueueTemplateMap = _.cloneDeep(savedQueueTemplateMapList);
    let cloneQueueTemplateMap = _.cloneDeep(tempQueueTemplateMap.find((tempQueueTemplateMapValue, tempQueueTemplateMapIndex) =>
      tempQueueTemplateMapIndex === index
    ))
    cloneQueueTemplateMap.customId = "QUEUE_TEMPLATE_MAP_" + customIdIndex;
    setCustomIdIndex(customIdIndex + 1)
    tempQueueTemplateMap.push(cloneQueueTemplateMap);
    dispatch(setQueueTemplateMapList(tempQueueTemplateMap));
  }

  function onChangeInput(name, e, index, key) {
    // console.log("onChangeInput", name, e, index, key)
    const tempQueueTemplateMap = _.cloneDeep(savedQueueTemplateMapList);
    tempQueueTemplateMap.map((tempQueueTemplateMapValue, tempQueueTemplateMapIndex) => {
      if (index === tempQueueTemplateMapIndex) {
        switch (name) {
          case "startDate":
          case "endDate":
            tempQueueTemplateMapValue[`${name}`] = e ? Number(moment(e).format('x')) : null;
            break;
          case "type":
            tempQueueTemplateMapValue[`${name}`] = e;
            tempQueueTemplateMapValue["dayOfWeek"] = null;
            tempQueueTemplateMapValue["startDate"] = null;
            tempQueueTemplateMapValue["endDate"] = null;
            break;
          case "templateCode":
            tempQueueTemplateMapValue[`${name}`] = e.templateCode;
            tempQueueTemplateMapValue["templateDescription"] = e.description;
            break;
          case "dayOfWeekList":
            if(e) {
              tempQueueTemplateMapValue.dayOfWeekList = tempQueueTemplateMapValue.dayOfWeekList.filter(item=>item!==key);
            } else {
              tempQueueTemplateMapValue.dayOfWeekList.push(key);
            }
            break;
          default:
            tempQueueTemplateMapValue[`${name}`] = e;
        }
      }
      return tempQueueTemplateMapValue;
    })
    dispatch(setQueueTemplateMapList(tempQueueTemplateMap));
  }

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + "/" + params.restUrlId + "/" + constants.RESTAURANT_TAB.QUEUE + "/" + constants.RESTAURANT_QUEUE_SUB_TAB.QUEUE_TEMPLATE_MAP);
  };

  const hasInvalidDay = (queueTemplateMapList) => {

    const queueTemplateMapForUniqueDay = new Set();
    let invalid = false;

    queueTemplateMapList.forEach((template)=>{

      if(template.type === QUEUE_TEMPLATE_MAP_TYPE.DATE_RANGE) {
        return;
      }

      if(template.dayOfWeekList.length === 0) {
        const tempMap = _.cloneDeep(duplicateDayError);
        tempMap.set(template.customId, 'Please select at least one day');
        setDuplicateDayError(tempMap);
        invalid = true;
        return;
      }

      template.dayOfWeekList.forEach((day)=>{
        if(queueTemplateMapForUniqueDay.has(`${template.templateCode}-${day}`)) {
          const tempMap = _.cloneDeep(duplicateDayError);
          tempMap.set(template.customId, 'Duplicate Day For This Template');
          setDuplicateDayError(tempMap);
          invalid = true;
        } else {
          queueTemplateMapForUniqueDay.add(`${template.templateCode}-${day}`)
        }
      })
    })

    return invalid;
    
  }

  const getFormattedList = (queueTemplateMapList) => {

    let tempsavedQueueTemplateMapList = _.cloneDeep(savedQueueTemplateMapList);
    const temptFormattedList = [];

    tempsavedQueueTemplateMapList.forEach((template)=>{

      if(template.type === QUEUE_TEMPLATE_MAP_TYPE.DATE_RANGE) {
        const temptTemplate = {...template};
        if(!temptTemplate.description) {
          temptTemplate.description = moment(temptTemplate.startDate).format('MM/DD/YYYY') + ' - ' + moment(temptTemplate.endDate).format('MM/DD/YYYY');
        }
        temptFormattedList.push(temptTemplate);
        return;
      }

      if(template.dayOfWeekList.length === 1) {

        const temptTemplate = {...template};
        temptTemplate.dayOfWeek = template.dayOfWeekList[0];
        temptFormattedList.push(temptTemplate);

        return;
      }

      template.dayOfWeekList.forEach((day)=>{
        const temptTemplate = {...template};

        if(temptTemplate.description) {
          temptTemplate.description += " "+day;
        }else {
          temptTemplate.description += day;
        }
        temptTemplate.dayOfWeek = day;
        temptFormattedList.push(temptTemplate);
      })
    })

    return temptFormattedList;

  }
  

  function onClickSave() {

    if(hasInvalidDay(savedQueueTemplateMapList)) {
      return
    }

    let tempsavedQueueTemplateMapList = _.cloneDeep(getFormattedList(savedQueueTemplateMapList));
    let newSavedQueueTemplateMapList = {
      restUrlId: params.restUrlId,
      queueTemplateMapList: tempsavedQueueTemplateMapList
    }

    QueueTemplateMapValidation.validateTemplate(tempsavedQueueTemplateMapList);

    if (QueueTemplateMapValidation.isValid()) {
      post(constants.PATH.SHOP.QUEUE.QUEUE_TEMPLATE_MAP_LIST, newSavedQueueTemplateMapList, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: "createSuccess" },
          dialogActions: [
            { label: { key: "backCreate" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      QueueTemplateMapValidation.resetErrorMessages();
    } else {
      setErrorVersion(QueueTemplateMapValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {

    const search = window.location.search;
    const query = new URLSearchParams(search);

    if(query.get('dayOfWeek') && (WEEKDAY.includes(query.get('dayOfWeek')) || HOLIDAY.includes(query.get('dayOfWeek')))) {
      handleAddQueueTemplateMapDayOfWeek(query.get('dayOfWeek'), QUEUE_TEMPLATE_MAP_TYPE.DAY_OF_WEEK);
    } else if (query.get('type') && (QUEUE_TEMPLATE_MAP_TYPE_LIST.includes(query.get('type')))) {
      handleAddQueueTemplateMapDayOfWeek(null, query.get('type'));
    } else {
      dispatch(setQueueTemplateMapList(constants.QUEUE_TEMPLATE_MAP_LIST));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if(!clear) {
      return;
    }

    dispatch(setQueueTemplateMapList(constants.QUEUE_TEMPLATE_MAP_LIST));
    setClear(false)
    QueueTemplateMapValidation.resetErrorMessages();
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  const { getRestaurantIdAndName } = useRestaurant(params.restUrlId)
  const displayMapCode = getRestaurantIdAndName()

  return (
    <>
      <EditCreateDisplay
        code={""}
        resource={resource}
        tabs={toolBar !== false ? tabs : ""}
        content={content}
        bottomBtns={bottomBtns}
        mapName={{ resource: "restaurant", key: "restUrlId" }}
        mapCode={displayMapCode}
        display="create"
      />
      <Drawer
        classes={{ paper: "drawer-margin-left" }}
        anchor="right"
        open={showTemplateCodePanel}
        onClose={handleTemplateCodeCloseClick}
      >
        <QueueTemplateList
          editBtn={false}
          createBtn={false}
          btnList={false}
          newFilter={{id: params.restUrlId}}
          onClickListRow={(item) => onClickListRow(item)}
          dialogFooterBtn={<>
            <TempBtn btnSetting={{
              variant: constants.STYLE.VARIANT.CONTAINED,
              color: constants.STYLE.PRIMARY,
              onClick: handleTemplateCodeCloseClick,
              icon: <ClearIcon />,
              label: { key: "close" },
            }} />
          </>
          } />
      </Drawer>
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};

const WEEKDAY = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
const HOLIDAY = ['Sat', 'Sun', 'PH'];
const QUEUE_TEMPLATE_MAP_TYPE_LIST = [
  'DAY_OF_WEEK', 'DATE_RANGE'
]