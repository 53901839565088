export const API_PATH = {
  urlMap: 'url_map',
  region: 'shop/region',
  city: 'shop/city',
  regionStatus: 'shop/region_status',
  cityStatus: 'shop/city_status',
}

export const ROUTER_PATH = {
  urlMap: '/url_map',
}
