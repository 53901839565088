import { configureStore, createStore } from '@reduxjs/toolkit'

import restaurantReducer from './reducers/restaurantReducer'
import systemReducer from './reducers/systemReducer'
import langReducer from './reducers/langReducer'
import themeReducer from './reducers/themeReducer'
import productReducer from './reducers/productReducer'
import productMapReducer from './reducers/productMapReducer'
import BrandReducer from './reducers/brandReducer'
import BrandGroupReducer from './reducers/brandGroupReducer'
import restaurantMediaReducer from './reducers/restaurantMediaReducer'
import restaurantTagReducer from './reducers/restaurantTagReducer'
import restaurantTagMapReducer from './reducers/restaurantTagMapReducer'
import urlMapReducer from './reducers/urlMapReducer'
import folderReducer from './reducers/folderReducer'
import folderMapReducer from './reducers/folderMapReducer'
import configMapReducer from './reducers/configMapReducer'
import bannerReducer from './reducers/bannerReducer'
import pushReducer from './reducers/pushReducer'
import areaReducer from './reducers/areaReducer'
import districtReducer from './reducers/districtReducer'
import landmarkReducer from './reducers/landmarkReducer'
import statusReducer from './reducers/statusReducer'
import queueConfigReducer from './reducers/queueConfigReducer'
import printConfigReducer from './reducers/printConfigReducer'
import adminDeviceReducer from './reducers/adminDeviceReducer'
import campaignDescriptionReducer from './reducers/campaignDescriptionReducer'
import tncReducer from './reducers/tncReducer'
import ticketTypeReducer from './reducers/ticketTypeReducer'
import queueTemplateReducer from './reducers/queueTemplateReducer'
import queueTemplateMapReducer from './reducers/queueTemplateMapReducer'
import printTemplateReducer from './reducers/printTemplateReducer'
import emailTemplateReducer from './reducers/emailTemplateReducer'
import paymentReducer from './reducers/paymentReducer'
import restaurantApproveReducer from './reducers/restaurantApproveReducer'
import brandApproveReducer from './reducers/brandApproveReducer'
import merchantInfoReducer from './reducers/merchantInfoReducer'
import reservationConfigReducer from './reducers/reservationConfigReducer'
import reservationTemplateReducer from './reducers/reservationTemplateReducer'
import reservationTemplateMapReducer from './reducers/reservationTemplateMapReducer'
import chargeDateReducer from './reducers/chargeDateReducer'
import merchantUserReducer from './reducers/merchantUserReducer'
import merchantSalesUserReducer from './reducers/merchantSalesUserReducer'
import merchantConfigReducer from './reducers/merchantConfigReducer'
import changeRemindReducer from './reducers/changeRemindReducer'
import contactUsReducer from './reducers/contactUsReducer'
import merchantShopReducer from './reducers/merchantShopReducer'
import selectTagReducer from './reducers/selectTagReducer'
import selectTagConfigReducer from './reducers/selectTagConfigReducer'
import selectTagConfigOptionsReducer from './reducers/selectTagConfigOptionsReducer'
import landingSectionReducer from './reducers/landingSectionReducer'
import landingItemEditReducer from './reducers/landingItemEditReducer'
import systemGroupReducer from './reducers/systemGroupReducer'
import systemUserReducer from './reducers/systemUserReducer'
import merchantUserGroupReducer from './reducers/merchantUserGroupReducer'
import restaurantPayment from './reducers/restaurantPayment'
import restaurantBlackoutReducer from './reducers/restaurantBlackoutReducer'
import restaurantSlideshowReducer from './reducers/restaurantSlideshowReducer'
import queueProfileReducer from './reducers/queueProfileReducer'
import queueTransferProfileReducer from './reducers/queueTransferProfileReducer'
import counterProfileReducer from './reducers/counterProfileReducer'
import counterReducer from './reducers/counterReducer'
import staffReducer from './reducers/staffReducer'
import merchantDeviceReducer from './reducers/merchantDeviceReducer'
import landmarkRestaurantMapReducer from './reducers/landmarkRestaurantMapReducer'
import regionReducer from './reducers/regionReducer'

export default configureStore({
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     immutableCheck: false,
  //     serializableCheck: false,
  //   }),
  reducer: {
    restaurant: restaurantReducer,
    system: systemReducer,
    lang: langReducer,
    theme: themeReducer,
    product: productReducer,
    productMap: productMapReducer,
    Brand: BrandReducer,
    BrandGroup: BrandGroupReducer,
    restaurantMedia: restaurantMediaReducer,
    restaurantTag: restaurantTagReducer,
    restaurantTagMap: restaurantTagMapReducer,
    urlMap: urlMapReducer,
    folder: folderReducer,
    folderMap: folderMapReducer,
    configMap: configMapReducer,
    banner: bannerReducer,
    push: pushReducer,
    area: areaReducer,
    district: districtReducer,
    landmark: landmarkReducer,
    adminDevice: adminDeviceReducer,
    campaignDescription: campaignDescriptionReducer,
    tnc: tncReducer,
    ticketType: ticketTypeReducer,
    queueTemplate: queueTemplateReducer,
    queueTemplateMap: queueTemplateMapReducer,
    printTemplate: printTemplateReducer,
    emailTemplate: emailTemplateReducer,
    payment: paymentReducer,
    restaurantApprove: restaurantApproveReducer,
    brandApprove: brandApproveReducer,
    merchantInfo: merchantInfoReducer,
    status: statusReducer,
    queueConfig: queueConfigReducer,
    printConfig: printConfigReducer,
    reservationConfig: reservationConfigReducer,
    reservationTemplate: reservationTemplateReducer,
    reservationTemplateMap: reservationTemplateMapReducer,
    chargeDate: chargeDateReducer,
    merchantUser: merchantUserReducer,
    merchantSalesUser: merchantSalesUserReducer,
    merchantConfig: merchantConfigReducer,
    changeRemind: changeRemindReducer,
    contactUs: contactUsReducer,
    selectTag: selectTagReducer,
    merchantShop: merchantShopReducer,
    selectTagConfig: selectTagConfigReducer,
    selectTagConfigOptions: selectTagConfigOptionsReducer,
    landingSection: landingSectionReducer,
    landingItemEdit: landingItemEditReducer,
    systemGroup: systemGroupReducer,
    systemUser: systemUserReducer,
    merchantUserGroup: merchantUserGroupReducer,
    restaurantPayment: restaurantPayment,
    restaurantBlackout: restaurantBlackoutReducer,
    restaurantSlideshow: restaurantSlideshowReducer,
    queueProfile: queueProfileReducer,
    queueTransferProfile: queueTransferProfileReducer,
    counterProfile: counterProfileReducer,
    counter: counterReducer,
    staff: staffReducer,
    merchantDevice: merchantDeviceReducer,
    landmarkRestaurantMap: landmarkRestaurantMapReducer,
    regionReducer: regionReducer,
  },
})
