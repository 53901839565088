import { useState, useEffect } from "react";
import { useStore } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { Card } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay';

import { constants } from '../../Constants/constants';
import { getList, put } from "../../helper/baseFetch";
import { TempBtn } from "../../Component/Input/TempBtn";
import { GlobalDialog } from "../../Component/GlobalDialog";

export const AdminDeviceList = () => {
  const store = useStore();
  const params = useParams();
  const [adminDeviceList, setAdminDeviceList] = useState([]);
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(false);
  const resource = "AdminDevice";
  const [selectSet, setSelectSet] = useState(new Set());
  const [selectedAllCheck, setSelectedAllCheck] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });

  const adminDeviceTitleList = [
    {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "activationCode",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "deviceType",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "deviceId",
      sort: true
    }, {
      key: constants.LIST_DISPLAY_TYPE.STRING,
      value: "activationStatus",
      sort: true
    }, 
    // {
    //   key: constants.LIST_DISPLAY_TYPE.STRING,
    //   value: "deactivate",
    //   sort: false
    // }
  ];
  const listFilters = [
    {
      className: "filter-input",
      label: { resource: resource, key: "deviceType" },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.ADMIN_DEVICE.DEVICE_TYPE,
      name: "deviceType",
      size: constants.SIZE.SMALL
    },
    {
      className: "filter-input",
      label: { resource: resource, key: "activationStatus" },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.ADMIN_DEVICE.ACTIVATION_STATUS,
      name: "activationStatus",
      size: constants.SIZE.SMALL
    }
  ];

  const [param, setParam] = useState({
    path: "",
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: 0,
  })

  function onChangeParam(value) {
    value.count = value.count + 1;
    setParam(value);
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param);
    tempParam.listFilters = list;
    tempParam.page = 0;
    onChangeParam(tempParam);
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param);
    tempParam.sort = sortItem;
    tempParam.direction = tempParam.direction === "DESC" ? "ASC" : "DESC";
    onChangeParam(tempParam);
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = page;
    onChangeParam(tempParam);
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param);
    tempParam.page = 0;
    tempParam.rowsPerPage = rowsPerPage;
    onChangeParam(tempParam);
  }

  function handleResetData(value) {
    setClear(value);
  }

  const checkAllSelected = (set) =>{
    let selectedPerPage =
    adminDeviceList.filter((item) => {
        return set.has(item.id)
      }).length;

    let currentPageEntry = adminDeviceList.filter(item=>item.activationStatus !== "P").length;

    if (selectedPerPage === (currentPageEntry) && selectedPerPage !== 0) {
      setSelectedAllCheck(true);
    } else {
      setSelectedAllCheck(false);
    }
  }

  const onMultiSelectList =(id, dataList, row)=>{

    let tempSet = _.cloneDeep(selectSet);
    if (id === "all") {
      if (!selectedAllCheck) {
        const tempList = adminDeviceList.filter((item)=>item.activationStatus !== "P").map((item)=>item.id);
        setSelectSet(new Set([...selectSet, ...tempList]));
        return;
      } 

      adminDeviceList.forEach((item)=>tempSet.delete(item.id));
      setSelectSet(tempSet);
      return;
    } 

    if (selectSet.has(id)) {
      tempSet.delete(id);
    } else {
      tempSet.add(id);
    }
  
    setSelectSet(tempSet);
    checkAllSelected(tempSet);
  }

  useEffect(() => {
    checkAllSelected(selectSet);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminDeviceList, selectSet])

  const hideMultiselectCheckbox = (data) =>{
    return data.activationStatus === "P";
  }

  const handleClose = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
  };

  function handleConfirmDialog() {
    setDialog({
      open: true,
      onClose: () => handleClose(),
      dialogTitle: { resource: "AdminDevice", key: "activationCode" },
      dialogContentText: { resource: "AdminDevice", key: "confirmDeactivate" },
      dialogActions: [
        { label: { key: "confirm" }, onClick: () => deactivateFunction(), icon: <CheckIcon /> },
        { label: { key: "cancelBtn" }, onClick: () => handleClose(), icon: <CloseIcon /> }
      ],
    });
  }

  function deactivateFunction() {
    const selectedIdList = Array.from(selectSet);
    put(constants.PATH.SHOP.ADMIN_DEVICE_DEACTIVATE + "/", {idList: selectedIdList}, (payload) => {
      handleDialog(true);
      setSelectedAllCheck(false);
      setSelectSet(new Set());
    }, undefined, store);
  };

  function handleDialog() {
    setDialog({
      open: true,
      onClose: () => handleAdminDeviceDeactivateClose(),
      dialogTitle: { key: "dialogTitle" },
      dialogContentText: { resource: "AdminDevice", key: "deactivateSuccess" },
      dialogActions: [{ label: { key: "close" }, onClick: () => handleAdminDeviceDeactivateClose(), icon: <CloseIcon /> }],
    });
  }

  const handleAdminDeviceDeactivateClose = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    handleResetData(true);
  };

  useEffect(() => {
    let tempParam = _.cloneDeep(param);
    const filter = { restUrlId: params.id };
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value;
      }
    })

    getList(constants.PATH.SHOP.ADMIN_DEVICE, { sort: { field: tempParam.sort, order: tempParam.direction }, filter: filter, pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage } }, (payload) => {
      setAdminDeviceList(payload);
      setReady(true);
      setClear(false);
    }, undefined, store);

  }, [param.count, clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <Card className="card-wrapper overflow">
      <ListDisplay
        page={param.page}
        rowsPerPage={param.rowsPerPage}
        onChangeListSort={onChangeListSort}
        resource={resource}
        dataList={adminDeviceList}
        idName="id"
        mutipleSelect={true}
        onMultiSelectList={onMultiSelectList}
        selectedAllCheck={selectedAllCheck}
        selectMap={selectSet}
        // previewSelectedIdList={selectedIdList}
        sort={param.sort}
        direction={param.direction}
        listTitles={adminDeviceTitleList}
        listFilters={param.listFilters}
        onChangeListPage={onChangeListPage}
        onChangeListRowsPerPage={onChangeListRowsPerPage}
        onChangeListFilters={onChangeListFilters}
        hideMultiselectCheckbox={hideMultiselectCheckbox}
        idParam= {params.id}
        redirectPath={"/" + constants.PATH.SHOP.ADMIN_DEVICE + "/"}
        dialogFooterBtn={
          selectSet?.size>0?
          <div className="get-push-card-wrapper">
            <TempBtn
              btnSetting=
              {{
                className: "get-push-card-cancel-btn",
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                onClick: handleConfirmDialog,
                icon: <DeleteIcon />,
                label: { resource: resource, key: "deactivate" },
              }}
            >
            </TempBtn>
          </div>
          :<></>}
      />
      {dialog && dialog.open &&
      <GlobalDialog dialogInfo={dialog} />
      }
    </Card>
  )
};