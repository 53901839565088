import { useState, useEffect } from "react";
import { useStore } from "react-redux";


import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import PublishIcon from "@mui/icons-material/Publish";

import { constants } from "../../Constants/constants";
import { TempBtn } from "../Input/TempBtn";
import { uploadFile } from "../../helper/baseFetch";
import { GlobalDialog } from "../GlobalDialog";
import { DropzoneArea } from "mui-file-dropzone";

export const UploadFile = ({ type, setFileKey, lotInventoryListIndex, sendList }) => {
  const store = useStore();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const handleUploadFileClose = () => {
    setOpen(false);
  };

  const handleUploadFileConfirm = async (newFile) => {
    localStorage.removeItem(constants.LOCAL_STORAGE_KEY.UPLOAD_PAYMENT_FILE)
    uploadFile(constants.PATH.APPROVAL.UPLOADPAYMENT + "/", { bankRecordFile: newFile[0] }, (payload) => {
      setOpen(false);
      sendList(payload);
    }, undefined, store);
    setOpen(false);
  };
  function uploadFileDialogOpen() {
    setOpen(true);
  };
  function handleUploadFile(e) {
    setFile(e);
  };

  useEffect(() => {
    setDialog({
      open: open,
      onClose: handleUploadFileClose,
      dialogTitle: { key: "dialogTitle" },
      dialogContent: <DropzoneArea
        showPreviews={true}
        showPreviewsInDropzone={false}
        useChipsForPreview
        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
        previewText="Selected files"
        filesLimit={1}
        onChange={file => handleUploadFile(file)}
      />,
      dialogActions:
        [
          { onClick: () => handleUploadFileConfirm(file), icon: <DoneIcon />, disabled: (file && file.length === 0) },
          { onClick: handleUploadFileClose, icon: <CloseIcon /> }
        ],
    });
  }, [open, file]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <TempBtn
        btnSetting=
        {{
          className: "get-push-card-cancel-btn",
          variant: constants.STYLE.VARIANT.CONTAINED,
          color: constants.STYLE.PRIMARY,
          onClick: uploadFileDialogOpen,
          icon: <PublishIcon />,
          label: { resource: "uploadPayment", key: "mainTitle" },
        }}
      >
      </TempBtn>
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};