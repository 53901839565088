import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector, useStore } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import { Box, Drawer } from '@mui/material'
import { Card } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import CachedIcon from '@mui/icons-material/Cached'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { makeStyles } from '@mui/styles'

import { constants as co } from '../../../Constants/constants'
import { put } from '../../../helper/baseFetch'
import { setLoading } from '../../../actions/systemAction'
import { setProductValue, setProductDefault } from '../../../actions/productCreateAction'
import { EditCreateDisplay } from '../../EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Input/TempTextInput'
import { TempAutoSelectInput } from '../../Input/TempAutoSelectInput'
import { TempBtn } from '../../Input/TempBtn'
import ProductCreateValidation from '../../../validations/productCreateValidation'
import { productValidationConstants } from '../../../Constants/validationConstants'
import { getRedeemGroup } from './ProductExistingRedeemGroupList'
import { RestaurantList } from '../../../Page/Restaurant/RestaurantList'
import { getString, permissionsCheck } from '../../../helper/util'
import { GlobalDialog } from '../../GlobalDialog'
import { TempCheckBox } from '../../Input/TempCheckBox'

const helperTextStyles = makeStyles(() => ({
  root: {
    color: 'red !important',
  },
}))

export const RedeemGroupCreateEdit = () => {
  const dispatch = useDispatch()
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const helperTextStylesClasses = helperTextStyles()
  const permission = localStorage.getItem(co.LOCAL_STORAGE_KEY.ROLE)
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false)
  const [hiddenInput, setHiddenInput] = useState(false)
  const [selectedRedeemLocationList, setSelectedRedeemLocationList] = useState([])
  const [restUrlIdList, setRestUrlIdList] = useState([])
  const [showRestaurantTextPanel, setShowRestaurantTextPanel] = useState(false)
  const [clear, setClear] = useState(false)
  const [ready, setReady] = useState(false)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  // eslint-disable-next-line no-unused-vars
  const [errorVersion, setErrorVersion] = useState(ProductCreateValidation.getVersion())

  const resource = 'productCreateRedeemGroupList'
  const savedProduct = useSelector((state) => state.product.ui.product)
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)

  const [isSelectedAllRest, setIsSelectedAllRest] = useState(false)
  const [allRestInput, setAllRestInput] = useState({ status: '', startTime: '', endTime: '', interval: 0 })

  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: co.LANDMARK_TAB.INFO,
    },
  ]

  const fields = [
    {
      className: 'edit-create-input',
      variant: co.STYLE.VARIANT.STANDARD,
      label: { resource: resource, key: 'redeemGroupCode' },
      value: savedProduct.draft.redeemGroup.redeemGroupCode,
      name: 'redeemGroupCode',
      isEndOfLine: false,
      disabled: true,
      helperText: ProductCreateValidation.getErrorMessages(
        productValidationConstants.KEY_REDEEM_GROUP_CODE,
        savedProduct.draft.redeemGroup.redeemGroupCode
      ).map((error) => {
        return error
      }),
    },
    {
      className: 'edit-create-input',
      variant: isFieldsDisabled ? co.STYLE.VARIANT.STANDARD : null,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'codeDescription' },
      value: savedProduct.draft.redeemGroup.codeDescription,
      name: 'codeDescription',
      isEndOfLine: false,
      disabled: isFieldsDisabled,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(
        productValidationConstants.KEY_REDEEM_GROUP_CODE_DESCRIPTION,
        savedProduct.draft.redeemGroup.redeemGroupCode
      ).map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      variant: isFieldsDisabled ? co.STYLE.VARIANT.STANDARD : null,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'type' },
      value: savedProduct.draft.redeemGroup.type,
      type: isFieldsDisabled ? '' : co.TYPE.SELECT,
      selectValue:
        !savedProduct.draft.redeemGroup.id && savedProduct.draft.category.type !== co.CHOICES.PRODUCT_CREATE.SELECT_CATEGORY_TYPE.FREE_ECOUPON
          ? co.CHOICES.PRODUCT_CREATE.CREATE_REDEEM_GROUP.REDEEM_TYPE
          : co.CHOICES.PRODUCT_CREATE.CREATE_REDEEM_GROUP.REDEEM_TYPE_EDIT,
      name: 'type',
      isEndOfLine: true,
      disabled: isFieldsDisabled,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(
        productValidationConstants.KEY_REDEEM_GROUP_REDEEM_TYPE,
        savedProduct.draft.redeemGroup.redeemGroupCode
      ).map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      variant: co.STYLE.VARIANT.STANDARD,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'selectLocation' },
      value: savedProduct.draft.redeemGroup.selectLocation,
      type: isFieldsDisabled || !savedProduct.draft.redeemGroup.id ? '' : co.TYPE.SELECT,
      selectValue: co.CHOICES.PRODUCT_CREATE.CREATE_REDEEM_GROUP.SELECT_LOCATION,
      name: 'selectLocation',
      isEndOfLine: false,
      disabled: isFieldsDisabled || !savedProduct.draft.redeemGroup.id ? true : false,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(
        productValidationConstants.KEY_REDEEM_GROUP_SELECT_LOCATION,
        savedProduct.draft.redeemGroup.redeemGroupCode
      ).map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      variant: co.STYLE.VARIANT.STANDARD,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'selectDate' },
      value: savedProduct.draft.redeemGroup.selectDate,
      type: isFieldsDisabled || !savedProduct.draft.redeemGroup.id ? '' : co.TYPE.SELECT,
      selectValue: co.CHOICES.PRODUCT_CREATE.CREATE_REDEEM_GROUP.SELECT_DATE,
      name: 'selectDate',
      isEndOfLine: false,
      disabled: isFieldsDisabled || !savedProduct.draft.redeemGroup.id ? true : false,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(
        productValidationConstants.KEY_REDEEM_GROUP_SELECT_DATE,
        savedProduct.draft.redeemGroup.redeemGroupCode
      ).map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      variant: co.STYLE.VARIANT.STANDARD,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'selectTime' },
      value: savedProduct.draft.redeemGroup.selectTime,
      type: isFieldsDisabled || !savedProduct.draft.redeemGroup.id ? '' : co.TYPE.SELECT,
      selectValue: co.CHOICES.PRODUCT_CREATE.CREATE_REDEEM_GROUP.SELECT_TIME,
      name: 'selectTime',
      isEndOfLine: true,
      disabled: isFieldsDisabled || !savedProduct.draft.redeemGroup.id ? true : false,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(
        productValidationConstants.KEY_REDEEM_GROUP_SELECT_TIME,
        savedProduct.draft.redeemGroup.redeemGroupCode
      ).map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `${
        !savedProduct.draft.redeemGroup.id && savedProduct.draft.redeemGroup.type === co.REDEEM_TYPE.FLEXIBLE_DAYS && 'display-none'
      } edit-create-input`,
      variant:
        (!savedProduct.draft.redeemGroup.id && savedProduct.draft.redeemGroup.type === co.REDEEM_TYPE.FLEXIBLE_DAYS) || isFieldsDisabled
          ? co.STYLE.VARIANT.STANDARD
          : null,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'startRedeemTimestamp' },
      value: savedProduct.draft.redeemGroup.startRedeemTimestamp === 0 ? '' : savedProduct.draft.redeemGroup.startRedeemTimestamp,
      name: 'startRedeemTimestamp',
      type: co.TYPE.DATETIME_LOCAL,
      step: 1,
      isEndOfLine: false,
      disabled: !savedProduct.draft.redeemGroup.id && savedProduct.draft.redeemGroup.type === co.REDEEM_TYPE.FLEXIBLE_DAYS ? true : isFieldsDisabled,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(
        productValidationConstants.KEY_REDEEM_GROUP_START_REDEEM_TIMESTAMP,
        savedProduct.draft.redeemGroup.redeemGroupCode
      ).map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `${
        !savedProduct.draft.redeemGroup.id && savedProduct.draft.redeemGroup.type === co.REDEEM_TYPE.FLEXIBLE_DAYS && 'display-none'
      } edit-create-input`,
      variant:
        (!savedProduct.draft.redeemGroup.id && savedProduct.draft.redeemGroup.type === co.REDEEM_TYPE.FLEXIBLE_DAYS) || isFieldsDisabled
          ? co.STYLE.VARIANT.STANDARD
          : null,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'endRedeemTimestamp' },
      value: savedProduct.draft.redeemGroup.endRedeemTimestamp === 0 ? '' : savedProduct.draft.redeemGroup.endRedeemTimestamp,
      name: 'endRedeemTimestamp',
      type: co.TYPE.DATETIME_LOCAL,
      step: 1,
      isEndOfLine: true,
      disabled: !savedProduct.draft.redeemGroup.id && savedProduct.draft.redeemGroup.type === co.REDEEM_TYPE.FLEXIBLE_DAYS ? true : isFieldsDisabled,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(
        productValidationConstants.KEY_REDEEM_GROUP_END_REDEEM_TIMESTAMP,
        savedProduct.draft.redeemGroup.redeemGroupCode
      ).map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `${
        !savedProduct.draft.redeemGroup.id && savedProduct.draft.redeemGroup.type === co.REDEEM_TYPE.FIXED_START_END && 'display-none'
      } edit-create-input`,
      variant:
        (!savedProduct.draft.redeemGroup.id && savedProduct.draft.redeemGroup.type === co.REDEEM_TYPE.FIXED_START_END) || isFieldsDisabled
          ? co.STYLE.VARIANT.STANDARD
          : null,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'startAfterDay' },
      value: savedProduct.draft.redeemGroup.startAfterDay,
      type: co.TYPE.NUMBER,
      min: 0,
      name: 'startAfterDay',
      isEndOfLine: false,
      disabled: !savedProduct.draft.redeemGroup.id && savedProduct.draft.redeemGroup.type === co.REDEEM_TYPE.FIXED_START_END ? true : isFieldsDisabled,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(
        productValidationConstants.KEY_REDEEM_GROUP_START_AFTER_DAY,
        savedProduct.draft.redeemGroup.redeemGroupCode
      ).map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `${
        !savedProduct.draft.redeemGroup.id && savedProduct.draft.redeemGroup.type === co.REDEEM_TYPE.FIXED_START_END && 'display-none'
      } edit-create-input`,
      variant:
        (!savedProduct.draft.redeemGroup.id && savedProduct.draft.redeemGroup.type === co.REDEEM_TYPE.FIXED_START_END) || isFieldsDisabled
          ? co.STYLE.VARIANT.STANDARD
          : null,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'endAfterDay' },
      value: savedProduct.draft.redeemGroup.endAfterDay,
      type: co.TYPE.NUMBER,
      min: 0,
      name: 'endAfterDay',
      isEndOfLine: false,
      disabled: !savedProduct.draft.redeemGroup.id && savedProduct.draft.redeemGroup.type === co.REDEEM_TYPE.FIXED_START_END ? true : isFieldsDisabled,
      helperTextIcon: true,
      helperText: ProductCreateValidation.getErrorMessages(
        productValidationConstants.KEY_REDEEM_GROUP_END_AFTER_DAY,
        savedProduct.draft.redeemGroup.redeemGroupCode
      ).map((error) => {
        return error
      }),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: savedProduct.draft.redeemGroup.id ? 'edit-create-input' : 'display-none',
      variant: isFieldsDisabled ? co.STYLE.VARIANT.STANDARD : null,
      size: co.SIZE.SMALL,
      label: { resource: resource, key: 'status' },
      value: savedProduct.draft.redeemGroup.status,
      type: isFieldsDisabled ? '' : co.TYPE.SELECT,
      selectValue: co.CHOICES.PRODUCT_CREATE.CREATE_CATEGORY.STATUS,
      name: 'status',
      isEndOfLine: true,
      disabled: isFieldsDisabled,
    },
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { resource: 'productCreateRedeemGroupList', key: 'addRedeemLocation' },
      onClick: handleAddRedeemLocation,
      name: 'button-on-top btn-margin',
      type: co.TYPE.BUTTON,
      icon: <AddIcon />,
      disabled: isFieldsDisabled,
    },
  ]

  const onChangeAllRestInput = (name, e) => {
    let newRedeemLocationList = _.cloneDeep(savedProduct.draft.redeemGroup.redeemLocationList)
    let newAllRestInput = { ...allRestInput }

    switch (name) {
      case 'startTime':
      case 'endTime':
        let time = e
        const timeTest = /^[0-9]{2}:[0-9]{2}$/
        if (time.match(timeTest)) {
          time += ':00'
        }
        newAllRestInput[name] = time
        break
      case 'interval':
        newAllRestInput[name] = Number(e)
        break
      case 'status':
        newAllRestInput[name] = e
        break
      default:
    }

    newRedeemLocationList.map((redeemLocation) => {
      if (redeemLocation.isSelectedForAllRest) {
        switch (name) {
          case 'status':
            redeemLocation[name] = newAllRestInput[name]
            break
          default:
            redeemLocation.timeSession[name] = newAllRestInput[name]
        }
      }
      return redeemLocation
    })

    dispatch(setProductValue(co.ACTION_KEY.PRODUCT.REDEEM, savedProduct, 'redeemLocationList', newRedeemLocationList))
    setAllRestInput(newAllRestInput)
  }

  const content = [
    {
      tab: (
        <>
          {fields.map((inputSetting, index) => {
            let content
            switch (inputSetting.type) {
              case co.TYPE.SELECT:
                content = <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
                break
              case co.TYPE.BUTTON:
                content = (
                  <>
                    <Box sx={{ mb: '16px' }}>
                      <TempBtn btnSetting={inputSetting} />
                    </Box>
                    {savedProduct.draft.redeemGroup.selectLocation &&
                      savedProduct.draft.redeemGroup.selectDate &&
                      savedProduct.draft.redeemGroup.selectTime && (
                        <Card sx={{ backgroundColor: '#fafafa', p: '20px', border: '2px solid black', mb: '16px' }}>
                          <TempCheckBox
                            checkBoxSetting={{
                              value: isSelectedAllRest,
                              label: { resource: 'productCreateRedeemGroupList', key: 'selectAllRedeemLocation' },
                            }}
                            handleChange={() => {
                              let newRedeemLocationList = _.cloneDeep(savedProduct.draft.redeemGroup.redeemLocationList)
                              newRedeemLocationList = newRedeemLocationList.map((item) => ({
                                ...item,
                                isSelectedForAllRest: !isSelectedAllRest,
                              }))
                              dispatch(setProductValue(co.ACTION_KEY.PRODUCT.REDEEM, savedProduct, 'redeemLocationList', newRedeemLocationList))
                              setAllRestInput({
                                status: '',
                                startTime: '',
                                endTime: '',
                                interval: 0,
                              })
                              setIsSelectedAllRest(!isSelectedAllRest)
                            }}
                          />
                          <div>
                            <TempAutoSelectInput
                              inputSetting={{
                                value: allRestInput.status,
                                type: co.TYPE.SELECT,
                                selectValue: co.CHOICES.PRODUCT_CREATE.CREATE_CATEGORY.STATUS,
                                name: 'status',
                                label: { resource: 'productCreateProductCategoryList', key: 'status' },
                                disabled: isFieldsDisabled,
                              }}
                              handleChange={onChangeAllRestInput}
                            />
                          </div>
                          <TempTextInput
                            inputSetting={{
                              type: co.TYPE.TIME,
                              value: allRestInput.startTime,
                              label: { resource: 'productCreateRedeemGroupList', key: 'startTime' },
                              disabled: isFieldsDisabled,
                              name: 'startTime',
                            }}
                            handleChange={onChangeAllRestInput}
                          />
                          <TempTextInput
                            inputSetting={{
                              type: co.TYPE.TIME,
                              value: allRestInput.endTime,
                              label: { resource: 'productCreateRedeemGroupList', key: 'endTime' },
                              disabled: isFieldsDisabled,
                              name: 'endTime',
                            }}
                            handleChange={onChangeAllRestInput}
                          />
                          <TempTextInput
                            inputSetting={{
                              variant: isFieldsDisabled ? co.STYLE.VARIANT.STANDARD : null,
                              label: { resource: 'productCreateRedeemGroupList', key: 'interval' },
                              value: allRestInput.interval,
                              type: co.TYPE.NUMBER,
                              name: 'interval',
                              min: 5,
                              max: 1440,
                              disabled: isFieldsDisabled,
                            }}
                            handleChange={onChangeAllRestInput}
                          />
                        </Card>
                      )}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '16px' }}>
                      Total: {savedProduct.draft.redeemGroup.redeemLocationList ? savedProduct.draft.redeemGroup.redeemLocationList.length : 0}
                    </Box>
                    {savedProduct.draft.redeemGroup.redeemLocationList &&
                      savedProduct.draft.redeemGroup.redeemLocationList.map((item, restUrlIdIdx) => (
                        <Card key={restUrlIdIdx} className="image-list">
                          {savedProduct.draft.redeemGroup.selectLocation &&
                            savedProduct.draft.redeemGroup.selectDate &&
                            savedProduct.draft.redeemGroup.selectTime && (
                              <TempCheckBox
                                checkBoxSetting={{
                                  value: item.isSelectedForAllRest,
                                  label: '',
                                }}
                                handleChange={() => {
                                  let newRedeemLocationList = _.cloneDeep(savedProduct.draft.redeemGroup.redeemLocationList)
                                  newRedeemLocationList[restUrlIdIdx].isSelectedForAllRest = !newRedeemLocationList[restUrlIdIdx].isSelectedForAllRest
                                  dispatch(setProductValue(co.ACTION_KEY.PRODUCT.REDEEM, savedProduct, 'redeemLocationList', newRedeemLocationList))
                                }}
                              />
                            )}
                          {item.id ? (
                            isFieldsDisabled ? (
                              <TempTextInput
                                inputSetting={{
                                  className: 'edit-create-input',
                                  variant: isFieldsDisabled ? co.STYLE.VARIANT.STANDARD : null,
                                  size: co.SIZE.SMALL,
                                  value: item.status ? item.status : '',
                                  name: 'status',
                                  label: { resource: 'productCreateProductCategoryList', key: 'status' },
                                  disabled: isFieldsDisabled,
                                }}
                                handleChange={onChangeInput}
                                index={restUrlIdIdx}
                              />
                            ) : (
                              <TempAutoSelectInput
                                inputSetting={{
                                  className: 'edit-create-input',
                                  size: co.SIZE.SMALL,
                                  variant: co.STYLE.VARIANT.OUTLINED,
                                  value: item.status || '',
                                  type: co.TYPE.SELECT,
                                  selectValue: co.CHOICES.PRODUCT_CREATE.CREATE_CATEGORY.STATUS,
                                  name: 'status',
                                  label: { resource: 'productCreateProductCategoryList', key: 'status' },
                                  disabled: isFieldsDisabled,
                                }}
                                handleChange={onChangeInput}
                                index={restUrlIdIdx}
                              />
                            )
                          ) : (
                            !hiddenInput && (
                              <div className="deleteIcon">
                                <TempBtn
                                  btnSetting={{
                                    className: 'edit-create-btn',
                                    variant: co.STYLE.VARIANT.CONTAINED,
                                    color: co.STYLE.PRIMARY,
                                    onClick: () => handleDeleteRedeemLocation(item.restUrlId, restUrlIdIdx),
                                    label: { key: 'deleteBtn' },
                                    icon: <DeleteIcon />,
                                    disabled: isFieldsDisabled,
                                  }}
                                />
                              </div>
                            )
                          )}
                          <div>
                            <TempTextInput
                              inputSetting={{
                                className: 'edit-create-input',
                                variant: co.STYLE.VARIANT.STANDARD,
                                size: co.SIZE.SMALL,
                                value: item.restUrlId,
                                label: { resource: 'productCreateRedeemGroupList', key: 'restUrlId' },
                                disabled: true,
                                helperTextIcon: true,
                                helperText: ProductCreateValidation.getErrorMessages(
                                  productValidationConstants.KEY_REDEEM_GROUP_REDEEM_LOCATION_REST_URL_ID,
                                  savedProduct.draft.redeemGroup.redeemGroupCode,
                                  item.restUrlId
                                ).map((error) => {
                                  return error
                                }),
                                FormHelperTextProps: { className: helperTextStylesClasses.root },
                              }}
                            />
                          </div>
                          <div>
                            <TempTextInput
                              inputSetting={{
                                className: 'edit-create-input',
                                variant: co.STYLE.VARIANT.STANDARD,
                                size: co.SIZE.SMALL,
                                value: item.restEnName,
                                label: { resource: 'productCreateRedeemGroupList', key: 'restEnName' },
                                disabled: true,
                              }}
                            />
                            <TempTextInput
                              inputSetting={{
                                className: 'edit-create-input',
                                variant: co.STYLE.VARIANT.STANDARD,
                                size: co.SIZE.SMALL,
                                value: item.restTcName,
                                label: { resource: 'productCreateRedeemGroupList', key: 'restTcName' },
                                disabled: true,
                              }}
                            />
                            <TempTextInput
                              inputSetting={{
                                className: 'edit-create-input',
                                variant: co.STYLE.VARIANT.STANDARD,
                                size: co.SIZE.SMALL,
                                value: item.restScName,
                                label: { resource: 'productCreateRedeemGroupList', key: 'restScName' },
                                disabled: true,
                              }}
                            />
                          </div>
                          {savedProduct.draft.redeemGroup.selectLocation &&
                            savedProduct.draft.redeemGroup.selectDate &&
                            savedProduct.draft.redeemGroup.selectTime && (
                              <div>
                                <TempTextInput
                                  inputSetting={{
                                    className: 'edit-create-input',
                                    type: co.TYPE.TIME,
                                    size: co.SIZE.SMALL,
                                    variant: isFieldsDisabled ? co.STYLE.VARIANT.STANDARD : null,
                                    value: item.timeSession.startTime,
                                    label: { resource: 'productCreateRedeemGroupList', key: 'startTime' },
                                    disabled: isFieldsDisabled,
                                    name: 'startTime',
                                    helperTextIcon: true,
                                    helperText: ProductCreateValidation.getErrorMessages(
                                      productValidationConstants.KEY_REDEEM_GROUP_REDEEM_LOCATION_START_TIME,
                                      savedProduct.draft.redeemGroup.redeemGroupCode,
                                      item.restUrlId
                                    ).map((error) => {
                                      return error
                                    }),
                                    FormHelperTextProps: { className: helperTextStylesClasses.root },
                                  }}
                                  handleChange={onChangeInput}
                                  index={restUrlIdIdx}
                                />
                                <TempTextInput
                                  inputSetting={{
                                    className: 'edit-create-input',
                                    type: co.TYPE.TIME,
                                    size: co.SIZE.SMALL,
                                    variant: isFieldsDisabled ? co.STYLE.VARIANT.STANDARD : null,
                                    value: item.timeSession.endTime,
                                    label: { resource: 'productCreateRedeemGroupList', key: 'endTime' },
                                    disabled: isFieldsDisabled,
                                    name: 'endTime',
                                    helperTextIcon: true,
                                    helperText: ProductCreateValidation.getErrorMessages(
                                      productValidationConstants.KEY_REDEEM_GROUP_REDEEM_LOCATION_END_TIME,
                                      savedProduct.draft.redeemGroup.redeemGroupCode,
                                      item.restUrlId
                                    ).map((error) => {
                                      return error
                                    }),
                                    FormHelperTextProps: { className: helperTextStylesClasses.root },
                                  }}
                                  handleChange={onChangeInput}
                                  index={restUrlIdIdx}
                                />
                                <TempTextInput
                                  inputSetting={{
                                    className: 'edit-create-input',
                                    variant: isFieldsDisabled ? co.STYLE.VARIANT.STANDARD : null,
                                    size: co.SIZE.SMALL,
                                    label: { resource: 'productCreateRedeemGroupList', key: 'interval' },
                                    value: item.timeSession.interval,
                                    type: co.TYPE.NUMBER,
                                    name: 'interval',
                                    min: 5,
                                    max: 1440,
                                    helperTextIcon: true,
                                    helperText: ProductCreateValidation.getErrorMessages(
                                      productValidationConstants.KEY_REDEEM_GROUP_REDEEM_LOCATION_INTERVAL,
                                      savedProduct.draft.redeemGroup.redeemGroupCode,
                                      item.restUrlId
                                    ).map((error) => {
                                      return error
                                    }),
                                    FormHelperTextProps: { className: helperTextStylesClasses.root },
                                    disabled: isFieldsDisabled,
                                  }}
                                  handleChange={onChangeInput}
                                  index={restUrlIdIdx}
                                />
                              </div>
                            )}
                        </Card>
                      ))}
                  </>
                )
                break
              default:
                content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            }
            return (
              <div className="edit-display-wrapper" key={index}>
                {content}
                {inputSetting.isEndOfLine && <div></div>}
              </div>
            )
          })}
        </>
      ),
      btn: savedProduct.draft.redeemGroup.id ? true : false,
    },
  ]
  const bottomBtns = [
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
      disabled: isFieldsDisabled,
    },
    {
      variant: co.STYLE.VARIANT.CONTAINED,
      color: co.STYLE.PRIMARY,
      label: { key: 'clear' },
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
      disabled: isFieldsDisabled,
    },
  ]

  function handleAddRedeemLocation() {
    dispatch(setLoading(true))
    setRestUrlIdList(
      savedProduct.draft.redeemGroup.redeemLocationList.reduce((result, item) => {
        if (item.status !== co.PRODUCT_EDIT_STATUS.DEACTIVE && item.restUrlId) {
          result.push(item.restUrlId)
        }
        return result
      }, [])
    )
    setShowRestaurantTextPanel(true)
    dispatch(setLoading(false))
  }

  function handleRestaurantTextCloseClick() {
    dispatch(setLoading(true))
    setShowRestaurantTextPanel(false)
    setSelectedRedeemLocationList([])
    setRestUrlIdList([])
    dispatch(setLoading(false))
  }

  function handleDeleteRedeemLocation(restUrlId, redeemLocationIdx) {
    dispatch(setLoading(true))
    let newRedeemLocationList = _.cloneDeep(savedProduct.draft.redeemGroup.redeemLocationList)
    newRedeemLocationList.splice(redeemLocationIdx, 1)
    dispatch(setProductValue(co.ACTION_KEY.PRODUCT.REDEEM, savedProduct, 'redeemLocationList', newRedeemLocationList))
    dispatch(setLoading(false))
  }

  const processAddRedeemLocation = (restUrlIdList) => {
    setShowRestaurantTextPanel(true)
    return new Promise((resolve) => {
      let oldRedeemLocationList = _.cloneDeep(savedProduct.draft.redeemGroup.redeemLocationList)
      let oldRestUrlIdList = oldRedeemLocationList.map((item) => {
        return item.restUrlId
      })

      restUrlIdList = restUrlIdList.filter((item) => {
        return item
      })

      oldRedeemLocationList.forEach((redeemLocation) => {
        if (restUrlIdList.some((restUrlId) => restUrlId === redeemLocation.restUrlId)) {
          redeemLocation.status =
            redeemLocation.status === co.PRODUCT_EDIT_STATUS.PENDING ? co.PRODUCT_EDIT_STATUS.PENDING : co.PRODUCT_EDIT_STATUS.ACTIVE
        } else {
          redeemLocation.status = co.PRODUCT_EDIT_STATUS.DEACTIVE
        }
      })

      let newRedeemLocationList = _.cloneDeep(oldRedeemLocationList)

      newRedeemLocationList = newRedeemLocationList.filter((redeemLocation) => {
        return redeemLocation.id ? redeemLocation : restUrlIdList.includes(redeemLocation.restUrlId)
      })

      let selectedRestUrlIdList = restUrlIdList.filter((restUrlId) => {
        return !oldRestUrlIdList.includes(restUrlId)
      })

      selectedRestUrlIdList.map((selectedRestUrlId) => {
        selectedRedeemLocationList.forEach((selectedRedeemLocation) => {
          if (selectedRedeemLocation.id === selectedRestUrlId)
            newRedeemLocationList.push({
              restUrlId: selectedRestUrlId,
              restTcName: selectedRedeemLocation.tcName,
              restEnName: selectedRedeemLocation.enName,
              restScName: selectedRedeemLocation.scName,
              timeSession: {
                startTime: '',
                endTime: '',
                interval: 30,
              },
            })
        })
        return {}
      })

      resolve(newRedeemLocationList)

      // console.log('restUrlIdList', restUrlIdList)
      // console.log('newRedeemLocationList', newRedeemLocationList)
      // console.log('oldRedeemLocationList', oldRedeemLocationList)
      // console.log('oldRestUrlIdList', oldRestUrlIdList)
      // console.log('selectedRestUrlIdList', selectedRestUrlIdList)
      // console.log('selectedRedeemLocationList', selectedRedeemLocationList)
    })
  }

  const handleRestUrlId = async () => {
    const newRedeemLocationList = await processAddRedeemLocation(restUrlIdList)
    setShowRestaurantTextPanel(false)
    setSelectedRedeemLocationList([])
    setRestUrlIdList([])
    dispatch(setProductValue(co.ACTION_KEY.PRODUCT.REDEEM, savedProduct, 'redeemLocationList', newRedeemLocationList))
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + co.PATH.PRODUCT.REDEEM_GROUP)
  }

  function onChangeInput(name, e, idx) {
    let newRedeemLocationList = _.cloneDeep(savedProduct.draft.redeemGroup.redeemLocationList)
    if (idx || idx === 0) {
      if (name === 'startTime' || name === 'endTime') {
        let time = e
        const timeRegex = /^[0-9]{2}:[0-9]{2}$/
        if (time.match(timeRegex)) {
          time = time + ':00'
        }
        newRedeemLocationList[idx].timeSession[name] = time
      } else if (name === 'interval') {
        if (e) {
          newRedeemLocationList[idx].timeSession[name] = Number(e)
        } else {
          newRedeemLocationList[idx][name] = e
        }
      } else {
        newRedeemLocationList[idx][name] = e
      }
      dispatch(setProductValue(co.ACTION_KEY.PRODUCT.REDEEM, savedProduct, 'redeemLocationList', newRedeemLocationList))
    } else if (name === 'startRedeemTimestamp' || name === 'endRedeemTimestamp') {
      dispatch(setProductValue(co.ACTION_KEY.PRODUCT.REDEEM, savedProduct, name, Number(moment(e).format('x'))))
    } else if (name === 'startAfterDay' || name === 'endAfterDay') {
      dispatch(setProductValue(co.ACTION_KEY.PRODUCT.REDEEM, savedProduct, name, Number(e)))
    } else if (name === 'type') {
      let changeRedeemType = {}
      newRedeemLocationList.map((item) => {
        item.timeSession.startTime = ''
        item.timeSession.endTime = ''
        item.timeSession.interval = 30
        return {}
      })
      if (savedProduct.draft.redeemGroup.id) {
        dispatch(setProductValue(co.ACTION_KEY.PRODUCT.REDEEM, savedProduct, name, e))
      } else {
        if (e === co.REDEEM_TYPE.PREORDER) {
          changeRedeemType = {
            type: e,
            startRedeemTimestamp: 0,
            endRedeemTimestamp: 0,
            startAfterDay: 0,
            endAfterDay: 366,
            selectLocation: true,
            selectDate: true,
            selectTime: true,
            redeemLocationList: newRedeemLocationList,
          }
        } else if (e === co.REDEEM_TYPE.FLEXIBLE_DAYS) {
          let date = new Date()
          let minDate = date.setHours(0, 0, 0)
          let maxDate = date.setHours(23, 59, 59)
          changeRedeemType = {
            type: e,
            startRedeemTimestamp: minDate,
            endRedeemTimestamp: maxDate,
            startAfterDay: 0,
            endAfterDay: 366,
            selectLocation: false,
            selectDate: false,
            selectTime: false,
            redeemLocationList: newRedeemLocationList,
          }
        } else {
          changeRedeemType = {
            type: e,
            startRedeemTimestamp: 0,
            endRedeemTimestamp: 0,
            startAfterDay: 0,
            endAfterDay: 366,
            selectLocation: false,
            selectDate: false,
            selectTime: false,
            redeemLocationList: newRedeemLocationList,
          }
        }
        dispatch(setProductValue(co.ACTION_KEY.PRODUCT.REDEEM, savedProduct, 'changeRedeemType', changeRedeemType))
      }
    } else {
      dispatch(setProductValue(co.ACTION_KEY.PRODUCT.REDEEM, savedProduct, name, e))
    }
  }

  const edit = async (redeemId, redeemGroupDisable) => {
    const data = await getRedeemGroup(redeemId, store)
    let newRedeemGroup = data
    if (redeemGroupDisable) {
      data.redeemDisable = redeemGroupDisable
    }
    dispatch(setProductValue(co.ACTION_KEY.PRODUCT.DRAFT, savedProduct, 'redeemGroup', newRedeemGroup))
    setReady(true)
  }

  function onClickSave() {
    if (savedProduct.draft.redeemGroup.id) {
      ProductCreateValidation.validateRedeemOnly(savedProduct.draft, true)
      if (ProductCreateValidation.isValid()) {
        put(
          co.PATH.PRODUCT.REDEEM_GROUP + '/' + savedProduct.draft.redeemGroup.id,
          savedProduct.draft.redeemGroup,
          () => {
            setDialog({
              open: true,
              onClose: onClickBackToEdit,
              dialogContentText: { key: 'editSuccess' },
              dialogActions: [
                { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
                { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
              ],
            })
            setClear(false)
          },
          undefined,
          store
        )
      } else {
        setErrorVersion(ProductCreateValidation.getVersion())
      }
    } else {
      let newDraft = _.cloneDeep(savedProduct.draft)
      const newRedeemGroup = _.cloneDeep(savedProduct.draft.redeemGroup)
      let newCodeNumber = _.cloneDeep(savedProduct.draft.codeNumber)

      if (!newRedeemGroup.redeemGroupCode) {
        newRedeemGroup.redeemGroupCode = 'RedeemGroup_' + newCodeNumber
        newCodeNumber = newCodeNumber + 1
      }

      const newRedeemGroupList = savedProduct.draft.redeemGroupList.filter((redeemGroup) => {
        return newRedeemGroup.redeemGroupCode !== redeemGroup.redeemGroupCode
      })

      newRedeemGroupList.push(newRedeemGroup)
      newRedeemGroupList.sort((a, b) => {
        return (
          Number(a.redeemGroupCode.substring(a.redeemGroupCode.lastIndexOf('_') + 1)) -
          Number(b.redeemGroupCode.substring(b.redeemGroupCode.lastIndexOf('_') + 1))
        )
      })
      newDraft.redeemGroupList = newRedeemGroupList
      newDraft.redeemGroup = newRedeemGroup
      if (newRedeemGroup) {
        newDraft.codeNumber = newCodeNumber + 1
      }
      newDraft.product = _.cloneDeep(co.PRODUCT)
      dispatch(setProductValue('', savedProduct, '', newDraft))
      ProductCreateValidation.validateRedeemOnly(newDraft, true)
      setErrorVersion(ProductCreateValidation.getVersion())
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (permissionsCheck(co.PERMISSION_KEY.REDEEM_GROUP_CREATE_EDIT_FINANCE)) {
      setIsFieldsDisabled(true)
      setHiddenInput(true)
    } else {
      setIsFieldsDisabled(savedProduct.draft.redeemGroup.redeemDisable)
      setHiddenInput(false)
    }
  }, [permission, savedProduct.draft.redeemGroup.redeemDisable]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let newSavedProduct = _.cloneDeep(savedProduct)
    let newDraft = _.cloneDeep(savedProduct.draft)

    if (params.id) {
      ProductCreateValidation.resetErrorMessages()
      edit(params.id, false)
    } else if (newSavedProduct.draft.redeemGroup.id) {
      ProductCreateValidation.resetErrorMessages()
      edit(newSavedProduct.draft.redeemGroup.id, true)
    } else if (!newDraft.redeemGroupList || newDraft.redeemGroupList.length === 0) {
      setReady(false)
    } else if ((newDraft.redeemGroupList || newDraft.redeemGroupList.length > 0) && !newSavedProduct.draft.redeemGroup.redeemLocationList) {
      newSavedProduct.draft.redeemGroup.redeemLocationList = []
    } else {
      if (clear === true) {
        dispatch(setProductDefault())
      }
      setReady(true)
    }
    setClear(false)
  }, [clear === true]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!savedProduct.draft.redeemGroup || (savedProduct.draft.redeemGroup && !savedProduct.draft.redeemGroup.redeemGroupCode)) {
      setReady(false)
    } else {
      setReady(true)
    }
  }, [savedProduct.draft.redeemGroupList, savedProduct.draft.redeemGroup, savedProduct.draft.redeemGroup.redeemGroupCode]) // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   console.log('savedProduct', savedProduct)
  // }, [savedProduct])

  // useEffect(() => {
  //   console.log('disableInput', disableInput)
  // }, [disableInput])

  return (
    ready && (
      <div className="step-content-content-wrapper">
        <div className="array-list-wrapper">
          <div className="sub-title">
            {getString(lang, 'productCreateRedeemGroupList', 'mainTitle', theme)}:{' '}
            <div className="code-title">
              {savedProduct.draft.redeemGroup.redeemGroupCode} - {savedProduct.draft.redeemGroup.codeDescription}
            </div>
          </div>
          {savedProduct.draft.redeemGroup.id && savedProduct.draft.redeemGroup.redeemDisable && (
            <TempBtn
              btnSetting={{
                className: 'edit-create-btn',
                variant: co.STYLE.VARIANT.CONTAINED,
                color: co.STYLE.PRIMARY,
                onClick: () => edit(savedProduct.draft.redeemGroup.id, true),
                icon: <CachedIcon />,
              }}
            />
          )}
        </div>
        <EditCreateDisplay
          display="none"
          code={savedProduct.redeemGroupId ? savedProduct.redeemGroupId : ''}
          resource={resource}
          tabs={tabs}
          content={content}
          onChangeInput={onChangeInput}
          bottomBtns={savedProduct.draft.redeemGroup.id ? bottomBtns : []}
        />
        <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={showRestaurantTextPanel} onClose={handleRestaurantTextCloseClick}>
          <RestaurantList
            editBtn={false}
            createBtn={false}
            btnList={false}
            mutipleSelect={true}
            setSelectedIdList={setRestUrlIdList}
            selectedIdList={restUrlIdList}
            setPreviewSelectedList={setSelectedRedeemLocationList}
            previewSelectedList={selectedRedeemLocationList}
            newPath={co.PATH.SHOP.RESTAURANT}
            newFilter={
              restUrlIdList && restUrlIdList.length > 0
                ? {
                    selected: {
                      column: 'restUrlId',
                      valueList: restUrlIdList
                        .map((item) => {
                          return item
                        })
                        .reverse(),
                    },
                  }
                : null
            }
            savedData={savedProduct.draft.redeemGroup.redeemLocationList}
            dialogFooterBtn={
              <>
                <TempBtn
                  btnSetting={{
                    className: 'get-push-card-cancel-btn',
                    variant: co.STYLE.VARIANT.CONTAINED,
                    color: co.STYLE.PRIMARY,
                    onClick: handleRestUrlId,
                    icon: <AddIcon />,
                    label: { key: 'submit' },
                  }}
                />
                <TempBtn
                  btnSetting={{
                    className: 'get-push-card-cancel-btn margin-left-16',
                    variant: co.STYLE.VARIANT.CONTAINED,
                    color: co.STYLE.PRIMARY,
                    onClick: handleRestaurantTextCloseClick,
                    icon: <ClearIcon />,
                    label: { key: 'close' },
                  }}
                />
              </>
            }
          />
        </Drawer>
        {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
      </div>
    )
  )
}
