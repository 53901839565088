import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import _ from "lodash";

import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { getOne, put } from "../../helper/baseFetch";
import { setSelectTag, setSelectTagEditValue } from "../../actions/selectTagAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { UploadImage } from "../../Component/UploadImage/UploadImage";
import { GlobalDialog } from "../../Component/GlobalDialog";
import { TempCustomCheckBox } from "../../Component/Custom/TempCustomCheckBox";
import SelectTagValidation from "../../validations/selectTagValidation";
import { selectTagValidationConstants } from '../../Constants/validationConstants';

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const SelectTagEdit = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "SelectTag";
  const savedSelectTag = useSelector(state => state.selectTag.ui.selectTag);
  const [selectTagInputList, setSelectTagInputList] = useState(_.cloneDeep(constants.SELECT_TAG_INPUT));
  const [, setErrorVersion] = useState(SelectTagValidation.getVersion());

  const helperTextStylesClasses = helperTextStyles();
  //console.log(savedSelectTag)
  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.SELECT_TAG_EDIT_TAB.INFO
    },
  ]
  const tempSelectTagEditList = [
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tag_code" },
      value: savedSelectTag.tagCode,
      name: "tagCode",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enName" },
      value: savedSelectTag.enName,
      name: "enName",
      custom: true,
      customName: { key: "nameCustomize" },
      customValue: savedSelectTag.scNameDisable,
      isEndOfLine: false,
      required: true,
      disabled: false,
      type: constants.TYPE.TEXT,
      helperTextIcon: true,
      helperText: SelectTagValidation.getErrorMessages(selectTagValidationConstants.KEY_EN_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcName" },
      value: savedSelectTag.tcName,
      name: "tcName",
      translateName: savedSelectTag.scNameDisable ? ["scName"] : null, 
      inputList: selectTagInputList,
      setInputList: setSelectTagInputList,
      isEndOfLine: false,
      required: true,
      type: constants.TYPE.TEXT,
      helperTextIcon: true,
      helperText: SelectTagValidation.getErrorMessages(selectTagValidationConstants.KEY_TC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scName" },
      value: savedSelectTag.scName,
      name: "scName",
      inputList: selectTagInputList,
      setInputList: setSelectTagInputList,
      isEndOfLine: true,
      disabled: savedSelectTag.scNameDisable,
      required: true,
      type: constants.TYPE.TEXT, 
      helperTextIcon: true,
      helperText: SelectTagValidation.getErrorMessages(selectTagValidationConstants.KEY_SC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]

  const content = [ //to-do export this function
    {
      tab: tempSelectTagEditList.map((inputSetting, index) => {
        let content = <></>;
        switch (inputSetting.type) {
          case constants.TYPE.TEXT:
            content =
              <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break;
          case constants.TYPE.SELECT:
            content =
              <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break;
          case constants.TYPE.IMAGE_MULTI:
            function handleContent(newPicture, index) {
              return <div className="image" key={index}>
                <UploadImage
                  picture={newPicture}
                  title={inputSetting.label}
                  name={inputSetting.name}
                  uploadImagePath={inputSetting.uploadImagePath}
                  saveImage={inputSetting.saveImage}
                  disableInput={inputSetting.disableInput} 
                  type = ""/>
              </div>
            }
            content = inputSetting.picture && inputSetting.picture.length > 0
              ? inputSetting.picture.map((pictureValue, index) => {
                return handleContent(pictureValue, index);
              })
              : handleContent();
            break;
          default:
            <></>
        }
        return <div className={inputSetting.backgroundColorClass ? "image-card-background card-border" : "edit-display-wrapper"} key={index}>
          {inputSetting.custom && <TempCustomCheckBox className="customize-btn" value={inputSetting.customValue} customName={inputSetting.customName} onSelectCheckbox={onSelectCheckbox} />}
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: true
    },
  ]
  const bottomBtns = [
    {
      className: "margin-left-16",
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    },
  ]

  function onSelectCheckbox(value, customName) {
    //bug --> cusomeName.key --> other component same problem, export this function
    if (customName.key === "nameCustomize") {
      dispatch(setSelectTagEditValue("scNameDisable", savedSelectTag, "", value));
    } 
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SELECTED_TAG.SELECTED_TAG);
  };

  function onChangeInput(name, e) {
    if (savedSelectTag.scNameDisable === true && name === "tcName") {
      dispatch(setSelectTagEditValue("CHANGE_TC_NAME_AND_SC_NAME", savedSelectTag, "", e));
    } else {
      dispatch(setSelectTagEditValue(name, savedSelectTag, "", e));
    }
  }

  function onClickSave() {

    SelectTagValidation.validateEditSelectTag(savedSelectTag);
    if (SelectTagValidation.isValid()) {
      put(constants.PATH.SELECTED_TAG.SELECTED_TAG+ "/" + params.id, savedSelectTag, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      SelectTagValidation.resetErrorMessages();
    } else {
      setErrorVersion(SelectTagValidation.getVersion());
    }

  }

  function onClickClear() {
    setClear(true);
    SelectTagValidation.resetErrorMessages();
  }

  useEffect(() => {
    if (clear === true) {
      getOne(constants.PATH.SELECTED_TAG.SELECTED_TAG + "/" + params.id, undefined, (payload) => {
        payload.scNameDisable = true;
        dispatch(setSelectTag(payload));
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedSelectTag && savedSelectTag.id ? savedSelectTag.id : ""}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="edit"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};
