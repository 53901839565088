import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CachedIcon from '@mui/icons-material/Cached';

import { constants } from '../../Constants/constants';
import { put } from "../../helper/baseFetch";
import { getOne } from "../../helper/baseFetch";
import { setMerchantSalesUser, setMerchantSalesUserValue } from "../../actions/merchantSalesUserAction";
import { getString } from "../../helper/util";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { TempCheckBox } from "../../Component/Input/TempCheckBox";
import { GlobalDialog } from "../../Component/GlobalDialog";

export const MerchantSalesUserEdit = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const [password, setPassword] = useState(null);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "MerchantSalesUser";
  const savedMerchantSalesUser = useSelector(state => state.merchantSalesUser.ui.merchantSalesUser);
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);
  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.MERCHANT_SALES_USER_EDIT_TAB.INFO
    },
  ]
  const tempMerchantSalesUserEditList = [
  ]
  const content = [
    {
      tab: tempMerchantSalesUserEditList.map((inputSetting, index) => {
        let content;
        switch (inputSetting.type) {
          case constants.TYPE.SELECT:
            content =
              <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break;
          case constants.TYPE.CHECKBOX:
            content =
              <span className="merchant-user-check-box-wrapper">
                <TempCheckBox
                  checkBoxSetting={{
                    className: inputSetting.className,
                    name: inputSetting.name,
                    label: inputSetting.label,
                    value: inputSetting.value,
                  }}
                  handleChange={handleCheckBoxChange} />
              </span>;
            break;
          case constants.TYPE.TITLE:
            content = <div className={`${inputSetting.className + " sub-title"}`}>{getString(lang, inputSetting.value.resource, inputSetting.value.key, theme)}</div>;
            break;
          default:
            content =
              <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
        }
        return <div className="edit-display-wrapper" key={index}>
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: true
    },
  ]

  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      disabled: !savedMerchantSalesUser.username || !savedMerchantSalesUser.password,
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    },
    {
      className: "special-button special-button-margin-left",
      variant: constants.STYLE.VARIANT.CONTAINED,
      icon: <CachedIcon />,
      label: { key: "resetPassword" },
      onClick: () => onResetPassword(null),
    },
  ]

  const handleClose = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
  };
  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.MERCHANT.MERCHANT_SALES_USER);
  };

  function onChangeInput(name, e) {
    if (name === "password") {
      setPassword(e);
    } else {
      dispatch(setMerchantSalesUserValue(name, savedMerchantSalesUser, "", e));
    }
  }

  function handleCheckBoxChange(name, e) {
    let tempMerchantSalesUser = _.cloneDeep(savedMerchantSalesUser);
    let permission = tempMerchantSalesUser[name];
    if (!e) {
      permission["read"] = true;
      permission["write"] = true;
    } else {
      permission["read"] = false;
      permission["write"] = false;
    };
    dispatch(setMerchantSalesUserValue(name, savedMerchantSalesUser, "", permission));
  }

  function onResetPassword(newPassword) {
    setDialog({
      open: true,
      onClose: handleClose,
      dialogContent:
        <TempTextInput
          inputSetting={{
            className: "full-size-input",
            size: constants.SIZE.SMALL,
            label: { resource: resource, key: "newPassword" },
            value: newPassword,
            name: "password",
          }}
          handleChange={onChangeInput}
        />,
      dialogActions: [
        { label: { key: "resetPassword" }, onClick: () => onClickReset(newPassword), icon: <CachedIcon /> },
        { label: { key: "backEdit" }, onClick: handleClose, icon: <ArrowBackIcon /> }
      ]
    })
  }

  function onClickReset(newPassword) {
    put(constants.PATH.MERCHANT.UPDATE_MERCHANT_USER_PASSWORD + "/" + params.id, { password: newPassword }, (payload) => {
      setDialog({
        open: true,
        onClose: onClickBackToEdit,
        dialogContentText: { key: "editSuccess" },
        dialogActions: [
          { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
          { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
        ]
      })
      setClear(false)
    }, undefined, store);
  }

  function onClickSave() {
    put(constants.PATH.MERCHANT.MERCHANT_SALES_USER + "/" + params.id, savedMerchantSalesUser, (payload) => {
      setDialog({
        open: true,
        onClose: onClickBackToEdit,
        dialogContentText: { key: "editSuccess" },
        dialogActions: [
          { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
          { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
        ]
      })
      setClear(false)
    }, undefined, store);
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (password) {
      onResetPassword(password);
    }
  }, [password]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clear === true) {
      getOne(constants.PATH.MERCHANT.MERCHANT_SALES_USER + "/" + params.id, undefined, (payload) => {
        dispatch(setMerchantSalesUser(payload));
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedMerchantSalesUser && savedMerchantSalesUser.username ? savedMerchantSalesUser.username : ""}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="edit"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};