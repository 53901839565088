import { Clear } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField } from '@mui/material'

import variables from '../../../styles/globalVariables.scss'
const { blackColor, subItemColor, middleGrayColor } = variables

export default function MUITextField({
  name,
  label,
  value,
  onChange,
  size = 'small',
  startAdornment,
  endAdornment,
  required = false,
  error = '',
  disabled = false,
  type,
  readOnly = false,
  sx = { flex: '1', width: '100%' },
  maxLength,
  onKeyDown,
  showClearAdornment = false,
  variant = 'outlined',
}) {

  const defaultSx = {
    '& .MuiFormLabel-root': {
      color: `${blackColor}`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${blackColor}`,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'rgba(18, 18, 18, 0.54)',
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: 'error.main',
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'error.main',
    },
  }

  const disabledSx = {
    '& .MuiInputBase-root.Mui-disabled': {
      color: `${blackColor}`,
      marginRight: 5,
      '& fieldset': {
        border: 'none',
      },
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'rgba(18, 18, 18, 0.54)',
      marginRight: 5,
    },
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: `${blackColor}`,
    },
  }

  let newSx = {
    ...sx,
    ...defaultSx,
  }

  if (disabled) {
    newSx = {
      ...disabledSx,
      ...newSx,
    }
  }

  return (
    <TextField
      variant={variant}
      name={name}
      label={label}
      value={value}
      onChange={(e) => {
        if (maxLength && e.target.value.length > maxLength) {
          return
        }
        onChange(e)
      }}
      sx={newSx}
      size={size}
      autoComplete="off"
      type={type || 'text'}
      InputProps={{
        startAdornment: startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>,
        endAdornment: (
          <>
            {endAdornment && !disabled && <InputAdornment position="end">{endAdornment}</InputAdornment>}
            {showClearAdornment && value && (
              <InputAdornment position="end">
                <IconButton onClick={() => onChange({ target: { value: '' } })}>
                  <Clear />
                </IconButton>
              </InputAdornment>
            )}
          </>
        ),
        readOnly: readOnly,
      }}
      InputLabelProps={{ shrink: true }}
      error={!!error}
      helperText={error}
      required={required}
      disabled={disabled}
      onKeyDown={onKeyDown}
    />
  )
}
