import { useSelector, useStore } from 'react-redux'
import { Box, Button, IconButton, Stack } from '@mui/material'
import { useHistory, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { newGetList } from '../../helper/baseFetch'
import MultiLangLabel from '../../Component/common/label/MultiLangLabel'
import MUITable from '../../Component/common/table/MUITable'
import Iconify from '../../Component/common/iconify/Iconify'
import { ICONIFY } from '../../Constants/iconify'
import { API_PATH } from '../../Constants/path'
import { Add } from '@mui/icons-material'
import City from './City'
import { LANG } from '../../Constants/lang'
import SharedDialog from '../../Component/common/dialog/SharedDialog'

export default function CityList({ regionList, getRegionList }) {
  const store = useStore()
  const history = useHistory()
  const lang = useSelector((state) => state.lang.ui.lang)
  const params = useParams()
  const [cityList, setCityList] = useState([])
  const [id, setId] = useState('')

  const [cityListLength, setCityListLength] = useState(0)
  const [cityListPagination, setCityListPagination] = useState({
    sort: 'updateTimestamp',
    order: 'DESC',
    pageSize: 10,
    page: 0,
    filter: JSON.stringify({}),
  })

  const [showCity, setShowCity] = useState(false)
  const [isCreateMode, setIsCreateMode] = useState(false)
  const [showSaveDialog, setShowSaveDialog] = useState(false)

  const columns = [
    {
      field: 'regionCode',
      headerName: 'Region Code',
      width: 100,
    },
    {
      field: 'cityCode',
      headerName: 'City Code',
      width: 100,
    },
    {
      field: 'name',
      headerName: 'Name',
      renderCell: (params) => {
        return <MultiLangLabel nameObj={params.row.name} direction="vertical" gap={0.5} />
      },
      flex: 1,
    },
    {
      field: 'sequence',
      headerName: 'Sequence',
      width: 100,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
    },
    {
      field: '',
      headerName: '',
      width: 50,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => onClickEditCity(params.row.id)}>
            <Iconify iconify={ICONIFY.edit} />
          </IconButton>
        )
      },
    },
  ]

  const getCityList = () => {
    newGetList({
      path: API_PATH.city,
      queryObj: cityListPagination,
      successCallback: (payload) => setCityList(payload),
      store: store,
      getListLength: (listLength) => setCityListLength(listLength),
    })
  }

  useEffect(() => {
    getCityList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityListPagination])

  const onClickCreate = () => {
    setIsCreateMode(true)
    setShowCity(true)
  }

  const onClickEditCity = (id) => {
    setId(id)
    setIsCreateMode(false)
    setShowCity(true)
  }

  return (
    <>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button startIcon={<Add />} variant="contained" onClick={onClickCreate}>
            Add New City
          </Button>
        </Box>
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          <MUITable
            columns={columns}
            rows={cityList}
            pagination={cityListPagination}
            setPagination={setCityListPagination}
            listLength={cityListLength}
          />
        </Box>
      </Box>
      {showCity && (
        <City
          id={id}
          isCreateMode={isCreateMode}
          showCity={showCity}
          setShowCity={setShowCity}
          getCityList={getCityList}
          regionList={regionList}
          getRegionList={getRegionList}
          setShowSaveDialog={setShowSaveDialog}
        />
      )}
      {showSaveDialog && (
        <SharedDialog
          open={showSaveDialog}
          onClose={() => setShowSaveDialog(false)}
          title=""
          content={<Box sx={{ display: 'flex', alignItems: 'center' }}>Successfully saved!</Box>}
          maxWidth="md"
          actions={
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" onClick={() => setShowSaveDialog(false)}>
                OK
              </Button>
            </Box>
          }
        />
      )}
    </>
  )
}
