import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { post } from "../../helper/baseFetch";
import { setArea, setAreaValue } from "../../actions/areaAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempCustomCheckBox } from "../../Component/Custom/TempCustomCheckBox";
import { GlobalDialog } from "../../Component/GlobalDialog";
import AreaValidation from "../../validations/areaValidation";
import { areaValidationConstants } from '../../Constants/validationConstants';

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const AreaCreate = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const [clear, setClear] = useState(false);
  const [areaInputList, setAreaInputList] = useState(_.cloneDeep(constants.AREA_INPUT));
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "Area";
  const savedArea = useSelector(state => state.area.ui.area)
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(AreaValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.AREA_EDIT_TAB.INFO
    }
  ]
  const tempAreaEditList = [
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enName" },
      value: savedArea.enName,
      name: "enName",
      custom: true,
      customName: { key: "nameCustomize" },
      customValue: savedArea.scNameDisable,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: AreaValidation.getErrorMessages(areaValidationConstants.KEY_EN_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcName" },
      value: savedArea.tcName,
      name: "tcName",
      translateName: savedArea.scNameDisable ? ["scName"] : null,
      inputList: areaInputList,
      setInputList: setAreaInputList,
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: AreaValidation.getErrorMessages(areaValidationConstants.KEY_TC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scName" },
      value: savedArea.scName,
      name: "scName",
      inputList: areaInputList,
      setInputList: setAreaInputList,
      isEndOfLine: true,
      disabled: savedArea.scNameDisable,
      required: true,
      helperTextIcon: true,
      helperText: AreaValidation.getErrorMessages(areaValidationConstants.KEY_SC_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "landmarkOrder" },
      value: savedArea.landmarkOrder,
      type: constants.TYPE.NUMBER,
      min: 0,
      name: "landmarkOrder",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: AreaValidation.getErrorMessages(areaValidationConstants.KEY_LANDMARK_ORDER),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    }
  ]
  const content = [
    {
      tab: tempAreaEditList.map((inputSetting, index) => {
        return <div className="edit-display-wrapper" key={index}>
          {inputSetting.custom && <TempCustomCheckBox className="customize-btn" value={inputSetting.customValue} customName={inputSetting.customName} onSelectCheckbox={onSelectCheckbox} />}
          <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: true
    }
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  function onSelectCheckbox(value) {
    dispatch(setAreaValue("scNameDisable", savedArea, "", value));
  }
  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.SHOP.RESTAURANT_AREA);
  };

  function onChangeInput(name, e) {
    if (savedArea.scNameDisable === true && name === "tcName") {
      dispatch(setAreaValue("CHANGE_TC_NAME_AND_SC_NAME", savedArea, "", e));
    } else if (name === "landmarkOrder") {
      dispatch(setAreaValue(name, savedArea, "", Number.isInteger(Number(e)) ? Number(e) : 0));
    } else {
      dispatch(setAreaValue(name, savedArea, "", e));
    }
  }

  function onClickSave() {
    AreaValidation.validateCreateArea(savedArea);
    if (AreaValidation.isValid()) {
      post(constants.PATH.SHOP.RESTAURANT_AREA, savedArea, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: "createSuccess" },
          dialogActions: [
            { label: { key: "backCreate" }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      AreaValidation.resetErrorMessages();
    } else {
      setErrorVersion(AreaValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    dispatch(setArea(constants.AREA_INPUT));
    setClear(false)
    AreaValidation.resetErrorMessages();
  }, [clear === true]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <EditCreateDisplay
        code=""
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="create"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};