import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { getOne, put } from "../../helper/baseFetch";
import { setFolderMap } from "../../actions/folderMapAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { GlobalDialog } from "../../Component/GlobalDialog";
import FolderMapValidation from "../../validations/folderMapValidation";
import { folderMapValidationConstants } from '../../Constants/validationConstants';

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const FolderMapEdit = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const savedFolder = useSelector(state => state.folder.ui.folder)
  const savedFolderMap = useSelector(state => state.folderMap.ui.folderMap)
  const resource = "FolderMap";
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(FolderMapValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.FOLDER_MAP_TAB.INFO
    }
  ]
  const tempFolderMapEditList = [
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "refCode" },
      value: savedFolderMap.refCode,
      name: "refCode",
      isEndOfLine: false,
      disabled: true,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "type" },
      value: savedFolderMap.type,
      name: "type",
      isEndOfLine: true,
      disabled: true,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "refEnName" },
      value: savedFolderMap.refEnName,
      name: "refEnName",
      isEndOfLine: false,
      disabled: true,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "refTcName" },
      value: savedFolderMap.refTcName,
      name: "refTcName",
      isEndOfLine: false,
      disabled: true,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "refScName" },
      value: savedFolderMap.refScName,
      name: "refScName",
      isEndOfLine: true,
      disabled: true,
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "sequence" },
      value: savedFolderMap.sequence,
      name: "sequence",
      type: constants.TYPE.NUMBER,
      isEndOfLine: false,
      disabled: false,
      required: true,
      helperTextIcon: true,
      helperText: FolderMapValidation.getErrorMessages(folderMapValidationConstants.KEY_SEQUENCE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "status" },
      value: savedFolderMap.status,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.FOLDER_MAP.STATUS,
      name: "status",
      isEndOfLine: true,
      disableClearable: true,
      required: true,
      helperTextIcon: true,
      helperText: FolderMapValidation.getErrorMessages(folderMapValidationConstants.KEY_STATUS),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]
  const content = [
    {
      tab: tempFolderMapEditList.map((inputSetting, index) => {
        let content;
        switch (inputSetting.type) {
          case constants.TYPE.SELECT:
            content =
              <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break;
          default:
            content =
              <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
        }
        return <div className="edit-display-wrapper" key={index}>
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: true
    }
  ]
  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.FOLDER.FOLDER + "/" + savedFolder.id + "/" + constants.FOLDER_TAB.FOLDER_MAP_LIST);
  };

  function onChangeInput(name, e) {
    let tempFolderMap = _.cloneDeep(savedFolderMap);
    tempFolderMap[`${name}`] = e;
    if (name === "sequence") {
      tempFolderMap[`${name}`] = Number.isInteger(Number(e)) ? Number(e) : 0;
    } else {
      tempFolderMap[`${name}`] = e;
    }
    dispatch(setFolderMap(tempFolderMap));
  }

  function onClickSave() {
    FolderMapValidation.validateEditFolderMap(savedFolderMap);
    if (FolderMapValidation.isValid()) {
      put(constants.PATH.FOLDER.FOLDER_MAP + "/" + savedFolderMap.id, savedFolderMap, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      FolderMapValidation.resetErrorMessages();
    } else {
      setErrorVersion(FolderMapValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(constants.PATH.FOLDER.FOLDER_MAP + "/" + params.id, undefined, (payload) => {
        dispatch(setFolderMap(payload));
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
    FolderMapValidation.resetErrorMessages();
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedFolderMap && savedFolderMap.id ? savedFolderMap.id : ""}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        mapName={{ resource: "Folder", key: "folderCode" }}
        mapCode={savedFolder.folderCode}
        display="edit"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};